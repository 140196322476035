<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vx-card>

    <h3 class="ml-5">{{ flow_type == "add" ? "Add" : "Edit" }} {{list_name}}</h3>

    <div class="vx-col md:w-1/2 w-full ml-4 mt-5">
      <vs-dropdown>
        <a class="a-icon" href.prevent>
          Content Language
          <vs-icon class="" icon="expand_more"></vs-icon>
        </a>

        <vs-dropdown-menu>

          <vs-dropdown-item @click="currLang='en'">
            {{ $t("English") }}
            <country-flag country="gb" size="normal" />
          </vs-dropdown-item>
          <vs-dropdown-item @click="currLang='zh'">
            {{ $t("Chinese") }}
            <country-flag country="cn" size="normal" />
          </vs-dropdown-item>

        </vs-dropdown-menu>

      </vs-dropdown>

      <span class="text-md align-middle float-right flex">
          <span class="align-middle">{{ getCountryLang }}</span>
          <country-flag class="ml-1" :country="getCountryFlag" size="normal" />
        </span>
    </div>

    <!-- Content Row -->
    <div class="vx-row my-2">
      <div class="vx-col md:w-1/2 w-full">
        <div class="mt-2 centerx w-full vx-row ml-3">
          <label class="text-sm my-4 my-1">{{ $t("modules.questionnaires.Status") }}</label>
          <vs-switch class="my-3 mx-4" color="success" v-model="formData['status']" vs-value="1">
              <span slot="on">{{$t('modules.profile.Active')}}</span>
              <span slot="off">{{$t('modules.profile.InActive')}}</span>
            </vs-switch>
        </div>
      </div>
    </div>

    <div class="vx-row">
      <table class="border-2 border-gray-200  w-full vx-col md:w-1/4">

        <thead class="border-solid border-2 border-gray-200 border-solid">
        <tr>
          <th class="border border-gray-200  py-3" v-if="APIListUrl">{{list_name}}
            <!-- {{ $t("modules.questionnaires."+list_name) }} -->
            </th>
        </tr>
        </thead>

        <tbody class="border-2 border-gray-200">
        <tr>
          <td class="text-center">
            <vs-input type="text" v-model="formData.name" />
          </td>
        </tr>
        </tbody>

      </table>

      <table class="border-2 border-gray-200  w-full vx-col md:w-3/4">

        <thead class="border-2 border-gray-200 border-solid">
        <tr>
          <th class="border border-gray-200 text-center py-4" colspan="0">{{ $t("modules.questionnaires.Code") }}</th>
          <th class="border border-gray-200 text-center py-4 w-1/4">{{ $t("modules.questionnaires.Average Score") }}
          </th>
          <th class="border border-gray-200 text-center py-4" colspan="0">{{ $t("modules.questionnaires.Comment") }}
          </th>
          <th class="border border-gray-200 text-center py-4" colspan="0"></th>
        </tr>
        </thead>

        <tbody class="border-2 border-gray-200 border-solid">

        <tr v-for="(rcItem, rcIndex) in formData.accuracy" :key="rcIndex">
          <!-- bind -->
          <td class="text-center">
            <vs-input type="text" v-model="rcItem.code" />
          </td>
          <td class="text-center">
            {{ `${rcItem.lower_score}% - ${rcItem.upper_score}%` }}
          </td>

          <td class="text-center">
            <vs-textarea rows="1" v-model="rcItem.comment[currLang]"></vs-textarea>
          </td>
          <td class="border-none">
            <vs-button @click="deleteRow(rcItem, rcIndex)">{{ $t("modules.questionnaires.Delete") }}</vs-button>
          </td>

        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <vs-button class="mt-4"
                       @click="addRow" color="primary"
                       icon="add_circle_outline">{{ $t("modules.questionnaires.Add") }}
            </vs-button>
          </td>

        </tr>
        </tbody>

      </table>
      

    </div>
    <!-- Prompt - it ask the % -->


    <!-- Prompt - it ask the Time (Score) -->
    <vs-prompt @accept="acceptTime" @close="closeTime"
               :active.sync="askTime"
               :is-valid="isTimeValid">
      <div class="con-exemple-prompt">
        <h5>Enter the Lower and Upper Score</h5>

        <div class="flex my-6">
          <vs-input placeholder="Lower Score (%)" v-model="tmpLowerTime" class="pr-4" />
          <vs-input placeholder="Upper Score (%)" v-model="tmpUpperTime" class="pl-4" />
        </div>

      </div>
    </vs-prompt>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" :disabled="!validateForm" @click="save">
            {{ $t("modules.questionnaires.Save Changes") }}
          </vs-button>
          <vs-button class="ml-4 mt-2" v-if="reset_btn" type="border" color="warning" @click="reset">
            {{ $t("modules.questionnaires.Reset") }}
          </vs-button>
          <vs-button class="ml-4 mt-2" v-if="cancel_btn" type="border" color="warning" @click="cancel">
            {{ $t("modules.questionnaires.Cancel") }}
          </vs-button>

        </div>
      </div>
    </div>

  </vx-card>
</template>

<script>
// import moduleRole          from '@/store/role/moduleRole.js'
import vSelect from "vue-select";
import axios from "@/axios";
import Vue from "vue";
import _ from "lodash";
import validator from "validator";
import CountryFlag from "vue-country-flag";

Vue.component("country-flag", CountryFlag);

export default {
  components: {
    vSelect,
    CountryFlag
  },
  data() {
    return {
      //%
      formData: {
        name: "",
        accuracy: [],
        status: 0
      },

      status: false,
      askTime: false,
      tmpLowerTime: "",
      tmpUpperTime: "",
      tmpCode: "",
      tmpComment: "",
      isTimeValid: false,
      colorx: "#103767",
      reset_btn: true,
      cancel_btn: false,
      validateForm: true,
      flow_type: "",
      currLang: "en",
      list_name: ""
    };
  },

  created() {

    if (this.$route.params.id) {
      this.flow_type = "edit";
      this.reset_btn = false;
      this.cancel_btn = true;
    } else {
      this.flow_type = "add";
      this.cancel_btn = true;
      this.reset_btn = true;
    }

    if (this.$route.params.id) {
      this.flow_type = "edit";
    } else {
      this.flow_type = "add";
    }

    if (this.flow_type === "edit") {
      this.getAccuracyDataDetail(this.$route.params.id);
      this.id = this.$route.params.id;
    }
  },

  computed: {
    // validateForm() {
    //   return !this.errors.any()
    // },
    rows() {
      const arr = _.map(this.formData.accuracy, (el) => {
        return [`${el.lower_score}% - ${el.upper_score}%`];
      });
      return arr;
    },
    getCountryFlag() {
      if (this.currLang == "en") {
        return "gb";
      }
      if (this.currLang == "zh") {
        return "cn";
      }
    },

    getCountryLang() {
      if (this.currLang == "en") {
        return this.$t("English");
      }
      if (this.currLang == "zh") {
        return this.$t("Chinese");
      }
    },

    APIStoreUpdateUrl() {
      switch (this.$route.name) {
        case "Problem Solving Codes AddEdit":
          return "/problem-solving-codes";
          break;
        case "Question Type AddEdit":
          return "/question-types";
          break;
        case "Question Thinking Code AddEdit":
          return "/question-thinking-codes";
          break;
        default:
          // console.warn('not configured`)
          break;
      }
    },
    APIListUrl() {
      switch (this.$route.name) {
        case "Problem Solving Codes AddEdit":
          this.list_name = 'Problem Solving Code'
          return "/problem-solving-codes";
          break;
        case "Question Type AddEdit":
          this.list_name = 'Question Type'
          return "/question-type";
          break;
        case "Question Thinking Code AddEdit":
          this.list_name = 'Question Thinking Code'
          return "/question-thinking-codes";
          break;
        default:
          // console.warn('not configured`)
          break;
      }
    }

  },

  methods: {

    validateTime() {
      //individual value
      if (!validator.isInt(`${this.tmpLowerTime}`, { min: 0, max: 180 })) {
        this.isTimeValid = false;
        return;
      }
      if (!validator.isInt(`${this.tmpUpperTime}`, { min: 0, max: 180 })) {
        this.isTimeValid = false;
        return;
      }

      //association check
      if (parseFloat(this.tmpLowerTime) >= parseFloat(this.tmpUpperTime)) {
        this.isTimeValid = false;
        return;
      }

      this.isTimeValid = true;
      //this.isTimeValid = this.timeRangeValid()
    },

    deleteRow(rcItem, rcIndex) {
      this.formData.accuracy.splice(rcIndex, 1);
    },

    getJson() {
      console.log(this.formData.accuracy);
    },
    addRow() {
      this.askTime = true;
    },
    closeTime() {

    },
    acceptTime() {
      this.formData.accuracy.push(
        {
          lower_score: this.tmpLowerTime,
          upper_score: this.tmpUpperTime,
          comment: {
            en: "",
            zh: ""
          }
        }
      );
      this.tmpLowerTime = "";
      this.tmpUpperTime = "";
    },

    getRandom() {
      const arr = ["C2", "C1", "B2", "B1", "A2", "A1"];
      return arr[Math.floor(Math.random() * arr.length)];
    },
    cancel() {
      this.$router.push(this.APIListUrl).catch(() => {
      });
    },
    reset() {
      // this.formData= { }
      window.location.reload();
      // this.$router.push("/problem-solving-codes");
    },
    save() {
      if (this.flow_type === "add") {
        axios.post(this.APIStoreUpdateUrl, this.formData)
          .then((response) => {
            if (response.status === 201) {
              this.notifyUI("Created!", this.list_name+" has been created", "success");
              this.$router.push(this.APIListUrl);
            }
          })
          .catch((error) => {
            this.displayErrors(error);
          })
          .then(() => this.$vs.loading.close());
      } else {
        // alert(this.formData.accuracy)
        axios.put(this.APIStoreUpdateUrl + "/" + this.id, this.formData)
          .then((response) => {
            if (response.status === 200) {
              this.$router.push(this.APIListUrl);
              this.notifyUI("Updated!", this.list_name+" has been updated", "success");
            }
          })
          .catch((error) => {
            this.displayErrors(error);
          })
          .then(() => this.$vs.loading.close());
      }
    },

    getAccuracyDataDetail(id) {
      axios.get(this.APIStoreUpdateUrl + "/" + id)
        .then((response) => {
          if (response.status === 200) {          
            this.formData = response.data
          }
        })
        .catch((error) => {
          alert(`Error: ${error}`);
        })
        .then(() => this.$vs.loading.close());
    }


  },

  watch: {
    currLang(val) {

    },
    //time
    tmpLowerTime(val) {
      this.validateTime();
    },
    tmpUpperTime(val) {
      this.validateTime();
    }
  }
};
</script>
