<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vx-card v-if="is_loaded">

    <vs-table :data="pageData.items" :sst="true" ref="table" :key="renderKey"
                      @search="handleSearch" @change-page="handleChangePage" @sort="handleSort"
                      :total="pageData.total"
                      :max-items="pageData.page_size" noDataText="" stripe pagination search>

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">


          <!-- ADD NEW -->
          <div class="dd-actions cursor-pointer mr-4 mb-4" style="margin-bottom: 0px !important">
            <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon"
                    @click="$router.push('/payments/new').catch(() => {})">
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base text-primary">{{$t('modules.payment.Add New')}}</span>
            </button>
          </div>
          <!-- REFRESH -->
          <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon" @click="refreshData()">
            <feather-icon icon="RefreshCwIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base text-primary">{{$t('modules.payment.Refresh')}}</span>
          </button>
        </div>
      </div>

      <template slot="thead">
        <vs-th sort-key="id">{{$t('modules.payment.SNO')}}</vs-th>
        <vs-th sort-key="payment_name">{{$t('modules.payment.Payment Name')}}</vs-th>
        <vs-th sort-key="merchant_name">{{$t('modules.payment.Merchant Name')}}</vs-th>
        <vs-th sort-key="payment_method">{{$t('modules.payment.Payment Method')}}</vs-th>
        <vs-th sort-key="created_at">{{$t('modules.payment.Created At')}}</vs-th>
       
        <vs-th sort-key="status">{{$t('modules.payment.Status')}}</vs-th>
        <vs-th>{{$t('modules.payment.Action')}}</vs-th>
      </template>

      <template>
       <vs-tr :key="indextr" v-for="(tr, indextr) in pageData.items">

          <vs-td :data="pageData.items[indextr].payment_name">
            {{ indextr + 1 }}
          </vs-td>

          <vs-td :data="pageData.items[indextr].payment_name">
            {{ pageData.items[indextr].payment_name }}
          </vs-td>

          <vs-td :data="pageData.items[indextr].merchant_name">
            {{ pageData.items[indextr].merchant_name }}
          </vs-td>

          <vs-td :data="pageData.items[indextr].payment_method">
            {{ pageData.items[indextr].payment_method }}
          </vs-td>

          <vs-td :data="pageData.items[indextr].created_at">
            {{ date_format(pageData.items[indextr].created_at) }}
          </vs-td>

          <vs-td :data="pageData.items[indextr].status">
            <vs-chip v-if="pageData.items[indextr].status==0" :color="'#d9534f'" class="product-order-status">{{$t('modules.profile.InActive')}}</vs-chip>
            <vs-chip v-if="pageData.items[indextr].status==1" :color="'#5cb85c'" class="product-order-status">{{$t('modules.profile.Active')}}</vs-chip>
          </vs-td>

          <vs-td class="whitespace-no-wrap">
            <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current edit_delete"
                          @click.stop="$router.push('/payments/edit/'+tr._id).catch(() => {})" />
            <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current edit_delete"
                          class="ml-2" @click.stop="deletePayment(tr._id)" />
          </vs-td>
        </vs-tr>
      </template>
      <template #footer>
                    <vs-pagination :total="pageData.total_pages" v-model="pageData.page" :key="renderKey" />
      </template>

    </vs-table>

  </vx-card>
</template>

<script>

import Vue from 'vue'
import axios from '@/axios'

export default {
  data () {
    return {
      formData: {},
      displayPrompt: false,
      pageData: {},
      is_loaded: false,
      selected: {},
      keyword: '',
      sort_by: '',
      ordering: '',
      page: 1,
      limit: 3,
      total_results: 0
    }
  },
  components: {},
  computed: {},
  methods: {
    handleSearch (searching) {
      this.page = 1 //reset page if criteria changed
      this.keyword = searching
      this.fetch_payments()
    },
    
    handleSort (col, ord) {
      this.sort_by = col
      this.ordering = ord
      this.fetch_payments()
    },

    handleChangePage (page) {
      this.page = page
      this.fetch_payments()
    },

    refreshData () {
      this.fetch_payments()
    },

    deletePayment (id) {
      this.$vs.loading()
      axios.delete(`/payments/${id}`)
        .then((response) => {
          if (response.status === 200) {
            this.notifyUI('Deleted!', 'Payment has been deleted', 'success')
              this.fetch_payments();
          }
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },

    date_format (date) {
      const d = new Date(date)
      return d.toDateString()
    },

    fetch_payments () {
      this.$vs.loading()

      axios.get('/payments', {
        params: {
          keyword: this.keyword,
          sort_by: this.sort_by,
          ordering: this.ordering,
          page: this.page,
          limit: this.limit
        }
      })
        .then((response) => {
          //this.pageData = response.data
          Vue.set(this, 'pageData', response.data)
          this.is_loaded = true

            
          if (this.page == 1) this.renderKey = Math.random()
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    }
  },
  created () {
    this.fetch_payments()
  },
  beforeDestroy () {

  }
}
</script>
