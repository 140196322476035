<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vx-card>

    <!-- Content Row -->
    <div class="vx-row">
      <div class="vx-col w-full">
      <div class="mt-4">
      <label class="text-sm">{{$t('modules.country.Country Name')}} <span class="text-danger"> * </span></label>
        <vs-input class="w-full mt-4 required" 
                  v-model="formData['name']"
                  v-validate="'required'"/>

        <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first("name") }}</span>
        </div>

        <div class="mt-4">
        <label class="text-sm">{{$t('modules.country.Country ISO')}} <span class="text-danger"> * </span></label>
        <vs-input class="w-full mt-4 required" 
                  v-model="formData['iso']"
                  v-validate="'required'"/>
        <span class="text-danger text-sm" v-show="errors.has('iso')">{{ errors.first("iso") }}</span>
        </div>

        <div class="mt-4">
        <label class="text-sm">{{$t('modules.country.Country Dailing Code')}} <span class="text-danger"> * </span></label>
        <vs-input class="w-full mt-4 required"
                  v-model="formData['dialing_code']"
                  v-validate="'required'"/>

        <span class="text-danger text-sm" v-show="errors.has('dialing_code')">{{ errors.first("dialing_code") }}</span>
        </div>
        <div class="mt-6 centerx w-full vx-row">
          <label class="text-sm my-4 my-1">{{$t('modules.country.Status')}}</label>
          <vs-switch color="success my-3 mx-4" v-model="formData['status']" vs-value="1">
            <span slot="on">{{$t('modules.profile.Active')}}</span>
            <span slot="off">{{$t('modules.profile.InActive')}}</span>
          </vs-switch>
        </div>

      </div>
    </div>


    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" :disabled="!validateForm" @click="save" >{{$t('modules.country.Save Changes')}}</vs-button>
          <vs-button class="ml-4 mt-2" v-if="reset_btn" type="border" color="warning" @click="reset">{{$t('modules.country.Reset')}}</vs-button>
          <vs-button class="ml-4 mt-2" v-if="cancel_btn" type="border" color="warning" @click="cancel">{{$t('modules.student.Cancel')}}
          </vs-button>
        </div>
      </div>
    </div>

  </vx-card>
</template>

<script>
// import moduleRole          from '@/store/role/moduleRole.js'
import vSelect from 'vue-select'
import axios from '@/axios'
import Vue from 'vue'

export default {
  components: {
    vSelect,
  },
  data () {
    return {
      formData: {
        name: '',
        status: '0',
      },
      colorx:'#103767',
      reset_btn: true,
      cancel_btn: false,
      validateForm: true,
      flow_type: ''
    }
  },

  created () {
    if (this.$route.params.id) this.flow_type = 'edit'
    else this.flow_type = 'add'
       this.cancel_btn= true

    if (this.flow_type === 'edit') {
      this.getCountryDetail(this.$route.params.id)
      this.id = this.$route.params.id
      this.reset_btn= false
       this.cancel_btn= true
    }
  },

  computed: {
    validateForm () {
      return !this.errors.any() && this.name !== '' && this.iso !== '' && this.dialing_code!==''
    },
  },

  methods: {
    cancel () {
      this.$router.push('/country').catch(() => { })
    },
    reset () {
      //this.$router.push('/country')
       this.formData=
        {
              

         }
    },
    save () {
      if (this.flow_type === 'add') {
        axios.post('/countries', this.formData)
          .then((response) => {
            if (response.status === 201) {
              this.notifyUI('Created!', 'Country has been created', 'success')
              this.$router.push('/country')
            }
          })
          .catch((error) => {
            //alert(`Error: ${error}`)
             const xhrResponse = JSON.parse(error.request.response) || {errors: [{'field':'*', 'suggestion':'Some error occurred'}]}
          //Check if error Array available with any items
          if (this.hasErrorItems(xhrResponse)) {
            this.getErrorItems(xhrResponse).forEach((item) => {
              // {item.field} -- gives which input field has problem
              // {item.suggestion} -- gives how to fix problem
              this.$vs.notify({
                title: item.field?item.field: 'Alert',
                text: item.suggestion,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })

              // if the error relates to any input field
              if (item.field) {
                //Add error to custom v-validator error
                this.errors.add({
                  field: item.field,
                  msg: item.suggestion
                })
              }

            })
          }

          })
          .then(() => this.$vs.loading.close())
      } else {
        axios.put(`/countries/${this.id}`, this.formData)
          .then((response) => {
            if (response.status === 200) {
              this.$router.push('/country')
              this.notifyUI('Updated!', 'Country has been updated', 'success')
            }
          })
          .catch((error) => {
            //alert(`Error: ${error}`)
             const xhrResponse = JSON.parse(error.request.response) || {errors: [{'field':'*', 'suggestion':'Some error occurred'}]}
          //Check if error Array available with any items
          if (this.hasErrorItems(xhrResponse)) {
            this.getErrorItems(xhrResponse).forEach((item) => {
              // {item.field} -- gives which input field has problem
              // {item.suggestion} -- gives how to fix problem
              this.$vs.notify({
                title: item.field?item.field: 'Alert',
                text: item.suggestion,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })

              // if the error relates to any input field
              if (item.field) {
                //Add error to custom v-validator error
                this.errors.add({
                  field: item.field,
                  msg: item.suggestion
                })
              }

            })
          }
          })
          .then(() => this.$vs.loading.close())
      }
    },

    getCountryDetail (id) {
      axios.get(`/countries/${id}`)
        .then((response) => {
          if (response.status === 200) {
            this.formData = response.data
          }
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },

  }
}
</script>
