<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vx-card>

    <h3> {{ flow_type == "add" ? $t('modules.student.Add') : $t('modules.student.Edit') }} {{$t('modules.student.Student')}}</h3>

    <!-- Content Row -->
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <div class="mt-6">
          <label class="text-sm">{{ $t("modules.student.First Name") }} <span class="text-danger"> * </span></label>
          <vs-input class="w-full mt-4 required"
                    v-model="formData['first_name']"
                    v-validate="'required|max:100'" />
          <span class="text-danger text-sm" v-show="errors.has('first_name')">{{ errors.first("first_name") }}</span>
        </div>

        <div class="mt-6">
          <label class="text-sm">{{ $t("modules.student.Date of Birth") }}<span class="text-danger"> * </span></label>
          <datepicker class="w-full mt-4 required" :format="dateFormat" v-model="formData['dob']"
                      label="Date of Birth" :language="getDateFormatLocale($i18n.locale)"></datepicker>
          <span class="text-danger text-sm" v-show="errors.has('dob')">{{ errors.first("dob") }}</span>
        </div>

        <div class="mt-6">
          <label class="text-sm">{{ $t("modules.student.Package") }}<span class="text-danger"> * </span></label>
          <vs-select class="w-full mt-4 required"
                     v-model="formData['package_id']" placeholder="Select Package" v-validate="'required'">
            <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in computedPackages" />
          </vs-select>
          <span class="text-danger text-sm" v-show="errors.has('package_id')">{{ errors.first("package_id") }}</span>
        </div>

        <div class="mt-6">
          <label class="text-sm">{{ $t("modules.student.Student phone") }}<span class="text-danger"> * </span></label>
          <vs-input class="w-full mt-4 required"
                    v-model="formData['mobile']"
                    v-validate="'required'" />
          <span class="text-danger text-sm" v-show="errors.has('mobile')">{{ errors.first("mobile") }}</span>
        </div>

        <div class="mt-4">
          <label class="text-sm">{{ $t("modules.student.Address") }} <span class="text-danger"> * </span></label>
          <vs-textarea rows="4" v-model="formData['address']" :placeholder="$t('modules.student.Enter Your Address')" />
          <span class="text-danger text-sm" v-show="errors.has('address')">{{ errors.first("address") }}</span>
        </div>



      </div>

      <div class="vx-col md:w-1/2 w-full">

        <div class="mt-6">
          <label class="text-sm">{{ $t("modules.student.Last Name") }}<span class="text-danger"> * </span></label>
          <vs-input class="w-full mt-4 required"
                    v-model="formData['last_name']"
                    v-validate="'required|max:100'" />
          <span class="text-danger text-sm" v-show="errors.has('last_name')">{{ errors.first("last_name") }}</span>
        </div>


        <div class="mt-6">
          <label class="text-sm">{{ $t("modules.student.Gender") }}<span class="text-danger"> * </span></label>
          <vs-select class="w-full mt-4 required" placeholder="Select Gender" v-model="formData['gender']">
            <vs-select-item value="female" text="Female" />
            <vs-select-item value="male" text="Male" />
            <span class="text-danger text-sm" v-show="errors.has('gender')">{{ errors.first("gender") }}</span>
          </vs-select>
        </div>

        <div class="mt-6">
          <label class="text-sm">{{ $t("modules.student.Grade") }}<span class="text-danger"> * </span></label>
          <vs-select class="w-full mt-4 required" placeholder="Select Grade" v-model="formData['grade']">
            <vs-select-item value="elementary" text="Elementary" />
            <vs-select-item value="intermediate" text="Intermediate" />
            <vs-select-item value="higher" text="Higher" />
          </vs-select>
          <span class="text-danger text-sm" v-show="errors.has('grade')">{{ errors.first("grade") }}</span>
        </div>

        <div class="mt-6">
          <label class="text-sm">{{ $t("modules.student.Email") }}<span class="text-danger">  </span></label>
          <vs-input class="w-full mt-4 required" v-model="formData['email']" v-validate="'required|email|min:3'" />
          <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first("email") }}</span>
        </div>



      </div>
    </div>




    <div class="vx-col md:w-1/2 w-full">

        <div class="vx-row mt-12">
            <label class="text-sm ml-4" style="margin-left: 4px;">{{ $t("modules.student.Status") }}</label>
            <vs-switch class="ml-4" color="success" v-model="formData['status']">
              <span slot="on">{{$t('modules.profile.Active')}}</span>
              <span slot="off">{{$t('modules.profile.InActive')}}</span>
            </vs-switch>
        </div>


        <div class="vx-row mt-12">
            <label class="ml-4 mb-2 block w-full">{{$t('modules.profile.Profile Image')}}</label>
            <input class="ml-4 float-left h-6" type="file" accept="image/*" @change="handleImage" ref="image">
            <vs-image class="shadow mt-6 block" :src="previewImage" />
        </div>

    </div>



    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <!-- <vs-button class="ml-auto mt-2" :disabled="!validateForm"  @click="save">
            {{ $t("modules.student.Save Changes") }}
          </vs-button> -->
           <vs-button class="ml-auto mt-2" @click="save">
            {{ $t("modules.student.Save Changes") }}
          </vs-button>
          <vs-button class="ml-4 mt-2" v-if="reset_btn" type="border" color="warning" @click="reset">
            {{ $t("modules.student.Reset") }}
          </vs-button>
          <vs-button class="ml-4 mt-2" v-if="cancel_btn" type="border" color="warning" @click="cancel">
            {{ $t("modules.profile.Cancel") }}
          </vs-button>
        </div>
      </div>
    </div>

  </vx-card>
</template>

<script>
// import moduleRole          from '@/store/role/moduleRole.js'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import axios from '@/axios'
import Datepicker from 'vuejs-datepicker'

export default {
  components: {
    vSelect,
    flatPickr,
    Datepicker
  },
  data () {
    return {
      flow_type: '',
      //id: 0,
      formData: {
        first_name: '',
        last_name: '',
        full_name: '',
        email: '',
        mobile: '',
        grade: '',
        gender: '',
        package_id: '',
        // student_id: "",
        address: '',
        dob: '',
        status: '1'
      },

      reset_btn: true,
      cancel_btn: false,
      status: false,
      previewImage: '',
      packages: ''
    }
  },

  created () {

    if (this.$route.params.id) {
      this.flow_type = 'edit'
      this.reset_btn = false
      this.cancel_btn = true
    } else {
      this.flow_type = 'add'
      this.cancel_btn = true
    }

    if (this.flow_type === 'edit') {
      this.getStudentDetail(this.$route.params.id)
      this.id = this.$route.params.id
    }
    this.getPackages()

  },

  computed: {
    validateForm () {
      return !this.errors.any()
    },
    computedPackages() {
      return this.packages
    }

  },

  methods: {
    cancel () {
      this.$router.push('/student').catch(() => {
      })
    },
    reset () {

      this.formData =
        {
          grade: '',
          gender: '',
          package_id: ''
        }


      //this.$router.push("/student");
    },
    save () {
      const form = this.processForm()

      if (this.flow_type === 'add') {
        axios.post('/students', form)
          .then((response) => {
            if (response.status === 200) {
              this.notifyUI('Created!', 'Student has been created', 'success')
              this.$router.push('/student')
            }
          })
          .catch((error) => {
            this.displayErrors(error)
          })
          .then(() => this.$vs.loading.close())
      } else {
        axios.put(`/students/${this.id}`, form)
          .then((response) => {
            if (response.status === 200) {
              this.notifyUI('Updated!', 'Student updated sucessfully', 'success')
              this.$router.push('/student')
            }
          })
          .catch((error) => {
            this.displayErrors(error)
          })
          .then(() => this.$vs.loading.close())
      }
    },

    getStudentDetail (id) {
      axios.get(`/students/${id}`)
        .then((response) => {
          if (response.status === 200) {
            const form = {}
            form.name = response.data.name
            form.status = response.data.status
            form._id = response.data._id
            //console.log( response.data.name)
            this.formData = response.data
            this.previewImage = this.formData.image
          }
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },
    handleImage (e) {
      const image = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = e => {
        this.previewImage = e.target.result
        console.log(this.previewImage)
      }
    },

    customDateFormatter (date) {
      return moment(date).format('dd MM YYYY')
    },
    getPackages () {
      axios.get('/packages', { params: { limit: 1000 } })
        .then((response) => {
          if (response.status === 200) {
            //need format [{text: 'Square', value: 1}, ...],
            this.packages = response.data.items.map(item => {
              return { text: item.name['en'], value: item._id }
            })
          }
        })
        .catch((error) => {
          //alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },
    processForm () {
      const form = new FormData()
      form.append('full_name', `${this.formData['first_name']  } ${  this.formData['last_name']}`) //manually append name
      form.append('last_name', this.formData['last_name']) //manually append name
      form.append('first_name', this.formData['first_name']) //manually append name
      //form.append("student_id", this.formData["student_id"]); //manually append name
      form.append('dob', this.formData['dob']) //manually append name
      form.append('gender', this.formData['gender']) //manually append name
      form.append('grade', this.formData['grade']) //manually append name
      form.append('email', this.formData['email']) //manually append email
      form.append('package_id', this.formData['package_id']) //manually append email
      form.append('mobile', this.formData['mobile']) //manually append
      form.append('address', this.formData['address']) //manually append

      form.append('status', this.formData['status'] ? 1 : 0) //manually append status , plugin bug
      if (this.$refs.image.files.length) {
        form.append('image', this.$refs.image.files[0])
      } //manually append file obj
      return form
    }

  }
}
</script>
