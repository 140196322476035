<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div>
    <vx-card v-if="is_loaded">

       <vs-table :data="pageData.items" :sst="true" ref="table" :key="renderKey"
                      @search="handleSearch" @change-page="handleChangePage" @sort="handleSort"
                      :total="pageData.total"
                      :max-items="pageData.page_size" noDataText="" stripe pagination search>

        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

          <div class="flex flex-wrap-reverse items-center data-list-btn-container">

            <!-- ACTION - DROPDOWN -->
           <!--  <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4 mb-4"
                         style="margin-bottom: 0px !important">

              <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon">
                <span class="mr-2">Actions</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </button>

              <vs-dropdown-menu>

                <vs-dropdown-item>
                        <span class="flex items-center">
                          <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                          <span>Delete</span>
                        </span>
                </vs-dropdown-item>

                <vs-dropdown-item>
                        <span class="flex items-center">
                          <feather-icon icon="ArchiveIcon" svgClasses="h-4 w-4" class="mr-2" />
                          <span>Archive</span>
                        </span>
                </vs-dropdown-item>

                <vs-dropdown-item>
                        <span class="flex items-center">
                          <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                          <span>Print</span>
                        </span>
                </vs-dropdown-item>

                <vs-dropdown-item>
                        <span class="flex items-center">
                          <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                          <span>Another Action</span>
                        </span>
                </vs-dropdown-item>

              </vs-dropdown-menu>
            </vs-dropdown> -->

            <!-- ADD NEW -->
            <div class="dd-actions cursor-pointer mr-4 mb-4" style="margin-bottom: 0px !important">
              <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon"
                      @click="$router.push('/user/new').catch(() => {})">
                <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                <span class="ml-2 text-base text-primary">{{$t('modules.role.Add New')}}</span>
              </button>
            </div>
            <!-- REFRESH -->
            <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon" @click="refreshData()">
              <feather-icon icon="RefreshCwIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base text-primary">{{$t('modules.role.Refresh')}}</span>
            </button>
          </div>
        </div>

        <template slot="thead">
          <vs-th sort-key="id">{{$t('modules.role.SNO')}}</vs-th>
          <vs-th sort-key="title">{{$t('modules.role.Role User')}}</vs-th>
          <vs-th sort-key="title">{{$t('modules.role.Roles')}}</vs-th>
          <vs-th>{{$t('modules.role.Action')}}</vs-th>
        </template>

        <template>
          <vs-tr :key="indextr" v-for="(tr, indextr) in pageData.items">

            <vs-td :data="pageData.items[indextr].name">
              {{ indextr + 1 }}
            </vs-td>


            <vs-td :data="pageData.items[indextr].name">
              {{ tr.name }}
            </vs-td>


            <vs-td :data="pageData.items[indextr].role_list">
              {{ tr.role_list }}
            </vs-td>


            <vs-td class="whitespace-no-wrap">
              <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current edit_delete"
                            @click.stop="showPopup(tr)" />
              <!--<feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current edit_delete"
                            class="ml-2" @click.stop="deleteUser(tr._id)" />-->
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
                    <vs-pagination :total="pageData.total_pages" v-model="pageData.page" :key="renderKey" />
        </template>

      </vs-table>

    </vx-card>

    <div class="centerx">
      <vs-popup class="holamundo"  title="User Roles" :active.sync="popupActivo">
        <div class="">

          <h2>Assigned Roles to <b>{{currentRoleUser.name}}</b></h2>

          <ul class="centerx my-4">
            <li v-for="(role, index) in userAssignedRoles" :key="index" class="my-4">
              <vs-checkbox v-model="role.is_checked">{{role.name}}</vs-checkbox>
            </li>
          </ul>

          <em class="mt-20 text-sm block">*Effective permission is calculated from aggregation of those roles</em>
          <div class="float-right mt-4">
            <vs-button color="primary" type="border" @click="save">Save</vs-button>
            <vs-button color="danger" type="border" class="ml-4" @click="cancel">Cancel</vs-button>
          </div>

        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>


import axios from '@/axios'
import _ from 'lodash'
import Vue from 'vue'

export default {
  data () {
    return {
      formData: {},
      displayPrompt: false,
      pageData: {},
      is_loaded: false,
      selected: {},
      keyword: '',
      sort_by: '',
      ordering: '',
      page: 1,
      limit: 3,
      total_results: 0,

      popupActivo: false,
      userAssignedRoles: [],
      allRoles: [],
      currentRoleUser: []
    }
  },
  components: {},
  computed: {},
  methods: {
    handleSearch (searching) {
      this.page = 1 //reset page if criteria changed
      this.keyword = searching
      this.fetch_users()
    },

    handleSort (col, ord) {
      this.sort_by = col
      this.ordering = ord
      this.fetch_users()
    },

    handleChangePage (page) {
      this.page = page
      this.fetch_users()
    },

    refreshData () {
      this.fetch_users()
    },

    deleteUser (id) {
      this.$vs.loading()
      axios.post(`/users/delete/${id}`)
        .then((response) => {
          if (response.status === 200) {
            this.$router.go(0)
          }
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },

    date_format (date) {
      const d = new Date(date)
      return d.toDateString()
    },

    fetch_users () {
      this.$vs.loading()

      axios.get('/role-assignments', {
        params: {
          keyword: this.keyword,
          sort_by: this.sort_by,
          ordering: this.ordering,
          page: this.page,
          limit: this.limit
        }
      })
        .then((response) => {

          const list = response.data

          list.items = list.items.map((item) => {
            if (item.roles && item.roles.length) {
              item.role_list = _.map(item.roles, 'name').join(', ')
              return item
            } else {
              item.role_list = '-'
              return item
            }
          })

          this.pageData = list

          this.is_loaded = true
          this.total_results = response.total
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },

    getAllRoles () {
      axios.get('/roles', {
        params: {
          limit: 1000
        }
      })
        .then((response) => {
          this.allRoles = response.data.items
        })
        .catch((e) => {
          alert(`Error: ${e}`)
        })
    },

    showPopup (roleuser) {
      const arrRoleIds = _.map(roleuser.roles, '_id')
      const tmp = []
      this.allRoles.forEach((item) => {
        if (arrRoleIds.includes(item._id)) {
          item.is_checked = true
        } else {
          item.is_checked = false
        }
        tmp.push(item)
      })

      this.userAssignedRoles = tmp
      this.currentRoleUser = roleuser
      this.popupActivo = true
    },

    cancel () {
      this.popupActivo = false
    },

    save () {
      const roles = _.map(_.filter(this.userAssignedRoles, {is_checked: true}), '_id')
      this.$vs.loading()
      axios.put(`/role-assignments/${this.currentRoleUser._id}`, {
        roles
      })
        .then((response) => {
          if (response.status === 200) {
            this.notifyUI('Updated', 'User has been assigned roles', 'success')
            this.$router.go(0)
          }
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    }

  },
  created () {
    this.fetch_users()
    this.getAllRoles()
  },
  beforeDestroy () {

  }
}
</script>
