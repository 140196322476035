
import navbarSearchAndPinList from '@/layouts/components/navbar/navbarSearchAndPinList'
import themeConfig, { colors } from '@/../themeConfig.js'

const userDefaults = {
  id           : '',
  first_name   : '',
  last_name    : '',
  display_name : '',
  email        : '',
  image        : '',
  phone        : '',
  verified     : ''

}

const userInfoLocalStorage = JSON.parse(localStorage.getItem('admin_info')) || {}

const getUserInfo = () => {
  const userInfo = {}
  // Update property in user
  Object.keys(userDefaults).forEach((key) => {
    // If property is defined in localStorage => Use that
    userInfo[key] = userInfoLocalStorage[key] ?  userInfoLocalStorage[key] : userDefaults[key]
  })

  userInfo['display_name']  = userInfoLocalStorage['first_name'] ? userInfoLocalStorage['first_name'] : 'User'
  userInfo['user_id']       = userInfoLocalStorage['id']
  userInfo['image']         = userInfoLocalStorage['image'] ? userInfoLocalStorage['image'] : '/avatar.png'
  return userInfo
}


// Check if device is touch device
// This is used to remove perfect scrollbar from touch devices
const is_touch_device = () => {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
  const mq = function (query) {
    return window.matchMedia(query).matches
  }

  if ('ontouchstart' in window || window.DocumentTouch) {
    return true
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('')
  return mq(query)
}




// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
  appUser                 : getUserInfo(),
  token                   : localStorage['admin_token'],
  logged_in               : localStorage['admin_logged_in'],
  authorization           : localStorage['admin_authorization']?JSON.parse(localStorage['admin_authorization']):{},
  bodyOverlay             : false,
  isVerticalNavMenuActive : true,
  is_touch_device         : is_touch_device(),
  mainLayoutType          : themeConfig.mainLayoutType || 'vertical',
  navbarSearchAndPinList,
  reduceButton            : themeConfig.sidebarCollapsed,
  verticalNavMenuWidth    : 'default',
  verticalNavMenuItemsMin : false,
  scrollY                 : 0,
  starredPages            : navbarSearchAndPinList['pages'].data.filter((page) => page.is_bookmarked),
  theme                   : themeConfig.theme || 'light',
  themePrimaryColor       : colors.primary,
  // Can be used to get current window with
  // Note: Above breakpoint state is for internal use of sidebar & navbar component
  windowWidth: null,
  ui_language: localStorage['language']?localStorage['language']:'en'
}


export default state
