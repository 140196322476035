<template>
    <div>
      <feather-icon icon="EditIcon" v-if="update_permission == true" svgClasses="w-5 h-5 hover:text-primary stroke-current edit_delete" @click.stop="editMedia()" />
      <feather-icon icon="TrashIcon" v-if="delete_permission == true" svgClasses="w-5 h-5 hover:text-danger stroke-current edit_delete" class="ml-2" @click.stop="deleteMedia()" />
    </div>
</template>

<script>
export default {
   data () {
    return {
      delete_permission : this.$store.state.media_permission.delete,
      update_permission : this.$store.state.media_permission.update,
    }
   },
  name: 'CellRendererActions',
  methods: {
    editMedia () {
      this.$router.push('/media/edit/'+this.params.data.media_id).catch(() => {})
    },
    deleteMedia () {
      this.$vs.loading()
      this.$store.dispatch('media/deleteMedia', this.params.data.media_id).then(() => { 
        this.$vs.loading.close()
        this.$vs.notify({
          color: 'danger',
          title: 'Media Deleted',
          text: 'Media Removed Successfully...!!!'
        })
       })
    },
  }
}
</script>
