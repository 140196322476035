<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vx-card>

    <vs-table :sst="true" ref="table" pagination search striped :data="pageData.items"
              @search="handleSearch" @change-page="handleChangePage" @sort="handleSort" v-model="selected"
              :total="pageData.total"
              :max-items="pageData.page_size" noDataText="" :key="renderKey">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">

        
          <!-- <div class="dd-actions cursor-pointer mr-4 mb-4" style="margin-bottom: 0px !important">
            <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon"
                   @click="$router.push('/video/new').catch(() => {})">
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base text-primary">Add Video</span>
            </button>
          </div> -->
          <!-- REFRESH -->
          <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon" @click="refreshData()">
            <feather-icon icon="RefreshCwIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base text-primary">Refresh</span>
          </button>
        </div>
      </div>

      <template slot="thead">
        <vs-th sort-key="id">Sl No</vs-th>
        <vs-th sort-key="title">Video</vs-th>
        <vs-th sort-key="status">Visibility</vs-th>
        <vs-th sort-key="views">Views</vs-th>
        <vs-th sort-key="created_at">Created At</vs-th>
         <vs-th sort-key="is_approved">Approval Status</vs-th>
        <vs-th>Action</vs-th>
      </template>

      <template>
        <vs-tr :key="indextr" v-for="(tr, indextr) in pageData.items">

          <vs-td :data="pageData.items[indextr].id">
            {{ indextr + 1 }}
          </vs-td>

          <vs-td :data="pageData.items[indextr].title" style="word-break: break-all;">
            {{ pageData.items[indextr].title }}
          </vs-td>

          <vs-td :data="pageData.items[indextr].status">
            {{ pageData.items[indextr].status }}
          </vs-td>

           <vs-td :data="pageData.items[indextr].views">
            {{ pageData.items[indextr].views }}
          </vs-td>

          <vs-td :data="pageData.items[indextr].createdAt">
            {{ date_format(pageData.items[indextr].createdAt) }}
          </vs-td>

          <vs-td :data="pageData.items[indextr].is_approved">
            <vs-chip v-if="pageData.items[indextr].is_approved==0" :color="'#d9534f'" class="product-order-status">Not Approved</vs-chip>
            <vs-chip v-if="pageData.items[indextr].is_approved==1" :color="'#5cb85c'" class="product-order-status">Approved</vs-chip>
          </vs-td>


          <vs-td class="whitespace-no-wrap">
            <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current edit_delete"
                            @click.stop="$router.push('/video/edit/'+tr._id).catch(() => {})" />
            <!-- <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current edit_delete" class="ml-2" @click.stop="deleteBlog(tr._id)" /> -->
          </vs-td>
        </vs-tr>
      </template>
      <template #footer>
        <vs-pagination v-model="page" :total="pageData.total_pages" :key="renderKey" />
      </template>

    </vs-table>
 <upload-video-modal
      :open-dialog="dialog"
      v-on:closeDialog="dialog = false"
    />
  </vx-card>
  

</template>


<script>
import moment from "moment";
import Vue from 'vue'
import axios from '@/axios'
import { getSupportedLocales } from "@/i18n/i18n"
import { mapGetters } from 'vuex'

import UploadVideoModal from '@/components/UploadVideoModal'
export default {
 data () {
    const locales = getSupportedLocales().map((el) => {
      return { text: el.name, value: el.code }
    })
    return {
      renderKey: 2,
      formData: {},
      displayPrompt: false,
      pageData: {},
      is_loaded: false,
      selected: {},
      keyword: '',
      sort_by: '',
      ordering: '',
      page: 1,
      limit: 10,
      total_results: 0,
      locales,
        dialog: false,
    settingsDialog: false
    }
  },
computed: {
    ...mapGetters(['currentUser', 'getUrl'])
  },
  methods: {

    handleSearch (searching) {
      this.page = 1
      this.keyword = searching
      this.getVideos()
    },

    handleSort (col, ord) {
      this.sort_by = col
      this.ordering = ord
      this.getVideos()
    },

    handleChangePage (page) {
      this.page = page
      this.getVideos()
    },

    refreshData () {
      this.getVideos()
    },


     getVideos() {
       this.$vs.loading()
      axios.get('/videos', {
        params: {
          keyword: this.keyword,
          sort_by: this.sort_by,
          ordering: this.ordering,
          page: this.page,
          limit: this.limit
        }
      })
        .then((response) => {
          // this.pageData = response.data
          // this.is_loaded = true
          // this.total_results = response.total
          console.log(response.data.items)

          this.videos = response.data.items;
          Vue.set(this, 'pageData', response.data)
          this.is_loaded = true
          if (this.page == 1) this.renderKey = Math.random()
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())


    },

       editItem(item) {
      this.$router.push({ name: `Detail`, params: { id: item.id } });
    },

     modal() {
       //alert('dattta')
      this.dialog = false
    },
  
    
  },

  components: {
    UploadVideoModal,
    
  },

  mounted() {
    this.drawer = this.$vuetify.breakpoint.mdAndDown ? false : true
  },
  created() {
    this.getVideos();
  },
  
  beforeRouteUpdate(to, from, next) {
    this.getVideos();
    next();
  },
};
</script>

<style lang="scss">
// .card,
// #tab .v-tabs-bar {
//   background: #f9f9f9 !important;
// }
</style>