<template>

<div>

<!-- display -->
<div v-for="(school,schoolIndex) in schools">
  <h4>[School] : {{ school.school_name}}</h4>

  <div v-for="(student,studentIndex) in school.students">
    <div class="card">
      <div>{{student.id}}</div>
      <div>{{student.name}}</div>
      <div>{{student.grade}}</div>
    </div>
    <hr>
    <br>
  </div>

</div>



  <!-- write -->

  <div v-for="(school,schoolIndex) in schools">
    <div><input type="text" v-model="school.school_name"></div>

    <div v-for="(student,studentIndex) in school.students">
      <div class="card">
        <div><input type="text" v-model="student.id"></div>
        <div><input type="text" v-model="student.name"></div>
        <div><input type="text" v-model="school.students[studentIndex].grade"></div>
        <button @click="deleteStudent(schoolIndex,studentIndex)">Delete</button>
      </div>

      <hr>
      <br>
    </div>

    <button @click="addStudent(schoolIndex)">Add</button>

  </div>



  <button @click="showJson" class="btn btn-primary bg-green-100 w-100 h-100">Show</button>
</div>

</template>


<script>
export default {
  name: 'Demo',
  data(){
    return {
      msg: 'hello',
      schools:[
        //school1
        {
          school_id: '001',
          school_name: 'School-1',
          students: []
        },

        //school2
        {
          school_id: '002',
          school_name: 'School-2',
          students: [{
            "id": 232,
            "name": "Vinay",
            "grade": 11
          },
          {
            "id": 112,
            "name": "Rohit",
            "grade": 10
          }]
        }

      ]

    }
  },

  created(){
    //alert(this.msg)
  },

  methods:{
    addStudent(schIndex){
      this.schools[schIndex].students.push({
        "id": 0,
        "name": "",
        "grade": 0
      })
    },

    deleteStudent(schIndex, stuIndex){
      this.schools[schIndex].students.splice(stuIndex, 1);
    },

    showJson(){
      alert(JSON.stringify(this.schools))
    }
  }
}

/*
{

  "students":[
    {
      "id": 232,
      "name": "Jacob",
      "grade": 2
    },
    {
      "id": 2,
      "name": "Jhon",
      "grade": 4
    }
  ]

}

 */
</script>
