<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>

    <vx-card>

        <!-- <div class="vx-row mb-10">

            <div class="vx-col md:w-1/2 w-full">
                <vs-dropdown>
                    <a class="a-icon" href.prevent>
                        {{ $t("modules.cms.Content Language") }}
                        <vs-icon class="" icon="expand_more"></vs-icon>
                    </a>

                    <vs-dropdown-menu>

                        <vs-dropdown-item @click="currLang='en'">
                            {{ $t("modules.cms.English") }}
                            <country-flag country="gb" size="normal" />
                        </vs-dropdown-item>
                        <vs-dropdown-item @click="currLang='zh'">
                            {{ $t("modules.cms.Chinese") }}
                            <country-flag country="cn" size="normal" />
                        </vs-dropdown-item>

                    </vs-dropdown-menu>

                </vs-dropdown>

                <span class="text-md align-middle float-right flex">
          <span class="align-middle">{{ getCountryLang }}</span>
          <country-flag class="ml-1" :country="getCountryFlag" size="normal" />
        </span>
            </div>


            <div class="vx-col md:w-1/2 w-full">

            </div>

        </div> -->

        <!-- Content Row -->
        <div class="vx-row">
            <div class="vx-col w-full">

                <div class="mt-4">
                    <label class="text-sm">{{ $t("modules.cms.CMS Name") }} <span class="text-danger"> * </span></label>

                    <vs-input class="w-full mt-4 required" v-model="formData['name']"
                              v-validate="'required'" name="name" />

                    <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first("name") }}</span>
                </div>


                <div class="mt-4">
                    <label class="text-sm">{{ $t("modules.cms.URL Slug") }} <span class="text-danger"> * </span></label>

                    <vs-input class="w-full mt-4 required" v-model="formData['slug']" v-validate="'required'"
                              name="slug" />

                    <span class="text-danger text-sm" v-show="errors.has('slug')">{{ errors.first("slug") }}</span>
                </div>


                <div class="mt-5">
                    <label class="text-sm">{{ $t("modules.cms.CMS Content") }} <span
                        class="text-danger"> * </span></label>

                    <vs-textarea v-model="formData['content']" v-validate="'required'"
                                 name="content" />
                    <span class="text-danger text-sm" v-show="errors.has('content')">{{ errors.first("content")
                        }}</span>
                </div>

                <div class="mt-6 w-full flex">
                    <label class="text-sm my-4 my-1">{{ $t("modules.cms.Status") }}</label>
                    <vs-switch color="success my-3 mx-4" v-model="formData['status']">
                        <span slot="on">{{ $t("modules.profile.Active") }}</span>
                        <span slot="off">{{ $t("modules.profile.InActive") }}</span>
                    </vs-switch>
                </div>

            </div>
        </div>


        <!-- Save & Reset Button -->
        <div class="vx-row">
            <div class="vx-col w-full">
                <div class="mt-8 flex flex-wrap items-center justify-end">
                    <vs-button class="ml-auto mt-2" @click="save">
                        {{ $t("modules.grade.Save Changes") }}
                    </vs-button>
                    <vs-button class="ml-4 mt-2" v-if="reset_btn" type="border" color="warning" @click="reset">
                        {{ $t("modules.grade.Reset") }}
                    </vs-button>
                    <vs-button class="ml-4 mt-2" v-if="cancel_btn" type="border" color="warning" @click="cancel">
                        {{ $t("modules.profile.Cancel") }}
                    </vs-button>
                </div>
            </div>
        </div>

    </vx-card>
</template>

<script>
// import moduleRole          from '@/store/role/moduleRole.js'
import vSelect from 'vue-select'
import axios from '@/axios'
import Vue from 'vue'
import CountryFlag from 'vue-country-flag'

Vue.component('country-flag', CountryFlag)

export default {
  components: {
    vSelect, CountryFlag
  },
  data () {
    return {
      formData: {
          name: '',
          slug: '',
          content: '',
          status:''

        // translations: {
        //   // fill obj array with default values
        //   en: { ...this.getDefaults() },
        //  // zh: { ...this.getDefaults() }
        // }
      },
      colorx: '#103767',
      reset_btn: true,
      cancel_btn: false,
      currLang: 'en'
    }
  },

  created () {
    if (this.$route.params.id) {
      this.flow_type = 'edit'
    } else {
      this.flow_type = 'add'
    }

    this.cancel_btn = true

    if (this.flow_type === 'edit') {
      this.getCmsDetail(this.$route.params.id)
      this.id = this.$route.params.id
      this.reset_btn = false
      this.cancel_btn = true
    }
  },

  computed: {
    validateForm () {
      return !this.errors.any() && this.name !== '' && this.content !== ''
    },
    // getCountryFlag () {
    //   if (this.currLang == 'en') {
    //     return 'gb'
    //   }
    //   if (this.currLang == 'zh') {
    //     return 'cn'
    //   }
    // },

    // getCountryLang () {
    //   if (this.currLang == 'en') {
    //     return this.$t('English')
    //   }
    //   if (this.currLang == 'zh') {
    //     return this.$t('Chinese')
    //   }
    // }

  },

  methods: {
    // getDefaults () {
    //   return {
    //     name: '',
    //     // slug: '',
    //     content: ''
    //   }
    // },
    cancel () {
      this.$router.push('/cms').catch(() => {
      })
    },
    reset () {
      //this.$router.push('/cms')
      this.formData = {}
    },
    save () {
      if (this.flow_type === 'add') {
        //const form = this.processForm()
        
        axios.post('/cms', this.formData)
          .then((response) => {
            if (response.status === 201) {
              this.notifyUI('Created!', 'CMS has been created', 'success')
              this.$router.push('/cms')
            }
          })
          .catch((error) => {
            this.displayFormErrors(error)
          })
          .then(() => this.$vs.loading.close())
      } else {
        //const form = this.processForm()
        axios.put(`/cms/${this.id}`, this.formData)
          .then((response) => {
            if (response.status === 200) {
              //this.$router.go(0)
              this.notifyUI('Updated!', 'CMS has been updated', 'success')
              this.$router.push('/cms')
            }
          })
          .catch((error) => {
            this.displayFormErrors(error)
          })
          .then(() => this.$vs.loading.close())
      }
    },

    getCmsDetail (id) {
      axios.get(`/cms/${id}`)
        .then((response) => {
          if (response.status === 200) {
            this.formData = response.data
          }
        })
        .catch((error) => {
          this.displayErrors(error)
        })
        .then(() => this.$vs.loading.close())
    },
    processForm () {
      
      const form =  new FormData()

      // //LANG BASED
      // const lf = Object.keys(this.getDefaults()) //['name', 'slug', 'content', 'status']
      // lf.forEach((item) => {
      //   //2-D
      //   form.en[item] = this.formData.translations['en'][item]
      //   //form.zh[item] = this.formData.translations['zh'][item]
      // })

      //NON-LANG BASED
      form.name = this.formData.name
      form.content = this.formData.content
      form.slug = this.formData.slug
      form.status = this.formData.status
      //alert(form)
      return form
    }
  }
}
</script>
