<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vx-card>

    <!-- <div class="vx-row mb-10">

      <div class="vx-col md:w-1/2 w-full">
        <vs-dropdown>
          <a class="a-icon" href.prevent>
            {{$t('lang.Content Language')}}
            <vs-icon class="" icon="expand_more"></vs-icon>
          </a>

          <vs-dropdown-menu>

            <vs-dropdown-item @click="currLang='en'">
              {{ $t("lang.English") }}
              <country-flag country="gb" size="normal" />
            </vs-dropdown-item>
            <vs-dropdown-item @click="currLang='zh'">
              {{ $t("lang.Chinese") }}
              <country-flag country="cn" size="normal" />
            </vs-dropdown-item>

          </vs-dropdown-menu>

        </vs-dropdown>

        <span class="text-md align-middle float-right flex">
          <span class="align-middle">{{ getCountryLang }}</span>
          <country-flag class="ml-1" :country="getCountryFlag" size="normal" />
        </span>
      </div>


      <div class="vx-col md:w-1/2 w-full">

      </div>

    </div> -->

    <!-- Content Row -->
    <div class="vx-row">

      <div class="vx-col md:w-1/2 w-full">
        <label class="text-sm">{{ $t("modules.setting.Site Name") }} <span class="text-danger"> * </span></label>
        <vs-input class="w-full mt-4" v-model="formData['site_name']"
                   />
        <!-- <span class="text-danger text-sm" v-show="errors.has('site_name')">{{ errors.first("site_name") }}</span> -->

  
        <vs-input class="w-full mt-4 required action_btn"
                  :label="$t('modules.setting.Site Description')"
                  v-model="formData['site_description']" />
        <span class="text-danger text-sm" v-show="errors.has('site_description')">{{ errors.first("site_description")
          }}</span>


        <vs-input class="w-full mt-4 required action_btn"
                  :label="$t('modules.setting.Site Owner')" v-model="formData['site_owner']" />
        <span class="text-danger text-sm" v-show="errors.has('site_owner')">{{ errors.first("site_owner") }}</span>


        <vs-input class="w-full mt-4 required"
                  :label="$t('modules.setting.Contact Email')"
                  v-model="formData['contact_email']"
                  />
        <span class="text-danger text-sm"
              v-show="errors.has('contact_email')">{{ errors.first("contact_email") }}</span>


        <!-- <label class="text-sm">{{ $t("modules.setting.Contact Email") }} <span class="text-danger"> * </span></label>
        <vs-textarea rows="3" v-model="formData['address']" placeholder="Address" />
        <span class="text-danger text-sm" v-show="errors.has('address')">{{ errors.first("address") }}</span> -->

        <vs-input class="w-full mt-4 required"
                  :label="$t('modules.setting.Meta Title')" v-model="formData['meta_title']"
                  v-validate="'required'" />
        <span class="text-danger text-sm" v-show="errors.has('meta_title')">{{ errors.first("meta_title") }}</span>

        <vs-input class="w-full mt-4 required"
                  :label="$t('modules.setting.Meta keywords')"
                  v-model="formData['meta_keywords']"
                  v-validate="'required'" />
        <span class="text-danger text-sm" v-show="errors.has('meta_keywords')">{{ errors.first("meta_keywords")
          }}</span>


        <!--
      <vs-input class="w-full mt-4 required"
                :label="$t('modules.setting.Facebook Link')" v-model="formData['facebook_link']" v-validate="'required'"/>
      <span class="text-danger text-sm" v-show="errors.has('facebook_link')">{{ errors.first("facebook_link") }}</span>

      <vs-input class="w-full mt-4 required"
                :label="$t('modules.setting.Twitter Link')" v-model="formData['twitter_link']" v-validate="'required'"/>
      <span class="text-danger text-sm" v-show="errors.has('twitter_link')">{{ errors.first("twitter_link") }}</span>

      <vs-input class="w-full mt-4 required"
                :label="$t('modules.setting.Linkedin Link')" v-model="formData['linked_link']" v-validate="'required'"/>
      <span class="text-danger text-sm" v-show="errors.has('linked_link')">{{ errors.first("linked_link") }}</span>

      <vs-input class="w-full mt-4 required"
                :label="$t('modules.setting.Instagram Link')"  v-model="formData['instagram_link']" v-validate="'required'"/>
      <span class="text-danger text-sm" v-show="errors.has('instagram_link')">{{ errors.first("instagram_link") }}</span>


      <vs-input class="w-full mt-4 required"
                :label="$t('modules.setting.Android App Link')" v-model="formData['android_app_link']" v-validate="'required'"/>
      <span class="text-danger text-sm" v-show="errors.has('android_app_link')">{{ errors.first("android_app_link") }}</span>

      <vs-input class="w-full mt-4 required"
                :label="$t('modules.setting.Ios App Link')" v-model="formData['ios_app_link']" v-validate="'required'"/>
      <span class="text-danger text-sm" v-show="errors.has('ios_app_link')">{{ errors.first("ios_app_link") }}</span> -->

        <vs-row class="mt-6">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="left" vs-w="3">
            <label class="text-md">{{ $t("modules.setting.Logo") }}</label>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="left" vs-w="9">
            <input class="w-100 vx-row mx-1" name="logo" type="file" accept="image/*" @change="handleLogo" ref="logo">
            <vs-image class="w-100 shadow" :src="previewLogo" />
          </vs-col>
        </vs-row>


        <vs-row class="mt-6">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="left" vs-w="3">
            <label class="text-md">{{ $t("modules.setting.Favicon") }}</label>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="left" vs-w="9">
            <input class="w-100 vx-row mx-1" name="favicon" type="file" accept="image/*" @change="handleFavIcon" ref="favicon">
            <vs-image class="w-100 shadow" :src="previewFavIcon" />
          </vs-col>
        </vs-row>

      </div>

      <div class="vx-col md:w-1/2 w-full">


        <div class="mt-4">
          <label class="text-sm">{{ $t("modules.setting.Meta Description") }} <span
            class="text-danger"> * </span></label>
          <vs-textarea rows="3" v-model="formData['meta_description']"
                       placeholder="Meta Description" />
          <span class="text-danger text-sm" v-show="errors.has('meta_description')">{{ errors.first("meta_description")
            }}</span>
        </div>


        <vs-input class="w-full mt-4 required" :label="$t('modules.setting.Copyright')"
                  v-model="formData['copyright']"
                  v-validate="'required'" />
        <span class="text-danger text-sm" v-show="errors.has('copyright')">{{ errors.first("copyright") }}</span>


        <vs-input class="w-full mt-4 required"
                  :label="$t('modules.setting.Telephone')" v-model="formData['telephone']"
                  v-validate="'required'" />
        <span class="text-danger text-sm" v-show="errors.has('telephone')">{{ errors.first("telephone") }}</span>

        <vs-input class="w-full mt-4 required"
                  :label="$t('modules.setting.Fax')" v-model="formData['fax']"
                  v-validate="'required'" />
        <span class="text-danger text-sm" v-show="errors.has('fax')">{{ errors.first("fax") }}</span>

        <vs-input class="w-full mt-4 required"
                  :label="$t('modules.setting.geocode')" v-model="formData['geocode']"
                  v-validate="'required'" />
        <span class="text-danger text-sm" v-show="errors.has('geocode')">{{ errors.first("geocode") }}</span>

        <div class="mt-4 block">
          <span class="required">
            <label class="text-xs text-black">{{ $t("modules.setting.Currency Side") }}</label>
          </span>
          <ul class="leftx">
            <li class="modelx">
              <vs-radio v-model="formData['currency_side']" vs-value="left">Left</vs-radio>
            </li>
            <li>
              <vs-radio v-model="formData['currency_side']" vs-value="right">Right</vs-radio>
            </li>
          </ul>
          <span class="text-danger text-sm" v-show="errors.has('currency_side')">{{ errors.first("currency_side")
            }}</span>
        </div>


        <!--        <vs-input class="w-full mt-4 required"
                  label="Currency Side" v-model="formData['currency_side']" v-validate="'required'"/>-->


        <vs-input class="w-full mt-4 required"
                  :label="$t('modules.setting.Currency Symbol')"
                  v-model="formData['currency_symbol']"
                  v-validate="'required'" />
        <span class="text-danger text-sm" v-show="errors.has('currency_symbol')">{{ errors.first("currency_symbol")
          }}</span>


      </div>
    </div>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" @click="save">{{ $t("modules.setting.Save Changes") }}</vs-button>
          <vs-button class="ml-4 mt-2" type="border" color="warning" @click="cancel">
            {{ $t("modules.setting.Cancel") }}
          </vs-button>
        </div>
      </div>
    </div>

  </vx-card>
</template>

<script>
// import moduleSetting          from '@/store/setting/moduleSetting.js'
import vSelect from 'vue-select'
import axios from '@/axios'
import CountryFlag from 'vue-country-flag'
import Vue from 'vue'

Vue.component('country-flag', CountryFlag)


export default {
  data () {
    return {
      formData: {
        geocode: '',
        site_name: '',
        site_description: '',
        site_owner: '',
        contact_email: '',
        site_title: '',
        copyright: '',
        footer_text: '',
        address: '',
        meta_title: '',
        meta_keywords: '',
        meta_description: '',
        telephone: '',
        fax: '',
        currency_side: '',
        currency_symbol: '',
        facebook_link: '',
        twitter_link: '',
        linked_link: '',
        instagram_link: '',
        android_app_link: '',
        ios_app_link: '',

        // translations: {
        //   // fill obj array with default values
        //   en: { ...this.getDefaults() },
        //  // zh: { ...this.getDefaults() }
        // }
      },
      add_image_before: false,
      add_image_after: true,
      add_icon_before: false,
      add_icon_after: true,
      iconUpload: 1,
      imageUpload: 1,
      previewFavIcon: '',
      previewLogo: '',
      currLang: 'en'
    }
  },
  components: {
    vSelect, CountryFlag
  },
  computed: {
    getCountryFlag () {
      if (this.currLang == 'en') {
        return 'gb'
      }
      if (this.currLang == 'zh') {
        return 'cn'
      }
    },

    getCountryLang () {
      if (this.currLang == 'en') {
        return this.$t('lang.English')
      }
      if (this.currLang == 'zh') {
        return this.$t('lang.Chinese')
      }
    }
  },
  methods: {
    getDefaults () {
      return {
        site_name: '',
        site_description: '',
        site_owner: '',
        contact_email: '',
        site_title: '',
        copyright: '',
        footer_text: '',
        address: '',
        meta_title: '',
        meta_keywords: '',
        meta_description: '',
        telephone: '',
        fax: '',
        currency_side: '',
        currency_symbol: '',
        facebook_link: '',
        twitter_link: '',
        linked_link: '',
        instagram_link: '',
        android_app_link: '',
        ios_app_link: '',
        logo:'',
        favicon:''
        
      }
    },

    cancel () {
      this.$router.push('/dashboard').catch(() => {
      })
    },
    save_changes () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.save()
        }
      })
    },

    save () {
      //const form = this.processForm()
      axios.put('/settings/general', this.formData)
        .then((response) => {
          if (response.status === 200) {
            this.notifyUI('Updated!', 'Settings has been updated', 'success')
          }
        })
        .catch((error) => {
          this.displayErrors(error)
        })
        .then(() => this.$vs.loading.close())
    },

    handleLogo (e) {
      const image = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = e => {
        this.previewLogo = e.target.result
        //console.log(this.previewImage)
      }
    },

    handleFavIcon (e) {
      const image = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = e => {
        this.previewFavIcon = e.target.result
        //console.log(this.previewImage)
      }
    },

    processForm () {
      const form = new FormData()

      //LANG BASED
      // const lf = [
      //   'site_name', 'site_description', 'site_owner', 'contact_email', 'address', 'meta_title', 'meta_keywords',
      //   'meta_description', 'copyright', 'telephone', 'fax', 'currency_side', 'currency_symbol', 'facebook_link',
      //   'twitter_link', 'linked_link', 'instagram_link', 'android_app_link', 'ios_app_link'
      // ]
      // lf.forEach((item) => {
      //   //2-D
      //   form.append(`en[${item}]`, this.formData.translations['en'][item]) //manually append name
      //   //form.append(`zh[${item}]`, this.formData.translations['zh'][item]) //manually append name
      // })

      //NON-LANG BASED
      form.append('geocode', this.formData.geocode)
      form.append('site_name', this.formData.site_name)
      form.append('site_description', this.formData.site_description)
      form.append('site_owner', this.formData.site_owner)
      form.append('contact_email', this.formData.contact_email)
      form.append('meta_title', this.formData.meta_title)
      form.append('meta_keywords', this.formData.meta_keywords)
      form.append('meta_description', this.formData.meta_description)
      form.append('copyright', this.formData.copyright)
      form.append('telephone', this.formData.telephone)
      form.append('fax', this.formData.fax)
      form.append('currency_side', this.formData.currency_side)
      form.append('currency_symbol', this.formData.currency_symbol)
      form.append('facebook_link', this.formData.facebook_link)
      form.append('twitter_link', this.formData.twitter_link)
      form.append('linked_link', this.formData.linked_link)
      form.append('instagram_link', this.formData.instagram_link)
      form.append('android_app_link', this.formData.android_app_link)
      form.append('ios_app_link', this.formData.ios_app_link)

      //FILES
      if (this.$refs.logo.files.length) {
        form.append('logo', this.$refs.logo.files[0])
      } //manually append file obj
      if (this.$refs.favicon.files.length) {
        form.append('favicon', this.$refs.favicon.files[0])
      } //manually append file obj

      return form
    },

    getGeneralSetting () {
      axios.get('/settings/general')
        .then((response) => {
          if (response.status === 200) {
            this.formData = response.data
            this.previewLogo = this.formData.logo
            this.previewFavIcon = this.formData.favicon
          }
        })
        .catch((error) => {
          this.displayErrors(error)
        })  
        .then(() => this.$vs.loading.close())
    }

  },
  created () {
    this.getGeneralSetting()
  },
  beforeDestroy () {
    // this.$store.unregisterModule('setting')
  }
}
</script>
<style>
.favicon_url {
  position: relative;
  top: 12px;
}
</style>
