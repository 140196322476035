<template>
    <div>{{date_format(this.params.value)}}</div>
</template>

<script>
export default {
  name: 'CellRendererDate',
  methods: {
    date_format (date) {
      const d = new Date(date)
      return d.toDateString()
    }
  }
}
</script>
