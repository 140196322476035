<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div>
    <!-- HEADING -->

    <vs-row class="bg-white my-4">
      <vs-col class="flex justify-items-center p-4">
        <h2 class="text-black"></h2>
        <vs-button class="mr-4" color="primary" type="filled" vs-align="right"
                   @click="$router.push('/teacher/new').catch(() => {})">{{ $t("modules.teacher.Add Teacher") }}
        </vs-button>
        <br>
        <vs-button class="mr-4" color="primary" type="filled" vs-align="right" @click="refreshData()">
          {{ $t("modules.teacher.Refresh") }}
        </vs-button>
        <vs-button class="mr-4" color="primary" type="filled" vs-align="right" @click="exportData()">
          {{ $t("modules.teacher.Export") }}
        </vs-button>

      </vs-col>
    </vs-row>

    <!-- SEARCH -->
    <div class="bg-white my-4">
      <vs-row>
        <vs-col class="flex justify-items-center p-4" vs-w="12">
          <h2 class="text-black text-sm">{{ $t("modules.teacher.Search Filter") }}</h2>
        </vs-col>
      </vs-row>

      <vs-row>


        <vs-col class="justify-items-center p-4 xs:w-full" vs-w="3" vs-xs="12">
          <vs-input class="inputx sm:w-full" :placeholder="$t('modules.teacher.Teacher Name')" @keyup="handleNameSearch"
                    v-model="name" />
        </vs-col>

        <vs-col class="flex justify-items-center p-4 xs:w-full" vs-w="4" vs-xs="12">
          <vs-select
              class="sm:w-full" @change="handleGradeSearch"
              :placeholder="$t('modules.teacher.Grade')"
              v-model="grade">
            <vs-select-item :key="index" :value="item.name" :text="item.title" v-for="(item,index) in grade_levels" />
          </vs-select>
        </vs-col>


      </vs-row>

    </div>
    <vx-card>


      <vs-table :data="pageData.items" :sst="true" ref="table" :key="renderKey"
                @search="handleSearch" @change-page="handleChangePage" @sort="handleSort"
                :total="pageData.total"
                :max-items="pageData.page_size" noDataText="" stripe pagination search>


        <template slot="thead">
          <vs-th sort-key="student_id">{{ $t("modules.teacher.ID") }}</vs-th>
          <vs-th sort-key="title">{{ $t("modules.teacher.Name") }}</vs-th>
          <vs-th sort-key="email">{{ $t("modules.teacher.Email") }}</vs-th>
          <vs-th sort-key="phone">{{ $t("modules.teacher.Teacher phone") }}</vs-th>
          <vs-th sort-key="grade">{{ $t("modules.teacher.Grade") }}</vs-th>
          <!-- <vs-th sort-key="age">Age</vs-th> -->
          <vs-th sort-key="gender">{{ $t("modules.teacher.Gender") }}</vs-th>
          <vs-th sort-key="created_at">{{ $t("modules.teacher.Created At") }}</vs-th>
          <vs-th sort-key="status">{{ $t("modules.teacher.Status") }}</vs-th>
          <vs-th>{{ $t("modules.teacher.Action") }}</vs-th>
        </template>

        <!-- <template slot-scope="{data}"> -->
        <template>
          <!-- <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in pageData.items"> -->
          <vs-tr :key="indextr" v-for="(tr, indextr) in pageData.items">

            <vs-td :data="pageData.items[indextr].teacher_id">
              #{{ pageData.items[indextr].teacher_id }}
            </vs-td>

            <vs-td :data="pageData.items[indextr].first_name">
              {{ pageData.items[indextr].first_name }} {{ pageData.items[indextr].last_name }}
            </vs-td>

            <vs-td :data="pageData.items[indextr].email">
              {{ pageData.items[indextr].email }}
            </vs-td>
            <vs-td :data="pageData.items[indextr].phone">
              {{ pageData.items[indextr].phone }}
            </vs-td>
            <vs-td :data="pageData.items[indextr].grade">
              {{ pageData.items[indextr].grade }}
            </vs-td>
            <vs-td :data="pageData.items[indextr].gender">
              {{ pageData.items[indextr].gender }}
            </vs-td>


            <vs-td :data="pageData.items[indextr].createdAt">
              {{ date_format(pageData.items[indextr].createdAt) }}
            </vs-td>

            <vs-td :data="pageData.items[indextr].status">
              <vs-chip v-if="pageData.items[indextr].status==0" :color="'#d9534f'" class="product-order-status">
                {{ $t("modules.profile.InActive") }}
              </vs-chip>
              <vs-chip v-if="pageData.items[indextr].status==1" :color="'#5cb85c'" class="product-order-status">
                {{ $t("modules.profile.Active") }}
              </vs-chip>
            </vs-td>

            <vs-td class="whitespace-no-wrap">
              <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current edit_delete"
                            @click.stop="$router.push('/teacher/edit/'+tr._id).catch(() => {})" />
              <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current edit_delete"
                            class="ml-2" @click.stop="deleteTeacher(tr._id)" />
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <vs-pagination :total="pageData.total_pages" v-model="pageData.page" :key="renderKey" />
        </template>

      </vs-table>

    </vx-card>
  </div>
</template>

<script>
// import moduleRole          from '@/store/role/moduleRole.js'
import Vue from 'vue'
import axios from '@/axios'

export default {
  data () {
    return {
      renderKey: 2,
      formData: {},
      displayPrompt: false,
      pageData: {},
      is_loaded: false,
      selected: {},
      name: '',
      grade: '',
      keyword: '',
      sort_by: '',
      ordering: '',
      page: 1,
      limit: 3,
      total_results: 0,
      grade_levels: [
        { name: '', title: 'All' },
        { name: 'elementary', title: 'Elementary' },
        { name: 'intermediate', title: 'Intermediate' },
        { name: 'higher', title: 'Higher' }
      ]
    }
  },
  components: {},
  computed: {},
  methods: {
    handleSearch (searching) {
      this.page = 1
      this.keyword = searching
      this.fetch_teachers()
    },

    handleSort (col, ord) {
      this.sort_by = col
      this.ordering = ord
      this.fetch_teachers()
    },

    handleChangePage (page) {
      this.page = page
      this.fetch_teachers()
    },
    handleNameSearch () {
      this.page = 1
      this.fetch_teachers()
    },
    handleGradeSearch () {
      this.page = 1
      this.fetch_teachers()
    },

    refreshData () {
      this.name = ''
      this.grade = ''
      this.fetch_teachers()
    },

    deleteTeacher (id) {
      this.$vs.loading()
      axios.delete(`/teachers/${id}`)
        .then((response) => {
          console.log(response)
          if (response.status === 200) {
            this.notifyUI('Deleted!', 'Teacher deleted sucessfully', 'success')

            this.fetch_teachers()
          }
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },

    exportData () {
      this.$vs.loading()
      axios({ method: 'get', url: '/export-teacher', responseType: 'arraybuffer' })
        .then((response) => {
          this.forceFileDownload(response, 'teacher_data')
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },
    forceFileDownload (response, title) {
      const url = window.URL.createObjectURL(new Blob([response.data], {
        type: 'application/vnd.ms-excel'
      }))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', title)
      document.body.appendChild(link)
      link.click()
    },


    date_format (date) {
      const d = new Date(date)
      return d.toDateString()
    },


    //Fecth from API
    fetch_teachers () {
      this.$vs.loading()

      axios.get('/teachers', {
        params: {
          name: this.name,
          grade: this.grade,
          keyword: this.keyword,
          sort_by: this.sort_by,
          ordering: this.ordering,
          page: this.page,
          limit: this.limit
        }
      })
        .then((response) => {
          //this.pageData = response.data
          Vue.set(this, 'pageData', response.data)
          this.is_loaded = true


          if (this.page == 1) {
            this.renderKey = Math.random()
          }
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    }
  },
  created () {
    this.fetch_teachers()
  },
  beforeDestroy () {
    // this.$store.unregisterModule('role')
  }
}
</script>
