<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>

  <div>
    <!-- HEADING -->
    <vs-row class="bg-white my-4">
      <vs-col class="flex justify-items-stretch p-4">
        <h2 class="text-black">
        {{page_title}}
        </h2>
        <vs-button class="ml-4" color="primary" type="filled" vs-align="right" 
        @click.prevent="redirectAdd">
          {{$t("modules.package.Add Package")}}
        </vs-button>
      </vs-col>
    </vs-row>

    <!-- SEARCH -->
    <div class="bg-white my-4">
      <vs-row>
        <vs-col class="flex justify-items-center p-4" vs-w="12">
          <h2 class="text-black text-sm">{{$t("modules.package.Search Filter")}}</h2>
        </vs-col>
      </vs-row>

      <vs-row>
        <!-- <vs-col class="justify-items-center p-4 xs:w-full" vs-w="4" vs-xs="12">
          <vs-input class="inputx sm:w-full" placeholder="Title" v-model="title"/>
        </vs-col> -->

        <vs-col class="justify-items-center p-4 xs:w-full" vs-w="4" vs-xs="12">
          <vs-input class="inputx sm:w-full" :placeholder="$t('modules.package.Add Package')" v-model="name"/>
        </vs-col>

        <vs-col class="flex justify-items-center p-4 xs:w-full" vs-w="4" vs-xs="12">
          <vs-select
            class="sm:w-full"
            placeholder="Grade"
            v-model="grade_level">
            <vs-select-item :key="index" :value="item.name" :text="item.title" v-for="(item,index) in grade_levels" />
          </vs-select>
        </vs-col>
      </vs-row>

    </div>

    <vs-row>
<!--       <vx-card vs-type="flex" v-if="is_loaded">
 -->        
  <vx-card>
 <vs-table v-if="is_loaded" :data="pageData.items" :sst="true" ref="table" :key="renderKey"
                      @search="handleSearch" @change-page="handleChangePage" @sort="handleSort"
                      :total="pageData.total"
                      :max-items="pageData.page_size" noDataText="" stripe pagination search>



        <template slot="thead">
          <vs-th sort-key="id">{{$t('modules.package.Package NO')}}</vs-th>
          <vs-th sort-key="name">{{$t('modules.package.Name')}}</vs-th>
           <vs-th sort-key="grade">{{$t('modules.package.Grade')}}</vs-th>
          <vs-th sort-key="created_at">{{$t('modules.package.Created At')}}</vs-th>
          <vs-th sort-key="status">{{$t('modules.package.Status')}}</vs-th> 
          <vs-th>{{$t('modules.package.Action')}}</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in pageData.items">

            <vs-td :data="pageData.items[indextr].name[$i18n.locale]">
                {{ indextr + 1 }}
              </vs-td>

              <vs-td :data="pageData.items[indextr].name[$i18n.locale]">
                {{ pageData.items[indextr].name[$i18n.locale] }}
              </vs-td>

              <vs-td :data="pageData.items[indextr].grade_level">
                {{ pageData.items[indextr].grade_level }}
              </vs-td>

              <vs-td :data="pageData.items[indextr].createdAt">
                {{ date_format(pageData.items[indextr].createdAt) }}
              </vs-td>

            <vs-td :data="pageData.items[indextr].status">
              <vs-chip v-if="pageData.items[indextr].status==0" :color="'#d9534f'" class="product-order-status">{{$t('modules.package.InActive')}}</vs-chip>
              <vs-chip v-if="pageData.items[indextr].status==1" :color="'#5cb85c'" class="product-order-status">{{$t('modules.package.Active')}}</vs-chip>
            </vs-td> 

            <td class="flex justify-between flex-nowrap">

              <feather-icon class="block" icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current edit_delete"
                            @click.stop="redirectEdit(tr)" />

              <feather-icon class="block" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current edit_delete"
                            @click.stop="deletePackage(tr._id)" />
            </td>
          </vs-tr>
        </template>
        <template #footer>
                    <vs-pagination :total="pageData.total_pages" v-model="pageData.page" :key="renderKey" />
        </template>

      </vs-table>
      </vx-card>
    </vs-row>
  </div>

</template>

<script>
import Vue from 'vue'
import axios from '@/axios'
import { getSupportedLocales } from "@/i18n/i18n"

export default {
  data () {
    const locales = getSupportedLocales().map((el) => {
      return { text: el.name, value: el.code }
    })
    return {

      renderKey: 2,
      formData: {},
      displayPrompt: false,
      pageData: {},
      is_loaded: false,
      selected: {},
      keyword: '',
      sort_by: '',
      ordering: '',
      page: 1,
      limit: 3,
      total_results: 0,
      grade_levels: [
        {name:'elementary', title:'Elementary'},
        {name:'middle', title:'Middle'},
        {name:'high', title:'High'}
      ],

        title: '',
        name: '',
        grade_level: '',
        locales

    }
  },
  components: {},
  watch: {

    name(){
      this.fetch_packages()
    },

    grade_level(){
      this.fetch_packages()
    },
    $route (to, from){
      this.$root.$emit('route-re-render')
    }
    
  },
  computed:{
    page_title(){
      if(this.$route.meta.type == 1)
        return this.$t("modules.package.Package")
      else
        return this.$t("modules.package.Pretest Package")
    }
  },
  methods: {

    redirectEdit(tr){
      let url
      switch(tr.type){
        case 1:
          url = '/package/edit/'+tr._id
        break

        case 2:
          url = '/pretest-package/edit/'+tr._id
        break
      }
      this.$router.push(url).catch(() => {})
    },
    redirectAdd(){
      let url
      switch(this.$route.meta.type){
        case 1:
          url = '/package/new'
        break

        case 2:
          url = '/pretest-package/new'
        break
      }
      this.$router.push(url).catch(() => {})
    },
    updateLocale (locale) {
      this.$i18n.locale = locale
    },
    handleSearch (searching) {
      this.page = 1 //reset page if criteria changed
      this.keyword = searching
      this.fetch_packages()
    },

    handleSort (col, ord) {
      this.sort_by = col
      this.ordering = ord
      this.fetch_packages()
    },

    handleChangePage (page) {
      this.page = page
      this.fetch_packages()
    },

    refreshData () {
      this.fetch_packages()
    },

    deletePackage (id) {
      this.$vs.loading()
      axios.delete(`/packages/${id}`)
        .then((response) => {
          if (response.status === 200) {
            this.notifyUI('Deleted!', 'Package has been deleted', 'success')
            this.fetch_packages()
          }
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },

    date_format (date) {
      const d = new Date(date)
      return d.toDateString()
    },


    //Fecth from API
    fetch_packages () {
      this.$vs.loading()


      var params = { type: this.$route.meta.type }

      if(this.keyword && String(this.keyword).trim() !== ''){
        params.keyword = this.keyword
      }

      if(this.name && String(this.name).trim() !== ''){
        params.name = this.name
      }

      if(this.grade_level && String(this.grade_level).trim() !== ''){
        params.grade_level = this.grade_level
      }

      if(this.sort_by && String(this.sort_by).trim() !== ''){
        params.sort_by = this.sort_by
      }

      if(this.ordering && String(this.ordering).trim() !== ''){
        params.ordering = this.ordering
      }
          params. page= this.page,
          params.  limit=this.limit
      axios.get('/packages',{
        params:params
      })
        .then((response) => {
         /*  this.pageData = response.data
          this.is_loaded = true
          this.total_results = response.total */
          Vue.set(this, 'pageData', response.data)
          this.is_loaded = true
          if (this.page == 1) this.renderKey = Math.random()
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    }
  },
  created () {
    this.fetch_packages()
  },
  beforeDestroy () {
  }
}
</script>
