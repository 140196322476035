<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vx-card>

    <!-- Content Row -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-4">
        <label class="text-sm">{{$t('modules.city.City Name')}} <span class="text-danger"> * </span></label>
        <vs-input class="w-full mt-4 required" 
                  v-model="formData['name']"
                  v-validate="'required'"/>

        <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first("name") }}</span>
        </div>

        <div class="mt-4">
        <label class="text-sm">{{$t('modules.city.Zipcode')}} <span class="text-danger"> * </span></label>
        <vs-input class="w-full mt-4 required" 
                  v-model="formData['zipcode']"
                  v-validate="'required'"/>

        <span class="text-danger text-sm" v-show="errors.has('zipcode')">{{ errors.first("zipcode") }}</span>
        </div>

        <div class="mt-4">
        <label class="text-sm">{{$t('modules.city.Country')}} <span class="text-danger"> * </span></label>
        <vs-select class="w-full mt-4 required"
          :color="colorx"
           placeholder="Select Country"
          v-model="formData['country']" v-validate="'required'"
        >
          <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in countries"/>
        </vs-select>
         <span class="text-danger text-sm" v-show="errors.has('country')">{{ errors.first("country") }}</span>
        </div>


        <div class="mt-6 centerx w-full vx-row">
          <label class="text-sm my-4 my-1">{{$t('modules.student.Status')}}</label>
          <vs-switch color="success my-3 mx-4" v-model="formData['status']" vs-value="1">
            <span slot="on">{{$t('modules.profile.Active')}}</span>
            <span slot="off">{{$t('modules.profile.InActive')}}</span>
          </vs-switch>
        </div>

      </div>
    </div>


    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" :disabled="!validateForm" @click="save" >{{$t('modules.student.Save Changes')}}</vs-button>
          <vs-button class="ml-4 mt-2" v-if="reset_btn" type="border" color="warning" @click="reset">{{$t('modules.student.Reset')}}</vs-button>
          <vs-button class="ml-4 mt-2" v-if="cancel_btn" type="border" color="warning" @click="cancel">{{$t('modules.profile.Cancel')}}
          </vs-button>
        </div>
      </div>
    </div>

  </vx-card>
</template>

<script>
// import moduleRole          from '@/store/role/moduleRole.js'
import vSelect from 'vue-select'
import axios from '@/axios'
import Vue from 'vue'

export default {
  components: {
    vSelect,
  },
  data () {
    return {
      formData: {
        name: '',
        status: '0',
      },
      colorx:'#103767',
      reset_btn: true,
      cancel_btn: false,
      validateForm: true,
      countries: [],
      flow_type: ''

    }
  },

  created () {
    if (this.$route.params.id) this.flow_type = 'edit'
    else this.flow_type = 'add'
       this.cancel_btn= true

    if (this.flow_type === 'edit') {
      this.getCityDetail(this.$route.params.id)
      this.id = this.$route.params.id
      this.reset_btn= false
       this.cancel_btn= true
    }

    this.getCountries()
  },

  computed: {
validateForm () {
      return !this.errors.any() && this.name !== '' && this.zipcode !== '' && this.country !== ''
    },
  },

  methods: {
    cancel () {
      this.$router.push('/city').catch(() => { })
    },
    reset () {
      //this.$router.push('/city')
       this.formData=
        {
              country:'' ,

         }
    },
    save () {
      if (this.flow_type === 'add') {
        axios.post('/cities', this.formData)
          .then((response) => {
            if (response.status === 201) {
              this.notifyUI('Created!', 'City has been created', 'success')
              this.$router.push('/city')
            }
          })
          .catch((error) => {
         this.displayErrors(error)
          })
          .then(() => this.$vs.loading.close())
      } else {
        axios.put(`/cities/${this.id}`, this.formData)
          .then((response) => {
            if (response.status === 200) {
              this.$router.push('/city')
              this.notifyUI('Updated!', 'City has been updated', 'success')
            }
          })
          .catch((error) => {
               this.displayErrors(error)
          })
          .then(() => this.$vs.loading.close())
      }
    },

    getCityDetail (id) {
      axios.get(`/cities/${id}`)
        .then((response) => {
          if (response.status === 200) {
            this.formData = response.data
          }
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },

    getCountries () {
      axios.get('/countries', { params: {limit: 1000} })
        .then((response) => {
          if (response.status === 200) {
            //need format [{text: 'Square', value: 1}, ...],
            this.countries = response.data.items.map(item => {
              return {text: item.name, value: item._id}
            })
          }
        })
        .catch((error) => {
          //alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },

  }
}
</script>
