import axios from '../axios'

const mutations = {

  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu

  TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE (state, value) {
    state.isVerticalNavMenuActive = value
  },
  TOGGLE_REDUCE_BUTTON (state, val) {
    state.reduceButton = val
  },
  UPDATE_MAIN_LAYOUT_TYPE (state, val) {
    state.mainLayoutType = val
  },
  UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN (state, val) {
    state.verticalNavMenuItemsMin = val
  },
  UPDATE_VERTICAL_NAV_MENU_WIDTH (state, width) {
    state.verticalNavMenuWidth = width
  },


  // VxAutoSuggest


  // ////////////////////////////////////////////
  // UI
  // ////////////////////////////////////////////

  TOGGLE_CONTENT_OVERLAY (state, val) {
    state.bodyOverlay = val
  },
  UPDATE_PRIMARY_COLOR (state, val) {
    state.themePrimaryColor = val
  },
  UPDATE_THEME (state, val) {
    state.theme = val
  },
  UPDATE_WINDOW_WIDTH (state, width) {
    state.windowWidth = width
  },
  UPDATE_WINDOW_SCROLL_Y (state, val) {
    state.scrollY = val
  },


  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  // Updates user info in state and localstorage
  UPDATE_USER_INFO (state, payload) {
    // Get Data localStorage
    const userInfo = JSON.parse(localStorage.getItem('admin_info')) || state.appUser
    for (const property of Object.keys(payload)) {
      if (payload[property] !== null) {
        // If some of user property is null - user default property defined in state.appUser
        state.appUser[property] = payload[property]
        // Update key in localStorage
        userInfo[property] = payload[property]
      }
    }
    // Store data in localStorage
    localStorage.setItem('admin_info', JSON.stringify(userInfo))
  },

  SET_TOKEN (state, token) {
    state.token = token
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    localStorage.setItem('admin_token', token)
  },

  SET_LOGIN_IN_STATE (state, login) {
    state.logged_in = login
    localStorage.setItem('admin_logged_in', login)
  },

  SET_AUTHORIZATION (state, authorization) {
    state.authorization = authorization
    localStorage.setItem('admin_authorization', JSON.stringify(authorization))
  },

  UPDATE_CHANGE_PASSWORD (state, obj) {
    state.oldPassword = obj.oldPassword
    state.newPassword = obj.newPassword
  },

  CLEAR_SESSION () {
    localStorage.removeItem('admin_info')
    localStorage.removeItem('admin_token')
    localStorage.removeItem('admin_logged_in')
    localStorage.removeItem('admin_authorization')
  },

  SET_UI_LANGUAGE (state, lang) {
    state.ui_language = lang
    localStorage.setItem("language", lang)
  }
}

export default mutations

