
//https://kazupon.github.io/vue-i18n/guide/lazy-loading.html

import Vue from 'vue'
import VueI18n from 'vue-i18n'
//import locale from "locales";
import supportedLocales from '@/i18n/supported'
import getBrowserLocale from '@/i18n/browserLocale'


Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

/* export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages()
}); */

export function getSupportedLocales () {
  const annotatedLocales = []

  for (const code of Object.keys(supportedLocales)) {
    annotatedLocales.push({
      code,
      name: supportedLocales[code]
    })
  }

  return annotatedLocales
}


//...

export default new VueI18n({
  // eslint-disable-next-line no-use-before-define
  locale: getStartingLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
})

function getStartingLocale () {
  const browserLocale = getBrowserLocale({ countryCodeOnly: true })
  if (Object.keys(supportedLocales).includes(browserLocale)) {
    return browserLocale
  } else {
    return process.env.VUE_APP_I18N_LOCALE || 'en'
  }
}
