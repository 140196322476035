<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vx-card>
 <div class="vx-row mb-10">

      <div class="vx-col md:w-1/2 w-full">
        <vs-dropdown>
          <a class="a-icon" href.prevent>
            Content Language
            <vs-icon class="" icon="expand_more"></vs-icon>
          </a>

          <vs-dropdown-menu>

            <vs-dropdown-item @click="currLang='en'">
              {{ $t("English") }}
              <country-flag country="gb" size="normal" />
            </vs-dropdown-item>
            <vs-dropdown-item @click="currLang='zh'">
              {{ $t("Chinese") }}
              <country-flag country="cn" size="normal" />
            </vs-dropdown-item>

          </vs-dropdown-menu>

        </vs-dropdown>

        <span class="text-md align-middle float-right flex">
          <span class="align-middle">{{ getCountryLang }}</span>
          <country-flag class="ml-1" :country="getCountryFlag" size="normal" />
        </span>
      </div>


      <div class="vx-col md:w-1/2 w-full">

      </div>

    </div>
    <!-- Content Row -->
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">

         <div class="mt-4">
        <label class="text-sm">{{$t('modules.course_type.Course Name')}} <span class="text-danger"> * </span></label>
        
        <vs-input class="w-full mt-4 required" 
                  
                  v-model="formData.translations[currLang]['course_name']"
                  v-validate="'required'"/>

        <span class="text-danger text-sm" v-show="errors.has('course_name')">{{ errors.first("course_name") }}</span>
         </div>


       <div class="mt-4">
        <label class="text-sm">{{$t('modules.course_type.Time to Read Each Section')}} <span class="text-danger"> * </span></label>
        
      <vs-input class="w-full mt-4 required" 
                  v-model="formData['duration_time_to_read']"

                  v-validate="'required'"/>

        <span class="text-danger text-sm" v-show="errors.has('duration_time_to_read')">{{ errors.first("duration_time_to_read") }}</span>
        </div>
      

       <div class="mt-4">
        <label class="text-sm">{{$t('modules.course_type.Number of time answer attempt')}} <span class="text-danger"> * </span></label>
       
      <vs-input class="w-full mt-4 required"
                  v-model="formData['answer_attempt_no']"
                  v-validate="'required'"/>

        <span class="text-danger text-sm" v-show="errors.has('answer_attempt_no')">{{ errors.first("answer_attempt_no") }}</span>
      </div>

      <div class="mt-4">
        <label class="text-sm">{{$t('modules.course_type.Does Paragraph disappers?')}}</label>
       
       <vs-checkbox class="w-full mt-4 "
       v-model="formData['paragraph_disappears']" ref="r_paragraph_disappears"></vs-checkbox>
      </div>

      <div class="mt-4" v-if="formData['paragraph_disappears']">
        <label class="text-sm">{{$t('modules.course_type.Paragraph Disappears after')}} <span class="text-danger"> * </span></label>
       
      <vs-input class="w-full mt-4 required"
                  v-model="formData['paragraph_timeout']"
                  v-validate="'required_if:r_paragraph_disappears'"/>

        <span class="text-danger text-sm" 
        v-show="errors.has('paragraph_timeout')">
        {{ errors.first("paragraph_timeout") }}</span>
      </div>



        <div class="mt-6 centerx w-full vx-row">
          <label class="text-sm my-4 my-1">{{$t('modules.course_type.Status')}}</label>
          <vs-switch color="success my-3 mx-4"                   
         v-model="formData['status']"
        vs-value="1">
            <span slot="on">{{$t('modules.profile.Active')}}</span>
            <span slot="off">{{$t('modules.profile.InActive')}}</span>
          </vs-switch>
        </div>

      </div>
    </div>


    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" :disabled="!validateForm" @click="save" >{{$t('modules.course_type.Save Changes')}}</vs-button>
          <vs-button class="ml-4 mt-2" v-if="reset_btn" type="border" color="warning" @click="reset">{{$t('modules.course_type.Reset')}}</vs-button>
          <vs-button class="ml-4 mt-2" v-if="cancel_btn" type="border" color="warning" @click="cancel">{{$t('modules.profile.Cancel')}}
          </vs-button>
        </div>
      </div>
    </div>

  </vx-card>
</template>

<script>
// import moduleRole          from '@/store/role/moduleRole.js'
import vSelect from 'vue-select'
import axios from '@/axios'
import Vue from 'vue'
import CountryFlag from 'vue-country-flag'
Vue.component('country-flag', CountryFlag)


export default {
  components: {
    vSelect,CountryFlag
  },
  data () {
    return {
      formData: {
        status:0,
       
        translations: {
          // fill obj array with default values
          en: { ...this.getDefaults() },
          zh: { ...this.getDefaults() }
        }
      },
      colorx:'#103767',
      reset_btn: true,
      cancel_btn: false,
      validateForm: true,
      countries: [],
      flow_type: '',
      currLang: 'en'


    }
  },

  created () {
    if (this.$route.params.id) this.flow_type = 'edit'
    else this.flow_type = 'add'
       this.cancel_btn= true

    if (this.flow_type === 'edit') {
      this.getGradeDetail(this.$route.params.id)
      this.id = this.$route.params.id
       this.reset_btn= false
       this.cancel_btn= true
    }


    
  },

  computed: {
  validateForm () {
      return !this.errors.any() && this.name !== '' && this.zipcode !== ''
    },
     getCountryFlag () {
      if (this.currLang == 'en') {
        return 'gb'
      }
      if (this.currLang == 'zh') {
        return 'cn'
      }
    },

    getCountryLang () {
      if (this.currLang == 'en') {
        return this.$t('English')
      }
      if (this.currLang == 'zh') {
        return this.$t('Chinese')
      }
    }
  },

  methods: {
    getDefaults () {
      return {
        course_name: ''
       
      }
    },
    cancel () {
      this.$router.push('/course-types').catch(() => { })
    },
    reset () {
      //this.$router.push('/course-types')
      this.formData
      {
        
      }
    },
    save () {
      if (this.flow_type === 'add') {
        const form = this.processForm()
        axios.post('/course-types', form)
          .then((response) => {
            if (response.status === 200) {
              this.notifyUI('Created!', 'Course Type has been created', 'success')
              this.$router.push('/course-types')
            }
          })
          .catch((error) => {
         this.displayFormErrors(error)
          })
          .then(() => this.$vs.loading.close())
      } else {
        const form = this.processForm()
        axios.put(`/course-types/${this.id}`, form)
          .then((response) => {
            if (response.status === 200) {
              this.$router.push('/course-types')
              this.notifyUI('Updated!', 'Course Type has been updated', 'success')
            }
          })
          .catch((error) => {
               this.displayFormErrors(error)
          })
          .then(() => this.$vs.loading.close())
      }
    },

    getGradeDetail (id) {
      axios.get(`/course-types/${id}`)
        .then((response) => {
          if (response.status === 200) {
            this.formData = response.data
          }
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },
     processForm () {
      const form = {en: {}, zh: {}}

      //LANG BASED
      const lf = Object.keys(this.getDefaults()) //['name', 'slug', 'content', 'status']
      lf.forEach((item) => {
        //2-D
        form.en[item] = this.formData.translations['en'][item]
        form.zh[item] = this.formData.translations['zh'][item]
        
      })

      //NON-LANG BASED
      form.status = this.formData.status
      form.answer_attempt_no = this.formData.answer_attempt_no
      form.duration_time_to_read = this.formData.duration_time_to_read
      form.paragraph_disappears = this.formData.paragraph_disappears
      form.paragraph_timeout = this.formData.paragraph_timeout
      return form
    }

   

  }
}
</script>
