import Vuex from 'vuex'
Vue.use(Vuex)

// Vuex Store
import store from './store/store'

import Vue from 'vue'

import App from './App.vue'
import i18n from './i18n/i18n'
import vuetify from './plugins/vuetify'



import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from 'vee-validate'


Vue.use(VeeValidate);

import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css' // Vuesax
Vue.use(Vuesax)


// mock
// import './fake-db/index.js'

// Theme Config urations
import '../themeConfig.js'

// ACL
// import acl from './acl/acl'

// Globally Registered Components
import './globalComponents.js'

// Styles: SCSS
import './assets/scss/main.scss'

// Tailwind
import '@/assets/css/main.css'

// Vue Router
import router from './router'


// Vuexy Admin Filters
import './filters/filters'


// Clipboard
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)


// Tour
import VueTour from 'vue-tour'
Vue.use(VueTour)
require('vue-tour/dist/vue-tour.css')


// VeeValidate
import VeeValidate from 'vee-validate'
Vue.use(VeeValidate)


// Google Maps
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    // Add your API key here
    key: ' ',
    libraries: 'places' // This is required if you use the Auto complete plug-in
  }
})

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)


// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'


// Feather font icon
require('./assets/css/iconfont.css')


// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

//Import Froala Editor
// import 'froala-editor/js/plugins.pkgd.min.js';
// //Import third party plugins
// import 'froala-editor/js/third_party/embedly.min';
// import 'froala-editor/js/third_party/font_awesome.min';
// import 'froala-editor/js/third_party/spell_checker.min';
// import 'froala-editor/js/third_party/image_tui.min';
// // Import Froala Editor css files.
// import 'froala-editor/css/froala_editor.pkgd.min.css';
//import CKEditor from 'ckeditor4-vue'

//Vue.use(CKEditor)
// Import and use Vue Froala lib.
// import VueFroala from 'vue-froala-wysiwyg'
// Vue.use(VueFroala)

import {en, zh} from 'vuejs-datepicker/dist/locale'


Vue.config.productionTip = false

Vue.mixin({
  data () {
    return {
      dateFormat: 'yyyy-MM-dd'
    }
  },
  methods: {
    rd1kIsNumber (evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    getDateFormatLocale (langCode) {
      switch (langCode) {
      case 'en':
        return en
        break

      case 'zh':
        return zh
        break
      }
    },
    notifyUI (title, msg, type) {

      let color = ''
      switch (type) {
      case 'success':
        color = '#28a745'
        break
      case 'warning':
        color = '#ffc107'
        break
      case 'error':
        color = '#dc3545'
        break
      default:
        color = '#00F'
      }


      this.$vs.notify({
        title,
        text: msg,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color,
        time: 10000,
        position:'bottom-left'
      })
    },
    date_format (date) {
      const d = new Date(date)
      return d.toDateString()
    },
    hasErrorItems (obj) {
      if (typeof obj.errors !== 'undefined' && obj.errors.length > 0) return true
      else return false
    },

    getErrorItems (obj) {
      return obj.errors
    },

    //dummy fn
    // Method for creating dummy notification time
    randomDate ({ hr, min, sec }) {
      const date = new Date()

      if (hr) date.setHours(date.getHours() - hr)
      if (min) date.setMinutes(date.getMinutes() - min)
      if (sec) date.setSeconds(date.getSeconds() - sec)

      return date
    },

    ucfirst (str) {
      return str.charAt(0).toUpperCase() + str.substring(1)
    },

    displayErrors (error) {
      if (error.message == 'Network Error') {
        this.notifyUI('Network Issue', 'Please check your network connection', 'error')
        return
      }

      if (error.request) {
        const xhrResponse = JSON.parse(error.request.response) || {errors: [{'field':'*', 'suggestion':'Some error occurred'}]}
        if (this.hasErrorItems(xhrResponse)) {

          // if (!(xhrResponse instanceof Array)) {
          //   xhrResponse = [{field:null, suggestion: xhrResponse.errors}]
          // }

          let ers = this.getErrorItems(xhrResponse)

          if (typeof ers === 'string') {
            ers = [{field: null, suggestion: ers}]
          }

          this.errors.clear()
          ers.forEach((item) => {
            // {item.field} -- gives which input field has problem
            // {item.suggestion} -- gives how to fix problem
            this.$vs.notify({
              title: item.field ? item.field : 'Alert',
              text: item.suggestion,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })

            // if the error relates to any input field
            if (item.field) {
              //Add error to custom v-validator error
              this.errors.add({
                field: item.field,
                msg: item.suggestion
              })
            }
          })

        }
      } else {
        this.notifyUI('Error', error, 'error')
      }
    },

    displayFormErrors (error) {
      if (error.message == 'Network Error') {
        this.notifyUI('Network Issue', 'Please check your network connection', 'error')
        return
      }

 
      if (error.request) {
        const xhrResponse = JSON.parse(error.request.response) || {errors: [{'field':'*', 'suggestion':'Some error occurred'}]}
        if (this.hasErrorItems(xhrResponse)) {

          // if (!(xhrResponse instanceof Array)) {
          //   xhrResponse = [{field:null, suggestion: xhrResponse.errors}]
          // }

          let ers = this.getErrorItems(xhrResponse)

          if (typeof ers === 'string') {
            ers = [{field: null, suggestion: ers}]
          }

          let show = true
          if (ers.length > 1) {
            show = false
          }

          this.errors.clear()
          ers.forEach((item, index) => {
            // {item.field} -- gives which input field has problem
            // {item.suggestion} -- gives how to fix problem

            if (show) {
              this.$vs.notify({
                title: /*item.field ? item.field :*/ 'Alert',
                text: item.suggestion,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
            }

            // if the error relates to any input field
            if (item.field) {
              //Add error to custom v-validator error
              this.errors.add({
                field: item.field,
                msg: item.suggestion
              })
            }
          })

          if (!show) {
            this.$vs.notify({
              title: 'Alert',
              text: 'Please Correct The Form Errors',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }


        }
      } else {
        this.notifyUI('Error', error, 'error')
      }
    },

    displayFormErrorsScroll (error) {
      if (error.message == 'Network Error') {
        this.notifyUI('Network Issue', 'Please check your network connection', 'error')
        return
      }

      if (error.request) {
        const xhrResponse = JSON.parse(error.request.response) || {errors: [{'field':'*', 'suggestion':'Some error occurred'}]}
        if (this.hasErrorItems(xhrResponse)) {

          // if (!(xhrResponse instanceof Array)) {
          //   xhrResponse = [{field:null, suggestion: xhrResponse.errors}]
          // }

          let ers = this.getErrorItems(xhrResponse)

          if (typeof ers === 'string') {
            ers = [{field: null, suggestion: ers}]
          }

          let show = true
          if (ers.length > 1) {
            show = false
          }

          this.errors.clear()
          ers.forEach((item, index) => {
            // {item.field} -- gives which input field has problem
            // {item.suggestion} -- gives how to fix problem

            if (show) {
              this.$vs.notify({
                title: 'Alert',
                text: item.suggestion,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
            }

            // if the error relates to any input field
            if (item.field) {
              //Add error to custom v-validator error
              this.errors.add({
                field: item.field,
                msg: item.suggestion
              })
            }


            //only first el
            if (index === 0) {
              //console.log(this.$refs[item.field])
              console.log(this.$refs[item.field])
              //const pos = this.getPos(this.$refs[item.field].$el)
              const el = document.getElementById(item.field)
              if (el) {
                const pos = this.getPos(el)
                //let s = jQuery("#field_" + errIndex).offset().top;
                  //this.scrollTo(pos.top, 1000)
              }

            }

          })

          if (!show) {
            this.$vs.notify({
              title: 'Alert',
              text: 'Please Correct The Form Errors',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }


        }
      } else {
        this.notifyUI('Error', error, 'error')
      }
    },

  }
})
Vue.config.productionTip = false
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

new Vue({
  router,
  store,
  i18n,
  vuetify,

  render: h => h(App)
}).$mount('#app')

