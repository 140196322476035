<!-- =========================================================================================
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="relative">

    <div class="vx-navbar-wrapper test2" :class="classObj">

      <vs-navbar class="vx-navbar navbar-custom navbar-skelton" :color="navbarColorLocal" :class="textColor">

        <!-- SM - OPEN SIDEBAR BUTTON -->
        <feather-icon class="sm:inline-flex xl:hidden cursor-pointer p-2" icon="MenuIcon" @click.stop="showSidebar" />
        <!-- <h1>{{ $t(`nav.${$route.name}`) }}</h1> -->
        <transition :name="routerTransition">

          <div v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
               class="router-header breadcrumb breadcumb_main flex flex-wrap items-center">
            <div
              class="content-area__heading"
              :class="{'pr-4 border-0 breadcrumb_pipe  border-solid border-grey-light' : $route.meta.breadcrumb}">
              <!-- <span>|</span> -->
            </div>

            <!-- BREADCRUMB -->
            <vx-breadcrumb class=" md:block hidden" v-if="$route.meta.breadcrumb" :route="$route" :isRTL="$vs.rtl" />

            <!-- DROPDOWN -->
            <!-- <vs-dropdown vs-trigger-click class="ml-auto md:block hidden cursor-pointer">
              <vs-button radius icon="icon-settings" icon-pack="feather" />
            </vs-dropdown> -->

          </div>
        </transition>
        <!-- <bookmarks :navbarColor="navbarColor" v-if="windowWidth >= 992" /> -->

        <vs-spacer />

        <!-- <i18n /> -->

         <search-bar />

        <!-- <cart-drop-down /> -->

        <message-drop-down :message="message"/>

        <notification-drop-down :notifications="notifications"/>

        <profile-drop-down />


      </vs-navbar>

    </div>
  </div>

</template>


<script>
import Bookmarks from './components/Bookmarks.vue'
//import I18n from './components/I18n.vue'
import SearchBar from './components/SearchBar.vue'
import CartDropDown from './components/CartDropDown.vue'
import NotificationDropDown from './components/NotificationDropDown.vue'
import ProfileDropDown from './components/ProfileDropDown.vue'
import MessageDropDown from './components/MessageDropDown.vue'

export default {
  data () {
    return {
      routerTransition: '',
      routeTitle: '',
      notifications: [],
      message: []
    }
  },
  name: 'the-navbar-vertical',
  props: {
    navbarColor: {
      type: String,
      default: '#fff'
    }
  },
  components: {
    Bookmarks,
    //I18n,
    SearchBar,
    CartDropDown,
    NotificationDropDown,
    ProfileDropDown,
    MessageDropDown
  },
  computed: {
    navbarColorLocal () {
      return this.$store.state.theme === 'dark' && this.navbarColor === '#fff' ? '#10163a' : this.navbarColor
    },
    verticalNavMenuWidth () {
      return this.$store.state.verticalNavMenuWidth
    },
    textColor () {
      return { 'text-white': (this.navbarColor !== '#10163a' && this.$store.state.theme === 'dark') || (this.navbarColor !== '#fff' && this.$store.state.theme !== 'dark') }
    },
    windowWidth () {

      return this.$store.state.windowWidth
    },

    // NAVBAR STYLE
    classObj () {
      if (this.verticalNavMenuWidth === 'default') {
        return 'navbar-default'
      } else if (this.verticalNavMenuWidth === 'reduced') {
        return 'navbar-reduced'
      } else if (this.verticalNavMenuWidth) {

        return 'navbar-full'
      }
    }
  },
  methods: {
    showSidebar () {
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
    }
  },

  created () {
    this.notifications = this.notifications = {
      unread:[
        {
          index    : 0,
          title    : 'New Message',
          msg      : 'Are your going to meet me tonight?',
          icon     : 'MessageSquareIcon',
          time     : this.randomDate({sec: 10}),
          category : 'primary'
        },
        { index    : 1,
          title    : 'New Order Recieved',
          msg      : 'You got new order of goods.',
          icon     : 'PackageIcon',
          time     : this.randomDate({sec: 40}),
          category : 'success'
        }
      ],
      other:[
        { index    : 2,
          title    : 'Server Limit Reached!',
          msg      : 'Server have 99% CPU usage.',
          icon     : 'AlertOctagonIcon',
          time     : this.randomDate({min: 1}),
          category : 'danger'
        },
        { index    : 3,
          title    : 'New Mail From Peter',
          msg      : 'Cake sesame snaps cupcake',
          icon     : 'MailIcon',
          time     : this.randomDate({min: 6}),
          category : 'primary'
        },
        { index    : 4,
          title    : 'Bruce\'s Party',
          msg      : 'Chocolate cake oat cake tiramisu',
          icon     : 'CalendarIcon',
          time     : this.randomDate({hr: 2}),
          category : 'warning'
        }
      ]
    }

    this.message = this.message = {
      unread:[
        {
          index    : 0,
          title    : 'New Message',
          msg      : 'Are your going to meet me tonight?',
          icon     : 'MessageSquareIcon',
          time     : this.randomDate({sec: 10}),
          category : 'primary'
        },
        { index    : 1,
          title    : 'New Order Recieved',
          msg      : 'You got new order of goods.',
          icon     : 'PackageIcon',
          time     : this.randomDate({sec: 40}),
          category : 'success'
        }
      ],
      other:[
        { index    : 2,
          title    : 'New Subscriber!',
          msg      : 'Tom subscribed to new package',
          icon     : 'AlertOctagonIcon',
          time     : this.randomDate({min: 1}),
          category : 'danger'
        },
        { index    : 3,
          title    : 'Peter Joined 1st level',
          msg      : 'Cake sesame snaps cupcake',
          icon     : 'MailIcon',
          time     : this.randomDate({min: 6}),
          category : 'primary'
        },
        { index    : 4,
          title    : 'Bruce\'s Party',
          msg      : 'Chocolate cake oat cake tiramisu',
          icon     : 'CalendarIcon',
          time     : this.randomDate({hr: 2}),
          category : 'warning'
        }
      ]
    }

  }
}
</script>
