<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vx-card>

    <!-- Content Row -->
    <div class="vx-row">
      <!-- <div class="vx-col md:w-1/2 w-full">
        <vs-dropdown>
          <a class="a-icon" href.prevent>
            Content Language
            <vs-icon class="" icon="expand_more"></vs-icon>
          </a>

          <vs-dropdown-menu>

            <vs-dropdown-item @click="currLang='en'">
              {{ $t("English") }}
              <country-flag country="gb" size="normal" />
            </vs-dropdown-item>
            <vs-dropdown-item @click="currLang='zh'">
              {{ $t("Chinese") }}
              <country-flag country="cn" size="normal" />
            </vs-dropdown-item>

          </vs-dropdown-menu>

        </vs-dropdown>

        <span class="text-md align-middle float-right flex">
          <span class="align-middle">{{ getCountryLang }}</span>
          <country-flag class="ml-1" :country="getCountryFlag" size="normal" />
        </span>
      </div> -->

      <div class="vx-col w-full">

         <!-- <div class="mt-4">
        <label class="text-sm">{{$t('modules.blog.Category')}} <span class="text-danger"> * </span></label>
        
        <vs-select class="w-full mt-4" :color="colorx" placeholder="Select Category" v-model="formData['category']"
        >
          <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in categories" />
        </vs-select>
                  <span class="text-danger text-sm" v-show="errors.has('category')">{{ errors.first("category") }}</span>

        </div> -->

         <div class="mt-4">
        <label class="text-sm">{{$t('modules.blog.Blog Name')}} <span class="text-danger"> * </span></label>
        
        <vs-input class="w-full mt-4 required" 
                  v-model="formData['name']"
                  v-validate="'required'"/>

        <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first("name") }}</span>
        </div>

          <div class="mt-4">
        <label class="text-sm">{{$t('modules.blog.URL Slug')}} <span class="text-danger"> * </span></label>
        
        <vs-input class="w-full mt-4 required" 
                  v-model="formData['slug']"
                  />

        <span class="text-danger text-sm" v-show="errors.has('slug')">{{ errors.first("slug") }}</span>
        </div>

          <div class="mt-4">
        <label class="text-sm">{{$t('modules.blog.Blog Content')}} <span class="text-danger"> * </span></label>
        
          <vs-textarea class="h-20"  v-model="formData['content']" />
          <span class="text-danger text-sm" v-show="errors.has('content')">{{ errors.first("content") }}</span>
        </div>


        <div class="mt-4 center">
          <div> {{$t('modules.blog.Visibility')}} </div>
          <ul class="leftx">
            <li>
              <vs-radio color="success" v-model="formData['visibility']" vs-value="1">{{$t('modules.blog.Public')}}</vs-radio>
            </li>
            <li>
              <vs-radio color="warning" v-model="formData['visibility']" vs-value="2">{{$t('modules.blog.Private')}}</vs-radio>
            </li>
          </ul>
        </div>

        <span class="text-danger text-sm" v-show="errors.has('visibility')">{{ errors.first("visibility") }}</span>


        <div class="mt-4 centerx w-full">
          <div>
          <input class="w-100 vx-row mx-1" type="file" name="image" accept="image/*" @change="handleImage" ref="image">
          <!--          <img :src="previewImage" alt="" class="">-->
          <vs-image class="w-100 shadow" :src="previewImage" />
          <!--   <vs-upload action="https://jsonplaceholder.typicode.com/posts/" @on-success="successUpload" />-->
          </div>
        </div>


        <div class="centerx w-full vx-row">
          <label class="text-sm mt-4 my-1 mx-4">{{$t('modules.blog.Status')}}</label>
          <vs-switch color="success my-3 mx-4" v-model="formData['status']" vs-value="1">
            <span slot="on">{{$t('modules.profile.Active')}}</span>
            <span slot="off">{{$t('modules.profile.InActive')}}</span>
          </vs-switch>
        </div>

      </div>
    </div>


    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" :disabled="!validateForm" @click="save" >{{$t('modules.grade.Save Changes')}}</vs-button>
          <vs-button class="ml-4 mt-2" v-if="reset_btn" type="border" color="warning" @click="reset">{{$t('modules.grade.Reset')}}</vs-button>
          <vs-button class="ml-4 mt-2" v-if="cancel_btn" type="border" color="warning" @click="cancel">{{$t('modules.profile.Cancel')}}
          </vs-button>
        </div>
      </div>
    </div>

  </vx-card>
</template>

<script>
// import moduleRole          from '@/store/role/moduleRole.js'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import axios from '@/axios'
import Vue from 'vue'
import CountryFlag from 'vue-country-flag'
Vue.component('country-flag', CountryFlag)

export default {
  components: {
    vSelect,
    flatPickr,
    CountryFlag
  },
  data () {
    return {
      formData: {
        name: '',
        content: '',
        slug: '',
        //category: '',
        status: '0',
        visibility: '1',
        
        // translations: {
        //   // fill obj array with default values
        //   en: { ...this.getDefaults() },
        //   zh: { ...this.getDefaults() }
        // }
      },
      colorx:'#103767',
     // categories:[{text: 'category name', value: 1}],
      reset_btn: true,
      cancel_btn: false,
      previewImage: '',
      flow_type: '',
      currLang: 'en'
    }
  },

  created () {
    if (this.$route.params.id) this.flow_type = 'edit'
    else this.flow_type = 'add'
          this.cancel_btn= true
    if (this.flow_type === 'edit') {
      this.getBlogDetail(this.$route.params.id)
      this.id = this.$route.params.id
       this.reset_btn= false
       this.cancel_btn= true
    }

    //this.getBlogCategories()
  },

  computed: {
    validateForm () {
      return true;//!this.errors.any() && this.name !== '' && this.slug !== '' && this.content!==''
    },
    getCountryFlag () {
      if (this.currLang == 'en') {
        return 'gb'
      }
      if (this.currLang == 'zh') {
        return 'cn'
      }
    },

    getCountryLang () {
      if (this.currLang == 'en') {
        return this.$t('English')
      }
      if (this.currLang == 'zh') {
        return this.$t('Chinese')
      }
    }
  },

  methods: {

    // getBlogCategories () {
    //   axios.get('/categories', { params: {limit: 1000} })
    //     .then((response) => {
    //       if (response.status === 200) {
    //         //need format [{text: 'Square', value: 1}, ...],
    //         this.categories = response.data.items.map(item => {
    //           return {text: item.name, value: item._id}
    //         })
    //       }
    //     })
    //     .catch((error) => {
    //     //  alert(`Error: ${error}`)
    //        this.displayErrors(error)
    //     })
    //     .then(() => this.$vs.loading.close())
    // },

    getDefaults () {
      return {
        name: '',
        content: ''
      }
    },
    cancel () {
      this.$router.push('/blog').catch(() => { })
    },
    reset () {
      //this.$router.push('/blog')

        this.formData=
        {
              // category:'' ,
              visibility: '1'
         }

    },
    save () {
      //const form = this.processForm()

      if (this.flow_type === 'add') {
        axios.post('/blogs', this.formData)
          .then((response) => {
            if (response.status === 201) {
              this.notifyUI('Created!', 'Blog has been created', 'success')
               this.$router.push('/blog')
            }
          })
          .catch((error) => {
           this.displayErrors("Required All Fields!")
          })
          .then(() => this.$vs.loading.close())
      } else {
        axios.put(`/blogs/${this.id}`, this.formData)
          .then((response) => {
            if (response.status === 200) {
              //this.$router.go(0)
              this.notifyUI('Updated!', 'Blog has been updated', 'success')
              this.$router.push('/blog')
            }
          })
          .catch((error) => {
           this.displayErrors (error);
          })
          .then(() => this.$vs.loading.close())
      }
    },

    getBlogDetail (id) {
      axios.get(`/blogs/${id}`)
        .then((response) => {
          if (response.status === 200) {
            this.formData = response.data
            this.previewImage = this.formData.image
          }
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },

    handleImage (e) {
      const image = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = e => {
        this.previewImage = e.target.result
        console.log(this.previewImage)
      }
    },

    processForm () {
      const form = new FormData()

      // //LANG BASED
      // const lf = [
      //   'name',
      //   'content'
      // ]
      // lf.forEach((item) => {
      //   //2-D
      //   form.append(`en[${item}]`, this.formData.translations['en'][item]) //manually append name
      //   form.append(`zh[${item}]`, this.formData.translations['zh'][item]) //manually append name
      // })

      //NON-LANG BASED
      //form.append('category', this.formData.category)
      form.append('name', this.formData.name)
      form.append('content', this.formData.content)
      form.append('slug', this.formData.slug)
      form.append('status', this.formData.status)
      form.append('visibility', this.formData.visibility)
      
      //FILES
      if (this.$refs.image.files.length) {
        form.append('image', this.$refs.image.files[0])
      } //manually append file obj
      //alert(this.formData.content);
      return form
      
    }

  }
}
</script>
