export default {
  methods: {

    getPos (el) {
      const left = el.getBoundingClientRect().left
      const top = el.getBoundingClientRect().top

      return {left, top}
    },

    /* FOR SCROLLING FUNCTIONALITY..*/

    //----------ANIMATION FUNCTIONS START---------
    linearTween (t) {
      return t
    },

    easeInQuad (t) {
      return t * t
    },

    easeOutQuad (t) {
      return -t * (t - 2)
    },

    easeInOutQuad (t) {
      t /= 0.5
      if (t < 1) return t * t / 2
      t--
      return (t * (t - 2) - 1) / 2
    },

    easeInCuaic (t) {
      return t * t * t
    },

    easeOutCuaic (t) {
      t--
      return t * t * t + 1
    },

    easeInOutCuaic (t) {
      t /= 0.5
      if (t < 1) return t * t * t / 2
      t -= 2
      return (t * t * t + 2) / 2
    },

            
    easeInQuart (t) {
      return t * t * t * t
    },

    easeOutQuart (t) {
      t--
      return -(t * t * t * t - 1)
    },

    easeInOutQuart (t) {
      t /= 0.5
      if (t < 1) return 0.5 * t * t * t * t
      t -= 2
      return -(t * t * t * t - 2) / 2
    },

    easeInQuint (t) {
      return t * t * t * t * t
    },

    easeOutQuint (t) {
      t--
      return t * t * t * t * t + 1
    },

    easeInOutQuint (t) {
      t /= 0.5
      if (t < 1) return t * t * t * t * t / 2
      t -= 2
      return (t * t * t * t * t + 2) / 2
    },

    easeInSine (t) {
      return -Mathf.Cos(t / (Mathf.PI / 2)) + 1
    },

            
    easeOutSine (t) {
      return Mathf.Sin(t / (Mathf.PI / 2))
    },

    easeInOutSine (t) {
      return -(Mathf.Cos(Mathf.PI * t) - 1) / 2
    },

    easeInExpo (t) {
      return Mathf.Pow(2, 10 * (t - 1))
    },

    easeOutExpo (t) {
      return -Mathf.Pow(2, -10 * t) + 1
    },

    easeInOutExpo (t) {
      t /= 0.5
      if (t < 1) return Mathf.Pow(2, 10 * (t - 1)) / 2
      t--
      return (-Mathf.Pow(2, -10 * t) + 2) / 2
    },

    easeInCirc (t) {
      return -Mathf.Sqrt(1 - t * t) - 1
    },

    easeOutCirc (t) {
      t--
      return Mathf.Sqrt(1 - t * t)
    },

    easeInOutCirc (t) {
      t /= 0.5
      if (t < 1) return -(Mathf.Sqrt(1 - t * t) - 1) / 2
      t -= 2
      return (Mathf.Sqrt(1 - t * t) + 1) / 2
    },
    //----------ANIMATION FUNCTIONS END----------


    // ----------SCROLLING FUNCTION START----------

    //Element to move, time in ms to animate

    scrollTo (element, duration, cxt = null) {
      if (!cxt) var e = document.documentElement
      else e = cxt
      if (e.scrollTop === 0) {
        const t = e.scrollTop
        ++e.scrollTop
        e = t + 1 === e.scrollTop-- ? e : document.body
      }
      this.scrollToC(e, e.scrollTop, element, duration)
    },

    // Element to move, element or px from, element or px to, time in ms to animate
    scrollToC (element, from, to, duration) {
      if (duration <= 0) return
      if (typeof from === 'object') from = from.offsetTop
      if (typeof to === 'object') to = to.offsetTop

      this.scrollToX(element, from, to, 0, 1 / duration, 20, this.easeOutCuaic)
    },

    scrollToX (element, xFrom, xTo, t01, speed, step, motion) {
      if (t01 < 0 || t01 > 1 || speed <= 0) {
        element.scrollTop = xTo
        return
      }
      element.scrollTop = xFrom - (xFrom - xTo) * motion(t01)
      t01 += speed * step

      setTimeout(() => {
        this.scrollToX(element, xFrom, xTo, t01, speed, step, motion)
      }, step)
    }

    // ----------SCROLLING FUNCTION END----------
  }
}