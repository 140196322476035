<template>
  <div>

    <!-- Changer button -->
    <vs-row vs-w="12" class="mb-10">
      <vs-col class="bg-white" :vs-w="6">
        <feather-icon icon="MoreHorizontalIcon" class="m-0 cursor-pointer"/>
        <vs-radio v-model="layout" vs-name="layout" vs-value="horizontal">
          Horizontal
        </vs-radio>
      </vs-col>
      <vs-col class="bg-white" :vs-w="6">
        <feather-icon icon="MoreVerticalIcon" class="m-0 cursor-pointer"/>
        <vs-radio v-model="layout" vs-name="layout" vs-value="vertical">
          Vertical
        </vs-radio>
      </vs-col>
    </vs-row>


    <!-- Vertical Layout -->
    <vs-row class="h-screen">
      <vs-col class="bg-white" vs-w="6">
        <mainview :markdown="markdownInternal"></mainview>
      </vs-col>
      <vs-col class="bg-white" vs-w="6">
        <preview></preview>
      </vs-col>
    </vs-row>


  </div>
</template>

<script>

import mainview from './mainview'
import preview from './preview'

export default {
  name: "Wysiwyg",
  props: ['markdown'],
  components: {
    mainview, preview
  },
  data () {
    return {
      layout: 'vertical',
      markdownInternal: this.markdown,
      mdDoc: '',
      theme: 'default',
      hasToolbar: true,
      previewExtensions: null
    }
  },
  methods: {

  },
  watch:{
    markdownInternal () {
      this.$emit('update:markdown')
    }
  },
  computed:{
    layoutWidth () {
      let width = ''
      switch (this.layout) {
      case 'horizontal':
        width = 12
        break

      case 'vertical':
        width =  6
        break
      }

      return width
    },
    layoutHeight () {
      let height = ''
      switch (this.layout) {
      case 'horizontal':
        height = ''
        break

      case 'vertical':
        height = 'h-full'
        break
      }

      return height
    }
  },
  created () {
    console.log('the app is created!')
    if (localStorage) {
      if (localStorage.hasOwnProperty('vue-mdse:locale')) {
        this.locale = localStorage['vue-mdse:locale']
      }
      if (localStorage.hasOwnProperty('vue-mdse:theme')) {
        this.theme = localStorage['vue-mdse:theme']
      }
    }
    console.log(`the app locale is ${this.locale} and theme is ${this.theme}!`)

    // this.$nextTick(function () {
    //   this.$refs.mdse.addOutsideMenu(getOutsideMenu)
    //   this.$refs.mdse.setEditorTheme(this.theme)
    //   const that = this
    //   const defHtml = axios.get('https://jhuix.github.io/showdowns/showdowns-features.md')
    //   defHtml
    //     .then(function (res) {
    //       that.mdDoc = res.data
    //       return axios.get("https://jhuix.github.io/showdowns/Showdown's-Markdown-syntax.md")
    //     })
    //     .then(function (res) {
    //       that.mdDoc = `${that.mdDoc  }\n\n${  res.data}`
    //     })
    //     .catch(function (error) {
    //       console.log(error)
    //     })
    // })
  }
}
</script>

