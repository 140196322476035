<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div>

        <!-- HEADING -->
        <vs-row class="bg-white my-4">
            <vs-col class="flex justify-items-center p-4">
                <vs-button class="mr-4" color="primary" type="filled" vs-align="right"
                           @click="$router.push('/student/new').catch(() => {})">{{ $t("modules.student.Add Student") }}
                </vs-button>
                <vs-button class="mr-4" color="primary" type="filled" vs-align="right" @click="refreshData()">
                    {{ $t("modules.student.Refresh") }}
                </vs-button>
                <vs-button class="mr-4" color="primary" type="filled" vs-align="right" @click="exportData()">
                    {{ $t("modules.student.Export") }}
                </vs-button>
            </vs-col>
        </vs-row>

        <!-- SEARCH -->
        <div class="bg-white my-4">
            <vs-row>
                <vs-col class="flex justify-items-center p-4" vs-w="12">
                    <h2 class="text-black text-sm">{{ $t("modules.student.Search Filter") }}</h2>
                </vs-col>
            </vs-row>

            <!-- CONTROLS -->
            <vs-row>
                <vs-col class="justify-items-center p-4 xs:w-full" vs-w="3" vs-sm="6" vs-xs="12">
                    <vs-input class="inputx sm:w-full" :placeholder="$t('modules.student.Package')"
                              @keyup="handlePackageSearch"
                              v-model="package_name" />
                </vs-col>

                <vs-col class="justify-items-center p-4 xs:w-full" vs-w="3" vs-sm="6" vs-xs="12">
                    <vs-input class="inputx sm:w-full" :placeholder="$t('modules.student.Student Name')"
                              @keyup="handleNameSearch" v-model="name" />
                </vs-col>

                <vs-col class="flex justify-items-center p-4 xs:w-full" vs-w="3" vs-sm="6" vs-xs="12">
                    <vs-select
                        class="sm:w-full" @change="handleGradeSearch"
                        :placeholder="$t('modules.student.Grade')"
                        v-model="grade">
                        <vs-select-item id="grade" :key="index" :value="item.name" :text="item.title"
                                        v-for="(item,index) in grade_levels" />
                    </vs-select>
                </vs-col>

                <vs-col class="flex justify-items-center p-4 xs:w-full" vs-w="3" vs-sm="6"  vs-xs="12">
                    <vs-select
                        class="sm:w-full" @change="handleScoreSearch"
                        :placeholder="$t('modules.student.Score')"
                        v-model="current_score">
                        <vs-select-item id="score" :key="index1" :value="item1.name1" :text="item1.title1"
                                        v-for="(item1,index1) in score" />
                    </vs-select>
                </vs-col>


            </vs-row>

        </div>

        <!-- TABLE -->
        <vx-card>
            <vs-table :data="pageData.items" :sst="true" ref="table" :key="renderKey"
                      @search="handleSearch" @change-page="handleChangePage" @sort="handleSort"
                      :total="pageData.total"
                      :max-items="pageData.page_size" noDataText="" stripe pagination search>

                <template slot="header">
                    <h3>
                        {{ $t("modules.student.Student List") }}
                    </h3>
                </template>

                <template slot="thead">
                    <vs-th sort-key="student_id">{{ $t("modules.student.ID") }}</vs-th>
                    <vs-th sort-key="title">{{ $t("modules.student.Name") }}</vs-th>
                    <vs-th sort-key="package">{{ $t("modules.student.Package Name") }}</vs-th>
                     <vs-th sort-key="package">{{ $t("modules.student.Teacher") }}</vs-th>
                    <vs-th sort-key="score">{{ $t("modules.student.Score") }}</vs-th>
                    <vs-th sort-key="grade">{{ $t("modules.student.Grade") }}</vs-th>
                    <vs-th sort-key="score">{{ $t("modules.student.Age") }}</vs-th>
                    <vs-th sort-key="gender">{{ $t("modules.student.Gender") }}</vs-th>
                    <vs-th sort-key="created_at">{{ $t("modules.student.Date") }}</vs-th>
                    <vs-th sort-key="status">{{ $t("modules.student.Status") }}</vs-th>
                    <vs-th>{{ $t("modules.student.Action") }}</vs-th>
                </template>

                <template >
                    <vs-tr :key="indextr" v-for="(tr, indextr) in pageData.items">

                        <vs-td :data="pageData.items[indextr].student_id">
                            #{{ pageData.items[indextr].student_id }}
                        </vs-td>

                        <vs-td :data="pageData.items[indextr].first_name">
                            {{ pageData.items[indextr].first_name }} {{ pageData.items[indextr].last_name }}
                        </vs-td>


                        <vs-td :data="pageData.items[indextr].package_name">
                            {{ pageData.items[indextr].package_name }}
                        </vs-td>

                        <vs-td>              
                        </vs-td>
                        <vs-td :data="pageData.items[indextr].current_score">
                            {{ pageData.items[indextr].current_score }}
                        </vs-td>

                        <vs-td :data="pageData.items[indextr].grade">
                            {{ pageData.items[indextr].grade }}
                        </vs-td>
                        <vs-td :data="pageData.items[indextr].dob">
                          {{ getAge(pageData.items[indextr].dob) }}
                        </vs-td>
                        <vs-td :data="pageData.items[indextr].gender">
                            {{ pageData.items[indextr].gender }}
                        </vs-td>

                        <vs-td :data="pageData.items[indextr].createdAt">
                            {{ date_format(pageData.items[indextr].createdAt) }}
                        </vs-td>

                        <vs-td :data="pageData.items[indextr].status">
                            <vs-chip v-if="pageData.items[indextr].status==0" :color="'#d9534f'" class="product-order-status">
                                {{ $t("modules.profile.InActive") }}
                            </vs-chip>
                            <vs-chip v-if="pageData.items[indextr].status==1" :color="'#5cb85c'" class="product-order-status">
                                {{ $t("modules.profile.Active") }}
                            </vs-chip>
                        </vs-td>

                        <vs-td class="whitespace-no-wrap">
                            <feather-icon icon="EditIcon"
                                          svgClasses="w-5 h-5 hover:text-primary stroke-current edit_delete"
                                          @click.stop="$router.push('/student/edit/'+tr._id).catch(() => {})" />
                            <feather-icon icon="TrashIcon"
                                          svgClasses="w-5 h-5 hover:text-danger stroke-current edit_delete"
                                          class="ml-2" @click.stop="deleteStudent(tr._id)" />
                        </vs-td>
                    </vs-tr>
                </template>

                <template #footer>
                    <vs-pagination :total="pageData.total_pages" v-model="pageData.page" :key="renderKey" />
                </template>

            </vs-table>

        </vx-card>

    </div>
</template>

<script>
// import moduleRole          from '@/store/role/moduleRole.js'
import Vue from 'vue'
import axios from '@/axios'

export default {

  data () {
    return {
      renderKey: 2,
      formData: {},
      displayPrompt: false,
      pageData: {},
      is_loaded: false,
      selected: {},
      package_name: '',
      name: '',
      grade: '',
      current_score: '',
      keyword: '',
      sort_by: '',
      ordering: '',
      page: 1,
      limit: 3,
      grade_levels: [
        { name: '', title: 'All' },
        { name: 'elementary', title: 'Elementary' },
        { name: 'intermediate', title: 'Intermediate' },
        { name: 'higher', title: 'Higher' }
      ],
      score: [
        { name1: '10', title1: '0-10' },
        { name1: '25', title1: '0-25' },
        { name1: '50', title1: '0-50' },
        { name1: '75', title1: '0-75' },
        { name1: '100', title1: '0-100' }
      ]
    }
  },
  components: {},
  computed: {

  },
  methods: {
    handleSearch (searching) {
      this.page = 1 //reset page if criteria changed
      this.keyword = searching
      this.fetch_students()
    },

    handleSort (col, ord) {
      this.sort_by = col
      this.ordering = ord
      this.fetch_students()
    },

    handleChangePage (page) {
      this.page = page
      this.fetch_students()
    },

    handlePackageSearch () {
      //this.package_name=package_name;
      this.page = 1 //reset page if criteria changed
      this.fetch_students()
    },
    handleNameSearch () {
      this.page = 1 //reset page if criteria changed
      this.fetch_students()
    },
    handleGradeSearch () {
      this.page = 1 //reset page if criteria changed
      this.fetch_students()
    },
    handleScoreSearch () {
      this.page = 1 //reset page if criteria changed
      this.fetch_students()
    },
    getAge(dob) {
        const today = new Date();
        const birthDate = new Date(dob);
        const age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        console.log(today)
        console.log(birthDate)
        console.log(m)
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
        {
            age--;
        }
        return age+' Yr'+', '+m+' m';
    },
    refreshData () {
      this.name = ''
      this.pakage_name = ''
      this.grade = ''
      this.current_score = ''
      this.fetch_students()
    },

    deleteStudent (id) {
      this.$vs.loading()
      axios.delete(`/students/${id}`)
        .then((response) => {
          console.log(response)
          if (response.status === 200) {
            this.notifyUI('Deleted!', 'Student deleted sucessfully', 'success')

            this.fetch_students()
          }
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },

    exportData () {
      this.$vs.loading()
      axios({ method: 'get', url: '/export-student', responseType: 'arraybuffer' })
        .then((response) => {
          this.forceFileDownload(response, 'student_data')
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },

    forceFileDownload (response, title) {
      const url = window.URL.createObjectURL(new Blob([response.data], {
        type: 'application/vnd.ms-excel'
      }))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', title)
      document.body.appendChild(link)
      link.click()
    },


    date_format (date) {
      const d = new Date(date)
      return d.toDateString()
    },


    //Fecth from API
    fetch_students () {
      this.$vs.loading()

      axios.get('/students', {
        params: {
          package_name: this.package_name,
          name: this.name,
          grade: this.grade,
          current_score: this.current_score,
          keyword: this.keyword,
          sort_by: this.sort_by,
          ordering: this.ordering,
          page: this.page,
          limit: this.limit
        }
      })
        .then((response) => {
          //this.pageData = response.data
          Vue.set(this, 'pageData', response.data)
          this.is_loaded = true
          if (this.page == 1) this.renderKey = Math.random()
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    }
  },
  created () {
    this.fetch_students()
  },
  beforeDestroy () {
    // this.$store.unregisterModule('role')
  }
}
</script>
