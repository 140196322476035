<template>
  <div>
    <vs-chip v-if="params.value==0" :color="'#d9534f'" class="product-order-status">InActive</vs-chip>
    <vs-chip v-if="params.value==1" :color="'#5cb85c'" class="product-order-status">Active</vs-chip>
    <vs-chip v-if="params.value==2" :color="'red'" class="product-order-status">Deleted</vs-chip>
  </div>
</template>

<script>
export default {
  name: 'CellRendererStatus',
  computed: {
  }
}
</script>

<style lang="scss" scpoped>
.ag-grid-cell-chip {
  &.vs-chip-success {
    background: rgba(var(--vs-success),.15);
    color: rgba(var(--vs-success),1) !important;
    font-weight: 500;
  }
  &.vs-chip-warning {
    background: rgba(var(--vs-warning),.15);
    color: rgba(var(--vs-warning),1) !important;
    font-weight: 500;
  }
  &.vs-chip-danger {
    background: rgba(var(--vs-danger),.15);
    color: rgba(var(--vs-danger),1) !important;
    font-weight: 500;
  }
}
</style>
