<template>

  <div class="locale-switcher">

    <vs-select v-model="$i18n.locale">
      <vs-select-item v-for="locale in locales" :value="locale.value" :text="locale.text" :key="locale.value">
        {{ locale.name }}
        <!-- Vuesax -->
      </vs-select-item>
    </vs-select>

  </div>
</template>


<script>
import { getSupportedLocales } from '@/i18n/i18n'

export default {
  data () {
    const locales = getSupportedLocales().map((el) => {
      return { text: el.name, value: el.code }
    })
    return {
      locales
    }
  },

  methods: {
    updateLocale (locale) {
      this.$i18n.locale = locale
    }
  }
}
</script>
