<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <vx-card>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4">
            <label class="text-sm" style="margin-left: 4px;">Question <span class="text-danger"> * </span></label>
            <vs-textarea rows="1" v-model="formData['question']" placeholder="Short Description..." v-validate="'required'" name="question" />
            <span class="text-danger text-sm"  v-show="errors.has('question')">{{ errors.first('question') }}</span>
          </div>
        </div>
        <div class="vx-col w-full">
          <label class="text-sm" style="margin-left: 4px;">Answer <span class="text-danger"> * </span></label>
          <quill-editor v-model="formData['answer']" v-validate="'required'" name="answer"></quill-editor>
        </div>
        <div class="vx-col w-full">
          <div class="mt-4">
            <label class="text-sm" style="margin-left: 4px;">Status</label>
            <vs-switch color="success" v-model="status_model">
                <span slot="on">Active</span>
                <span slot="off">InActive</span>
            </vs-switch>
          </div>
        </div>
      </div>

      <!-- Save & Reset Button -->
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <vs-button class="ml-auto mt-2" @click="save_changes" :disabled="!validateForm">Save Changes</vs-button>
            <vs-button class="ml-4 mt-2" v-if="reset_btn" type="border" color="warning" @click="reset_data">Reset</vs-button>
            <vs-button class="ml-4 mt-2" v-if="canel_btn" type="border" color="warning" @click="cancel_data">Cancel</vs-button>
           </div>
        </div>
      </div>

    </vx-card>
</template>

<script>
// import moduleFAQ          from '@/store/faq/moduleFAQ.js'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  data () {
    return {
      formData      : {},
      reset_btn : true,
       canel_btn : false,
    }
  },
  components: {
    quillEditor
  },
  computed: {
    faq ()     { 
      if (!this.$route.params.id) {
        return {'status':'0'}
      } else {
         this.reset_btn = false
            this.canel_btn = true
        return this.$store.getters['faq/getFAQ'](this.$route.params.id)
      }
    },
    status_model: {
      get () {
        return this.formData['status'] === '1'
      },
      set (status) {
        this.formData['status'] = status ? '1' : '0'
      }
    },
    validateForm () {
      return !this.errors.any() && this.formData.question !== '' && this.formData.answer !== '' && this.formData.question && this.formData.answer
    }
  },
  methods: {
     cancel_data(){
        this.$router.push('/faq/all/').catch(() => {})
    },
    save_changes () {
      this.$validator.validateAll().then(result => {
          this.$vs.loading()
        if (result) {
            this.formData['answer'] = this.formData['answer'].replace(/<[\/]{0,1}(span)[^><]*>/ig,"")
            this.formData['answer'] = this.formData['answer'].replace(/<[\/]{0,1}(p)[^><]*>/ig,"")
          if (this.formData['faq_id']) {
            this.$store.dispatch('faq/updateFAQ', Object.assign({}, this.formData)).then(() => {
               this.$vs.loading.close()  
              const success = 'FAQ Updated Successfully...!!!'
              this.notif_sucess(success) 
            }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
            })
          } else {
            this.$store.dispatch('faq/addFAQ', Object.assign({}, this.formData)).then(() => {
              this.$store.dispatch('faq/fetchFAQs')
               this.$vs.loading.close()  
              const success = 'FAQ Added Successfully...!!!'
              this.notif_sucess(success) 
            }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
            })
          }
        }
      })
    },
    reset_data () {
      this.formData = Object.assign({}, this.faq)
    },
      notif (error) {
      this.$vs.notify({
        title: 'Alert',
        text: error.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    },
    notif_sucess (success) {
      this.$vs.notify({
        title: 'success',
        text: success,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color:"success"
      })
    },
  },
  created () {
    if (!this.faq) {
      this.$store.dispatch('faq/fetchFAQs').then(() => {
        this.formData = Object.assign({}, this.faq)
      })
    } else {
      this.formData = Object.assign({}, this.faq)
    }
    // this.$store.registerModule('faq', moduleFAQ)
  },
  beforeDestroy () {
    // this.$store.unregisterModule('faq')
  }
}
</script>
