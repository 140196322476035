<!-- =========================================================================================
  File Name: DashboardAnalytics.vue
  Description: Dashboard Analytics
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>

  <div id="dashboard-analytics">

    <vs-row class="bg-white mt-6 p-10" vs-w="12" v-if="dashboard_loaded" vs-justify="space-between">
      <vs-col vs-w="3" vs-sm="4" vs-xs="6" vs-type="flex">
        <StatisticsCardLine
            icon="UsersIcon"
            color="info"
            :statistic="count.student"
            :statistic-title="$t('dashboard.Students')"
            style="max-width:200px;max-height:150px"/>
      </vs-col>
      <vs-col vs-w="3" vs-sm="4" vs-xs="6" vs-type="flex">
        <StatisticsCardLine
            icon="UsersIcon"
            color="info"
            :statistic="count.teacher"
            :statistic-title="$t('dashboard.Teachers')"
            style="max-width:200px;max-height:150px"/>
      </vs-col>
      <vs-col vs-w="3" vs-sm="4" vs-xs="6" vs-type="flex">
        <StatisticsCardLine
            icon="BoxIcon"
            color="info"
            :statistic="count.pretest_package"
            :statistic-title="$t('dashboard.Pretest')"
            style="max-width:200px;max-height:150px"/>
      </vs-col>

      <vs-col vs-w="3" vs-sm="4" vs-xs="6" vs-type="flex">
        <StatisticsCardLine
            icon="BoxIcon"
            color="info"
            :statistic="count.pretest_package"
            :statistic-title="$t('dashboard.Packages')"
            style="max-width:200px;max-height:150px"/>
      </vs-col>
    </vs-row>


    <div class="vx-row" v-if="dashboard_loaded">
      <!--      sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2-->
      <!--  Graph1 : Full width    -->
      <div class="vx-col w-full  mb-base">
        <vx-card>
          <div>
            <!--  GRAPH 1  -->
            <apexchart type="area" height="350" :options="graph1Config" :series="graph1Data"></apexchart>
          </div>
          <div>
            <vs-radio class="px-10" v-model="graph1_type" vs-name="graph1_type" vs-value="cumulative">{{$t("dashboard.Overall Count")}}</vs-radio>
            <vs-radio class="px-10" v-model="graph1_type" vs-name="graph1_type" vs-value="absolute">{{$t("dashboard.New Registrations")}}</vs-radio>
          </div>
        </vx-card>
      </div>
      <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4 mb-base">
        <vx-card>
          <div class="chart-wrap">
            <div>
              <!--  GRAPH 2  -->
              <apexchart type="bar" height="350" :options="EchartOptions" :series="Eseries"></apexchart>
            </div>
          </div>
        </vx-card>
      </div>
      <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4 mb-base">
        <vx-card>
          <div class="chart-wrap">
            <div>
              <!--  GRAPH 3  -->
              <apexchart type="donut" width="350" :options="chartOptions" :series="series"></apexchart>
            </div>
          </div>
        </vx-card>
      </div>
    </div>

    <vx-card v-if="is_loaded">

      <vs-row vs-type="flex" vs-justify="space-between" class="mb-10">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
          <h2 class="text-black flex">{{ $t("dashboard.Student List") }}</h2>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <vs-button class="flex" color="primary" type="filled" vs-align="left"
                     @click="$router.push('/student').catch(() => {})">
            {{ $t("dashboard.View All") }}
          </vs-button>
        </vs-col>
      </vs-row>


      <vs-table :sst="true" ref="table" :data="pageData.items"
                @search="handleSearch" @change-page="handleChangePage" @sort="handleSort" v-model="selected"
                :total="pageData.total"
                :max-items="pageData.page_size">
        <template slot="thead">
          <vs-th sort-key="student_id">{{ $t("modules.student.ID") }}</vs-th>
          <vs-th sort-key="title">{{ $t("modules.student.Name") }}</vs-th>

          <vs-th sort-key="package">{{ $t("modules.student.Package Name") }}</vs-th>
          <vs-th sort-key="package">{{ $t("modules.student.Teacher") }}</vs-th>
          <vs-th sort-key="score">{{ $t("modules.student.Score") }}</vs-th>
          <vs-th sort-key="grade">{{ $t("modules.student.Grade") }}</vs-th>
          <vs-th sort-key="score">{{ $t("modules.student.Age") }}</vs-th>
          <vs-th sort-key="gender">{{ $t("modules.student.Gender") }}</vs-th>
          <vs-th sort-key="created_at">{{ $t("modules.student.Date") }}</vs-th>
          <!-- <vs-th sort-key="status">{{ $t("modules.student.Status") }}</vs-th> -->
          <vs-th>{{ $t("modules.student.Action") }}</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

            <vs-td :data="data[indextr].student_id">
              #{{ data[indextr].student_id }}
            </vs-td>

            <vs-td :data="data[indextr].first_name">
              {{ data[indextr].first_name }} {{ data[indextr].last_name }}
            </vs-td>


            <vs-td :data="data[indextr].package_name">
              {{ data[indextr].package_name }}
            </vs-td>

            <vs-td>
            </vs-td>

            <vs-td :data="data[indextr].current_score">
              {{ data[indextr].current_score }}
            </vs-td>

            <vs-td :data="data[indextr].grade">
              {{ data[indextr].grade }}
            </vs-td>

            <vs-td :data="data[indextr].dob">
              {{ getAge(data[indextr].dob) }}
            </vs-td>

            <vs-td :data="data[indextr].gender">
              {{ data[indextr].gender }}
            </vs-td>

            <vs-td :data="data[indextr].createdAt">
              {{ date_format(data[indextr].createdAt) }}
            </vs-td>

            <!-- <vs-td :data="data[indextr].status">
              <vs-chip v-if="data[indextr].status==0" :color="'#d9534f'" class="product-order-status">{{$t('modules.profile.InActive')}}</vs-chip>
              <vs-chip v-if="data[indextr].status==1" :color="'#5cb85c'" class="product-order-status">{{$t('modules.profile.Active')}}</vs-chip>
            </vs-td> -->

            <vs-td class="whitespace-no-wrap">
              <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current edit_delete"
                            @click.stop="$router.push('/student/edit/'+tr._id).catch(() => {})"/>
              <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current edit_delete"
                            class="ml-2" @click.stop="deleteStudent(tr._id)"/>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <vs-pagination v-model="page" :total="pageData.total" :max="6"/>
        </template>

      </vs-table>
    </vx-card>


    <br>
    <br>

    <vx-card v-if="is_loaded1">

      <vs-row vs-type="flex" vs-justify="space-between" class="mb-10">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">
          <h2 class="text-black flex">{{ $t('dashboard.Teacher List') }}</h2>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <vs-button class="flex" color="primary" type="filled" vs-align="left"
                     @click="$router.push('/teacher').catch(() => {})">
            {{ $t('dashboard.View All') }}
          </vs-button>
        </vs-col>
      </vs-row>

      <vs-table :sst="true" ref="table" :data="pageData1.items"
                @search="handleSearch" @change-page="handleChangePage" @sort="handleSort" v-model="selected"
                :total="pageData.total"
                :max-items="pageData.page_size">
        <template slot="thead">
          <vs-th sort-key="student_id">{{ $t("modules.teacher.ID") }}</vs-th>
          <vs-th sort-key="title">{{ $t("modules.teacher.Name") }}</vs-th>
          <vs-th sort-key="email">{{ $t("modules.teacher.Email") }}</vs-th>
          <vs-th sort-key="phone">{{ $t("modules.teacher.Phone Number") }}</vs-th>
          <vs-th sort-key="grade">{{ $t("modules.teacher.Grade") }}</vs-th>
          <!-- <vs-th sort-key="age">Age</vs-th> -->
          <vs-th sort-key="gender">{{ $t("modules.teacher.Gender") }}</vs-th>
          <vs-th sort-key="created_at">{{ $t("modules.teacher.Date") }}</vs-th>
          <!-- <vs-th sort-key="status">{{ $t("modules.teacher.Status") }}</vs-th> -->
          <vs-th>{{ $t("modules.teacher.Action") }}</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

            <vs-td :data="data[indextr].teacher_id">
              #{{ data[indextr].teacher_id }}
            </vs-td>

            <vs-td :data="data[indextr].first_name">
              {{ data[indextr].first_name }} {{ data[indextr].last_name }}
            </vs-td>

            <vs-td :data="data[indextr].email">
              {{ data[indextr].email }}
            </vs-td>
            <vs-td :data="data[indextr].phone">
              {{ data[indextr].phone }}
            </vs-td>
            <vs-td :data="data[indextr].grade">
              {{ data[indextr].grade }}
            </vs-td>
            <vs-td :data="data[indextr].gender">
              {{ data[indextr].gender }}
            </vs-td>

            <vs-td :data="data[indextr].createdAt">
              {{ date_format(data[indextr].createdAt) }}
            </vs-td>

            <!-- <vs-td :data="data[indextr].status">
              <vs-chip v-if="data[indextr].status==0" :color="'#d9534f'" class="product-order-status">{{$t('modules.profile.InActive')}}</vs-chip>
              <vs-chip v-if="data[indextr].status==1" :color="'#5cb85c'" class="product-order-status">{{$t('modules.profile.Active')}}</vs-chip>
            </vs-td> -->

            <vs-td class="whitespace-no-wrap">
              <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current edit_delete"
                            @click.stop="$router.push('/teacher/edit/'+tr._id).catch(() => {})"/>
              <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current edit_delete"
                            class="ml-2" @click.stop="deleteTeacher(tr._id)"/>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <vs-pagination v-model="page" :total="pageData.total" :max="6"/>
        </template>

      </vs-table>

    </vx-card>
  </div>

</template>


<script>
import VueApexCharts from "vue-apexcharts";
import UploadVideoModal from '@/components/UploadVideoModal'

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts,UploadVideoModal)

import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";
import VxTimeline from "@/components/timeline/VxTimeline";
import vSelect from "vue-select";

import {CalendarView, CalendarViewHeader} from "vue-simple-calendar";

// require("vue-simple-calendar/static/css/default.css");
import axios from "@/axios";
import Vue from 'vue'

export default {
  data() {
    return {
      displayPrompt: false,
      pageData: {},
      pageData1: {},
      is_loaded: false,
      is_loaded1: false,
      selected: {},
      package_name: "",
      name: "",
      grade: "",
      current_score: "",
      keyword: "",
      sort_by: "",
      ordering: "",
      page: 1,
      limit: 3,
      total_results: 0,

      count: {},

      dashboard_loaded: false,
      //GRAPH 1
      graph1_type: 'cumulative', //this only for graph type 1
      graph1Data: [
        { name: 'Students', data: [] },
        { name: 'Teachers', data: [] },
        { name: 'Pre Tests', data: [] }
      ],
      graph1DataStore:{
        cumulative: [],
        absolute: [],
      },
      graph1Config: {
        chart: {
          height: 350,
          type: 'area'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'datetime',
          categories: []
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          },
        },
      },


      series: [44, 55, 13, 33],
      chartOptions: {
        chart: {
          width: 300,
          type: 'donut'
        },
        dataLabels: {
          enabled: false
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                show: false
              }
            }
          }
        ],
        legend: {
          position: 'bottom',
          // offsetY: 0,
          width: 200
        }

      },

      Eseries: [{
        name: 'Net Profit',
        data: [44, 55, 57]
      }],
      EchartOptions: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 5,
          colors: ['transparent']
        },
        xaxis: {
          categories: ['Feb', 'Mar', 'Apr'],
        },
        yaxis: {
          title: {
            text: '$ (thousands)'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "$ " + val + " thousands"
            }
          }
        }
      },

    };
  },
  components: {
    StatisticsCardLine,
    // apexchart: VueApexCharts
  },

  methods: {
    appendData() {
      const arr = this.series.slice()
      arr.push(Math.floor(Math.random() * (100 - 1 + 1)) + 1)
      this.series = arr
    },

    removeData() {
      if (this.series.length === 1) {
        return
      }
      const arr = this.series.slice()
      arr.pop()
      this.series = arr
    },

    randomize() {
      this.series = this.series.map(function () {
        return Math.floor(Math.random() * (100 - 1 + 1)) + 1
      })
    },

    reset() {
      this.series = [44, 55, 13, 33]
    },

    handleSearch(searching) {
      this.keyword = searching;
      this.fetch_students();
    },

    handleSort(col, ord) {
      this.sort_by = col;
      this.ordering = ord;
      this.fetch_students();
    },

    handleChangePage(page) {
      this.page = page;
      this.fetch_students();
    },
    date_format(date) {
      const d = new Date(date);
      return d.toDateString();
    },
    getAge(dob) {
      const today = new Date();
      const birthDate = new Date(dob);
      const age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      console.log(today)
      console.log(birthDate)
      console.log(m)
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age + ' Yr' + ', ' + m + ' m';
    },
    deleteStudent(id) {
      this.$vs.loading()
      axios.delete(`/students/${id}`)
          .then((response) => {
            console.log(response)
            if (response.status === 200) {
              this.notifyUI('Deleted!', 'Student deleted sucessfully', 'success')

              this.fetch_students();
            }
          })
          .catch((error) => {
            alert(`Error: ${error}`)
          })
          .then(() => this.$vs.loading.close())
    },
    //Fecth from API
    fetch_students() {
      this.$vs.loading();

      axios.get("/students", {
        params: {
          package_name: this.package_name,
          name: this.name,
          grade: this.grade,
          current_score: this.current_score,
          keyword: this.keyword,
          sort_by: this.sort_by,
          ordering: this.ordering,
          page: this.page,
          limit: this.limit
        }
      })
          .then((response) => {
            this.pageData = response.data;
            this.is_loaded = true;
            this.total_results = response.total;
          })
          .catch((error) => {
            alert(`Error: ${error}`);
          })
          .then(() => this.$vs.loading.close());
    },
    //Fecth from API
    fetch_teachers() {
      this.$vs.loading();

      axios.get("/teachers", {
        params: {
          name: this.name,
          grade: this.grade,
          keyword: this.keyword,
          sort_by: this.sort_by,
          ordering: this.ordering,
          page: this.page,
          limit: this.limit
        }
      })
          .then((response) => {
            this.pageData1 = response.data;
            this.is_loaded1 = true;
            this.total_results1 = response.total;
          })
          .catch((error) => {
            alert(`Error: ${error}`);
          })
          .then(() => this.$vs.loading.close());
    },

    fetch_dashboard() {
      this.$vs.loading();

      axios.get("/dashboard", {})
          .then((response) => {
            this.count = response.data.count // count of student teacher and package in top tiles

            this.graph1Config.xaxis.categories = response.data.graph1.x_axis // X-axis that is the date line
            this.graph1Data = response.data.graph1.y_axis.cumulative // Y-axis , 3 lines set
            this.graph1DataStore = response.data.graph1.y_axis // for later use
            this.dashboard_loaded = true
          })
          .catch((error) => {
            alert(`Error: ${error}`);
          })
          .then(() => this.$vs.loading.close());
    }


  },
  created() {
    //this.fetch_students();
    //this.fetch_teachers();
    //this.fetch_dashboard();
  },

  watch:{
    graph1_type(val){

      switch (val) {
        case 'cumulative':
          this.graph1Data = this.graph1DataStore.cumulative // update value Y-axis , 3 lines set
        break
        case 'absolute':
          this.graph1Data = this.graph1DataStore.absolute // update value Y-axis , 3 lines set
          break
      }

    }
  }

};
</script>

<style lang="scss">
/*! rtl:begin:ignore */
#dashboard-analytics {

  .greet-user {
    position: relative;

    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }

    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }

  }

  @media (max-width: 576px) {
    .decore-left, .decore-right {
      width: 140px;
    }
  }

}

/*! rtl:end:ignore */
</style>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/simple-calendar.scss";
</style>
