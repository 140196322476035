<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vx-card>

    <!-- Content Row -->
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">


          <vs-select
          :color="colorx"
          :label="$t('modules.questionnaires.Question type')"
          v-model="formData['question_type_code']" v-validate="'required'"
        >
          <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in question_types"/>
        </vs-select>


        <label class="text-sm">{{$t('modules.questionnaires.Question Thinking Code')}}</label>
        <vs-textarea rows="3" v-model="formData['question_thinking_code']" placeholder="" />
        <span class="text-danger text-sm" v-show="errors.has('question_thinking_code')">{{ errors.first("question_thinking_code") }}</span>

        <label class="text-sm">{{$t('modules.questionnaires.Problem Solution Code')}}</label>
        <vs-textarea rows="3" v-model="formData['problem_solution_code']" placeholder="" />
        <span class="text-danger text-sm" v-show="errors.has('problem_solution_code')">{{ errors.first("problem_solution_code") }}</span>


        <div class="mt-6 centerx w-full vx-row">
          <label class="text-sm my-4 my-1">{{$t('modules.questionnaires.Status')}}</label>
          <vs-switch color="success my-3 mx-4" v-model="formData['status']" vs-value="1">
            <span slot="on">Active</span>
            <span slot="off">InActive</span>
          </vs-switch>
        </div>

      </div>
    </div>


    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" :disabled="!validateForm" @click="save" >{{$t('modules.questionnaires.Save Changes')}}</vs-button>
          <vs-button class="ml-4 mt-2" v-if="reset_btn" type="border" color="warning" @click="reset">{{$t('modules.questionnaires.Reset')}}</vs-button>
          <vs-button class="ml-4 mt-2" v-if="cancel_btn" type="border" color="warning" @click="cancel">{{$t('modules.questionnaires.Cancel')}}
          </vs-button>
        </div>
      </div>
    </div>

  </vx-card>
</template>

<script>
// import moduleRole          from '@/store/role/moduleRole.js'
import vSelect from 'vue-select'
import axios from '@/axios'
import Vue from 'vue';
import _ from "lodash";
import validator from "validator";

export default {
  components: {
    vSelect,
  },
  data () {
    return {
      formData: {
        question_type_code: '',
        question_thinking_code: '',
        problem_solution_code:'',
        status: '0',
      },
      colorx:'#103767',
      reset_btn: true,
      cancel_btn: false,
      validateForm: true,
      question_types: [],
      flow_type: ''

    }
  },

  created () {
    if (this.$route.params.id) this.flow_type = 'edit'
    else this.flow_type = 'add'

    if (this.flow_type === 'edit') {
      this.getQuestionnairesDetail(this.$route.params.id)
      this.id = this.$route.params.id
    }
   this.getQuestions();
    
  },

  computed: {
validateForm () {
      return !this.errors.any() && this.question_type_code !== '' && this.question_thinking_code !== '' && this.problem_solution_code !== ''
    },
  },

  methods: {
    cancel () {
      this.$router.push('/questionnaires').catch(() => { })
    },
    reset () {
      this.$router.push('/questionnaires')
    },
    save () {
      if (this.flow_type === 'add') {
        axios.post('/questionnaires', this.formData)
          .then((response) => {
            if (response.status === 201) {
              this.notifyUI('Created!', 'Question has been created', 'success')
              this.$router.push('/questionnaires')
            }
          })
          .catch((error) => {
         this.displayErrors(error)
          })
          .then(() => this.$vs.loading.close())
      } else {
        axios.put(`/questionnaires/${this.id}`, this.formData)
          .then((response) => {
            if (response.status === 200) {
              this.$router.push('/questionnaires')
              this.notifyUI('Updated!', 'Question has been updated', 'success')
            }
          })
          .catch((error) => {
               this.displayErrors(error)
          })
          .then(() => this.$vs.loading.close())
      }
    },

     getQuestions() {
      axios.get("/questiontypes", { params: { limit: 1000 } })
        .then((response) => {
          if (response.status === 200) {
            //need format [{text: 'Square', value: 1}, ...],
            this.question_types = response.data.items.map(item => {
              return { text: item.name, value: item._id };
            });
          }
        })
        .catch((error) => {
          //alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close());
    },

    getQuestionnairesDetail (id) {
      axios.get(`/questionnaires/${id}`)
        .then((response) => {
          if (response.status === 200) {
            this.formData = response.data
          }
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },

   

  }
}
</script>
