<template>
  <div>

    <vs-row>
      <vs-col vs-w="12">
        <input type="file" name="image" accept="image/*" @input="setImage" class="mt-10"/>
      </vs-col>
    </vs-row>

    <vs-row vs-type="flex" vs-align="flex-between" vs-justify="center" vs-w="12">
      <!--      v-show="!has_cropped"-->

      <vs-col vs-type="flex" vs-align="center" vs-justify="center" vs-w="12">
        <!-- Image previewer -->
        <img :src="imageSrc" width="100" class="img_crop_preview" v-if="this.imageSrc"/>
      </vs-col>

      <vs-col vs-type="flex" vs-align="flex-end" vs-justify="center" vs-w="12">
        <!-- Cropper container -->
        <div
            v-if="this.imageSrc"
            class="my-3 d-flex align-items-center justify-content-center mx-auto"
        >
          <vue-cropper
              class="w-full"
              ref="cropper"
              :guides="true"
              :src="imageSrc"

              :minContainerWidth="900"
              :minContainerHeight="400"

              :minCanvasWidth="900"
              :minCanvasHeight="400"

          ></vue-cropper>

        </div>
      </vs-col>

      <vs-col vs-w="12">
        <vs-button v-if="this.imageSrc" @click="cropImage" color="primary" type="filled" class="mt-4">Crop</vs-button>
      </vs-col>
    </vs-row>



    <vs-row vs-align="flex-end" vs-type="flex" vs-justify="space-between" vs-w="12" class="mt-10" v-if="this.croppedImageSrc">
      <vs-col vs-w="12">
        <h4>Cropped Image</h4>
      </vs-col>
      <vs-col vs-w="12">
        <!-- Cropped image previewer -->
        <vs-divider border-style="solid" color="dark">Crop result</vs-divider>
        <img class="ml-2 w-50 bg-light mb-10" :src="croppedImageSrc"/>
      </vs-col>
    </vs-row>


    <vs-button v-if="croppedImageSrc" @click="uploadImage" class="mt-10">Upload</vs-button>

  </div>
</template>

<script>
import config from '@/config'

import VueCropper from "vue-cropperjs"
import "cropperjs/dist/cropper.css"
import axios from '@/axios'

export default {
  name: 'cropUpload',
  components: {
    VueCropper
  },
  data () {
    return {
      imageSrc: "",
      has_cropped: false,
      croppedImageSrc: "",
      apiBaseUrl : config.apiBaseUrl
    }
  },
  methods: {
    setImage (e) {
      const that = this

      const file = e.target.files[0]
      if (!file.type.includes("image/")) {
        alert("Please select an image file")
        return
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader()
        reader.onload = event => {
          this.imageSrc = event.target.result

          // Rebuild cropperjs with the updated source
          that.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        alert("Sorry, FileReader API not supported")
      }
    },
    cropImage () {
      this.has_cropped = true
      // Get image data for post processing, e.g. upload or setting image src
      this.croppedImageSrc = this.$refs.cropper.getCroppedCanvas().toDataURL()
    },
    uploadImage () {
      var that = this
      this.$refs.cropper.getCroppedCanvas().toBlob(function (blob) {
        const formData = new FormData()

        // Append image file
        formData.append("image", blob)

        axios
          .post(`${that.apiBaseUrl}/paragraph-image-upload`, formData)
          .then(response => {
            that.$emit('paragraph-image-uploaded', response.data)
          })
          .catch(function (error) {
            that.displayFormErrors(error)
            console.log(error)
          })
      })
    }
  }
}
</script>

<style>
.img_crop_preview{
  border: 2px solid grey;
  box-sizing: content-box;
  padding: 5px;
}
</style>