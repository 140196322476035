<template>
  <div>

  <div class="login_new_sec">
  <div class="list_loign">

  <vs-input
        v-validate="'required|email|min:3'"
        data-vv-validate-on="blur"
        name="email"
        icon-no-border
        :maxlength="40"
        icon="icon icon-user"
        icon-pack="feather"
        label-placeholder="Email"
        v-model="email"
        class="w-full"/>
  </div>
  <div class="list_loign">

  <vs-input
        data-vv-validate-on="blur"
        v-validate="'required|min:6|max:30'"
        type="password"
        name="password"
        icon-no-border
        icon="icon icon-lock"
        icon-pack="feather"
        label-placeholder="Password"
        v-model="password"
        class="w-full mt-6" />
  </div>
   <span class="text-danger text-sm">{{ errors.first('email') }}</span>
  </div>







    <span class="text-danger text-sm">{{ errors.first('password') }}</span>
    <div class="flex flex-wrap justify-between my-5">
       <!-- <vs-checkbox v-model="checkbox_remember_me" class="mb-3">Remember Me</vs-checkbox> -->
       <br/>
        <router-link to="/pages/forgot-password">Forgot Password?</router-link>
    </div>
    <div class="flex flex-wrap mb-3">
      <vs-button :disabled="!validateForm" @click="loginJWT">Login</vs-button>
    </div>
  </div>
</template>

<script>
export default {
  /*  created(){
    alert(this.$apiAdmin)
    alert(this.apiAdmin)
  }, */


  data () {
    return {
      email: '',
      password:'',
      //  email: 'admin@isabel.sg',
      //password: 'Isabel@123',
      checkbox_remember_me: false
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.email !== '' && this.password !== ''
    }
  },
  methods: {
    checkLogin () {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {

        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.notifyUI('Login Attempt', 'You are already logged in!', 'warning')

        return false
      }
      return true
    },

    loginJWT () {

      // if (!this.checkLogin()) return
      // Loading
      this.$vs.loading()

      const payload = {
        checkbox_remember_me: this.checkbox_remember_me,
        //userDetails: {
        email: this.email,
        password: this.password
        //}
      }

      this.$store.dispatch('loginJWT', payload)
        .then(() => { this.$vs.loading.close(); this.$router.push('/dashboard'); })
        .catch(error => {
          this.displayErrors(error)
        })
        .then(() => { this.$vs.loading.close() })
    },

    registerUser () {
      if (!this.checkLogin()) return
      this.$router.push('/pages/register').catch(() => {})
    }

  }
}

</script>

