/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import jwt from '@/jwt.js'
import axios from '@/axios.js'
import router from '@/router'

const actions = {

  // eslint-disable-next-line no-unused-vars
  loginJWT ({ commit, state }, payload) {

    return new Promise((resolve, reject) => {

      jwt.login(payload.email, payload.password)
        .then(response => {
          commit('UPDATE_USER_INFO', response.data.user)
          localStorage.setItem('token', response.data.token)

          commit('SET_TOKEN', response.data.token)
          commit('SET_LOGIN_IN_STATE', 1)
          commit('SET_AUTHORIZATION', response.data.authorization)

          // document.location.href="/dashboard";
          //router.push(router.currentRoute.query.to || '/dashboard')
          resolve(response)
        })
        .catch(error => {

          reject(error)
        })

    })

  },

  forgotPasswordJWT ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt.forgotPassword(payload.email)
        .then(response => {
          //commit('UPDATE_RECOVERY_EMAIL', payload.email)
          //router.push('/pages/reset-password').catch(() => { })
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  resetPasswordJWT ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt.resetPassword(payload.email, payload.verificationCode, payload.newPassword)
        .then(response => {
          // router.push('/reset').catch(() => {
          // })
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  changePasswordJWT ({ commit}, form) {
    return new Promise((resolve, reject) => {
      const user = JSON.parse(localStorage.getItem('admin_info'))
      //console.log(formData)
      axios.post(`/changePassword/${ user._id}`, form)
        .then((response) => {
          commit('UPDATE_CHANGE_PASSWORD', response.data)
          router.push('/login').catch(() => {})
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },


  logout ({ commit }) {
    commit('UPDATE_USER_INFO', {})
    commit('SET_TOKEN', '')
    commit('SET_LOGIN_IN_STATE', 0)
    commit('SET_AUTHORIZATION', '')
    commit('CLEAR_SESSION')
  },

  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu
  updateVerticalNavMenuWidth ({ commit }, width) {
    commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
  },

  // /////////////////////////////////////////////
  // UI
  // /////////////////////////////////////////////

  toggleContentOverlay ({ commit }) {
    commit('TOGGLE_CONTENT_OVERLAY')
  },
  updateTheme ({ commit }, val) {
    commit('UPDATE_THEME', val)
  },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  updateUserInfo ({ commit }, payload) {
    commit('UPDATE_USER_INFO', payload)
  },
  updateToken ({ commit }, payload) {
    commit('SET_ID_TOKEN', payload)
  },
  updateLoginState ({ commit }, payload) {
    commit('SET_LOGIN_IN_STATE', payload)
  },
  updateAuthorization ({ commit }, payload) {
    commit('SET_AUTHORIZATION', payload)
  },
  updateUserRole ({ dispatch }, payload) {
    // Change client side
    payload.aclChangeRole(payload.userRole)
    // Make API call to server for changing role
    // Change userInfo in localStorage and store
    dispatch('updateUserInfo', {userRole: payload.userRole})
  },

  changeUiLanguage({ commit }, lang){
    commit('SET_UI_LANGUAGE', lang)
  },
}

export default actions
