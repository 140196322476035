<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>

    <div class="">


        <!-- HEADING -->
        <vx-card>
            <vs-row class="bg-white">
                <vs-col class="flex justify-items-center">
                    <h2 class="text-black">
                        {{ $t(`modules.package.${flow_type == "add" ? "Add Package" : "Edit Package"}`) }}</h2>
                </vs-col>
            </vs-row>
        </vx-card>

        <vx-card class="mt-2">

          <vs-row class="flex" vs-w="12" vs-justify="space-between">
            <vs-col vs-w="6" class="mt-6"> &nbsp; </vs-col>
            <vs-col vs-w="6" vs-type="flex" class="mt-6" vs-justify="space-between">

              <div class="flex items-center">
                <span class="align-middle">{{ getCountryLang }}</span>
                <country-flag class="ml-1" :country="getCountryFlag" size="normal" />
              </div>

              <div class="float-right">
                <vs-dropdown>
                  <a class="a-icon" href.prevent>
                    {{ $t("Language") }}
                    <vs-icon class="" icon="expand_more"></vs-icon>
                  </a>

                  <vs-dropdown-menu>
                    <vs-dropdown-item @click="currLang='en'">
                      {{ $t("English") }}
                      <country-flag country="gb" size="normal" />
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="currLang='zh'">
                      {{ $t("Chinese") }}
                      <country-flag country="cn" size="normal" />
                    </vs-dropdown-item>
                  </vs-dropdown-menu>

                </vs-dropdown>
              </div>

            </vs-col>
          </vs-row>

            <vs-row class="bg-white mt-6" vs-w="12">
                <!-- title -->
                <vs-col vs-w="6" vs-sm="6" vs-xs="12" vs-type="flex">
                    <vs-row class="bg-white" vs-w="12">
                        <vs-col vs-w="12" vs-type="flex">
                            <vs-input class="w-full flex-wrap" :label="$t('modules.package.Name')"
                                      :placeholder="$t('modules.package.Package name')"
                                      v-model="formData['name'][currLang]" v-validate="'required'"
                                      name="random-name" id="random-name" />
                        </vs-col>
                        <vs-col vs-w="12" vs-type="flex">
                            <span class="text-danger text-sm w-full flex-wrap" v-show="errors.has('random-name')">
                                {{ errors.first('random-name') }}
                            </span>
                        </vs-col>
                    </vs-row>
                </vs-col>
                <!-- grade -->
                <vs-col vs-w="6" vs-sm="6" vs-xs="12" vs-type="flex">
                    <vs-row class="bg-white" vs-w="12">
                        <vs-col vs-w="12" vs-type="flex">
                            <vs-select
                                @change="fetch_teachers"
                                class="w-full mt-6 sm:ml-4"
                                :placeholder="$t('modules.package.Grade')"
                                v-model="formData['grade_level']" ref="grade_level" name="random-grade_level" id="random-grade_level">
                                <vs-select-item :key="index" :value="item.name" :text="item.title"
                                                v-for="(item,index) in grade_levels" />
                            </vs-select>
                        </vs-col>
                        <vs-col vs-w="12" vs-type="flex">
                            <span class="text-danger text-sm w-full flex-wrap sm:ml-4" v-show="errors.has('random-grade_level')">
                                {{ errors.first('random-grade_level') }}
                            </span>
                        </vs-col>
                    </vs-row>
                </vs-col>
            </vs-row>

            <!-- Description -->
            <vs-row class="bg-white" vs-w="12">
                <vs-col vs-w="12" class="my-6 mb-0">
                    <vs-textarea :label="$t('modules.package.Description')"
                                 v-model="formData['description'][currLang]"
                                 name="random-description" id="random-description"/>
                </vs-col>
                <vs-col vs-w="12">
                     <span class="text-danger text-sm w-full flex-wrap" v-show="errors.has('random-description')">
                        {{ errors.first('random-description') }}
                    </span>
                </vs-col>
            </vs-row>

            <vs-row class="bg-white flex" vs-w="12" vs-justify="space-between">

                <vs-col vs-w="6" vs-sm="6" vs-xs="12" vs-type="flex" v-if="is_full_version">
                  <vs-row class="bg-white" vs-w="12">
                    <vs-col vs-w="6" vs-type="flex" style="margin-top:20px;">

                      <vs-input-number class="w-full flex-wrap" v-model="formData['price']" :label="$t('modules.package.Price')" v-validate="'required'" name="random-price" id="random-price" />

                    </vs-col>
                    <vs-col vs-w="12" vs-type="flex">
                              <span class="text-danger text-sm w-full flex-wrap" v-show="errors.has('random-price')">
                                  {{ errors.first('random-price') }}
                              </span>
                    </vs-col>
                  </vs-row>
                </vs-col>

                <!-- [history]: commented since full package also have versions | v-if="!is_full_version"-->
                <vs-col vs-w="6" vs-sm="6" vs-xs="12" vs-type="flex">
                  <vs-row class="bg-white" vs-w="12">
                    <vs-col vs-w="6" vs-type="flex" style="margin-top:20px;">

                      <vs-input-number class="w-full flex-wrap" v-model="formData['version']" :label="$t('modules.package.Version')" v-validate="'required'" name="random-version" id="random-version" />

                    </vs-col>
                    <vs-col vs-w="12" vs-type="flex">
                              <span class="text-danger text-sm w-full flex-wrap" v-show="errors.has('random-version')">
                                  {{ errors.first('random-version') }}
                              </span>
                    </vs-col>
                  </vs-row>
                </vs-col>

            </vs-row>

            <vs-row class="bg-white" vs-w="12" >
              <vs-col class="my-6" vs-w="6" vs-sm="6" vs-xs="12" vs-type="flex">
                <label class="text-sm align-middle mt-4 pr-4">
                  {{ $t("modules.package.Status") }}
                </label>
                <vs-switch class="align-middle" color="success my-3"
                           v-model="formData['status']" vs-value="1" @input="rd1kIsNumber($event)">
                  <span slot="on">{{ $t("modules.package.Active") }}</span>
                  <span slot="off">{{ $t("modules.package.InActive") }}</span>
                </vs-switch>
              </vs-col>
            </vs-row>

            <!-- Teachers -->
            <vs-row class="bg-white" vs-w="12">
                <vs-col vs-w="12" class="my-6">
                    <vs-row class="bg-white" vs-w="12">
                        <vs-col vs-w="12" vs-type="flex">
                            <label class="text-sm">{{ $t("modules.package.Teachers Incharge") }}</label>
                            <multiselect v-model="formData['teachers_incharge']" tag-placeholder="Add a teacher"
                                         placeholder="Search or add a teacher" label="first_name"
                                         track-by="_id"
                                         :options="teachers"
                                         :noOptions="$t('modules.package.No teachers found for the chosen grade')"
                                         :multiple="true" :taggable="true"
                                         name="random-teachers_incharge" id="random-teachers_incharge"></multiselect>
                        </vs-col>
                        <vs-col vs-w="12" vs-type="flex">
                            <span class="text-danger text-sm w-full flex-wrap" v-show="errors.has('random-teachers_incharge')">
                                {{ errors.first('random-teachers_incharge') }}
                            </span>
                        </vs-col>
                    </vs-row>
                </vs-col>
            </vs-row>


       
            <vs-row class="bg-white" vs-w="12">
                <vs-col vs-w="12" class="my-6">
                    <input class="w-100 vx-row mx-1" type="file" accept="image/*" @change="handleImage"
                           name="random-image" v-validate="'required'"
                           ref="image" id="random-image">
                    <img :src="previewImage" class="shadow mt-4">
                </vs-col>
                <vs-col vs-w="12" class="my-6">
                    <span class="text-danger text-sm w-full flex-wrap" v-show="errors.has('image')">
                        {{ errors.first('image') }}
                    </span>
                </vs-col>
            </vs-row>
 
        </vx-card>


        <div v-if="loaded">
          <!-- para - que - and -->
          <!-- section is a obj containing section name and contents -->
          <!-- a contents is a obj containing para and its que -->
          <vs-collapse type="margin" accordion @change="triggerWindowResize">
            <template v-for="(item, index) in formData.sections">

              <vs-collapse-item  :key="index">

                <div slot="header">
                  <!--   Trigger header open collapse -->

                  <vs-row vs-w="12" @click="triggerWindowResize">
                    <vs-col vs-w="12">
                      <vs-chip color="primary">
                        <vs-avatar icon="widgets" />
                        Section : {{(index+1)}}
                      </vs-chip>
                    </vs-col>
                  </vs-row>
                </div>

                <package-section :section.sync="item" :sectionIndex="index" :currLang="currLang" :errors="errors"
                                 @delete-section="delSection"
                                 :question_type="question_type" :question_thinking_codes="question_thinking_codes" :problem_solving_codes="problem_solving_codes" :course_types="course_types"/>
                <vs-divider color="#fe8501" icon="widgets"> </vs-divider>

              </vs-collapse-item>

            </template>
          </vs-collapse>
          <!--   section renderes the paragraph and its associated questions     -->
          <!--        -->
          <vs-row vs-w="12" vs-justify="center" vs-type="flex" vs-align="center">
            <vs-col vs-w="6" vs-justify="center" vs-type="flex" vs-align="center">
              <vs-button class="mr-4 my-4" color="primary" type="filled" @click="addSection">
                {{ $t("modules.package.Add Section") }}
              </vs-button>

              <vs-button class="mr-4 my-4" color="primary" type="filled" @click="save">
                {{ $t("modules.package.Save") }}
              </vs-button>
              <vs-button class="mr-4 my-4" color="primary" type="filled" @click="cancel">
                {{ $t("modules.package.Cancel") }}
              </vs-button>
            </vs-col>
          </vs-row>
        </div>


    </div>
</template>

<script>
// import moduleRole          from '@/store/role/moduleRole.js'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import axios from '@/axios'
import Vue from 'vue'
import VueCollapse from 'vue2-collapse'

Vue.use(VueCollapse)

import PackageSection from './components/PackageSection.vue'
import CountryFlag from 'vue-country-flag'

Vue.component('country-flag', CountryFlag)

import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import _ from 'lodash'

import scrollMixin from '../../../components/scrollMixin'

export default {
  name: 'PackageAddEdit',
  mixins: [scrollMixin],
  components: {
    vSelect,
    //flatPickr,
    PackageSection,
    CountryFlag,
    Multiselect
  },
  data () {
    return {
      // each section needs this master data
      question_type: [],
      question_type_loaded: false,

      question_thinking_codes: [],
      question_thinking_codes_loaded: false,

      problem_solving_codes: [],
      problem_solving_codes_loaded: false,

      course_types: [],
      course_types_loaded: false,


      collapseKey: 0,
      apiurl:'',
      uiurl:'',
      initial_teacher_fetch: false,
      teachers: [],
      mdl_teachers: [],
      currLang: 'en',

      grade_levels: [
        { name: 'elementary', title: 'Elementary' },
        { name: 'middle', title: 'Middle' },
        { name: 'high', title: 'High' }
      ],

      formData: {
        type: '', // 1= full package , 2= pretest package
        price: 0,
        version: 1,
        title: {
          en: '',
          zh: ''
        },
        name: {
          en: '',
          zh: ''
        },
        description: {
          en: '',
          zh: ''
        },
        teachers_incharge: [],

        grade_level: '',
        sections: [

          ///////////////////////////////////////////////
        ]
      },

      colorx: '#103767',
      categories: [{ text: 'category name', value: 1 }],
      reset_btn: true,
      cancel_btn: false,
      previewImage: '',
      validateForm: true,
      flow_type: ''
    }
  },

  created () {
    this.formData.type = this.$route.meta.type
    var apiurl,uiurl

    switch(this.$route.name){

      case 'PackageAdd':
      case 'PackageEdit':
      apiurl = '/packages'
      uiurl = '/package'
      break;

      case 'PretestPackageAdd':
      case 'PretestPackageEdit':
      apiurl = '/packages'
      uiurl = '/pretest-package'
      break;
    }

    this.apiurl = apiurl
    this.uiurl = uiurl

    if (this.$route.params.id) {
      this.flow_type = 'edit'
      this.$vs.loading()
      this.getPackageDetail(this.$route.params.id).then(() => {
        this.fetch_teachers()
      })
      this.id = this.$route.params.id
    } else {
      this.flow_type = 'add'
      this.fetch_teachers()
    }

    //MASTER
    this.getThinkingCodes()
    this.getProblemSolvingCodes()
    this.getQuestionTypeCodes()
    this.getCourseTypes()

  },

  // computed: {
  //   validateForm () {
  //     return !this.errors.any() && this.name !== '' && this.slug !== '' && this.content !== ''
  //   }
  // },

  watch: {
    currLang (val) {
      //alert(val)
    }
  },

  computed: {
    loaded(){
      return this.question_type_loaded && this.question_thinking_codes_loaded && this.problem_solving_codes_loaded && this.course_types_loaded
    },
    is_full_version () {
      return this.$route.meta.type == 1
    },
    getCountryFlag () {
      if (this.currLang == 'en') {
        return 'gb'
      }
      if (this.currLang == 'zh') {
        return 'cn'
      }
    },

    getCountryLang () {
      if (this.currLang == 'en') {
        return this.$t('English')
      }
      if (this.currLang == 'zh') {
        return this.$t('Chinese')
      }
    }

  },

  methods: {
    getThinkingCodes() {
      axios.get('/question-thinking-codes', {params: {limit: 1000}})
          .then((response) => {
            if (response.status === 200) {
              const question_thinking_codes = _.map(response.data.items, function (el) {
                const children = _.map(_.uniqBy(el.accuracy, 'code'), function (el) {
                  return { label: el.code, value: el.code }
                })
                return {label: el.name, options: children}
              })
              this.question_thinking_codes = question_thinking_codes
              this.question_thinking_codes_loaded = true
            }
          })
          .catch((error) => {
            //alert(`Error: ${error}`)
          })
          .then(() => this.$vs.loading.close())
    },
    getProblemSolvingCodes() {
      axios.get('/problem-solving-codes', {params: {limit: 1000}})
          .then((response) => {
            if (response.status === 200) {
              const problem_solving_codes = _.map(response.data.items, function (el) {
                const children = _.map(_.uniqBy(el.accuracy, 'code'), function (el) {
                  return { label: el.code, value: el.code }
                })
                return {label: el.name, options: children}
              })
              this.problem_solving_codes = problem_solving_codes
              this.problem_solving_codes_loaded = true
            }
          })
          .catch((error) => {
            //alert(`Error: ${error}`)
          })
          .then(() => this.$vs.loading.close())
    },
    getQuestionTypeCodes() {
      axios.get('/question-types', {params: {limit: 1000}})
          .then((response) => {
            if (response.status === 200) {

              //need format [{text: 'Square', value: 1}, ...],
              /*
              need format
              ```
              [
                  {
                      "label": "Option A",
                      "value": 0
                  },
                  {
                      "label": "Option B",
                      "value": 1,
                      "disabled": true,                                  // Disable the option
                      "item": "<strong>Option B is not allowed</strong>" // Define the "item" key to style the look of the text when in an option component
                  },
                  {
                      "label": "Option C",
                      "options": [                                       // Providing an "options" key makes the option an optgroup
                          {
                              "label": "Option D",
                              "value": 2
                          }
                      ]
                  }
              ]
              ```
               */

              const question_type = _.map(response.data.items, function (el) {
                const children = _.map(_.uniqBy(el.accuracy, 'code'), function (el) {
                  return {
                    label: el.code, value: el.code,
                    //item: `<strong>${el.code}</strong>`
                  }
                })
                return {label: el.name, options: children}
              })
              this.question_type = question_type
              this.question_type_loaded = true
            }
          })
          .catch((error) => {
            //alert(`Error: ${error}`)
          })
          .then(() => this.$vs.loading.close())
    },

    getCourseTypes() {
      axios.get('/course-types', {params: {limit: 1000}})
          .then((response) => {
            if (response.status === 200) {
              //need format [{text: 'Square', value: 1}, ...],
              this.course_types = response.data.items.map(item => {
                return {text: item.course_name, value: item._id} //value: item.course_name
              })
              this.course_types_loaded = true
            }
          })
          .catch((error) => {
            //alert(`Error: ${error}`)
          })
          .then(() => this.$vs.loading.close())
    },


    triggerWindowResize(){
      console.log('tff')
      window.dispatchEvent(new Event('resize'))
    },
    fetch_grade_teachers(){
      if(this.initial_teacher_fetch){
        this.fetch_teachers()
      }
    },
    delSection (index) {
      this.formData.sections.splice(index, 1)
    },
    getErrorMsg (e, l) {
      let part_1 = ''
      switch (e) {
      case 'name':
        part_1 = 'Please enter the name'
        break

      }

      let part_2 = ''
      switch (l) {
      case 'en':
        part_2 = ' in english'
        break
      case 'zh':
        part_2 = ' in chinese'
        break
      }
          
      return this.$t(part_1 + part_2)
    },
    fetch_teachers () {

      axios.get('/teachers', {
        params: {
          grade: this.formData.grade_level,
          limit: 1000
        }
      })
        .then((response) => {
          const teachers  = response.data.items.map((t) => {
            return { _id: t._id, first_name: t.first_name }
          })
          this.teachers = teachers

          //add  names
          var teacher_list = this.mdl_teachers.map((item, i) => {
            const tmp = { _id:item, first_name: '(unknown)' }
            const found = _.find(teachers, { _id:item })

            if (found) {
              tmp.first_name = found.first_name
            }
            return tmp
          })

          //remove unknows
          teacher_list = teacher_list.filter((el,i)=>{
            return el.first_name != '(unknown)'
          })

          this.formData['teachers_incharge'] = teacher_list
          this.initial_teacher_fetch = true
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },


    addSection () {
      this.formData.sections.push(this.getDefaultSection())
    },
    cancel () {
      this.$router.push(this.uiurl).catch(() => {
      })
    },
    reset () {
      this.$router.push(this.uiurl)
    },
    save () {
      const form = this.processForm()
      this.errors.clear()


      if (this.flow_type === 'add') {
        this.$vs.loading()
        axios.post(this.apiurl, form)
          .then((response) => {
            if (response.status === 201) {
              this.notifyUI('Created!', 'Package has been created', 'success')
              this.$router.push(this.uiurl)
            }
          })
          .catch((error) => {
            this.displayFormErrors(error)
          })
          .then(() => this.$vs.loading.close())
      } else {
        this.$vs.loading()
        const form = this.processForm()
        axios.put(`${this.apiurl}/${this.id}`, form)
          .then((response) => {
            if (response.status === 200) {
              //this.$router.go(0)
              this.notifyUI('Updated!', 'Package has been updated', 'success')
              this.$router.push(this.uiurl)
            }
          })
          .catch((error) => {
            this.displayFormErrorsScroll(error)
          })
          .then(() => this.$vs.loading.close())
      }
    },

    handleImage (e) {
      const image = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = e => {
        this.previewImage = e.target.result
        // console.log(this.previewImage)
      }
    },

    getPackageDetail (id) {

      return axios.get(`${this.apiurl}/${id}`)
        .then((response) => {
          if (response.status === 200) {
            this.formData = response.data
            this.mdl_teachers = response.data.teachers_incharge // due to ["array-item"] to [{}] bug
            //_.merge(this.formData, response.data)
            this.previewImage = this.formData.image

          }
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },



    processForm () {
      const form = new FormData()

      // form.append('name', this.formData['name']) //manually append name
      // form.append('description', this.formData['description']) //manually append name
      //
      // form.append('grade_type', this.formData['grade_type']) //manually append
      // form.append('sections', this.formData['sections']) //manually append

      form.append('data', JSON.stringify(this.formData))

      //form.append('status', this.formData['status'] ? 1 : 0) //manually append status , plugin bug


      if (this.$refs.image.files.length) {
        form.append('image', this.$refs.image.files[0])
      }

      //manually append file obj
      return form
    },

    serialize (obj) {
      const str = []
      for (const p in obj) {
        if (obj.hasOwnProperty(p)) {
          str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`)
        }
      }
      return str.join('&')
    },

    getDefaultSection () {
      return {
        course_type: '',
        instruction: {
          en: '',
          zh: ''
        },
        contents: []
      }
    }


  },

  mounted(){
    setInterval(()=>{
      this.$nextTick(() => {
        let sel = '.vs-collapse-item.open-item > .vs-collapse-item--content'
        let elements = document.querySelectorAll(sel);
        if(elements){
          for (let i = 0; i < elements.length; i++) {
            // console.log(element)
            elements[i].style.maxHeight = 'none'
          }
        }
      }, 500);
    })
  }
}
</script>

<style>
#random-price{
    border: 1px solid grey;
    margin-left: 10px; 
}
#random-version{
  border: 1px solid grey;
  margin-left: 10px;
}
#container_price{
  margin-top:20px;
  background: #0c63e4;
}
</style>