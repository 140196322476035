<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vx-card>
    <h3> {{ flow_type == "add" ? $t('modules.student.Add') : $t('modules.student.Edit') }} {{$t('modules.teacher.Teacher')}}</h3>

    <!-- Content Row -->
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">

        <div class="mt-4">
          <label class="text-sm">{{ $t("modules.teacher.First Name") }} <span class="text-danger"> * </span></label>
          <vs-input class="w-full mt-4 required"
                    v-model="formData['first_name']"
                    v-validate="'required|max:100'"
          />
          <span class="text-danger text-sm" v-show="errors.has('first_name')">{{ errors.first("first_name") }}</span>
        </div>

        <div class="mt-4">
          <label class="text-sm">{{ $t("modules.teacher.Teacher ID") }} <span class="text-danger"> * </span></label>
          <vs-input class="w-full mt-4 required"
                    v-model="formData['teacher_id']"
                    v-validate="'required|max:100'"
          />
          <span class="text-danger text-sm" v-show="errors.has('teacher_id')">{{ errors.first("teacher_id") }}</span>
        </div>

        <div class="mt-4">
          <label class="text-sm">{{ $t("modules.teacher.Teacher phone") }} <span class="text-danger"> * </span></label>
          <vs-input class="w-full mt-4 required"
                    v-model="formData['phone']"
                    v-validate="'required|min:10|max:10'" />
          <span class="text-danger text-sm" v-show="errors.has('phone')">{{ errors.first("phone") }}</span>
        </div>

        <div class="mt-4">
          <label class="text-sm">{{ $t("modules.teacher.Gender") }} <span class="text-danger"> * </span></label>
          <vs-select class="w-full mt-4 required" placeholder="Select Gender" v-model="formData['gender']">
            <vs-select-item value="female" text="Female" />
            <vs-select-item value="male" text="Male" />
          </vs-select>
          <span class="text-danger text-sm" v-show="errors.has('gender')">{{ errors.first("gender") }}</span>

        </div>


        <div class="mt-4">
          <label class="text-sm">{{ $t("modules.teacher.Joining Date") }} <span class="text-danger"> * </span></label>
          <datepicker class="w-full mt-4 required" :format="dateFormat" v-model="formData['joined_date']"
                      label="Joining Date" :language="getDateFormatLocale($i18n.locale)"></datepicker>
          <span class="text-danger text-sm" v-show="errors.has('joined_date')">{{ errors.first("joined_date") }}</span>
        </div>


        <div class="w-full">
          <label class="text-sm">{{ $t("modules.teacher.Address") }} <span class="text-danger"> * </span></label>
          <vs-textarea class="w-full" rows="5" v-model="formData['address']"
                       :placeholder="$t('modules.teacher.Enter Your Address')" />
          <span class="text-danger text-sm" v-show="errors.has('address')">{{ errors.first("address") }}</span>
        </div>

        <div class="w-full">
          <label class="text-sm" style="margin-left: 4px;">{{ $t("modules.teacher.Status") }}</label>
          <vs-switch color="success" v-model="formData['status']">
            <span slot="on">{{$t('modules.profile.Active')}}</span>
            <span slot="off">{{$t('modules.profile.InActive')}}</span>
          </vs-switch>
        </div>


      </div>

      <div class="vx-col md:w-1/2 w-full">
        <div class="mt-4">
          <label class="text-sm">{{ $t("modules.teacher.Last Name") }} <span class="text-danger"> * </span></label>
          <vs-input class="w-full mt-4 required" v-model="formData['last_name']"
                    v-validate="'required|max:100'" />
          <span class="text-danger text-sm" v-show="errors.has('last_name')">{{ errors.first("last_name") }}</span>
        </div>


        <div class="mt-4">
          <label class="text-sm">{{ $t("modules.teacher.Email") }} <span class="text-danger"> * </span></label>
          <vs-input class="w-full mt-4 required"
                    v-model="formData['email']"
                    v-validate="'required|email|min:3'" />
          <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first("email") }}</span>
        </div>


        <div class="mt-4">
          <label class="text-sm">{{ $t("modules.teacher.Date of Birth") }} <span class="text-danger"> * </span></label>
          <datepicker class="w-full mt-4 required" :format="dateFormat" v-model="formData['dob']"
                      label="Date of Birth" :language="getDateFormatLocale($i18n.locale)"></datepicker>
          <span class="text-danger text-sm" v-show="errors.has('dob')">{{ errors.first("dob") }}</span>
        </div>

        <div class="mt-4">
          <label class="text-sm">{{ $t("modules.teacher.Grade") }} <span class="text-danger"> * </span></label>
          <vs-select class="w-full mt-4 required" placeholder="Select Grade" v-model="formData['grade']">
            <vs-select-item value="elementary" text="Elementary" />
            <vs-select-item value="intermediate" text="Intermediate" />
            <vs-select-item value="higher" text="Higher" />
          </vs-select>
          <span class="text-danger text-sm" v-show="errors.has('grade')">{{ errors.first("grade") }}</span>
        </div>

      </div>
    </div>


    <h2 class="mt-10">{{ $t("modules.teacher.Account Details") }}</h2>
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">

        <div class="mt-4">
          <label class="text-sm">{{ $t("modules.teacher.Bank Swift Code") }} <span
            class="text-danger"> * </span></label>
          <vs-input class="w-full mt-4 required"
                    v-model="formData['bank_swift_code']"
                    v-validate="'required|max:100'"
          />
          <span class="text-danger text-sm" v-show="errors.has('bank_swift_code')">{{ errors.first("bank_swift_code")
            }}</span>
        </div>


        <div class="mt-4">
          <label class="text-sm">{{ $t("modules.teacher.Branch Name") }} <span class="text-danger"> * </span></label>
          <vs-input class="w-full mt-4 required"
                    v-model="formData['branch_name']"
                    v-validate="'required|max:100'"
          />

          <span class="text-danger text-sm" v-show="errors.has('branch_name')">{{ errors.first("branch_name") }}</span>
        </div>

      </div>
      <div class="vx-col md:w-1/2 w-full">
        <div class="mt-4">
          <label class="text-sm">{{ $t("modules.teacher.Account Number") }} <span class="text-danger"> * </span></label>
          <vs-input class="w-full mt-4 required"
                    v-model="formData['account_number']"
                    v-validate="'required|max:100'"
          />
          <span class="text-danger text-sm" v-show="errors.has('account_number')">{{ errors.first("account_number")
            }}</span>
        </div>


        <div class="mt-4">
          <label class="text-sm">{{ $t("modules.teacher.Branch Address") }} <span class="text-danger"> * </span></label>
          <vs-input class="w-full mt-4 required"
                    v-model="formData['branch_address']"
                    v-validate="'required|max:100'"
          />

          <span class="text-danger text-sm" v-show="errors.has('branch_address')">{{ errors.first("branch_address")
            }}</span>
        </div>


      </div>


    </div>
    <br>
    <div class="vx-col md:w-1/2 w-full">
      <input class="w-100 vx-row mx-1" type="file" accept="image/*" @change="handleImage" ref="image">
      <!--          <img :src="previewImage" alt="" class="">-->
      <vs-image class="w-100 shadow" :src="previewImage" />
      <!--   <vs-upload action="https://jsonplaceholder.typicode.com/posts/" @on-success="successUpload" />-->
    </div>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-20 flex flex-wrap items-center justify-end">
          <!-- <vs-button class="ml-auto mt-2" :disabled="!validateForm" @click="save">
            {{ $t("modules.teacher.Save Changes") }}
          </vs-button> -->
           <vs-button class="ml-auto mt-2"  @click="save">
            {{ $t("modules.teacher.Save Changes") }}
          </vs-button>
          <vs-button class="ml-4 mt-2" v-if="reset_btn" type="border" color="warning" @click="reset">
            {{ $t("modules.student.Reset") }}
          </vs-button>
          <vs-button class="ml-4 mt-2" v-if="cancel_btn" type="border" color="warning" @click="cancel">
            {{ $t("modules.student.Cancel") }}
          </vs-button>
        </div>
      </div>
    </div>

  </vx-card>
</template>

<script>
// import moduleRole          from '@/store/role/moduleRole.js'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import axios from '@/axios'
import Datepicker from 'vuejs-datepicker'

export default {
  components: {
    vSelect,
    flatPickr,
    Datepicker

  },
  data () {
    return {
      flow_type: '',
      //id: 0,
      formData: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        grade: '',
        gender: '',
        package_id: '',
        teacher_id: '',
        address: '',
        dob: '',
        status: '1',
        joined_date: '',
        bank_swift_code: '',
        branch_name: '',
        branch_address: '',
        account_number: ''
      },
      reset_btn: true,
      cancel_btn: false,
      status: false,
      previewImage: ''
    }
  },

  created () {

    if (this.$route.params.id) {
      this.flow_type = 'edit'
      this.reset_btn = false
      this.cancel_btn = true
    } else {
      this.flow_type = 'add'
      this.cancel_btn = true

    }

    if (this.flow_type === 'edit') {
      this.getTeacherDetail(this.$route.params.id)
      this.id = this.$route.params.id
    }

  },

  computed: {
    validateForm () {
      return !this.errors.any()
    }

  },

  methods: {
    cancel () {
      this.$router.push('/teacher').catch(() => {
      })
    },
    reset () {
      this.formData =
        {
          grade: '',
          gender: '',
          package_id: ''
        }
    },
    save () {
      const form = this.processForm()

      if (this.flow_type === 'add') {
        axios.post('/teachers', form)
          .then((response) => {
            if (response.status === 200) {
              this.notifyUI('Created!', 'Teacher has been created', 'success')
              this.$router.push('/teacher')
            }
          })
          .catch((error) => {
            this.displayErrors(error)
          })
          .then(() => this.$vs.loading.close())
      } else {
        axios.put(`/teachers/${this.id}`, form)
          .then((response) => {
            if (response.status === 200) {
              this.notifyUI('Updated!', 'Profile updated sucessfully', 'success')
              this.$router.push('/teacher')
            }
          })
          .catch((error) => {
            this.displayErrors(error)
          })
          .then(() => this.$vs.loading.close())
      }
    },

    getTeacherDetail (id) {
      axios.get(`/teachers/${id}`)
        .then((response) => {
          if (response.status === 200) {
            const form = {}
            form.name = response.data.name
            form.status = response.data.status
            form._id = response.data._id
            //console.log( response.data.name)
            this.formData = response.data
            this.previewImage = this.formData.image
          }
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },
    handleImage (e) {
      const image = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = e => {
        this.previewImage = e.target.result
        console.log(this.previewImage)
      }
    },

    customDateFormatter (date) {
      return moment(date).format('dd MM YYYY')
    },
    processForm () {
      const form = new FormData()
      form.append('first_name', this.formData['first_name']) //manually append name
      form.append('last_name', this.formData['last_name']) //manually append name
      form.append('teacher_id', this.formData['teacher_id']) //manually append name
      form.append('dob', this.formData['dob']) //manually append name
      form.append('gender', this.formData['gender']) //manually append name
      form.append('grade', this.formData['grade']) //manually append name
      form.append('email', this.formData['email']) //manually append email
      form.append('joined_date', this.formData['joined_date']) //manually append email
      form.append('phone', this.formData['phone']) //manually append
      form.append('address', this.formData['address']) //manually append
      form.append('bank_swift_code', this.formData['bank_swift_code']) //manually append
      form.append('account_number', this.formData['account_number']) //manually append
      form.append('branch_name', this.formData['branch_name']) //manually append
      form.append('branch_address', this.formData['branch_address']) //manually append

      form.append('status', this.formData['status'] ? 1 : 0) //manually append status , plugin bug
      if (this.$refs.image.files.length) {
        form.append('image', this.$refs.image.files[0])
      } //manually append file obj
      return form
    }

  }
}
</script>
