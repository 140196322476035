<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vx-card>

    <!-- Content Row -->
    <div class="vx-row">

      <div class="vx-col md:w-1/2 w-full">

        <vs-input class="w-full mt-4   "
                  :label="$t('modules.setting.Facebook Page')"  v-model="formData['facebook_page']"  />
        <span class="text-danger text-sm" v-show="errors.has('facebook_page')">{{ errors.first("facebook_page") }}</span>


        <vs-input class="w-full mt-4    action_btn"
                  :label="$t('modules.setting.Twitter Page')" v-model="formData['twitter_page']"/>
        <span class="text-danger text-sm"  v-show="errors.has('twitter_page')">{{ errors.first('twitter_page') }}</span>


        <vs-input class="w-full mt-4    action_btn"
                  :label="$t('modules.setting.Linkedin Page')"  v-model="formData['linkedin_page']"/>
        <span class="text-danger text-sm"  v-show="errors.has('linkedin_page')">{{ errors.first('linkedin_page') }}</span>

         <label class="text-sm">{{$t('modules.setting.Analytics Code')}} </label>
        <vs-textarea rows="3" v-model="formData['analytics_code']" placeholder="" />
        <span class="text-danger text-sm" v-show="errors.has('analytics_code')">{{ errors.first("analytics_code") }}</span>

        <vs-input class="w-full mt-4   "
                 :label="$t('modules.setting.Instagram Page')" v-model="formData['instagram_page']"  />
        <span class="text-danger text-sm" v-show="errors.has('instagram_page')">{{ errors.first("instagram_page") }}</span>


<!--
        <vs-input class="w-full mt-4   "
                  :label="$t('modules.setting.Google Plus Page')" v-model="formData['google_plus_page']"  />
        <span class="text-danger text-sm" v-show="errors.has('google_plus_page')">{{ errors.first("google_plus_page")}}</span>

        <vs-input class="w-full mt-4   "
                   :label="$t('modules.setting.Tumblr Page')" v-model="formData['tumblr_page']"  />
        <span class="text-danger text-sm" v-show="errors.has('tumblr_page')">{{ errors.first("tumblr_page") }}</span>

        <vs-input class="w-full mt-4   "
                  :label="$t('modules.setting.Youtube Url')" v-model="formData['youtube_url']"  />
        <span class="text-danger text-sm" v-show="errors.has('youtube_url')">{{ errors.first("youtube_url") }}</span>
        
        <vs-input class="w-full mt-4   "
                  :label="$t('modules.setting.Iphone Page')"  v-model="formData['iphone_page']"  />
        <span class="text-danger text-sm" v-show="errors.has('iphone_page')">{{ errors.first("iphone_page")}}</span>

        

        <vs-input class="w-full mt-4    action_btn"
                  :label="$t('modules.setting.Android Page')" v-model="formData['android_page']"/>
        <span class="text-danger text-sm"  v-show="errors.has('android_page')">{{ errors.first('android_page') }}</span>




        <vs-input class="w-full mt-4   "
                  :label="$t('modules.setting.Facebook App Id')" v-model="formData['facebook_app_id']"  />
        <span class="text-danger text-sm" v-show="errors.has('facebook_app_id')">{{ errors.first("facebook_app_id") }}</span>

        <vs-input class="w-full mt-4 " :label="$t('modules.setting.Facebook Secret Key')" v-model="formData['facebook_secret_key']"  />
        <span class="text-danger text-sm" v-show="errors.has('facebook_secret_key')">{{ errors.first("facebook_secret_key") }}</span>


        <vs-input class="w-full mt-4   "
                  :label="$t('modules.setting.Twitter Api Key')" v-model="formData['twitter_api_key']"  />
        <span class="text-danger text-sm" v-show="errors.has('twitter_api_key')">{{ errors.first("twitter_api_key") }}</span>

        <vs-input class="w-full mt-4   "
                  :label="$t('modules.setting.Twitter Secret Key')" v-model="formData['twitter_secret_key']"  />
        <span class="text-danger text-sm" v-show="errors.has('twitter_secret_key')">{{ errors.first("twitter_secret_key") }}</span>

        <vs-input class="w-full mt-4   "
                  :label="$t('modules.setting.Gmap Api Key')" v-model="formData['gmap_api_key']"  />
        <span class="text-danger text-sm" v-show="errors.has('gmap_api_key')">{{ errors.first("gmap_api_key") }}</span>


        

        <div class="mt-6 centerx w-full vx-row">
          <label class="text-sm my-4 my-1">{{$t('modules.setting.Status')}}</label>
          <vs-switch color="success my-3 mx-4" v-model="formData['status']" vs-value="1">
            <span slot="on">Active</span>
            <span slot="off">InActive</span>
          </vs-switch>
        </div> -->

      </div>
    </div>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" @click="save_changes">{{$t('modules.setting.Save Changes')}}</vs-button>
          <vs-button class="ml-4 mt-2" type="border" color="warning" @click="cancel">{{$t('modules.setting.Cancel')}}</vs-button>
        </div>
      </div>
    </div>

  </vx-card>
</template>

<script>
// import moduleSetting          from '@/store/setting/moduleSetting.js'
import vSelect from 'vue-select'
import axios from "@/axios";

export default {
  data () {
    return {
      formData: {},
      add_image_before: false,
      add_image_after: true,
      add_icon_before: false,
      add_icon_after: true,
      iconUpload: 1,
      imageUpload: 1,
      previewFavIcon: '',
      previewLogo: ''
    }
  },
  components: {
    vSelect
  },

  methods: {
    cancel () {
      this.$router.push('/dashboard').catch(() => { })
    },
    save_changes () {
      this.$validator.validateAll().then(result => {

        if (result) {
          this.save()
        }
      })
    },

    save () {
     // const form = this.processForm()
      axios.put('/settings/social_media', this.formData)
        .then((response) => {
          if (response.status === 200) {
            this.notifyUI('Updated!', 'Social Media Settings has been updated', 'success')
          }
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },

    handleLogo (e) {
      const image = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = e => {
        this.previewLogo = e.target.result
        console.log(this.previewImage)
      }
    },

    handleFavIcon (e) {
      const image = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = e => {
        this.previewFavIcon = e.target.result
        console.log(this.previewImage)
      }
    },

    processForm () {
      const form = new FormData()
      form.append('facebook_page', this.formData['facebook_page']) //manually append name
      form.append('twitter_page', this.formData['twitter_page']) //manually append slug
      form.append('linkedin_page', this.formData['linkedin_page']) //manually append category
      form.append('google_plus_page', this.formData['google_plus_page']) //manually append visibility
      form.append('tumblr_page', this.formData['tumblr_page']) //manually append visibility
      form.append('youtube_url', this.formData['youtube_url']) //manually append visibility
      form.append('android_page', this.formData['android_page']) //manually append visibility
      form.append('iphone_page', this.formData['iphone_page']) //manually append visibility
      form.append('analytics_code', this.formData['analytics_code']) //manually append visibility
      form.append('facebook_app_id', this.formData['facebook_app_id']) //manually append visibility
      form.append('facebook_secret_key', this.formData['facebook_secret_key']) //manually append visibility
      form.append('twitter_api_key', this.formData['twitter_api_key']) //manually append visibility
      form.append('twitter_secret_key', this.formData['twitter_secret_key']) //manually append visibility
      form.append('gmap_api_key', this.formData['gmap_api_key']) //manually append visibility
      form.append('status', this.formData['status']) //manually append visibility
      
      form.append('instagram_page', this.formData['instagram_page']) //manually append visibility
     


      return form
    },

    getSocialSetting () {
      axios.get('/settings/social_media')
        .then((response) => {
          if (response.status === 200) {
            this.formData = response.data
            this.previewLogo = this.formData.logo
            this.previewFavIcon = this.formData.favicon
          }
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    }

  },
  created () {
    this.getSocialSetting()
  },
  beforeDestroy () {
    // this.$store.unregisterModule('setting')
  }
}
</script>
<style>
.favicon_url {
  position: relative;
  top: 12px;
}
</style>
