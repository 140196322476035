<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo.display_name">

    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ activeUserInfo.display_name }}</p>
      <small>{{$t("modules.profile.Admin")}}</small>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">

      <div class="con-img ml-3">
        <img :src="activeUserInfo.image" alt="user-img" width="40" height="40"
             class="rounded-full shadow-md cursor-pointer block" @error="$event.target.src=defaultImage" />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">

         <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="redirect">

            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">{{$t("modules.profile.Profile")}}</span>
         </li>

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/changepassword').catch(() => {})">
            <feather-icon icon="InfoIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">{{$t("modules.profile.Change Password")}}</span>
          </li>
          <vs-divider class="m-1" />
          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">{{$t("modules.profile.Logout")}}</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>

export default {
  data () {
    return {
      defaultImage: require("@/assets/images/default.jpg")
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.appUser
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('logout')

      this.$vs.notify({
        title: 'Alert',
        text: 'logout done successfully',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'success'
      })

      this.$router.push('/login').catch(() => {})
    },
    redirect()
    {
      this.$router.push('/user/edit/'+this.activeUserInfo.user_id).catch(() => {})
    }
  }
}
</script>
