<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vx-card>

    <!-- Content Row -->
    <div class="vx-row">
      <div class="vx-col w-full">
<div>
       <label class="text-sm">{{$t('modules.payment.Payment Name')}} <span class="text-danger"> * </span></label>
        <vs-input class="w-full  required"  
                  v-model="formData['payment_name']"
                  v-validate="'required'"/>

        <span class="text-danger text-sm" v-show="errors.has('payment_name')">{{ errors.first("payment_name") }}</span>

</div>
<div>
       <label class="text-sm">{{$t('modules.payment.Merchant Name')}} <span class="text-danger"> * </span></label>
        <vs-input class="w-full required"  
                  v-model="formData['merchant_name']"
                  v-validate="'required'"/>

        <span class="text-danger text-sm" v-show="errors.has('merchant_name')">{{ errors.first("merchant_name") }}</span>
   </div>
   <div>
       <label class="text-sm">{{$t('modules.payment.Merchant Password')}} <span class="text-danger"> * </span></label>
        <vs-input class="w-full  required"  
                  v-model="formData['merchant_password']"
                  v-validate="'required'"/>

        <span class="text-danger text-sm" v-show="errors.has('merchant_password')">{{ errors.first("merchant_password") }}</span>
</div>
   <div>
       <label class="text-sm">{{$t('modules.payment.Merchant Key')}} <span class="text-danger"> * </span></label>
        <vs-input class="w-full   required" 
                  v-model="formData['merchant_key']"
                  v-validate="'required'"/>

        <span class="text-danger text-sm" v-show="errors.has('merchant_key')">{{ errors.first("merchant_key") }}</span>
</div>
<div>
       <label class="text-sm">{{$t('modules.payment.Merchant Secret Key')}} <span class="text-danger"> * </span></label>
        <vs-input class="w-full   required"  
                  v-model="formData['merchant_secret_key']"
                  v-validate="'required'"/>

        <span class="text-danger text-sm" v-show="errors.has('merchant_secret_key')">{{ errors.first("merchant_secret_key") }}</span>
</div>
<div>
       <label class="text-sm">{{$t('modules.payment.Payment Method')}} <span class="text-danger"> * </span></label>
        <vs-input class="w-full   required" 
                  v-model="formData['payment_method']"
                  v-validate="'required'"/>

        <span class="text-danger text-sm" v-show="errors.has('payment_method')">{{ errors.first("payment_method") }}</span>
</div>

        <div class="mt-6 centerx w-full vx-row">
          <label class="text-sm my-4 my-1">{{$t('modules.payment.Status')}}</label>
          <vs-switch color="success my-3 mx-4" v-model="formData['status']" vs-value="1">
            <span slot="on">{{$t('modules.profile.Active')}}</span>
            <span slot="off">{{$t('modules.profile.InActive')}}</span>
          </vs-switch>
        </div>

      </div>
    </div>


    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" :disabled="!validateForm" @click="save" >{{$t('modules.payment.Save Changes')}}</vs-button>
          <vs-button class="ml-4 mt-2" v-if="reset_btn" type="border" color="warning" @click="reset">{{$t('modules.payment.Reset')}}</vs-button>
          <vs-button class="ml-4 mt-2" v-if="cancel_btn" type="border" color="warning" @click="cancel">{{$t('modules.payment.Cancel')}}
          </vs-button>
        </div>
      </div>
    </div>

  </vx-card>
</template>

<script>
// import moduleRole          from '@/store/role/moduleRole.js'
import vSelect from 'vue-select'
import axios from '@/axios'
import Vue from 'vue'

export default {
  components: {
    vSelect,
  },
  data () {
    return {
      formData: {
        payment_name: '',
        merchant_name: '',
        merchant_password: '',
        merchant_key: '',
        merchant_secret_key: '',
        payment_method: '',
        status: '0',
      },
      colorx:'#103767',
      reset_btn: true,
      cancel_btn: false,
      validateForm: true,
      flow_type: ''

    }
  },

  created () {
    
      if (this.$route.params.id) {
      this.flow_type = "edit";
       this.reset_btn= false
       this.cancel_btn= true
    } else {
      this.flow_type = "add";
       this.cancel_btn= true
       this.reset_btn= true
    }

    if (this.$route.params.id) this.flow_type = 'edit'
    else this.flow_type = 'add'

    if (this.flow_type === 'edit') {
      this.getPaymentDetail(this.$route.params.id)
      this.id = this.$route.params.id
    }

    this.getpayments()
  },

  computed: {
validateForm () {
      return !this.errors.any() && this.payment_name !== '' && this.merchant_name !== '' && this.merchant_password !== '' && this.merchant_key !== '' && this.merchant_secret_key !== '' && this.payment_method !== ''
    },
  },

  methods: {
    cancel () {
      this.$router.push('/payments').catch(() => { })
    },
    reset () {

       this.formData=
        {
             grade:"",
             gender:"",
            package_id:'' 
         }
      //this.$router.push('/payments')
    },
    save () {
      if (this.flow_type === 'add') {
        axios.post('/payments', this.formData)
          .then((response) => {
            if (response.status === 201) {
              this.notifyUI('Created!', 'Payment has been created', 'success')
              this.$router.push('/payments')
            }
          })
          .catch((error) => {
         this.displayErrors(error)
          })
          .then(() => this.$vs.loading.close())
      } else {
        axios.put(`/payments/${this.id}`, this.formData)
          .then((response) => {
            if (response.status === 200) {
              this.$router.push('/payments')
              this.notifyUI('Updated!', 'Payment has been updated', 'success')
            }
          })
          .catch((error) => {
               this.displayErrors(error)
          })
          .then(() => this.$vs.loading.close())
      }
    },

    getPaymentDetail (id) {
      axios.get(`/payments/${id}`)
        .then((response) => {
          if (response.status === 200) {
            this.formData = response.data
          }
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },

    getpayments () {
      axios.get('/payments', { params: {limit: 1000} })
        .then((response) => {
          if (response.status === 200) {
            //need format [{text: 'Square', value: 1}, ...],
            this.payments = response.data.items.map(item => {
              return {text: item.payment_name, value: item._id}
            })
          }
        })
        .catch((error) => {
          //alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },

  }
}
</script>
