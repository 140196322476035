<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vx-card>

    <!-- Content Row -->
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
<div class="mt-4">
   <label class="text-sm">{{$t('modules.grade.Grade Name')}} <span class="text-danger"> * </span></label>
  
        <vs-input class="w-full mt-4 required" 
                  v-model="formData['name']"
                  v-validate="'required'"/>

        <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first("name") }}</span>
</div>

<div class="mt-4">
   <label class="text-sm">{{$t('modules.grade.Grade level')}} <span class="text-danger"> * </span></label>
         <vs-select :color="colorx" class="w-full mt-4 required" placeholder="Select Grade" v-model="formData['grade_level']">
          <vs-select-item value="elementary" text="Elementary"/>
          <vs-select-item value="intermediate" text="Intermediate"/>
          <vs-select-item value="higher" text="Higher"/>
        </vs-select>
 <span class="text-danger text-sm" v-show="errors.has('grade_level')">{{ errors.first("grade_level") }}</span>

</div>


       


        <div class="mt-6 centerx w-full vx-row">
          <label class="text-sm my-4 my-1">{{$t('modules.grade.Status')}}</label>
          <vs-switch color="success my-3 mx-4" v-model="formData['status']" vs-value="1">
            <span slot="on">{{$t('modules.profile.Active')}}</span>
            <span slot="off">{{$t('modules.profile.InActive')}}</span>
          </vs-switch>
        </div>

      </div>
    </div>


    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" :disabled="!validateForm" @click="save" >{{$t('modules.grade.Save Changes')}}</vs-button>
          <vs-button class="ml-4 mt-2" v-if="reset_btn" type="border" color="warning" @click="reset">{{$t('modules.grade.Reset')}}</vs-button>
          <vs-button class="ml-4 mt-2" v-if="cancel_btn" type="border" color="warning" @click="cancel">{{$t('modules.profile.Cancel')}}
          </vs-button>
        </div>
      </div>
    </div>

  </vx-card>
</template>

<script>
// import moduleRole          from '@/store/role/moduleRole.js'
import vSelect from 'vue-select'
import axios from '@/axios'
import Vue from 'vue'

export default {
  components: {
    vSelect,
  },
  data () {
    return {
      formData: {
        name: '',
        status: '0',
      },
      colorx:'#103767',
      reset_btn: true,
      cancel_btn: false,
      validateForm: true,
      countries: [],
      flow_type: ''

    }
  },

  created () {
    if (this.$route.params.id) this.flow_type = 'edit'
    else this.flow_type = 'add'
       this.cancel_btn= true

    if (this.flow_type === 'edit') {
      this.getGradeDetail(this.$route.params.id)
      this.id = this.$route.params.id
       this.reset_btn= false
       this.cancel_btn= true
    }

    
  },

  computed: {
validateForm () {
      return !this.errors.any() && this.name !== '' && this.zipcode !== ''
    },
  },

  methods: {
    cancel () {
      this.$router.push('/grade').catch(() => { })
    },
    reset () {
      //this.$router.push('/grade')
       this.formData=
        {
              grade_level:""

         }
    },
    save () {
      if (this.flow_type === 'add') {
        axios.post('/grades', this.formData)
          .then((response) => {
            if (response.status === 201) {
              this.notifyUI('Created!', 'Grade has been created', 'success')
              this.$router.push('/grade')
            }
          })
          .catch((error) => {
         this.displayErrors(error)
          })
          .then(() => this.$vs.loading.close())
      } else {
        axios.put(`/grades/${this.id}`, this.formData)
          .then((response) => {
            if (response.status === 200) {
              this.$router.push('/grade')
              this.notifyUI('Updated!', 'Grade has been updated', 'success')
            }
          })
          .catch((error) => {
               this.displayErrors(error)
          })
          .then(() => this.$vs.loading.close())
      }
    },

    getGradeDetail (id) {
      axios.get(`/grades/${id}`)
        .then((response) => {
          if (response.status === 200) {
            this.formData = response.data
          }
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },

   

  }
}
</script>
