<template>
    <div>{{country_name(this.params.value)}}</div>
</template>

<script>
export default {
  name: 'CellRendererCountry',
  computed: {
  },
  methods: {
    country_name(countryId) {
        var splitArray = countryId.split(',');
        let tmp = [];
      for(let i=0;i<splitArray.length;i++){
        const getCountryId = parseInt(splitArray[i])
        var  getCountriesName = this.$store.getters['country/getCountryName'](getCountryId)
        tmp.push(getCountriesName);
      }
      return tmp.toString()
    },
  },
  created () {
  },
}
</script>
