// axios
import axios from 'axios'
import store from './store/store'
import router from './router'

import config from './config'
// const asset_url = 'https://assets.demo.com/'
// const asset_s3_url = 'https://app-image.s3.ap-south-1.amazonaws.com/'

const ax = axios.create({
  baseURL : 'https://api.pupiltube.com/admin',   //config.apiBaseUrl,
  // asset_url,
  // asset_s3_url,
  // You can add your headers here
  headers: {'Authorization': `Bearer ${  localStorage['admin_token']}`}
  //#TODO
})

//req
ax.interceptors.request.use(
  config => {
    const token = localStorage['admin_token']
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
      config.headers['X-LANGUAGE'] = store.state.ui_language
    }
    return config
  },
  error => {
    Promise.reject(error)
  })

//res
ax.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response.status === 401) {
      router.push('/login')
    }
    return Promise.reject(error)
  }
)


//https://github.com/axios/axios#request-config
export default ax
