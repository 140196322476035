<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vx-card>
    <div id="page-user-list" v-if="list_permission == false">
      <div class="NOpermissions">
        <span>No Permissions</span>
      </div>
    </div>
    <vs-table ref="table" pagination :max-items="this.$store.state.pps" search :data="faqs"
              v-if="list_permission == true">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- ADD NEW -->
          <div v-if="create_permission == true" class="dd-actions cursor-pointer mr-4 mb-4"
               style="margin-bottom: 0px !important">
            <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon"
                    @click="$router.push('/faq/new').catch(() => {})">
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4"/>
              <span class="ml-2 text-base text-primary">Add New</span>
            </button>
          </div>
          <!-- REFRESH -->
          <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon" @click="refreshData()">
            <feather-icon icon="RefreshCwIcon" svgClasses="h-4 w-4"/>
            <span class="ml-2 text-base text-primary">Refresh</span>
          </button>
        </div>
      </div>

      <template slot="thead">
        <vs-th sort-key="faq_id">S.NO</vs-th>
        <vs-th sort-key="question">Question</vs-th>
        <vs-th sort-key="answer">Answer</vs-th>
        <vs-th sort-key="created_at">Created At</vs-th>
        <vs-th sort-key="status">Status</vs-th>
        <vs-th v-if="disableAction == true">Action</vs-th>
        <vs-th v-if="disableAction == false"></vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">

          <vs-td :data="data[indextr].faq_id">
            {{ indextr + 1 }}
          </vs-td>

          <vs-td :data="data[indextr].question">
            {{ data[indextr].question }}
          </vs-td>

          <vs-td :data="data[indextr].answer">
            {{ data[indextr].answer }}
          </vs-td>

          <vs-td :data="data[indextr].created_at">
            {{ date_format(data[indextr].created_at) }}
          </vs-td>

          <vs-td :data="data[indextr].status">
            <vs-chip v-if="data[indextr].status==0" :color="'#d9534f'" class="product-order-status">InActive</vs-chip>
            <vs-chip v-if="data[indextr].status==1" :color="'#5cb85c'" class="product-order-status">Active</vs-chip>
          </vs-td>

          <vs-td class="whitespace-no-wrap">
            <feather-icon icon="EditIcon" v-if="update_permission == true"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current edit_delete"
                          @click.stop="$router.push('/faq/edit/'+tr.faq_id).catch(() => {})"/>
            <feather-icon icon="TrashIcon" v-if="delete_permission == true"
                          svgClasses="w-5 h-5 hover:text-danger stroke-current edit_delete" class="ml-2"
                          @click.stop="deleteFaq(tr.faq_id)"/>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

  </vx-card>
</template>

<script>
// import moduleFaq          from '@/store/faq/moduleFaq.js'

export default {
  data() {
    return {
      promptName: 'Add Faq',
      formData: {},
      displayPrompt: false,
      disableAction: true,
      delete_permission: this.$store.state.faq_permission.delete,
      create_permission: this.$store.state.faq_permission.create,
      list_permission: this.$store.state.faq_permission.list,
      update_permission: this.$store.state.faq_permission.update
    }
  },
  components: {},
  computed: {
    faqs() {
      return this.$store.getters['faq/queriedFAQs']
    },
    validateForm() {
      // return !this.errors.any() && this.formData.faq_name !== '' && this.formData.faq_code !== ''
      return true
    }
  },
  methods: {
    date_format(date) {
      const d = new Date(date)
      return d.toDateString()
    },
    refreshData() {
      this.$vs.loading()
      this.$store.dispatch('faq/fetchFAQs').then(() => {
        this.$vs.loading.close()
      })
    },
    deleteFaq(faq_id) {
      this.$vs.loading()
      this.$store.dispatch('faq/deleteFAQ', faq_id).then(() => {
        this.$vs.loading.close()
        const error = "FAQ Removed Successfully...!!!"
        this.notif(error)
      }).catch((error) => {
        this.$vs.loading.close()
        this.notif(error)
      })
    },
    notif(error) {
      this.$vs.notify({
        title: 'Removed',
        text: error,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    },
  },
  created() {
    if (this.$store.state.faq_permission.update == false && this.$store.state.faq_permission.delete == false) {
      this.disableAction = false
    }
    // this.$store.registerModule('faq', moduleFaq)
    if (this.faqs.length == 0) {
      this.$vs.loading()
      this.$store.dispatch('faq/fetchFAQs').then(() => {
        this.$vs.loading.close()
      })
    }
  },
  beforeDestroy() {
    // this.$store.unregisterModule('faq')
  }
}
</script>
