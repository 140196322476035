<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div>

    <vs-row class="bg-white my-4">
      <vs-col class="flex justify-items-center p-4">
        <h2 class="text-black"></h2>

        <vs-button class="mr-4" color="primary" type="filled" vs-align="right"
                   @click="$router.push(APIListUrl+'/new').catch(() => {})">Add {{list_name}}
          <!-- {{ $t("modules.questionnaires.Add Problem Solving Code") }} -->
        </vs-button>
        <br>
        <vs-button class="mr-4" color="primary" type="filled" vs-align="right" @click="refreshData()">
          {{ $t("modules.questionnaires.Refresh") }}
        </vs-button>
        <vs-button class="mr-4" color="primary" type="filled" vs-align="right" @click="exportData()">
          {{ $t("modules.questionnaires.Export") }}
        </vs-button>

      </vs-col>
    </vs-row>
    <!-- SEARCH -->
    <div class="bg-white my-4">
      <vs-row>
        <vs-col class="flex justify-items-center p-4" vs-w="12">
          <h2 class="text-black text-sm">{{ $t("modules.questionnaires.Search Filter") }}</h2>
        </vs-col>
      </vs-row>

      <vs-row>

        <vs-col class="justify-items-center p-4 xs:w-full" vs-w="4" vs-xs="12">
          <vs-input class="inputx sm:w-full" :placeholder="list_name"
                    @keyup="handleNameSearch" v-model="name" />
        </vs-col>

        <!--
                <vs-col class="justify-items-center p-4 xs:w-full" vs-w="4" vs-xs="12">
                  <vs-input class="inputx sm:w-full" placeholder="Code"
                  @keyup="fetch_questiontype" v-model="code" />
                </vs-col>
        -->

      </vs-row>

    </div>
    <vx-card >

      <vs-table :data="pageData.items" :sst="true" ref="table" :key="renderKey"
                @search="handleSearch" @change-page="handleChangePage" @sort="handleSort"  :total="pageData.total" :max-items="pageData.page_size" noDataText="" stripe pagination search >

        <template slot="thead">
          <vs-th sort-key="id">{{ $t("modules.questionnaires.SNO") }}</vs-th>
          <vs-th sort-key="name" v-if="APIListUrl">{{list_name}}
            <!-- {{ $t("modules.questionnaires.Problem Solving Code") }} -->
          </vs-th>
          <vs-th sort-key="status">{{ $t("modules.questionnaires.Status") }}</vs-th>
          <vs-th>{{ $t("modules.questionnaires.Action") }}</vs-th>
        </template>

        <template>
          <vs-tr :key="indextr" v-for="(tr, indextr) in pageData.items">

            <vs-td :data="pageData.items[indextr].name">
              <!-- #{{ pageData.items[indextr].id }} -->
              {{ indextr + 1 }}
            </vs-td>

            <vs-td :data="pageData.items[indextr].name">
              {{ pageData.items[indextr].name }}
              <!-- {{ data[indextr].name }} -->
            </vs-td>


            <vs-td :data="pageData.items[indextr].status">
              <vs-chip v-if="pageData.items[indextr].status==0" :color="'#d9534f'" class="product-order-status">{{$t('modules.profile.InActive')}}</vs-chip>
              <vs-chip v-if="pageData.items[indextr].status==1" :color="'#5cb85c'" class="product-order-status">{{$t('modules.profile.Active')}}</vs-chip>
            </vs-td>

            <vs-td class="whitespace-no-wrap">
              <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current edit_delete"
                            @click.stop="$router.push(APIListUrl+'/edit/'+tr._id).catch(() => {})" />
              <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current edit_delete"
                            class="ml-2" @click.stop="deletegrade(tr._id)" />
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
           <vs-pagination :total="pageData.total_pages" v-model="pageData.page" :key="renderKey" />
        </template>

      </vs-table>

    </vx-card>
  </div>
</template>

<script>

import Vue from 'vue'
import axios from '@/axios'

export default {
  data () {
    return {
      renderKey: 2,
      formData: {},
      displayPrompt: false,
      pageData: {},
      is_loaded: false,
      selected: {},
      // problem_solution_code: "",
      // question_thinking_code: "",
      // question_type: "",
      list_name: '',
      download_file_name: '',
      name: '',
      code: '',
      keyword: '',
      sort_by: '',
      ordering: '',
      page: 1,
      limit: 3,
      total_results: 0,
      accuracy: []

    }


  },

  components: { },
  computed: {
    APIStoreUpdateUrl () {
      switch (this.$route.name) {
      case 'Problem Solving Code List':
        return '/problem-solving-codes'
        break
      case 'Question Type List':
        return '/question-types'
        break
      case 'Question Thinking Code List':
        return '/question-thinking-codes'
        break
      default:
        // console.warn('not configured`)
        break
      }
    },
    APIListUrl () {
      switch (this.$route.name) {
      case 'Problem Solving Code List':
        this.list_name = 'Problem Solving Code'
        this.download_file_name = 'problem_solving_data'
        return '/problem-solving-codes'
        break
      case 'Question Type List':
        this.list_name = 'Question Type'
        this.download_file_name = 'question_type_data'
        return '/question-type'
        break
      case 'Question Thinking Code List':
        this.list_name = 'Question Thinking Code'
        this.download_file_name = 'question_thinking_data'
        return '/question-thinking-codes'
        break
      default:
        // console.warn('not configured`)
        break
      }
    }
  },
  methods: {
    handleSearch (searching) {
      this.page = 1
      this.keyword = searching
      this.fetch_questiontype()
    },
    handleSort (col, ord) {
      this.sort_by = col
      this.ordering = ord
      this.fetch_questiontype()
    },
    handleChangePage (page) {
      this.page = page
      this.fetch_questiontype()
      this.refreshData()
    },
    handleNameSearch () {
      this.page = 1 //reset page if criteria changed
      this.fetch_questiontype()
    },
    refreshData () {
      this.name = ''
      this.code = ''
      this.fetch_questiontype()
    },

    deletegrade (id) {
      this.$vs.loading()
      axios.delete(this.APIStoreUpdateUrl +'/'+ id)
        .then((response) => {
          if (response.status === 200) {
            this.notifyUI('Deleted!', this.list_name+' has been deleted', 'success')
            this.fetch_questiontype()
          }
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },

    date_format (date) {
      const d = new Date(date)
      return d.toDateString()
    },
    exportData () {
      this.$vs.loading()
      axios({ method: 'get', url: `${this.APIStoreUpdateUrl  }/`, responseType: 'arraybuffer' })
        .then((response) => {
          this.forceFileDownload(response, this.download_file_name)
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },
    forceFileDownload (response, title) {
      const url = window.URL.createObjectURL(new Blob([response.data], {
        type: 'application/vnd.ms-excel'
      }))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', title)
      document.body.appendChild(link)
      link.click()
    },
    fetch_questiontype () {
      this.$vs.loading()

      axios.get(this.APIStoreUpdateUrl, {
        params: {
          name: this.name,
          code: this.code,
          keyword: this.keyword,
          sort_by: this.sort_by,
          ordering: this.ordering,
          page: this.page,
          limit: this.limit
        }
      })
        .then((response) => {
          // this.pageData = response.data
          // this.is_loaded = true
          // this.total_results = response.total

          Vue.set(this, 'pageData', response.data)
          this.is_loaded = true
          if (this.page == 1) this.renderKey = Math.random()
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    }
  },
  created () {
    this.fetch_questiontype()
  },
  beforeDestroy () {

  },
  watch:{
    $route (to, from){
      this.$root.$emit('route-re-render')
    }
  }
}
</script>
