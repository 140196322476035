<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div>

        <vs-row class="bg-white my-4">
            <vs-col class="flex justify-items-center p-4">
                <h2 class="text-black"></h2>
                <vs-button class="mr-4" color="primary" type="filled" vs-align="right"
                           @click="$router.push('/rubric-mapping/new').catch(() => {})">
                    {{ $t("modules.rubric_map.Add Rubric Mapping") }}
                </vs-button>
                <br>
                <vs-button class="mr-4" color="primary" type="filled" vs-align="right" @click="refreshData()">
                    {{ $t("modules.rubric_map.Refresh") }}
                </vs-button>
                <vs-button class="mr-4" color="primary" type="filled" vs-align="right" @click="exportData()">
                    {{ $t("modules.rubric_map.Export") }}
                </vs-button>

            </vs-col>
        </vs-row>
        <!-- SEARCH -->
        <div class="bg-white my-4">
            <vs-row>
                <vs-col class="flex justify-items-center p-4" vs-w="12">
                    <h2 class="text-black text-sm">{{ $t("modules.rubric_map.Search Filter") }}</h2>
                </vs-col>
            </vs-row>

            <vs-row>


                <vs-col class="justify-items-center p-4 xs:w-full" vs-w="3" vs-xs="12">
                    <vs-input class="inputx sm:w-full" :placeholder="$t('modules.rubric_map.Package')"
                              @keyup="handleNameSearch" v-model="package" />
                </vs-col>

                <vs-col class="flex justify-items-center p-4 xs:w-full" vs-w="4" vs-xs="12">
                    <vs-select
                        class="sm:w-full" @change="handleGradeSearch"
                        :placeholder="$t('modules.rubric_map.Grade level')"
                        v-model="grade_level">
                        <vs-select-item :key="index" :value="item.name" :text="item.title"
                                        v-for="(item,index) in grade_levels" />
                    </vs-select>
                </vs-col>


            </vs-row>

        </div>
        <vx-card v-if="is_loaded">

            <vs-table :sst="true" ref="table" pagination search :data="pageData.items"
                      @search="handleSearch" @change-page="handleChangePage" @sort="handleSort" v-model="selected"
                      :total="pageData.total"
                      :max-items="pageData.page_size" noDataText="" :key="renderKey">

                <template slot="thead">
                    <vs-th sort-key="id"> {{ $t("modules.rubric_map.Sl No") }}</vs-th>
                    <vs-th sort-key="title">{{ $t("modules.rubric_map.Package") }}</vs-th>
                    <vs-th sort-key="level">{{ $t("modules.rubric_map.Grade level") }}</vs-th>
                    <vs-th sort-key="created_at">{{ $t("modules.rubric_map.Created At") }}</vs-th>
                    <vs-th sort-key="status">{{ $t("modules.rubric_map.Status") }}</vs-th>
                    <vs-th>{{ $t("modules.rubric_map.Action") }}</vs-th>
                </template>

                <template slot-scope="{data}">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in pageData.items">

                        <vs-td :data="pageData.items[indextr]">
                            {{ indextr + 1 }}
                        </vs-td>

                        <vs-td :data="pageData.items[indextr].package.name[$i18n.locale]">
                            {{ pageData.items[indextr].package.name[$i18n.locale] }}
                        </vs-td>
                        <vs-td :data="pageData.items[indextr].grade_level">
                            {{ pageData.items[indextr].grade_level }}
                        </vs-td>

                        <vs-td :data="pageData.items[indextr].createdAt">
                            {{ date_format(pageData.items[indextr].createdAt) }}
                        </vs-td>

                        <vs-td :data="pageData.items[indextr].status">
                            <vs-chip v-if="pageData.items[indextr].status==0" :color="'#d9534f'" class="product-order-status">
                                {{ $t("modules.profile.InActive") }}
                            </vs-chip>
                            <vs-chip v-if="pageData.items[indextr].status==1" :color="'#5cb85c'" class="product-order-status">
                                {{ $t("modules.profile.Active") }}
                            </vs-chip>
                        </vs-td>

                        <vs-td class="whitespace-no-wrap">
                            <feather-icon icon="EditIcon"
                                          svgClasses="w-5 h-5 hover:text-primary stroke-current edit_delete"
                                          @click.stop="$router.push('/rubric-mapping/edit/'+tr._id).catch(() => {})" />
                            <feather-icon icon="TrashIcon"
                                          svgClasses="w-5 h-5 hover:text-danger stroke-current edit_delete"
                                          class="ml-2" @click.stop="deletegrade(tr._id)" />
                        </vs-td>
                    </vs-tr>
                </template>
                <template #footer>
                    <vs-pagination v-model="page" :total="pageData.total_pages" :key="renderKey" />
                </template>

            </vs-table>

        </vx-card>
    </div>
</template>

<script>

import Vue from 'vue'
import axios from "@/axios";

export default {
    data() {
        return {
            renderKey: 2,
            formData: {},
            displayPrompt: false,
            pageData: {},
            is_loaded: false,
            selected: {},
            package: "",
            grade_level: "",
            keyword: "",
            sort_by: "",
            ordering: "",
            page: 1,
            limit: 3,
            total_results: 0,
            grade_levels: [
                { name: "", title: "All" },
                { name: "elementary", title: "Elementary" },
                { name: "intermediate", title: "Intermediate" },
                { name: "higher", title: "Higher" }
            ]
        };
    },
    components: {},
    computed: {},
    methods: {
        handleSearch(searching) {
            this.page = 1
            this.keyword = searching;
            this.fetch_rubric();
        },

        handleSort(col, ord) {
            this.sort_by = col;
            this.ordering = ord;
            this.fetch_rubric();
        },

        handleChangePage(page) {
            this.page = page;
            this.fetch_rubric();
        },

        refreshData() {
            this.pakage_name = "";
            this.grade_level = "";
            this.fetch_rubric();
        },
        handleNameSearch() {
            this.page = 1
            this.fetch_rubric();
        },
        handleGradeSearch() {
            this.page = 1
            this.fetch_rubric();
        },
        deletegrade(id) {
            this.$vs.loading();
            axios.delete(`/rubric-mappings/${id}`)
                .then((response) => {
                    if (response.status === 200) {
                        this.notifyUI("Deleted!", "Rubric Mapping has been deleted", "success");
                        this.fetch_rubric();
                    }
                })
                .catch((error) => {
                    alert(`Error: ${error}`);
                })
                .then(() => this.$vs.loading.close());
        },

        date_format(date) {
            const d = new Date(date);
            return d.toDateString();
        },
        exportData() {
            this.$vs.loading();
            axios({ method: "get", url: "/export-rubric-mapping", responseType: "arraybuffer" })
                .then((response) => {
                    this.forceFileDownload(response, "rubric_mapping_data");
                })
                .catch((error) => {
                    alert(`Error: ${error}`);
                })
                .then(() => this.$vs.loading.close());
        },
        forceFileDownload(response, title) {
            const url = window.URL.createObjectURL(new Blob([response.data], {
                type: "application/vnd.ms-excel"
            }));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", title);
            document.body.appendChild(link);
            link.click();
        },
        fetch_rubric() {
            this.$vs.loading();

            axios.get("/rubric-mappings", {
                params: {
                    package: this.package,
                    grade_level: this.grade_level,
                    keyword: this.keyword,
                    sort_by: this.sort_by,
                    ordering: this.ordering,
                    page: this.page,
                    limit: this.limit
                }
            })
            .then((response) => {
                // this.pageData = response.data;
                // this.is_loaded = true;
                // this.total_results = response.total;
                Vue.set(this, 'pageData', response.data)
              this.is_loaded = true
              if (this.page == 1) this.renderKey = Math.random()
            })
            .catch((error) => {
                alert(`Error: ${error}`);
            })
            .then(() => this.$vs.loading.close());
        }
    },
    created() {
        this.fetch_rubric();
    },
    beforeDestroy() {

    }
};
</script>
