<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <vx-card>

      <!-- Content Row -->
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full">
          
          <vs-input class="w-full mt-4 required"  :maxlength=40 label="Title " v-model="formData['title']" v-validate="'required'" name="title" />
          <span class="text-danger text-sm"  v-show="errors.has('title')">{{ errors.first('title') }}</span>

          <div class="mt-4">
            <label class="text-sm " style="margin-left: 4px;">Module Name <span class="text-danger">*</span></label>
            <v-select class="w-full select-large" label="module_name" value="module_id" v-model="module_name_model" :clearable="false" :options="modules" v-validate="'required'" name="module_id" />
            <span class="text-danger text-sm"  v-show="errors.has('module_id')">{{ errors.first('module_id') }}</span>
          </div>

          <div class="mt-4">
            <label class="text-sm" style="margin-left: 4px;">Category Name <span class="text-danger">*</span></label>
            <v-select class="w-full select-large" label="category_name" value="category_id" v-model="category_name_model" :clearable="false" :options="main_categories" v-validate="'required'" name="category_id" />
            <span class="text-danger text-sm"  v-show="errors.has('category_id')">{{ errors.first('category_id') }}</span>
          </div>

        <!--   <div class="mt-4">
            <label class="text-sm" style="margin-left: 4px;">Sub Category Name</label>
            <v-select class="w-full select-large" label="category_name" value="category_id" v-model="sub_category_name_model" :clearable="false" :options="sub_categories" name="sub_category_id" />
          </div> -->
          <div class="mt-4">
                <label class="text-sm" style="margin-left: 4px;">Sub Category Name</label>
                <select class="w-full select-large custom_select"  v-model="formData['sub_category_id']"  @change="onChange($event)">
                  <option value="0"></option>
                  <option v-for="sub_categories in sub_categories" name="sub_category_id" v-bind:value="sub_categories.category_id">{{ sub_categories.category_name }}</option>
               </select>
          </div>

          <div class="mt-4">
            <label class="text-sm" style="margin-left: 4px;">Organization <span class="text-danger">*</span></label>
            <v-select class="w-full select-large" label="name" value="org_id" v-model="organization_model" :clearable="false" :options="organizations" v-validate="'required'" name="org_id" />
            <span class="text-danger text-sm"  v-show="errors.has('org_id')">{{ errors.first('org_id') }}</span>
          </div>
          <div class="mt-4" v-if="showCountrylist">
            <label class="text-sm" style="margin-left: 4px;">Country <span class="text-danger">*</span></label>
            <v-select class="w-full select-large" label="country_name" value="country_id" v-model="country_model" multiple :clearable="false" :options="countries" v-validate="'required'" name="country_id" />
          </div>
        <!--     <div class="mt-4" v-if="enableProgram">
            <label class="text-sm" style="margin-left: 4px;">Program</label>
            <v-select class="w-full select-large" label="title" value="program_id" v-model="program_model" :clearable="false" :options="programs" name="program_id" />
          </div> -->


          <div class="mt-4" v-if="enableProgram">
                <label class="text-sm" style="margin-left: 4px;">Program</label>
                <select class="w-full select-large custom_select"  v-model="formData['program_id']"  @change="onprogramChange($event)">
                  <option value="0"></option>
                  <option v-for="programs in programs" name="program_id" v-bind:value="programs.program_id">{{ programs.title }}</option>
               </select>
          </div>


          <div class="mt-4">
            <label class="text-sm" style="margin-left: 4px;">Tags <span class="text-danger">*</span></label>
            <v-select class="w-full select-large" value="tag_id"  v-bind:options="tags" v-model="tags_model" multiple label="tag_name" :clearable="false" track-by="tag_name" v-validate="'required'" name="media_tag_id" @remove="toggleUnSelectMarket" hide-selected />
           <vue-multiselect class="w-full select-large action_btn" value="tag_id"  v-bind:options="tags" v-model="tags_model" multiple label="tag_name" :clearable="false" track-by="tag_name" v-validate="'required'" name="media_tag_id" @remove="toggleUnSelectMarket" hide-selected ></vue-multiselect>
         
          </div>

          <div class="mt-4">
            <label class="text-sm" style="margin-left: 4px;">Short Description <span class="text-danger">*</span></label>
            <vs-textarea rows="2" v-validate="'required'"  :maxlength=80 name="short_desc" v-model="formData['short_desc']" placeholder="Short Description..." />
            <span class="text-danger text-sm"  v-show="errors.has('short_desc')">{{ errors.first('short_desc') }}</span>
          </div>
        </div>

        <div class="vx-col md:w-1/2 w-full">
          <div class="mt-4">
            <label class="text-sm" style="margin-left: 4px;">Long Description <span class="text-danger">*</span></label>
            <vs-textarea rows="4" v-validate="'required'" :maxlength=240 name="long_desc" v-model="formData['long_desc']" placeholder="Long Description..." />
            <span class="text-danger text-sm" v-show="errors.has('long_desc')">{{ errors.first('long_desc') }}</span>
          </div>

          <vs-input class="w-full mt-4 required" label="Media Duration (min) " v-model="formData['media_duration']" @keypress="isNumber($event)" v-validate="'required'" name="media duration" />
          <span class="text-danger text-sm"  v-show="errors.has('media_duration')">{{ errors.first('media_duration') }}</span>

          <div class="mt-4">
            <label class="text-sm" style="margin-left: 4px;">Media Type <span class="text-danger">*</span></label><br>
           <vs-radio v-model="formData['media_type']" v-if="enableMusic" name="media_type" @change="onchnage_media_type(2)" vs-value="2" style="width:100px">Music </vs-radio>
            <vs-radio v-model="formData['media_type']" v-if="enableVido" name="media_type" @change="onchnage_media_type(1)" vs-value="1" >Video </vs-radio>   
        </div>

          <!-- Avatar Row -->
          <div class="vx-row mt-4">
            <div class="vx-col w-full">
              <div class="flex items-start flex-col sm:flex-row profile_camera_icon thumb_icon">
                <img :src="formData['thumb_url']||'/400x400.png'" class="rounded h-32 w-32" />
                <!-- <vs-avatar :src="data.avatar" size="80px" class="mr-4" /> -->
                <div style="position: relative; left: 20px;" v-if="add_thumbnail" >
                  <input type="file" class="hidden" ref="update_thumb" @change="update_thumbnail" accept=".png,.jpg,.jpeg">
                  <!-- Toggle comment of below buttons as one for actual flow & currently shown is only for demo -->
                  <vs-button class="" v-if="add_thumbnail" @click="$refs.update_thumb.click()">Add Thumbnail</vs-button>
                </div>


                 <div style="position: relative; left: 20px;" v-if="remove_thumbnails" >
                  <input type="file" class="hidden" ref="remove_thumb" @change="remove_thumbnail" accept=".png,.jpg,.jpeg">
                  <!-- Toggle comment of below buttons as one for actual flow & currently shown is only for demo -->
                  <vs-button type="border"  v-if="remove_thumbnails"  @click="$refs.remove_thumb.click()">Replace Thumbnail</vs-button>
                </div>


              </div>
            </div>
          </div>
           <div class="mt-4" v-if="enableMp3">
              <div class="mt-4" v-if="upload_medias">
                <label class="text-sm" style="margin-left: 4px;">Upload Media</label><br>
                <input type="file" ref="du" class="hidden" @change="upload_media" accept=".mp4,.wmv,.mp3">
                <vs-button size="small" type="border" @click="$refs.du.click()">
                  <feather-icon icon="UploadIcon" svgClasses="h-4 w-4" />
                  <span class="ml-2 text-base text-primary">Upload</span>
                </vs-button>
              </div>
          </div>

            <div class="mt-4" v-if="enableMp3 == false">
              <div class="mt-4" v-if="upload_medias">
                <label class="text-sm" style="margin-left: 4px;">Upload Media</label><br>
                <input type="file" ref="du" class="hidden" @change="upload_media" accept=".mp4,.wmv">
                <vs-button size="small" type="border" @click="$refs.du.click()">
                  <feather-icon icon="UploadIcon" svgClasses="h-4 w-4" />
                  <span class="ml-2 text-base text-primary">Upload</span>
                </vs-button>
              </div>
          </div>
           <div class="mt-4" v-if="enableMp3">
                <div class="mt-4" v-if="remove_medias">
                  <label class="text-sm" style="margin-left: 4px;">Replace Media</label><br>
                  <input type="file" ref="du" class="hidden" @change="remove_media" accept=".mp4,.wmv,.mp3">
                  <vs-button size="small" type="border" @click="$refs.du.click()">
                    <feather-icon icon="UploadIcon" svgClasses="h-4 w-4" />
                    <span class="ml-2 text-base text-primary">Replace</span>
                  </vs-button>
                </div>
              </div>

           <div class="mt-4" v-if="enableMp3 == false">
              <div class="mt-4" v-if="remove_medias">
                  <label class="text-sm" style="margin-left: 4px;">Replace Media</label><br>
                  <input type="file" ref="du" class="hidden" @change="remove_media" accept=".mp4,.wmv">
                  <vs-button size="small" type="border" @click="$refs.du.click()">
                    <feather-icon icon="UploadIcon" svgClasses="h-4 w-4" />
                    <span class="ml-2 text-base text-primary">Replace</span>
                  </vs-button>
                </div>
              </div>

          <div class="mt-4">
            <label class="text-sm" style="margin-left: 4px;">Uploaded Media Url</label><br>
            <vs-chip class="uploaded_media_url">{{formData['media_url']}}</vs-chip>
          </div><br>

          <div class="mt-4">
            <label class="text-sm" style="margin-left: 4px;">Status</label>
            <vs-switch color="success" v-model="status_model">
                <span slot="on">Active</span>
                <span slot="off">InActive</span>
            </vs-switch>
          </div>

        </div>
        
      </div>

      <!-- Save & Reset Button -->
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <vs-button class="ml-auto mt-2" @click="save_changes" :disabled="!validateForm">Save Changes</vs-button>
             <vs-button class="ml-4 mt-2" v-if="reset_btn" type="border" color="warning" @click="reset_data">Reset</vs-button>
            <vs-button class="ml-4 mt-2" v-if="canel_btn" type="border" color="warning" @click="cancel_data">Cancel</vs-button>
          </div>
        </div>
      </div>

    </vx-card>
</template>

<script>
// import moduleMedia          from '@/store/media/moduleMedia.js'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import vueMultiselect from "vue-multiselect";

export default {
  data () {
    return {
      promptName :'Edit Profile', formData :{}, interests :[], enableVido:true, enableMusic:true, add_thumbnail: true, remove_thumbnails : '',
      upload_medias:true, remove_medias:'', media_url:0, thumb_url:0, is_isabel_7_min:0, is_general:0, reset_btn : true, canel_btn : false, enableMp3:true,
      showCountrylist : false,errImageupload:'',enableProgram:false
    }
  },
  components: {
    vSelect,
    flatPickr,
    vueMultiselect
  },
  computed: {
    media ()     { 
      if(!this.$route.params.id){
        return {'is_isabel_7_min':0,'is_general':0,'country_id':'','status':"0",'media_url':'','thumb_url':'/400x400.png',"module_id":"","category_id":"","media_tag_id":"","media_type":"2",program_id:"0"};
      }else{
            
         if(this.formData.thumb_url != ""){
            this.remove_thumbnails = true;
            this.add_thumbnail = false;
            this.upload_medias = false;
            this.remove_medias = true;
            this.media_url = 1 
            this.thumb_url = 1 
            this.reset_btn = false
            this.canel_btn = true
        }
        return this.$store.getters['media/getMedia'](this.$route.params.id)
      }
    },
     countries () { 
      return this.$store.getters['country/getActiveCountries']
    },
    tags () { 
      return this.$store.getters['tag/getActiveTags']
    },
    programs ()     { 
      console.log('cacaaaaa',this.formData['country_id'])
      if(this.formData['country_id'] == 1){
        console.log('123')
          return this.$store.getters['program/getMediaProgram'](this.formData['module_id'])      

      }else{

        console.log('else')
         return this.$store.getters['program/getSelectedprogram'](this.formData['country_id']) 

      }
    },
    modules () { 
      return this.$store.getters['module/getActiveModules']
    },
    categories ()     { 
      return this.$store.getters['category/queriedCategories']      
    },
    main_categories () { 
      return this.$store.getters['category/getMainMediaCategories'](this.formData['module_id'])
    },
    sub_categories () { 
      const subCat = this.$store.getters['category/getSubCategories'](this.formData['category_id'])
     // if(subCat.length > 0){
      //  const subcatvalue =subCat[0]['category_id']
      // localStorage.setItem('subcatvalue',subcatvalue)
      // }
      return subCat
    },
    organizations ()     { 
      return this.$store.getters['organization/getActiveOrganizations']      
    },
    modules () { 
      return this.$store.getters['module/getActiveModules']
    },
    categories () { 
      return this.$store.getters['category/queriedCategories']
    },
    status_model: {
      get () {
        return this.formData['status'] === '1'
      },
      set (status) {
        this.formData['status'] = status ? '1' : '0'
      }
    },
    module_name_model: {
      get () {
        return this.$store.getters['module/getModule'](this.formData['module_id'])
      },
      set (module_name) {
          this.formData['category_id'] = ''
        this.formData['sub_category_id'] = ''
        this.formData['module_id'] = module_name['module_id']
      }
    },
    category_name_model: {
      get () {
        console.log('came',this.formData['category_id'])
         if(this.formData['category_id'] !== ""){
          if(this.formData['category_id'] == 1 || this.formData['category_id'] == 4 || this.formData['category_id'] == 6){
            this.enableProgram = true
          }else{
            this.enableProgram = false
          }

          if(this.formData['category_id'] == 2){
                this.enableMp3 = true
                this.enableMusic = true
                  this.enableVido = false
                  this.formData['media_type'] = 2
            }else{
                  this.enableMp3 = false
                  this.enableVido = true
                  this.enableMusic = false
                  this.formData['media_type'] = 1
            }
          }
        return this.$store.getters['category/getCategory'](this.formData['category_id'])
      },
      set (category_name) {
        this.formData['media_url'] = ''
        this.media_url = 0
        this.sub_category_name_model = ''
        this.unsetdata()
        this.formData['category_id'] = category_name['category_id']
      }
    },
    sub_category_name_model: {
      get () {
        return this.$store.getters['category/getCategory'](this.formData['sub_category_id'])
      },
      set (category_name) {
        //localStorage.setItem('subcatvalue',category_name['category_id'])
        console.log('checl',this.formData['sub_category_id'])
        this.formData['sub_category_id'] = category_name['category_id']
      }
    },
    organization_model: {
      get () {
         if(this.formData['org_id'] == 1){
           this.showCountrylist = true
         }else{
           this.showCountrylist = false
         }
        return this.$store.getters['organization/getOrganization'](this.formData['org_id'])
      },
      set (obj) {
        this.formData['country_id'] = ""
        const country =  this.$store.getters['organization/getOrganization'](obj.org_id)
        this.formData['org_id'] = obj.org_id
        if(obj.org_id == 1){
          this.showCountrylist = true
        }else{
          this.formData['country_id']  = country.country_id
          this.showCountrylist = false
        }
      }
    },
    program_model: {
      get () {
        return this.$store.getters['program/getProgram'](this.formData['program_id'])
      },
      set (obj) {
        this.formData['program_id'] = obj.program_id
      }
    },
      country_model: {
      get () {
        const countryId = this.formData['country_id'].toString()
        if(countryId !== ""){
          return this.$store.getters['country/getSelectedCountries'](countryId||'')
        }
      },
      set (obj) {
         let tmp = [];
        for (var i = 0; i < obj.length; i++) {
          tmp.push(obj[i].country_id);
        };
        this.formData['country_id'] = tmp //.join(',');
        console.log('tmp',tmp)
      }
    },
    tags_model: {
      get () {
        const tagId = this.formData['media_tag_id'].toString()
        return this.$store.getters['tag/getSelectedTags'](tagId||'')
      },
      set (tags) {
        let tmp = [];
        for (var i = 0; i < tags.length; i++) {
          tmp.push(tags[i].tag_id);
        };
        this.formData['media_tag_id'] = tmp //.join(',');
      }
    },
    validateForm () {
      //return true
      //return !this.errors.any() && this.formData.title !== '' && this.formData.module_id !== '' && this.formData.category_id !== ''  && this.formData.country_id !== '' && this.formData.org_id !== '' && this.formData.program_id !== '' && this.formData.media_tag_id !== '' && this.formData.short_desc !== '' && this.formData.long_desc !== '' && this.formData.media_duration !== '' && this.formData.thumb_url !== '' && this.formData.media_url !== '' && this.formData.title && this.formData.module_id && this.formData.category_id && this.formData.country_id && this.formData.org_id && this.formData.program_id && this.formData.media_tag_id && this.formData.short_desc && this.formData.long_desc && this.formData.media_duration && this.formData.thumb_url && this.formData.media_url
      if(this.showCountrylist == true){
            return !this.errors.any() && this.formData.title !== '' && this.formData.module_id !== '' && this.formData.category_id !== ''  && this.formData.org_id !== '' && this.formData.media_tag_id !== '' && this.formData.country_id !== '' && this.formData.short_desc !== '' && this.formData.long_desc !== '' && this.formData.media_duration !== '' && this.thumb_url == 1 && this.media_url == 1 && this.formData.title && this.formData.module_id && this.formData.category_id && this.formData.org_id && this.formData.country_id && this.formData.media_tag_id && this.formData.short_desc && this.formData.long_desc && this.formData.media_duration 
      }else{
            return !this.errors.any() && this.formData.title !== '' && this.formData.module_id !== '' && this.formData.category_id !== ''  && this.formData.org_id !== '' && this.formData.media_tag_id !== '' && this.formData.short_desc !== '' && this.formData.long_desc !== '' && this.formData.media_duration !== '' && this.thumb_url == 1 && this.media_url == 1 && this.formData.title && this.formData.module_id && this.formData.category_id && this.formData.org_id && this.formData.media_tag_id && this.formData.short_desc && this.formData.long_desc && this.formData.media_duration 
      }
    }
  },
  methods: {
    onChange(event) {
      localStorage.setItem('subcatvalue',event.target.value)
    },
    onprogramChange(event) {
      localStorage.setItem('program_id',event.target.value)
    },
    toggleUnSelectMarket({ value, id }) {
      this.toggleUnSelectLojas(value, id);
    },
    toggleUnSelectLojas(value, id) {
    },
    isNumber: function($event) {
         let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      // only allow number and one dot
       if ((keyCode < 48 || keyCode > 57) && (keyCode !== 58 || this.formData['media_duration'].indexOf(':') != -1)) { // 46 is dot
        $event.preventDefault();
       }

       // restrict to 2 decimal places
       if(this.formData['media_duration']!=null && this.formData['media_duration'].indexOf(":")>-1  && keyCode != 58 && (this.formData['media_duration'].split(':')[1].length > 1)){
       $event.preventDefault();
       }
    },
    unsetdata(){
      this.sub_category_id = ''
      this.formData['sub_category_name_model'] = ""
    },
      cancel_data(){
        this.$router.push('/media/all/').catch(() => {})
    },
    save_changes () {
      this.$vs.loading()
    //  if(this.formData['sub_category_id'] == undefined){
              this.formData['sub_category_id'] = 0
      //      }
            if (localStorage["subcatvalue"]) {
                this.formData['sub_category_id'] = localStorage.getItem('subcatvalue')
            }
            if (localStorage["program_id"]) {
                this.formData['program_id'] = localStorage.getItem('program_id')
            }

            
      if(this.formData['sub_category_id'] == "undefined" || this.formData['sub_category_id'] == undefined){
        this.formData['sub_category_id'] = 0
      }
      // if (!this.validateForm) return
      if(!this.$route.params.id){
        this.$validator.validateAll().then(result => {
          //if (result) {
            this.formData['general_media'] = 0
            this.formData['media_tag_id'] = this.formData['media_tag_id'].toString()
            this.formData['country_id']   = this.formData['country_id'].toString()
          
            this.$store.dispatch('media/addMedia', Object.assign({}, this.formData)).then(() => {
              localStorage.removeItem("subcatvalue");
              localStorage.removeItem("program_id");
              
              this.$store.dispatch('media/fetchMedias')
               this.$vs.loading.close()  
              const success = 'Media Added Successfully...!!!'
              this.notif_sucess(success) 
            }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
            })
          //}
        })
      }else{
        this.$validator.validateAll().then(result => {
          if (result) {
            this.formData['general_media'] = 0
             this.formData['media_tag_id'] = this.formData['media_tag_id'].toString()
            this.formData['country_id']   = this.formData['country_id'].toString()
            this.$store.dispatch('media/updateMedia', Object.assign({}, this.formData)).then(() => {
              this.$store.dispatch('media/fetchMedias')
              localStorage.removeItem("program_id");
               localStorage.removeItem("subcatvalue");
               this.$vs.loading.close()  
              const success = 'Media Updated Successfully...!!!'
              this.notif_sucess(success) 
            }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
            })
          }
        })
      }
    },
    reset_data () {
       this.add_thumbnail = true;
        this.remove_thumbnails = false;
         this.upload_medias = true;
        this.remove_medias = false;
      this.formData = Object.assign({}, this.media)
    },
    notif (error) {
      this.$vs.notify({
        title: 'Alert',
        text: error.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    },
    notif_failed (error) {
      this.$vs.notify({
        title: 'Alert',
        text: error,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color:"danger"
      })
    },
     update_thumbnail (e) {
      this.$vs.loading()
                let file = e.target.files[0];
                let reader = new FileReader();
                const fileSize = file.size
                var sizeInMB = (fileSize / (1024*1024)).toFixed(2);
                reader.readAsDataURL(file);
                reader.onload = e => {
                    const src = e.target.result;
                    const img = new Image();
                    img.onload = () => {
                      // if((img.height > 400 || img.width > 400) || (sizeInMB > 2)){
                       if(sizeInMB > 2)
                      {
                            this.errImageupload = 1
                            this.$refs.update_thumb.value = ''
                            this.$vs.loading.close() 
                            const error = "The uploaded image size should be below 2 MB and its resolution should be 400*400 pixel..!!"
                            this.notif_failed(error)
                      }else{
                            this.errImageupload = 0
                      }
                    };
                    img.src = src;
                };
    setTimeout(() => {
      if(this.errImageupload == 0){
      this.$store.dispatch('media/uploadImage', {image:e.target.files[0], media_id:this.formData.media_id}).then((res) => {
        this.formData['thumb_url'] = res
        this.remove_thumbnails = true;
        this.thumb_url = 1
        this.add_thumbnail = false;
        this.$refs.update_thumb.value = ''
        this.$vs.loading.close()
      }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
         })
      }
    }, 200);
  },
    onchnage_media_type(e){
      if(e == 2){
        this.enableMp3 = true
      }else{
        this.enableMp3 = false
      }
    },
     remove_thumbnail(e) {
       this.$vs.loading()
                let file = e.target.files[0];
                let reader = new FileReader();
                const fileSize = file.size
                var sizeInMB = (fileSize / (1024*1024)).toFixed(2);
                reader.readAsDataURL(file);
                reader.onload = e => {
                    const src = e.target.result;
                    const img = new Image();
                    img.onload = () => {
                    //  if((img.height > 400 || img.width > 400) || (sizeInMB > 2)){
                     if(sizeInMB > 2)
                      {
                            this.errImageupload = 1
                            this.$refs.remove_thumb.value = ''
                            this.$vs.loading.close() 
                            const error = "The uploaded image size should be below 2 MB and its resolution should be 400*400 pixel..!!"
                            this.notif_failed(error)
                      }else{
                            this.errImageupload = 0
                      }
                    };
                    img.src = src;
                };
              setTimeout(() => {
                  if(this.errImageupload == 0){
                      this.$store.dispatch('media/removeImage', {image:e.target.files[0], media_id:this.formData.media_id,oldImage:this.formData['thumb_url']}).then((res)=>{
                        this.add_thumbnail = false;
                        this.$refs.remove_thumb.value = ''
                        this.formData['thumb_url'] = res
                        this.remove_thumbnails = true;
                        this.thumb_url = 1
                        this.$vs.loading.close()
                      }).catch((error) => { 
                                  this.$vs.loading.close()
                                  this.notif(error)     
                        })
                    }
              }, 200);
    },
     upload_media (e) {
      let file = e.target.files[0];
       const fileSize = file.size
      var sizeInMB = (fileSize / (1024*1024)).toFixed(2);
       if(sizeInMB > 500){
            this.$refs.du.value = ''
            this.$vs.loading.close()
            const error = "Maximum we can upload 500Mb size of media..!!"
            this.notif_failed(error)
       }else{
          this.$vs.loading()
          this.$store.dispatch('media/uploadMedia', {media:e.target.files[0], media_id:this.formData.media_id}).then((res) => {
            this.formData['media_url'] = res
            this.upload_medias = false;
            this.remove_medias = true;
            this.media_url = 1
            this.$vs.loading.close()
          }).catch((error) => { 
                      this.$vs.loading.close()
                      this.notif(error)     
            })
          }
    },
     remove_media (e) {
        let file = e.target.files[0];
       const fileSize = file.size
      var sizeInMB = (fileSize / (1024*1024)).toFixed(2);
       if(sizeInMB > 500){
            this.$refs.du.value = ''
            this.$vs.loading.close()
            const error = "Maximum we can upload 500Mb size of media..!!"
            this.notif_failed(error)
       }else{
          this.$vs.loading()
          this.$store.dispatch('media/removeMedia', {media:e.target.files[0],image:this.formData['media_url'], media_id:this.formData.media_id}).then((res) => {
            this.formData['media_url'] = res
            this.$refs.du.value = ''
            this.upload_medias = false;
            this.remove_medias = true;
            this.media_url = 1
            this.$vs.loading.close()
          }).catch((error) => { 
                      this.$vs.loading.close()
                      this.notif(error)     
            })
       }
    }, 
        notif_sucess (success) {
      this.$vs.notify({
        title: 'success',
        text: success,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color:"success"
      })
    },
  },
  created () {
    // this.$store.registerModule('media', moduleMedia)
    if (!this.media) {
      this.$store.dispatch('media/fetchMedias').then(() => {
        this.formData = Object.assign({}, this.media)
      })
    } else {
      this.formData = Object.assign({}, this.media)
    }
    if (this.modules.length === 0) {
      this.$store.dispatch('module/fetchModules')
    }
    if (this.categories.length === 0) {
      this.$store.dispatch('category/fetchCategories')
    }
    if (this.organizations.length === 0) {
      this.$store.dispatch('organization/fetchOrganizations')
    }
    if (this.programs.length === 0) {
      this.$store.dispatch('program/fetchPrograms')
    }
     if(this.countries.length==0){
      this.$store.dispatch('country/fetchCountries')
    }
    if (this.tags.length === 0) {
      this.$store.dispatch('tag/fetchTags')
    }
  },
  beforeDestroy () {
    // this.$store.unregisterModule('media')
  }
}
</script>
