<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <vx-card>    
      <!-- Content Row -->
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full">
         <div class="mt-4">
            <label class="text-sm required" style="margin-left: 4px;">{{$t('modules.role.Employee Name ')}} <span class="text-danger">*</span></label>
            <v-select class="w-full select-large" :label="$t('modules.role.first_name')"  value="employee_id" v-model="employee_model" :clearable="false" :options="employees" v-validate="'required'" name="role_id" />
          </div>
           <div class="mt-4">
            <label class="text-sm required" style="margin-left: 4px;">{{$t('modules.role.Role Name')}} <span class="text-danger">*</span></label>
            <v-select class="w-full select-large" :label="$t('modules.role.name')"  value="role_id" v-model="role_model" :clearable="false" :options="roles" v-validate="'required'" name="role_id" />
          </div>
        </div>
      </div>

      <!-- Save & Reset Button -->
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <vs-button class="ml-auto mt-2" @click="save_changes" :disabled="!validateForm">{{$t('modules.role.Save Changes')}</vs-button>
            <vs-button class="ml-4 mt-2" v-if="reset_btn" type="border" color="warning" @click="reset_data">{{$t('modules.role.Reset')}</vs-button>
            <vs-button class="ml-4 mt-2" v-if="canel_btn" type="border" color="warning" @click="cancel_data">{{$t('modules.role.Cancel')}</vs-button>
          </div>
        </div>
      </div>

    </vx-card>
</template>

<script>
// import moduleRole          from '@/store/role/moduleRole.js'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  data () {
    return {
      formData      : {},
      employeeSelected:0,
      roleSelected:0,
        reset_btn : true,
       canel_btn : false,

    }
  },
  components: {
    vSelect,
    flatPickr
  },
  computed: {
    role ()     { 
      if (!this.$route.params.id) {
        return {
        }
      } else {
              this.reset_btn = false
              this.canel_btn = true
              this.employeeSelected =1 
              this.roleSelected =1
        return this.$store.getters['role/getRoleUser'](this.$route.params.id)
      }
    },
    employees ()     { 
      return this.$store.getters['employee/queriedRolesEmployees']      
    },
    roles ()     { 
      return this.$store.getters['role/queriedRoles']      
    },
     employee_model: {
      get () {
        return this.$store.getters['employee/getRoleEmployee'](this.formData['employee_id'])
      },
      set (obj) {
        this.employeeSelected = 1
        this.formData['employee_id'] = obj.employee_id
         return this.$store.getters['employee/getRoleEmployee'](obj.employee_id)
      }
    },
    role_model: {
      get () {

        return this.$store.getters['role/getRole'](this.formData['role_id'])
      },
      set (obj) {
        this.roleSelected = 1
        this.formData['role_id'] = obj.role_id
        return  this.$store.getters['role/getRole'](obj.role_id)
      }
    },
    validateForm () {
    //return true;
      return !this.errors.any()  && this.employeeSelected ==1  && this.roleSelected ==1
    }
  },
  methods: {
     cancel_data(){
        this.$router.push('/user_role/all/').catch(() => {})
    },
   save_changes () {
     this.$vs.loading()
      this.formData['emp_cognito_id']='0232a67f-6942-4e48-a583-96f14084cfaa'
      this.formData['role_access_mod_id']='1'
      this.formData['map_id']=this.$route.params.id
      if(!this.$route.params.id){
      this.$validator.validateAll().then(result => {
        this.$store.dispatch('role/addUserRole', Object.assign({}, this.formData)).then(() => {
              this.$vs.loading.close()  
              const success = 'Role added successfully...!!!'
              this.notif_sucess(success) 
        }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
         })
      })
    }else{
         this.$validator.validateAll().then(result => {
        this.$store.dispatch('role/updateUserRole', Object.assign({}, this.formData)).then(() => {
          this.$vs.loading.close()  
              const success = 'Role Updated Successfully...!!!'
              this.notif_sucess(success) 
        }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
         })
        })
      }
    },
    reset_data () {
      this.formData = Object.assign({}, this.role)
    },
    notif (error) {
      this.$vs.notify({
        title: 'Alert',
        text: error.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    },
    notif (error) {
      this.$vs.notify({
        title: 'Alert',
        text: error.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    },
      notif_sucess (success) {
      this.$vs.notify({
        title: 'success',
        text: success,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color:"success"
      })
    },
  },
  created () {
    // this.$store.registerModule('role', moduleRole)
      this.$store.dispatch('role/fetchRoles').then(() => {
        this.formData = Object.assign({}, this.role)
      })
   
     this.$store.dispatch('role/fetchUserRoles')
     
      this.$store.dispatch('employee/fetchEmployees')
    
  },
  beforeDestroy () {
    // this.$store.unregisterModule('role')
  }
}
</script>
