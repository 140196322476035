/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import store from './store/store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,

  scrollBehavior () {
    return { x: 0, y: 0 }
  },

  routes: [

    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          redirect: '/login'
        },
        {
          path: '/dashboard',
          name: 'dashboard',
          component: () => import('./views/DashboardAnalytics.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: true
          }
        },
        // =============================================================================
        // Application Routes
        // =============================================================================

        /*COUNTRY*/
        {
          path: '/country',
          name: 'Country',
          component: () => import('./views/apps/country/CountryList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Country', active: true }
            ],
            rule: 'editor',
            parent: 'country',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/country/new',
          name: 'Country',
          component: () => import('./views/apps/country/CountryAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Country', url: '/country' },
              { title: 'Add Country', active: true }
            ],
            rule: 'editor',
            parent: 'country',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/country/edit/:id',
          name: 'Edit Country',
          component: () => import('./views/apps/country/CountryAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Country', url: '/country' },
              { title: 'Edit Country', active: true }
            ],
            rule: 'editor',
            parent: 'country',
            no_scroll: true,
            requiresAuth: true
          }
        },
         /*HELP&SUPPORT*/
         {
          path: '/help-support',
          name: 'Help-support-list',
          component: () => import('./views/apps/help_support/HelpsupportList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Help & Support', active: true }
            ],
            rule: 'editor',
            parent: 'help_support',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/help-support/reply/:id',
          name: 'Help-support',
          component: () => import('./views/apps/help_support/HelpSupportAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Help & Support', url: '/help-support' },
              { title: 'Help & Support', active: true }
            ],
            rule: 'editor',
            parent: 'help_support',
            no_scroll: true,
            requiresAuth: true
          }
        },
         /*FEEDBACK*/
         {
           path: '/provider-feedback',
          name: 'Provider-Feedback',
          component: () => import('./views/apps/feedback/FeedbackList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Feedback', active: true }
            ],
            rule: 'editor',
            parent: 'feedback',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/provider-feedback/view/:id',
          name: 'Provider-Feedback',
          component: () => import('./views/apps/feedback/FeedbackAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Feedback', url: '/provider-feedback' },
              { title: 'Feedback', active: true }
            ],
            rule: 'editor',
            parent: 'feedback',
            no_scroll: true,
            requiresAuth: true
          }
        },
        /*Grade*/
        {
          path: '/grade',
          name: 'Grade',
          component: () => import('./views/apps/grade/GradeList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Grade', active: true }
            ],
            rule: 'editor',
            parent: 'grade',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/grade/new',
          name: 'Grade',
          component: () => import('./views/apps/grade/GradeAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Grade', url: '/grade' },
              { title: 'Add Grade', active: true }
            ],
            rule: 'editor',
            parent: 'grade',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/grade/edit/:id',
          name: 'Edit Grade',
          component: () => import('./views/apps/grade/GradeAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Grade', url: '/grade' },
              { title: 'Edit Grade', active: true }
            ],
            rule: 'editor',
            parent: 'grade',
            no_scroll: true,
            requiresAuth: true
          }
        },
        /*Course Type*/
        {
          path: '/course-types',
          name: 'Course Type',
          component: () => import('./views/apps/course_type/CourseTypeList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Course Type', active: true }
            ],
            rule: 'editor',
            parent: 'Course Type',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/course-types/new',
          name: 'Course Type',
          component: () => import('./views/apps/course_type/CourseTypeAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Course Type', url: '/course-types' },
              { title: 'Add Course Type', active: true }
            ],
            rule: 'editor',
            parent: 'Course Type',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/course-types/edit/:id',
          name: 'Edit Course Type',
          component: () => import('./views/apps/course_type/CourseTypeAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Course Type', url: '/course-types' },
              { title: 'Edit Course Type', active: true }
            ],
            rule: 'editor',
            parent: 'Course Type',
            no_scroll: true,
            requiresAuth: true
          }
        },
        /*Rubric Mapping*/
        {
          path: '/rubric-mapping',
          name: 'Rubric Mapping',
          component: () => import('./views/apps/rubric_mapping/RubricMappingList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Rubric Mapping', active: true }
            ],
            rule: 'editor',
            parent: 'Rubric Mapping',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/rubric-mapping/new',
          name: 'Rubric Mapping',
          component: () => import('./views/apps/rubric_mapping/RubricMappingAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Rubric Mapping', url: '/rubric-mapping' },
              { title: 'Add Rubric Mapping', active: true }
            ],
            rule: 'editor',
            parent: 'Rubric Mapping',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/rubric-mapping/edit/:id',
          name: 'Edit Rubric Mapping',
          component: () => import('./views/apps/rubric_mapping/RubricMappingAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Rubric Mapping', url: '/rubric-mapping' },
              { title: 'Edit Rubric Mapping', active: true }
            ],
            rule: 'editor',
            parent: 'Rubric Mapping',
            no_scroll: true,
            requiresAuth: true
          }
        },
        /*Student*/
        {
          path: '/student',
          name: 'Student',
          component: () => import('./views/apps/student/StudentList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Student', active: true }
            ],
            rule: 'editor',
            parent: 'student',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/student/new',
          name: 'Student',
          component: () => import('./views/apps/student/StudentAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Student', url: '/student' },
              { title: 'Add Student', active: true }
            ],
            rule: 'editor',
            parent: 'student',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/student/edit/:id',
          name: 'Edit Student',
          component: () => import('./views/apps/student/StudentAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Student', url: '/student' },
              { title: 'Edit Student', active: true }
            ],
            rule: 'editor',
            parent: 'student',
            no_scroll: true,
            requiresAuth: true
          }
        },
        /*teacher*/
        {
          path: '/teacher',
          name: 'Teacher',
          component: () => import('./views/apps/teacher/TeacherList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Teacher', active: true }
            ],
            rule: 'editor',
            parent: 'teacher',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/teacher/new',
          name: 'teacher',
          component: () => import('./views/apps/teacher/TeacherAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Teacher', url: '/teacher' },
              { title: 'Add Teacher', active: true }
            ],
            rule: 'editor',
            parent: 'teacher',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/teacher/edit/:id',
          name: 'Edit Teacher',
          component: () => import('./views/apps/teacher/TeacherAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Teacher', url: '/teacher' },
              { title: 'Edit Teacher', active: true }
            ],
            rule: 'editor',
            parent: 'teacher',
            no_scroll: true,
            requiresAuth: true
          }
        },
        /*CITY*/
        {
          path: '/city',
          name: 'City',
          component: () => import('./views/apps/city/CityList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'City', active: true }
            ],
            rule: 'editor',
            parent: 'city',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/city/new',
          name: 'City',
          component: () => import('./views/apps/city/CityAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'City', url: '/city' },
              { title: 'Add City', active: true }
            ],
            rule: 'editor',
            parent: 'city',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/city/edit/:id',
          name: 'Edit City',
          component: () => import('./views/apps/city/CityAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'City', url: '/city' },
              { title: 'Edit City', active: true }
            ],
            rule: 'editor',
            parent: 'city',
            no_scroll: true,
            requiresAuth: true
          }
        },
        /*CLASS*/
        {
          path: '/classes',
          name: 'Class',
          component: () => import('./views/apps/classes/ClassList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Class', active: true }
            ],
            rule: 'editor',
            parent: 'class',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/classes/new',
          name: 'Class',
          component: () => import('./views/apps/classes/ClassAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Class', url: '/classes' },
              { title: 'Add Class', active: true }
            ],
            rule: 'editor',
            parent: 'class',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/classes/edit/:id',
          name: 'Edit Class',
          component: () => import('./views/apps/classes/ClassAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Class', url: '/classes' },
              { title: 'Edit Class', active: true }
            ],
            rule: 'editor',
            parent: 'classes',
            no_scroll: true,
            requiresAuth: true
          }
        },
        /*LANGUAGE*/
        {
          path: '/languages',
          name: 'Language',
          component: () => import('./views/apps/settings/languages/LanguageList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Language', active: true }
            ],
            rule: 'editor',
            parent: 'language',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/languages/new',
          name: 'Language',
          component: () => import('./views/apps/settings/languages/LanguageAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Language', url: '/languages' },
              { title: 'Add Language', active: true }
            ],
            rule: 'editor',
            parent: 'language',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/languages/edit/:id',
          name: 'Edit Language',
          component: () => import('./views/apps/settings/languages/LanguageAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Language', url: '/languages' },
              { title: 'Edit Language', active: true }
            ],
            rule: 'editor',
            parent: 'language',
            no_scroll: true,
            requiresAuth: true
          }
        },


        /*PAYMENT*/
        {
          path: '/payments',
          name: 'Payment',
          component: () => import('./views/apps/settings/payment/PaymentList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Payment', active: true }
            ],
            rule: 'editor',
            parent: 'payment',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: 'payments/new',
          name: 'Payment',
          component: () => import('./views/apps/settings/payment/PaymentAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Payment', url: '/payments' },
              { title: 'Add Payment', active: true }
            ],
            rule: 'editor',
            parent: 'payment',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: 'payments/edit/:id',
          name: 'Edit Payment',
          component: () => import('./views/apps/settings/payment/PaymentAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Payment', url: '/payments' },
              { title: 'Edit Payment', active: true }
            ],
            rule: 'editor',
            parent: 'payment',
            no_scroll: true,
            requiresAuth: true
          }
        },

        {
          path: '/category/all',
          name: 'Categories',
          component: () => import('./views/apps/category/Category.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Categories', active: true }
            ],
            rule: 'editor',
            parent: 'category',
            no_scroll: true,
            requiresAuth: true

          }
        },

        /*QUESTION TYPE*/
        {
          path: '/question-type',
          name: 'Question Type List',
          component: () => import('./views/apps/rubric_classifications/Rubric_classificationsList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Question Type', active: true }
            ],
            rule: 'editor',
            parent: 'question type',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/question-type/new',
          name: 'Question Type AddEdit',
          component: () => import('./views/apps/rubric_classifications/Rubric_classificationsAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Question Type', url: '/question-type' },
              { title: 'Add Question Type', active: true }
            ],
            rule: 'editor',
            parent: 'question type',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: 'question-type/edit/:id',
          name: 'Question Type AddEdit',
          component: () => import('./views/apps/rubric_classifications/Rubric_classificationsAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Question Type', url: '/question-type' },
              { title: 'Edit Question Type', active: true }
            ],
            rule: 'editor',
            parent: 'question type',
            no_scroll: true,
            requiresAuth: true
          }
        },

        /*QUESTION Thinking code*/
        {
          path: '/question-thinking-codes',
          name: 'Question Thinking Code List',
          component: () => import('./views/apps/rubric_classifications/Rubric_classificationsList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Question Thinking Code', active: true }
            ],
            rule: 'editor',
            parent: 'question thinking codes',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/question-thinking-codes/new',
          name: 'Question Thinking Code AddEdit',
          component: () => import('./views/apps/rubric_classifications/Rubric_classificationsAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Question Thinking Code', url: '/question-thinking-codes' },
              { title: 'Add Question Thinking Code', active: true }
            ],
            rule: 'editor',
            parent: 'question thinking code',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: 'question-thinking-codes/edit/:id',
          name: 'Question Thinking Code AddEdit',
          component: () => import('./views/apps/rubric_classifications/Rubric_classificationsAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Question Thinking Code', url: '/question-thinking-codes' },
              { title: 'Edit Question Thinking Code', active: true }
            ],
            rule: 'editor',
            parent: 'question thinking code',
            no_scroll: true,
            requiresAuth: true
          }
        },

        /*Problem Solving Codes*/
        {
          path: '/problem-solving-codes',
          name: 'Problem Solving Code List',
          component: () => import('./views/apps/rubric_classifications/Rubric_classificationsList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Problem Solving Codes', active: true }
            ],
            rule: 'editor',
            parent: 'Problem Solving Codes',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/problem-solving-codes/new',
          name: 'Problem Solving Codes AddEdit',
          component: () => import('./views/apps/rubric_classifications/Rubric_classificationsAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Problem Solving Codes', url: '/problem-solving-codes' },
              { title: 'Add Problem Solving Codes', active: true }
            ],
            rule: 'editor',
            parent: 'Problem Solving Codes',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: 'problem-solving-codes/edit/:id',
          name: 'Problem Solving Codes AddEdit',
          component: () => import('./views/apps/rubric_classifications/Rubric_classificationsAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Problem Solving Codes', url: '/problem-solving-codes' },
              { title: 'Edit Problem Solving Codes', active: true }
            ],
            rule: 'editor',
            parent: 'Problem Solving Codes',
            no_scroll: true,
            requiresAuth: true
          }
        },


        ///

        {
          path: '/questionnaires',
          name: 'Questionnaires',
          component: () => import('./views/apps/questionnaire/QuestionnaireList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Questionnaires', active: true }
            ],
            rule: 'editor',
            parent: 'questionnaire',
            no_scroll: true,
            requiresAuth: true

          }
        },

        {
          path: 'questionnaires/new',
          name: 'Questionnaires',
          component: () => import('./views/apps/questionnaire/QuestionnaireAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Questionnaires', url: '/questionnaires' },
              { title: 'Add Questionnaires', active: true }
            ],
            rule: 'editor',
            parent: 'questionnaires',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: 'questionnaires/edit/:id',
          name: 'Edit Questionnaires',
          component: () => import('./views/apps/questionnaire/QuestionnaireAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Questionnaires', url: '/questionnaires' },
              { title: 'Edit', active: true }
            ],
            rule: 'editor',
            parent: 'questionnaires',
            no_scroll: true,
            requiresAuth: true
          }
        },

        /*RUBRIC CLASSIFICATIONS*/
        {
          path: '/rubric-classifications',
          name: 'Rubric Classifications',
          component: () => import('./views/apps/rubric_classifications/Rubric_classificationsList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Rubric Classifications', active: true }
            ],
            rule: 'editor',
            parent: 'rubric classifications',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: 'rubric-classifications/new',
          name: 'Rubric Classifications',
          component: () => import('./views/apps/rubric_classifications/Rubric_classificationsAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Rubric Classifications', url: '/rubric-classifications' },
              { title: 'Add', active: true }
            ],
            rule: 'editor',
            parent: 'rubric-classifications',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: 'rubric-classifications/edit/:id',
          name: 'Edit Rubric Classifications',
          component: () => import('./views/apps/rubric_classifications/Rubric_classificationsAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Rubric Classifications', url: '/rubric-classifications' },
              { title: 'Edit', active: true }
            ],
            rule: 'editor',
            parent: 'rubric-classifications',
            no_scroll: true,
            requiresAuth: true
          }
        },

        {
          path: '/changepassword',
          name: 'Change Password',
          component: () => import('./views/apps/user/Changepassword.vue'),
          meta: {
            rule: 'editor',
            parent: 'dashboard',
            no_scroll: true,
            requiresAuth: true
          }
        },

        {
          path: '/cms',
          name: 'CMS',
          component: () => import('./views/apps/cms/CmsList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'CMS', active: true }
            ],
            rule: 'editor',
            parent: 'cms',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/cms/new',
          name: 'CMS',
          component: () => import('./views/apps/cms/CmsAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'CMS', url: '/cms' },
              { title: 'Add CMS', active: true }
            ],
            rule: 'editor',
            parent: 'cms',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/cms/edit/:id',
          name: 'Edit CMS',
          component: () => import('./views/apps/cms/CmsAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'CMS', url: '/cms' },
              { title: 'Edit CMS', active: true }
            ],
            rule: 'editor',
            parent: 'cms',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/faq/all',
          name: 'FAQs',
          component: () => import('./views/apps/faq/FaqList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'FAQs', active: true }
            ],
            rule: 'editor',
            parent: 'faq',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/faq/new',
          name: 'New FAQ',
          component: () => import('./views/apps/faq/FaqEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'FAQ', url: '/faq/all' },
              { title: 'Add FAQ', active: true }
            ],
            rule: 'editor',
            parent: 'faq',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/faq/edit/:id',
          name: 'Edit FAQ',
          component: () => import('./views/apps/faq/FaqEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'FAQ', url: '/faq/all' },
              { title: 'Edit FAQ', active: true }
            ],
            rule: 'editor',
            parent: 'faq',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/blog',
          name: 'Blog',
          component: () => import('./views/apps/blog/BlogList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Blog', active: true }
            ],
            rule: 'editor',
            parent: 'blog',
            no_scroll: true,
            requiresAuth: true
          }
        },

        {
          path: '/video',
          name: 'Video',
          component: () => import('./views/apps/video/VideoList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Video', active: true }
            ],
            rule: 'editor',
            parent: 'video',
            no_scroll: true,
            requiresAuth: true
          }
        },

      
     

        {
          path: 'details/:id',
          name: 'Detail',
          component: () => import('./views/apps/video/Details.vue'),
            meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Video', active: true }
            ],
            rule: 'editor',
            parent: 'video',
            no_scroll: true,
            requiresAuth: true
          }

        },

        {
          path: '/video/new',
          name: 'Video',
          component: () => import('./views/apps/video/VideoAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Video', url: '/video' },
              { title: 'Add Video', active: true }
            ],
            rule: 'editor',
            parent: 'video',
            no_scroll: true,
            requiresAuth: true
          }
        },

        {
          path: '/video/edit/:id',
          name: 'Video',
          component: () => import('./views/apps/video/VideoAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Video', url: '/video' },
              { title: 'Edit Video', active: true }
            ],
            rule: 'editor',
            parent: 'video',
            no_scroll: true,
            requiresAuth: true
          }
        },


        {
          path: '/blog/new',
          name: 'Blog',
          component: () => import('./views/apps/blog/BlogAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Blog', url: '/blog' },
              { title: 'Add Blog', active: true }
            ],
            rule: 'editor',
            parent: 'blog',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/blog/edit/:id',
          name: 'Blog',
          component: () => import('./views/apps/blog/BlogAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Blog', url: '/blog' },
              { title: 'Edit Blog', active: true }
            ],
            rule: 'editor',
            parent: 'blog',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/media/all',
          name: 'Media',
          component: () => import('./views/apps/media/MediaList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Media', active: true }
            ],
            rule: 'editor',
            parent: 'media1',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/media/new',
          name: 'Media',
          component: () => import('./views/apps/media/MediaEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Media', url: '/media/all' },
              { title: 'Add Media', active: true }
            ],
            rule: 'editor',
            parent: 'media1',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/media/edit/:id',
          name: 'Media',
          component: () => import('./views/apps/media/MediaEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Media', url: '/media/all' },
              { title: 'Edit Media', active: true }
            ],
            rule: 'editor',
            parent: 'media1',
            no_scroll: true,
            requiresAuth: true
          }
        },
        //vendor
        {
          path: '/vendor',
          name: 'Vendor',
          component: () => import('./views/apps/vendor/VendorList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Vendor', active: true }
            ],
            rule: 'editor',
            parent: 'vendorAll',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/vendor/new',
          name: 'Vendor',
          component: () => import('./views/apps/vendor/VendorAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Vendor', url: '/vendor' },
              { title: 'Add Vendor', active: true }
            ],
            rule: 'editor',
            parent: 'vendorAll',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/vendor/edit/:id',
          name: ' Vendor',
          component: () => import('./views/apps/vendor/VendorAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Vendor', url: '/vendor' },
              { title: 'Edit Vendor', active: true }
            ],
            rule: 'editor',
            parent: 'vendorAll',
            no_scroll: true,
            requiresAuth: true
          }
        },
        /** ROLES AND PERMISSIONS **/
        {
          path: '/role',
          name: 'Role',
          component: () => import('./views/apps/role/RoleList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Role', active: true }
            ],
            rule: 'editor',
            parent: 'roleAll',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/role/new',
          name: 'Role',
          component: () => import('./views/apps/role/RoleAddEdit.vue'), //add & edit
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Role', url: '/role/all' },
              { title: 'Add Role', active: true }
            ],
            rule: 'editor',
            parent: 'roleUser',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/role/edit/:id',
          name: 'Role',
          component: () => import('./views/apps/role/RoleAddEdit.vue'), //add & edit
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Role', url: '/role/all' },
              { title: 'Edit Role', active: true }
            ],
            rule: 'editor',
            parent: 'roleAll',
            no_scroll: true,
            requiresAuth: true
          }
        },

        //--
        {
          path: '/role-assignment',
          name: 'Role Assignment',
          component: () => import('./views/apps/role/RoleAssignmentList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Role Users', active: true }
            ],
            rule: 'editor',
            parent: 'roleUser',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/role-assignment/new',
          name: 'User Role',
          component: () => import('./views/apps/role/RoleAssignmentAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'User Role', url: '/role-assignment' },
              { title: 'Add User Role', active: true }
            ],
            rule: 'editor',
            parent: 'roleUser',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/role-assignment/edit/:id',
          name: ' Role',
          component: () => import('./views/apps/role/RoleAssignmentAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'User Role', url: '/role-assignment/all' },
              { title: 'Edit User Role', active: true }
            ],
            rule: 'editor',
            parent: 'roleAll',
            no_scroll: true,
            requiresAuth: true
          }
        },

        ///////////////////////////////////////////////////////////////////////////////////

        {
          path: '/user',
          name: 'User',
          component: () => import('./views/apps/user/UserList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'User', active: true }
            ],
            rule: 'editor',
            parent: 'user',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/user/new',
          name: 'Add User',
          component: () => import('./views/apps/user/UserAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'User', url: '/user' },
              { title: 'Add User', active: true }
            ],
            rule: 'editor',
            parent: 'user',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/user/edit/:id',
          name: 'Edit User',
          component: () => import('./views/apps/user/UserAddEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'User', url: '/user' },
              { title: 'Edit User', active: true }
            ],
            rule: 'editor',
            parent: 'user',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/edit/my-profile/id',
          name: 'Edit Profile',
          component: () => import('./views/apps/user/UserAddEdit.vue'),
          meta: {
            rule: 'editor',
            parent: 'user',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/setting',
          name: 'Setting',
          component: () => import('./views/apps/settings/SettingEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Setting', active: true }
            ],
            rule: 'editor',
            parent: 'Setting',
            no_scroll: true,
            requiresAuth: true
          }
        },

        {
          path: '/setting/social-media',
          name: 'Socail',
          component: () => import('./views/apps/settings/SocialSettingEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Socail', active: true }
            ],
            rule: 'editor',
            parent: 'Social',
            no_scroll: true,
            requiresAuth: true
          }
        },

        {
          path: '/setting/email',
          name: 'Email',
          component: () => import('./views/apps/settings/EmailSettingEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Email', active: true }
            ],
            rule: 'editor',
            parent: 'Email',
            no_scroll: true,
            requiresAuth: true
          }
        },

        {
          path: '/pages/user-settings',
          name: 'page-user-settings',
          component: () => import('@/views/pages/user-settings/UserSettings.vue'),
          meta: {

            pageTitle: 'Settings',
            rule: 'editor'
          }
        },

        {
          path: '/pages/knowledge-base',
          name: 'page-knowledge-base',
          component: () => import('@/views/pages/KnowledgeBase.vue'),
          meta: {

            pageTitle: 'KnowledgeBase',
            rule: 'editor'
          }
        },
        {
          path: '/pages/knowledge-base/category',
          name: 'page-knowledge-base-category',
          component: () => import('@/views/pages/KnowledgeBaseCategory.vue'),
          meta: {

            parent: 'page-knowledge-base',
            rule: 'editor'
          }
        },
        {
          path: '/pages/knowledge-base/category/question',
          name: 'page-knowledge-base-category-question',
          component: () => import('@/views/pages/KnowledgeBaseCategoryQuestion.vue'),
          meta: {

            parent: 'page-knowledge-base',
            rule: 'editor'
          }
        },
        {
          path: '/pages/search',
          name: 'page-search',
          component: () => import('@/views/pages/Search.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Pages' },
              { title: 'Search', active: true }
            ],
            pageTitle: 'Search',
            rule: 'editor'
          }
        },
        {
          path: '/pages/invoice',
          name: 'page-invoice',
          component: () => import('@/views/pages/Invoice.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Pages' },
              { title: 'Invoice', active: true }
            ],
            pageTitle: 'Invoice',
            rule: 'editor'
          }
        },

        // FULL
        {
          path: '/package',
          name: 'PackageList',
          component: () => import('./views/apps/package/PackageList.vue'),
          meta: {
            type: 1,
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Package', active: true }
            ],
            rule: 'editor',
            parent: 'package',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/package/new',
          name: 'PackageAdd',
          component: () => import('./views/apps/package/PackageAddEdit.vue'),
          meta: {
            type: 1,
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Package', url: '/package' },
              { title: 'Add Package', active: true }
            ],
            rule: 'editor',
            parent: 'package',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/package/edit/:id',
          name: 'PackageEdit',
          component: () => import('./views/apps/package/PackageAddEdit.vue'),
          meta: {
            type: 1,
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Package', url: '/package' },
              { title: 'Edit Package', active: true }
            ],
            rule: 'editor',
            parent: 'package',
            no_scroll: true,
            requiresAuth: true
          }
        },

        // PRETEST

        {
          path: '/pretest-package',
          name: 'PretestPackageList',
          component: () => import('./views/apps/package/PackageList.vue'),
          meta: {
            type: 2,
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Pretest Package', active: true }
            ],
            rule: 'editor',
            parent: 'pretest-package',
            no_scroll: true,
            requiresAuth: true
          }
        },

        {
          path: '/pretest-package/new',
          name: 'PretestPackageAdd',
          component: () => import('./views/apps/package/PackageAddEdit.vue'),
          meta: {
            type: 2,
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Pretest Package', url: '/pretest-package' },
              { title: 'Add Pretest Package', active: true }
            ],
            rule: 'editor',
            parent: 'pretest-package',
            no_scroll: true,
            requiresAuth: true
          }
        },
        {
          path: '/pretest-package/edit/:id',
          name: 'PretestPackageEdit',
          component: () => import('./views/apps/package/PackageAddEdit.vue'),
          meta: {
            type: 2,
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Pretest Package', url: '/pretest-package' },
              { title: 'Edit Pretest Package', active: true }
            ],
            rule: 'editor',
            parent: 'pretest-package',
            no_scroll: true,
            requiresAuth: true
          }
        },

        {
          path: '/for/demo',
          name: 'Demo',
          component: () => import('./views/apps/package/ForDemo.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Package', url: '/package' },
              { title: 'Edit Package', active: true }
            ],
            rule: 'editor',
            parent: 'package',
            no_scroll: true,
            requiresAuth: true
          }
        }

      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/callback',
          name: 'auth-callback',
          component: () => import('@/views/Callback.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/login',
          name: 'page-login',
          component: () => import('@/views/pages/login/Login.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/register',
          name: 'page-register',
          component: () => import('@/views/pages/register/Register.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/forgot-password',
          name: 'page-forgot-password',
          component: () => import('@/views/pages/ForgotPassword.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/reset-password',
          name: 'page-reset-password',
          component: () => import('@/views/pages/ResetPassword.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/lock-screen',
          name: 'page-lock-screen',
          component: () => import('@/views/pages/LockScreen.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/error-500',
          name: 'page-error-500',
          component: () => import('@/views/pages/Error500.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/not-authorized',
          name: 'page-not-authorized',
          component: () => import('@/views/pages/NotAuthorized.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/maintenance',
          name: 'page-maintenance',
          component: () => import('@/views/pages/Maintenance.vue'),
          meta: {
            rule: 'editor'
          }
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ]
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  if (
    to.path === '/login' ||
    to.path === '/pages/forgot-password' ||
    to.path === '/pages/error-404' ||
    to.path === '/pages/error-500' ||
    to.path === '/pages/register' ||
    to.path === '/callback' ||
    store.state.token
  ) {
    return next()
  }
})


export default router
