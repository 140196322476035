/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
//@icons = https://vue-feather-icons.egoist.sh/

//import store from '../../../store/store'


const atleastCrud = (module) => {
  let allowed = true
  if (module.access.list || module.access.create || module.access.edit || module.access.delete) {
    allowed = true
  }
  return allowed
}


const $AllowedModules = []
$AllowedModules.push({ url: '/dashboard', name: 'Dashboard', icon: 'HomeIcon', i18n: 'Dashboard' })

var store = {
  getters: {
    authorization : JSON.parse(localStorage['admin_authorization'])
  }
}
//#TODO

if (store.getters.authorization) {

  const authorization = store.getters.authorization

  let parent_rubric_classification = {}
  let parent_setting = {}
  let parent_role_and_permission = {}

  if (authorization && authorization.length) {
    authorization.forEach((module) => {
      if (module.module_name == 'user' && atleastCrud(module)) {
        //USER
        $AllowedModules.push({ url: '/user', name: 'User', icon: 'UserIcon', i18n: 'User' })
      }
      // if (module.module_name == 'student' && atleastCrud(module)) {
      //   //student
      //   $AllowedModules.push({ url: '/student', name: 'Student', icon: 'PenToolIcon', i18n: 'Student' })
      // }
      // if (module.module_name == 'teacher' && atleastCrud(module)) {
      //   //teacher
      //   $AllowedModules.push({ url: '/teacher', name: 'Teacher', icon: 'BookIcon', i18n: 'Teacher' })
      // }

      // if (module.module_name == 'grade' && atleastCrud(module)) {
      //   //grade
      //   $AllowedModules.push({ url: '/grade', name: 'Grade', icon: 'PieChartIcon', i18n: 'Grade' })
      // }

      if (module.module_name == 'category' && atleastCrud(module)) {
        //CATEGORY
        $AllowedModules.push({ url: '/category', name: 'Category', icon: 'DropletIcon', i18n: 'Category' })
      }

      if (module.module_name == 'blog' && atleastCrud(module)) {
        //BLOG
        $AllowedModules.push({ url: '/blog', name: 'Blog', icon: 'LinkIcon', i18n: 'Blog' })
      }

      if (module.module_name == 'Video' && atleastCrud(module)) {
        //Videos
        $AllowedModules.push({ url: '/video', name: 'Video', icon: 'GlobeIcon', i18n: 'Video' })
      }

      if (module.module_name == 'cms' && atleastCrud(module)) {
        //CMS
        $AllowedModules.push({ url: '/cms', name: 'CMS', icon: 'BriefcaseIcon', i18n: 'CMS' })
      }


      if (module.module_name == 'city' && atleastCrud(module)) {
        //CITY
        $AllowedModules.push({ url: '/city', name: 'City', icon: 'MapIcon', i18n: 'City' })
      }
      

      if (module.module_name == 'city' && atleastCrud(module)) {
        //CLASS
        $AllowedModules.push({ url: '/classes', name: 'Class', icon: 'MapIcon', i18n: 'Class' })
      }

      if (module.module_name == 'country' && atleastCrud(module)) {
        //COUNTRY
        $AllowedModules.push({ url: '/country', name: 'Country', icon: 'GlobeIcon', i18n: 'Country' })
      }

     
      //Sub Menu
      if (module.module_name == 'setting' || module.module_name == 'email_setting' || module.module_name == 'social_media' || module.module_name == 'language' || module.module_name == 'payment' && atleastCrud(module)) {
        if (Object.keys(parent_setting).length == 0) {
          parent_setting = {
            url: '/setting',
            name: 'Setting',
            icon: 'SettingsIcon',
            i18n: 'Setting',
            submenu: []
          }
        }

        if (module.module_name == 'setting' && atleastCrud(module)) {
          parent_setting.submenu.push({ url: '/setting', name: 'General', slug: 'general', i18n: 'General' })
        }

        if (module.module_name == 'setting' && atleastCrud(module)) {
          parent_setting.submenu.push({
            url: '/setting/email',
            name: 'SMTP & SMS',
            slug: 'email',
            i18n: 'SMTP & SMS'
          })
        }

        if (module.module_name == 'setting' && atleastCrud(module)) {
          parent_setting.submenu.push({
            url: '/setting/social-media',
            name: 'Socail Media',
            slug: 'socail_media',
            i18n: 'Socail Media'
          })
        }

        // if (module.module_name == 'setting' && atleastCrud(module)) {
        //   parent_setting.submenu.push({
        //     url: '/languages',
        //     name: 'Language',
        //     slug: 'language',
        //     i18n: 'Language'
        //   })
        // }

        // if (module.module_name == 'setting' && atleastCrud(module)) {
        //   parent_setting.submenu.push({
        //     url: '/payments',
        //     name: 'Payment',
        //     slug: 'payment',
        //     i18n: 'Payment'
        //   })
        // }


      }

      // if (module.module_name == 'setting' && atleastCrud(module)) {
      //   //VENDOR
      //   $AllowedModules.push({ url: '/setting', name: 'Setting', icon: 'SettingsIcon', i18n: 'Setting' })
      // }

      // if (module.module_name == 'package' && atleastCrud(module)) {
      //   //PACKAGE
      //   $AllowedModules.push({ url: '/package', name: 'Package', icon: 'CodesandboxIcon', i18n: 'Package' })
      // }


      // if (module.module_name == 'rubric_classification' && atleastCrud(module)) {
      //   //VENDOR
      //   $AllowedModules.push({ url: '/rubric-classifications', name: 'Rubric Classification', icon: 'LayersIcon', i18n: 'Rubric Classification' })
      // }


      //Sub Menu
      if (module.module_name == 'question_thinking_code' || module.module_name == 'problem_solving_code' || module.module_name == 'rubric_classification' && atleastCrud(module)) {
        if (Object.keys(parent_rubric_classification).length == 0) {
          parent_rubric_classification = {
            url: '/question-thinking-codes',
            name: 'Rubric Classification',
            icon: 'ShieldIcon',
            i18n: 'Rubric Classification',
            submenu: []
          }
        }

        if (module.module_name == 'question_thinking_code' && atleastCrud(module)) {
          parent_rubric_classification.submenu.push({
            url: '/question-thinking-codes',
            name: 'Question Thinking Code',
            slug: 'question_thinking_code',
            i18n: 'Question Thinking Code'
          })
        }

        if (module.module_name == 'problem_solving_code' && atleastCrud(module)) {
          parent_rubric_classification.submenu.push({
            url: '/problem-solving-codes',
            name: 'Problem Solving Codes',
            slug: 'problem-solving-codes',
            i18n: 'Problem Solving Codes'
          })
        }

        if (module.module_name == 'rubric_classification' && atleastCrud(module)) {
          parent_rubric_classification.submenu.push({
            url: '/question-type',
            name: 'Question Type',
            slug: 'question-type',
            i18n: 'Question Type'
          })
        }

      }

      // if (module.module_name == 'rubric_mapping' && atleastCrud(module)) {
      //   //rubric mapping
      //   $AllowedModules.push({
      //     url: '/rubric-mapping',
      //     name: 'Rubric Mapping',
      //     icon: 'SlackIcon',
      //     i18n: 'Rubric Mapping'
      //   })
      // }
      // if (module.module_name == 'course_type' && atleastCrud(module)) {
      //   //course type
      //   $AllowedModules.push({
      //     url: '/course-types',
      //     name: 'Course Type',
      //     icon: 'GitPullRequestIcon',
      //     i18n: 'Course Type'
      //   })
      // }
      // if (module.module_name == 'pretest_package' && atleastCrud(module)) {
      //   //PACKAGE
      //   $AllowedModules.push({
      //     url: '/pretest-package',
      //     name: 'Pretest Package',
      //     icon: 'BoxIcon',
      //     i18n: 'Pretest Package'
      //   })
      // }

      //Sub Menu
      if (module.module_name == 'role_and_permission' && atleastCrud(module)) {
        //ROLES & PERMISSION
        $AllowedModules.push({
          url: '/role_and_permission',
          name: 'Roles & Permissions',
          icon: 'CreditCardIcon',
          i18n: 'Roles & Permissions'
        })
      }

      //Sub Menu
      if (module.module_name == 'role' || module.module_name == 'role_assignment' && atleastCrud(module)) {
        if (Object.keys(parent_role_and_permission).length == 0) {
          parent_role_and_permission = {
            url: '/role-and-permission',
            name: 'Role & Permission',
            icon: 'ShieldIcon',
            i18n: 'Role & Permission',
            submenu: []
          }
        }

        if (module.module_name == 'role' && atleastCrud(module)) {
          parent_role_and_permission.submenu.push({ url: '/role', name: 'Role', slug: 'role', i18n: 'Role' })
        }

        if (module.module_name == 'role_assignment' && atleastCrud(module)) {
          parent_role_and_permission.submenu.push({
            url: '/role-assignment',
            name: 'Role Assignment',
            slug: 'role-assignment',
            i18n: 'Role Assignment'
          })
        }

      }

      //Sub Menu
      if (module.module_name == 'email_setting' || module.module_name == 'general_setting' && atleastCrud(module)) {
        if (Object.keys(parent_setting).length == 0) {
          parent_setting = {
            url: '/setting',
            name: 'Setting',
            icon: 'GitPullRequestIcon',
            i18n: 'Setting',
            submenu: []
          }
        }

        if (module.module_name == 'email_setting' && atleastCrud(module)) {
          parent_setting.submenu.push({
            url: '/email-setting',
            name: 'Email Setting',
            slug: 'email-setting',
            i18n: 'Email Setting'
          })
        }
      }
    })

    //ADD SUB-MENUS

    if (Object.keys(parent_rubric_classification).length > 0) {
      //$AllowedModules.push(parent_rubric_classification)
    }

    if (Object.keys(parent_setting).length > 0) {
      $AllowedModules.push(parent_setting)
    }


    if (Object.keys(parent_role_and_permission).length > 0) {
      $AllowedModules.push(parent_role_and_permission)
    }

  }

}


export default $AllowedModules
