<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vx-card>

    <h3>{{ flow_type == "add" ? $t('modules.student.Add') : $t('modules.student.Edit') }}
      {{ $t('modules.rubric_map.Rubric Mapping') }} </h3>

    <!-- Content Row -->
    <div class="vx-row mt-10" v-if="loaded">
      <div class="vx-col md:w-1/2 w-full">

        <div class="mt-4">
          <label class="text-sm">{{ $t('modules.rubric_map.Package') }} <span class="text-danger"> * </span></label>

          <vs-select
              :color="colorx" class="w-full mt-4 required"
              :placeholder="placeholder_txt"
              v-model="formData['package']" v-validate="'required'"
          >
            <vs-select-item  :is-selected.sync="item.isSelected" :key="index" :value="item.value" :text="item.text[$i18n.locale]" v-for="(item,index) in packages"/>
          </vs-select>
          <span class="text-danger text-sm" v-show="errors.has('package')">{{
              errors.first("package")
            }}</span>

        </div>

      </div>


      <div class="vx-col md:w-1/2 w-full">
        <div class="mt-4">
          <label class="text-sm">{{ $t('modules.rubric_map.Grade level') }} <span class="text-danger"> * </span></label>

          <vs-select :color="colorx" class="w-full mt-4 required" placeholder="Select Grade"
                     v-model="formData['grade_level']">
            <vs-select-item value="elementary" text="Elementary"/>
            <vs-select-item value="intermediate" text="Intermediate"/>
            <vs-select-item value="higher" text="Higher"/>
          </vs-select>
          <span class="text-danger text-sm" v-show="errors.has('grade_level')">{{ errors.first("grade_level") }}</span>

        </div>

      </div>
    </div>

    <div class="vx-col md:w-1/2 w-full mt-4">
      <label class="text-sm my-4 my-1">{{ $t('modules.rubric_map.Status') }}</label>
      <vs-switch class="ml-0" color="success" v-model="formData['status']" vs-value="1">
        <span slot="on">{{ $t('modules.profile.Active') }}</span>
        <span slot="off">{{ $t('modules.profile.InActive') }}</span>
      </vs-switch>
    </div>


    <table class="mt-10 ">

      <thead class="border-2 border-gray-50 border-solid">
      <tr class="border-2 border-gray-50 border-solid  ">
        <th class="border border-gray-50 py-4">
          {{ $t('modules.rubric_map.Reading time for each article (average time)') }}
        </th>
        <th class="border border-gray-50 text-center py-4 " colspan="0">{{ $t('modules.rubric_map.Accuracy %') }}</th>
      </tr>
      </thead>


      <tbody class="border-2 border-gray-50 border-solid" style="border-bottom: none!important;">
      <tr class="border-2 border-gray-50 border-solid">
        <td class="border-2 border-gray-50 border-solid"> &nbsp;</td>
        <!-- for %s -->
        <td v-for="(colItem, colIndex) in cols" class="border-2 border-gray-50 border-solid p-2">
          {{ colItem }}
        </td>

        <!-- for Add+ -->
        <td class="w-4">
          <vs-button class="flex-wrap"
                     @click="addCol" color="primary" type="border"
                     icon="add_circle_outline" size="small">Add
          </vs-button>
        </td>
      </tr>

      <tr v-for="(rcItem, rcIndex) in formData['rubric']">
        <td class="border-2 border-gray-50 border-solid p-2">
          {{ `${rcItem.time.lower}minutes - ${rcItem.time.upper}minutes` }}
        </td>

        <!-- bind -->
        <template v-for="(accItem, accIndex) in rcItem.accuracy">
          <td class="border-2 border-gray-50 border-solid p-2">
            <!-- <span class="text-gray-500 font-semibold">{{}}</span> -->
            <vs-input placeholder="Assign Package" v-model="accItem.package" maxlength="4"/>
          </td>
        </template>

      </tr>

      <tr class="border-none">
        <td rowspan="0" class="border-none">
          <vs-button class="flex-wrap mt-4"
                     @click="addRow" color="primary" type="border"
                     icon="add_circle_outline" size="small">Add
          </vs-button>
        </td>

      </tr>
      </tbody>
      {{ $t("package.Note") }}: {{ $t("package.First add row and then add column") }}
    </table>


    <!-- Prompt - it ask the % -->
    <vs-prompt
        title=""
        @cancel=""
        @accept="acceptPercent"
        @close="closePercent"
        :active.sync="askPercent"
        :is-valid="isPercentValid">
      <div class="con-exemple-prompt">
        <h5>{{ $t('modules.rubric_map.Enter the Lower and Upper percentage') }}</h5>
        <br>
        <h6 style="color:grey;">Hint(Lower:90 Upper:100,Lower:80 Upper:89,Lower:60 Upper:70)</h6>
        <div class="flex my-6">
          <vs-input :placeholder="$t('modules.rubric_map.Lower%')" v-model="tmpLowerPercentage" class="pr-4"/>
          <vs-input :placeholder="$t('modules.rubric_map.Upper%')" v-model="tmpUpperPercentage" class="pl-4"/>
        </div>
      </div>
    </vs-prompt>


    <!-- Prompt - it ask the Time (minutes) -->
    <vs-prompt
        title=""
        @cancel=""
        @accept="acceptTime"
        @close="closeTime"
        :active.sync="askTime"
        :is-valid="isTimeValid">
      <div class="con-exemple-prompt">
        <h5>{{ $t('modules.rubric_map.Enter the Lower and Upper Time limit') }}</h5>
        <br>
        <h6 style="color:grey;">Hint(Lower:0 Upper:5,Lower:6 Upper:10,Lower:11 Upper:15)</h6>
        <div class="flex my-6">
          <vs-input :placeholder="$t('modules.rubric_map.Lower Time (minutes)')" v-model="tmpLowerTime" class="pr-4"/>
          <vs-input :placeholder="$t('modules.rubric_map.Upper Time (minutes)')" v-model="tmpUpperTime" class="pl-4"/>
        </div>
      </div>
    </vs-prompt>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" :disabled="!validateForm" @click="save">
            {{ $t('modules.student.Save Changes') }}
          </vs-button>
          <vs-button class="ml-4 mt-2" v-if="reset_btn" type="border" color="warning" @click="reset">
            {{ $t('modules.student.Reset') }}
          </vs-button>
          <vs-button class="ml-4 mt-2" v-if="cancel_btn" type="border" color="warning" @click="cancel">
            {{ $t('modules.profile.Cancel') }}
          </vs-button>
          <!--         <vs-button class="ml-4 mt-2" type="border" color="warning" @click="getJson"></vs-button>
           -->      </div>
      </div>
    </div>

  </vx-card>
</template>

<script>
// import moduleRole          from '@/store/role/moduleRole.js'
import vSelect from "vue-select";
import axios from "@/axios";
import Vue from "vue";
import _ from "lodash";
import validator from 'validator';

export default {
  components: {
    vSelect
  },
  data() {
    return {
      placeholder_txt: '',
      loaded: false,
      //%
      askPercent: false,
      tmpLowerPercentage: "",
      tmpUpperPercentage: "",
      isPercentValid: false,

      //Time
      askTime: false,
      tmpLowerTime: "",
      tmpUpperTime: "",
      isTimeValid: false,

      formData: {
        status: "1",
        package: '', //_id
        grade_level: '',
        rubric: []
      },

      colorx: "#103767",
      reset_btn: true,
      cancel_btn: false,
      validateForm: true,
      countries: [],
      flow_type: '',
      packages: ''
      // cols: [
      //   '100%-85%',
      //   '84%-70%',
      //   '69%-55%'
      // ],
      // rows: [
      //   '0minutes - 5minutes',
      //   '6minutes - 10minutes',
      //   '11minutes - 15minutes'
      // ]

    };
  },

  async created() {

    if (this.$route.params.id) {
      this.flow_type = "edit";
      this.reset_btn = false
      this.cancel_btn = true
    } else {
      this.flow_type = "add";
      this.cancel_btn = true
      this.reset_btn = false

    }

    if (this.flow_type === "edit") {
      await this.getRubricDetail(this.$route.params.id);
      this.getPackages()
      this.id = this.$route.params.id;
    }else this.getPackages()

    this.placeholder_txt = this.$t("package.Select Package")
  },

  computed: {
    // validateForm() {
    //   return !this.errors.any()
    // },

    cols() {
      var arr = _.map(this.formData.rubric, (el) => {
        var list = [];
        _.forEach(el.accuracy, function (acc) {
          list.push(`${acc.lower}% - ${acc.upper}%`);
        });
        return list;
      });

      return arr[0];
      // return [
      //   '100%-85%',
      //   '84%-70%',
      //   '69%-55%'
      //   ]
    },
    rows() {
      var arr = _.map(this.formData.rubric, (el) => {
        return `${el.time.lower}minutes - ${el.time.lower}upper`;
      });
      return arr;
      // return [
      //   '0minutes - 5minutes',
      //   '6minutes - 10minutes',
      //   '11minutes - 15minutes'
      // ]
    }
  },

  methods: {
    getPackages() {
      axios.get('/packages', {params: {limit: 1000}})
          .then((response) => {
            if (response.status === 200) {
              //need format [{text: 'Square', value: 1}, ...],
              this.packages = response.data.items.map(item => {
                let isSelected = item._id == this.formData['package']
                console.log('FormData: '+ this.formData['package'] +' | '+ 'Item: ' + item._id)
                return {text: item.name, value: item._id, isSelected}
              })
              this.loaded = true
            }
          })
          .catch((error) => {
            //alert(`Error: ${error}`)
          })
          .then(() => this.$vs.loading.close())
    },

    getJson() {
      console.log(this.formData.rubric);
    },
    addRow() {
      this.askTime = true;
    },
    closeTime() {

    },
    acceptTime() {
      this.formData.rubric.push(
          //`${this.tmpLowerTime}minutes - ${this.tmpUpperTime}minutes`,
          {
            time: {
              lower: this.tmpLowerTime,
              upper: this.tmpUpperTime
            },
            accuracy: this.getExistingAccuracy()
          }
      );
      this.tmpLowerTime = "";
      this.tmpUpperTime = "";
    },
    getExistingAccuracy() {
      var arr = [];
      if (this.formData.rubric.length) {
        arr = this.formData.rubric[0].accuracy;
      }

      return JSON.parse(JSON.stringify(arr));
    },

    addCol() {
      this.askPercent = true;
    },
    closePercent() {

    },
    acceptPercent() {

      //update in  each obj
      _.map(this.formData.rubric, (el) => {
        el.accuracy.push({
          lower: this.tmpLowerPercentage,
          upper: this.tmpUpperPercentage,
          package: ""
        });
      });
      // this.cols.push(
      //   //`${this.tmpLowerPercentage}%-${this.tmpUpperPercentage}%`,
      // )
      this.tmpLowerPercentage = "";
      this.tmpUpperPercentage = "";
    },


    getRandom() {
      var arr = ["C2", "C1", "B2", "B1", "A2", "A1"];
      return arr[Math.floor(Math.random() * arr.length)];
    },
    cancel() {
      this.$router.push("/rubric-mapping").catch(() => {
      });
    },
    reset() {

    },
    save() {
      if (this.flow_type === "add") {
        axios.post("/rubric-mappings", this.formData)
            .then((response) => {
              if (response.status === 201) {
                this.notifyUI("Created!", "Rubric Mapping has been created", "success");
                this.$router.push("/rubric-mapping");
              }
            })
            .catch((error) => {
              this.displayErrors(error);
            })
            .then(() => this.$vs.loading.close());
      } else {

        axios.put(`/rubric-mappings/${this.id}`, this.formData)
            .then((response) => {
              if (response.status === 200) {
                this.$router.push("/rubric-mapping");
                this.notifyUI("Updated!", "Rubric Mapping has been updated", "success");
              }
            })
            .catch((error) => {
              this.displayErrors(error);
            })
            .then(() => this.$vs.loading.close());
      }
    },

    getRubricDetail(id) {
      axios.get(`/rubric-mappings/${id}`)
          .then((response) => {
            if (response.status === 200) {
              this.formData = response.data;
            }
          })
          .catch((error) => {
            alert(`Error: ${error}`);
          })
          .then(() => this.$vs.loading.close());
    },

    validatePercent() {
      //individual value
      if (!validator.isInt(this.tmpLowerPercentage + '', {min: 0, max: 100})) {
        this.isPercentValid = false
        //this.notifyUI(this.$t('modules.rubric_mapping.Invalid'), this.$t('modules.rubric_mapping.Percentage must be number between 0 and 100'), "warning");
        return
      }
      if (!validator.isInt(this.tmpUpperPercentage + '', {min: 0, max: 100})) {
        this.isPercentValid = false
        //this.notifyUI(this.$t('modules.rubric_mapping.Invalid'), this.$t('modules.rubric_mapping.Percentage must be number between 0 and 100'), "warning");
        return
      }

      //association check
      if (parseFloat(this.tmpLowerPercentage) >= parseFloat(this.tmpUpperPercentage)) {
        this.isPercentValid = false
        //this.notifyUI(this.$t('modules.rubric_mapping.Invalid'), this.$t('modules.rubric_mapping.Lower percentage should be less that Upper percentage'), "warning");
        return
      }

      this.isPercentValid = this.percentRangeValid()
    },

    validateTime() {
      //individual value
      if (!validator.isInt(this.tmpLowerTime + '', {min: 0, max: 180})) {
        this.isTimeValid = false
        return
      }
      if (!validator.isInt(this.tmpUpperTime + '', {min: 0, max: 180})) {
        this.isTimeValid = false
        return
      }

      //association check
      if (parseFloat(this.tmpLowerTime) >= parseFloat(this.tmpUpperTime)) {
        this.isTimeValid = false
        return
      }

      this.isTimeValid = this.timeRangeValid()
    },

    timeRangeValid() {
      var is_valid = true

      for (var i = 0; i < this.formData.rubric.length; i++) {
        if (parseFloat(this.tmpLowerTime) <= parseFloat(this.formData.rubric[i].time.upper)) {
          is_valid = false
          break;
        }

        if (parseFloat(this.tmpUpperTime) <= parseFloat(this.formData.rubric[i].time.upper)) {
          is_valid = false
          break;
        }
      }

      return is_valid
    },

    percentRangeValid() {
      if (this.formData.rubric.length == 0) //accessing [0]
        return true;

      var is_valid = true

      for (var i = 0; i < this.formData.rubric[0].accuracy.length; i++) {
        //loop in accuracy
        if (parseFloat(this.tmpLowerPercentage) <= parseFloat(this.formData.rubric[0].accuracy[i].upper)) {
          is_valid = false
          break;
        }

        if (parseFloat(this.tmpUpperPercentage) <= parseFloat(this.formData.rubric[0].accuracy[i].upper)) {
          is_valid = false
          break;
        }
      }
      return is_valid
    },
  },

  watch: {
    //%
    tmpLowerPercentage(val) {
      this.validatePercent()
    },
    tmpUpperPercentage(val) {
      this.validatePercent()
    },
    //time
    tmpLowerTime(val) {
      this.validateTime()
    },
    tmpUpperTime(val) {
      this.validateTime()
    }
  }
}
</script>
