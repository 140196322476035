import axios from './axios'
import store from './store/store'

export default {
  forgotPassword (email) {
    return new Promise((resolve, reject) => {

      axios.post('forgot', {
        email
      })
        .then((resp) => {
          if (email) {
            resolve()
          }
        })
        .catch((resp) => {

          reject(resp)

        })
    })
  },

  resetPassword (email, verificationCode, newPassword) {
    return axios.post('/reset', {
      email,
      password: newPassword,
      verification_code: verificationCode
    })
  },

  login (email, password) {
    //alert('123333333')
    return axios.post('/login', {
      email,
      password
    })
  },

  registerUser (name, email, pwd) {
    return axios.post('/signup', {
      displayName: name,
      email,
      password: pwd
    })
  },

  refreshToken () {
    return axios.post('/api/auth/refresh-token', { accessToken: store.getters.token })
  },
  userInfo () {
    return axios.get('/signin')
  }
}
