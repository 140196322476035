<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <vx-card>
 <div id="page-user-list"  v-if="list_permission == false" >
            <div class="NOpermissions">
              <span>No Permissions</span>
            </div>
        </div>
        <vs-table ref="table" pagination :max-items="this.$store.state.pps" search :data="categories" v-if="list_permission == true">
            
            <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

              <div class="flex flex-wrap-reverse items-center data-list-btn-container">
        
                <!-- ACTION - DROPDOWN -->
                <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4 mb-4" style="margin-bottom: 0px !important">

                  <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon">
                    <span class="mr-2">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </button>

                  <vs-dropdown-menu>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Delete</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="ArchiveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Archive</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Print</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Another Action</span>
                      </span>
                    </vs-dropdown-item>

                  </vs-dropdown-menu>
                </vs-dropdown>

                <!-- ADD NEW -->
                <div v-if="create_permission == true" class="dd-actions cursor-pointer mr-4 mb-4" style="margin-bottom: 0px !important">
                  <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon" @click="addDataPrompt()">
                    <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                      <span class="ml-2 text-base text-primary">Add New</span>
                  </button>
                </div>
                <!-- REFRESH -->
                <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon" @click="refreshData()">
                  <feather-icon icon="RefreshCwIcon" svgClasses="h-4 w-4" />
                  <span class="ml-2 text-base text-primary">Refresh</span>
                </button>
              </div>
            </div>

            <template slot="thead">
                <vs-th sort-key="category_id">S.NO</vs-th>
                <vs-th sort-key="category_name">Category Name</vs-th>
                <vs-th sort-key="parent_id">Parent Category</vs-th>
                <vs-th sort-key="module_id">Module</vs-th>
                <vs-th sort-key="created_at">Created At</vs-th>
                <vs-th sort-key="status">Status</vs-th>
                 <vs-th v-if="disableAction == true">Action</vs-th>
                <vs-th v-if="disableAction == false"></vs-th>
            </template>

            <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                     <vs-td :data="data[indextr].category_id">
                        {{indextr+1}}
                    </vs-td>

                    <vs-td :data="data[indextr].category_name">
                        {{data[indextr].category_name}}
                    </vs-td>

                    <vs-td :data="data[indextr].parent_id">
                        {{parent_category_name(data[indextr].parent_id)}}
                    </vs-td>

                    <vs-td :data="data[indextr].module_id">
                        {{module_name(data[indextr].module_id)}}
                    </vs-td>

                    <vs-td :data="data[indextr].created_at">
                        {{date_format(data[indextr].created_at)}}
                    </vs-td>

                    <vs-td :data="data[indextr].status">
                        <vs-chip v-if="data[indextr].status==0" :color="'#d9534f'" class="product-order-status">InActive</vs-chip>
                        <vs-chip v-if="data[indextr].status==1" :color="'#5cb85c'" class="product-order-status">Active</vs-chip>
                    </vs-td>

                    <vs-td class="whitespace-no-wrap">
                      <feather-icon icon="EditIcon" v-if="update_permission == true" svgClasses="w-5 h-5 hover:text-primary stroke-current edit_delete" @click.stop="editDataPrompt(tr)" />
                      <!--<feather-icon icon="TrashIcon"  v-if="delete_permission == true" svgClasses="w-5 h-5 hover:text-danger stroke-current edit_delete" class="ml-2" @click.stop="deleteCategory(tr.category_id)" />-->
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>

        <vs-prompt
            :title="promptName"
            :accept-text= "promptName"
            button-cancel = "border"
            @accept="addEditCategory"
            :is-valid="validateForm"
            :active.sync="displayPrompt"
            >
            <div>
                <form>
                    <div class="vx-row">
                        <div class="vx-col w-full toggle_switch_main">
                            <div class="mt-4">
                              <label class="text-sm" style="margin-left: 4px;">Module Name</label>
                              <v-select placeholder="General" class="w-full select-large" label="module_name" value="module_id" v-model="module_name_model" :clearable="false" :options="modules" name="module_id" />
                            </div>
                            <vs-input :maxlength=40
                              v-model="formData['category_name']"
                              label="Category Name"
                              v-validate="'required'" 
                              name="category_name" 
                              class="w-full mb-4 mt-5 required" 
                              placeholder="Category Name" />
                            <span class="text-danger text-sm"  v-show="errors.has('category_name')">{{ errors.first('category_name') }}</span>
                            <div class="mt-4" v-if="category_show">
                              <div class="vx-row">
                                <div class="vx-col w-1/2"><vs-radio v-model="radioInput" vs-value="Main Category">Main Category</vs-radio></div>
                                <div class="vx-col w-1/2"><vs-radio v-model="radioInput" vs-value="Sub Category">Sub Category</vs-radio></div>
                              </div>
                            </div>
                            <div class="mt-4" v-if="radioInput=='Sub Category'">
                              <label class="text-sm" style="margin-left: 4px;">Parent Category</label>
                              <v-select class="w-full select-large" label="category_name" value="category_id" v-model="parent_category_model" :clearable="false" :options="parent_categories" name="parent_id" />
                              <!-- <span class="text-danger text-sm"  v-show="errors.has('parent_id')">{{ errors.first('parent_id') }}</span> -->
                            </div>
                          <div class="mt-4">
                              <div class="flex items-start flex-col sm:flex-row">
                                  <img :src="formData['image_url'] ||'/300x300.png'" class="mr-8 rounded h-24 w-24"  />
                                      <div class="mt-4" v-if="add_image">
                                          <input type="file" class="hidden" ref="image_url" @change="image_url" accept=".png,.jpg,.jpeg" >
                                          <vs-button class="mr-4 mb-4" v-if="add_image"  @click="$refs.image_url.click()">Add Image</vs-button>
                                      </div>

                                      <div class="mt-4"  v-if="remove_image">
                                          <input type="file" class="hidden" ref="image_url" @change="remove_images" accept=".png,.jpg,.jpeg" >
                                          <vs-button type="border" color="danger"  v-if="remove_image" @click="$refs.image_url.click()">Replace Image</vs-button>
                                      </div>
                                  </div>
                              </div>

                            <div class="mt-4">
                               <toggle-button class="toggle_switch" :value="true" v-model="status_model" :labels="{checked: 'Active', unchecked: 'InActive'}"/>
                            </div>
                           <!--   <div class="mt-4">
                              <vs-switch color="success" v-model="status_model">
                                  <span slot="on">Active</span>
                                  <span slot="off">InActive</span>
                              </vs-switch>
                            </div>
                           <vs-select class="w-full select-large mt-5" label="Status">
                                <vs-select-item select :key="0" :value="1" :text="'Active'" class="w-full" />
                                <vs-select-item :key="1" :value="0" :text="'InActive'" class="w-full" />
                            </vs-select> -->
                        </div>
                    </div>
                </form>
            </div>
        </vs-prompt>

    </vx-card>
</template>

<style>
    
</style>

<script>
// import moduleCategory          from '@/store/category/moduleCategory.js'
import vSelect from 'vue-select'
import { ToggleButton } from 'vue-js-toggle-button'
export default {
  data () {
    return {
      promptName           :'Add Category',
      formData             : {},
      displayPrompt        : false,
      old_parent_id        : 0,
      category_show        : false,
      disableAction        : true,
      delete_permission    : this.$store.state.category_permission.delete,
      create_permission    : this.$store.state.category_permission.create,
      list_permission      : this.$store.state.category_permission.list,
      update_permission    : this.$store.state.category_permission.update,
      add_image            : true,
      remove_image         : '' 
    }
  },
  components: {
    vSelect,
    ToggleButton
  },
  computed: {
    categories ()     { 
      return this.$store.getters['category/queriedCategories']      
    },
    modules () { 
      return this.$store.getters['module/getActiveCategoryModules']
    },
    parent_categories () { 
      var pc = this.$store.getters['category/getMainCategories'](this.formData['module_id'])
      if (pc.length>0) {
        let bool = false
        for (var i = pc.length - 1; i >= 0; i--) {
          if (pc[i]['category_id'] === this.old_parent_id) {
            bool = true
          }
        }
        if (!bool) {
          this.formData['parent_id'] = pc[0]['category_id']
        }
      }
      return pc
    },
    status_model: {
      get () {
        return this.formData['status'] === '1'
      },
      set (status) {
        this.formData['status'] = status ? '1' : '0'
      }
    },
    module_name_model: {
      get () {
        return this.$store.getters['module/getModule'](this.formData['module_id'])
      },
      set (module_name) {
        this.formData['module_id'] = module_name['module_id']
      }
    },
    parent_category_model: {
      get () {
        if(this.formData['module_id'] == 0){
          var parent_id = 0
          return this.$store.getters['category/getGeneralCategoryName'](parent_id)

        }else{
          return this.$store.getters['category/getCategory'](this.formData['parent_id'])
        }
        var check =  this.$store.getters['category/getCategory'](parent_id)
      },
      set (category_name) {
        this.formData['parent_id'] = category_name['category_id']
        this.old_parent_id = this.formData['parent_id']
      }
    },
    radioInput: {
      get () {
        return this.formData['parent_id'] == 0 ? 'Main Category' : 'Sub Category'
      },
      set (radio) {
        this.formData['parent_id'] = radio === 'Main Category' ? 0 : this.old_parent_id || 1
      }
    },
    validateForm () {
      if(this.formData.module_id !== ''){
        this.category_show = true
      }
      return !this.errors.any() && this.formData.category_name !== '' && this.formData.module_id !== ''
    }
  },
  methods: {
    date_format (date) {
      const d = new Date(date)
      return d.toDateString()
    },
    module_name (moduleId) {
      if(moduleId == 0){
        return "General"
      }else{
        return this.$store.getters['module/getModuleName'](moduleId)
      }
    },
    parent_category_name(categoryId) {
      return this.$store.getters['category/getCategoryName'](categoryId)
    },
    refreshData () {
      this.$vs.loading()
      this.$store.dispatch('category/fetchCategories').then(() => { this.$vs.loading.close() })
    },
    addDataPrompt () {
      this.formData       = {'category_name':'', 'module_id':'0', 'parent_id':0, 'status':'0','image_url':''}
      this.old_parent_id  = 0
      this.promptName     = 'Add Category'
      this.category_show  = false
      this.add_image      = true;
      this.remove_image   = false
      this.displayPrompt  = true
    },
    editDataPrompt (category) {
      this.promptName     = 'Edit Category'
      this.displayPrompt  = true
      this.formData = Object.assign({}, category)
      if(this.formData['image_url'] == ""){
        this.add_image    = true;
        this.remove_image = false;
      }else{
        this.add_image    = false;
        this.remove_image = true;
      }
      this.old_parent_id = this.formData['parent_id']
    },
      notif_failed (error) {
      this.$vs.notify({
        title: 'Alert',
        text: error,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color:"danger"
      })
    },
     image_url (e) {
      this.$vs.loading()
                let file = e.target.files[0];
                let reader = new FileReader();
                 const fileSize = file.size
                var sizeInMB = (fileSize / (1024*1024)).toFixed(2);
                reader.readAsDataURL(file);
                reader.onload = e => {
                    const src = e.target.result;
                    const img = new Image();
                    img.onload = () => {
                      //if((img.height > 300 || img.width > 300) || (sizeInMB > 2)){
                       if(sizeInMB > 2)
                      {
                            this.errImageupload = 1
                            this.$refs.image_url.value = ''
                            this.$vs.loading.close() 
                            const error = "The uploaded image size should be below 2 MB and its resolution should be 300*300 pixel..!!"
                            this.notif_failed(error)
                      }else{
                            this.errImageupload = 0
                      }
                    };
                    img.src = src;
                };
    setTimeout(() => {
      if(this.errImageupload == 0){
      this.$store.dispatch('category/uploadImage', {image:e.target.files[0], tag_id:this.$route.params.id}).then((res)=>{
        this.formData['image_url'] = res
        this.upload_check   = 1
        this.remove_image   = true;
        this.add_image      = false;
        this.$refs.image_url.value = ''
        this.$vs.loading.close()  
      }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
         })
      }
    }, 200);
  },
  
    remove_images(e) {
       this.$vs.loading()
                let file = e.target.files[0];
                let reader = new FileReader();
                 const fileSize = file.size
                var sizeInMB = (fileSize / (1024*1024)).toFixed(2);
                reader.readAsDataURL(file);
                reader.onload = e => {
                    const src = e.target.result;
                    const img = new Image();
                    img.onload = () => {
                     // if((img.height > 300 || img.width > 300) || (sizeInMB > 2)){
                      if(sizeInMB > 2)
                      {
                            this.errImageupload = 1
                            this.$refs.image_url.value = ''
                            this.$vs.loading.close() 
                            const error = "The uploaded image size should be below 2 MB and its resolution should be 300*300 pixel..!!"
                            this.notif_failed(error)
                      }else{
                            this.errImageupload = 0
                      }
                    };
                    img.src = src;
                };
    setTimeout(() => {
      if(this.errImageupload == 0){
          this.$store.dispatch('category/removeImage', {image:e.target.files[0], tag_id:this.$route.params.id,oldImage:this.formData['image_url']}).then((res)=>{
            this.add_image    = false;
            this.$refs.image_url.value = ''
            this.formData['image_url'] = res 
            this.remove_image = true;
            this.upload_check = 1
            this.$refs.image_url.value = ''
            this.$vs.loading.close()
          }).catch((error) => { 
                      this.$vs.loading.close()
                      this.notif(error)     
            })
         }
    }, 200);
    },
    addEditCategory () {
      this.$vs.loading()
      if (this.promptName === 'Edit Category') {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.$store.dispatch('category/updateCategory', Object.assign({}, this.formData)).then(() => {
               this.$vs.loading.close()  
              const success = 'Category Updated Successfully...!!!'
              this.notif_sucess(success) 
            }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
            })
          }
        })
      } else {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.$store.dispatch('category/addCategory', Object.assign({}, this.formData)).then(() => {
              this.$vs.loading.close()  
              const success = 'Category Updated Successfully...!!!'
              this.notif_sucess(success) 
            }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
            })
          }
        })
      }
    },
    deleteCategory (category_id) {
       this.$vs.loading()
      this.$store.dispatch('category/deleteCategory', category_id).then(() => {
          this.$vs.loading.close()
      }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
         })
    },
    notif (error) {
      this.$vs.notify({
        title: 'Alert',
        text: error.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    },
    notif_sucess (success) {
      this.$vs.notify({
        title: 'success',
        text: success,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color:"success"
      })
    },
  },
  created () {
    if(this.$store.state.category_permission.update == false && this.$store.state.category_permission.delete == false){
      this.disableAction = false
    }
    // this.$store.registerModule('category', moduleCategory)
    if (this.categories.length === 0) {
       this.$vs.loading()
       this.$store.dispatch('category/fetchCategories').then(() => { this.$vs.loading.close() })
    }
    if (this.modules.length === 0) {
      this.$store.dispatch('module/fetchModules')
    }
  },
  beforeDestroy () {
    // this.$store.unregisterModule('category')
  }
}
</script>
