<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vx-card>

    <!-- Content Row -->
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">

        <vs-input class="w-full mt-4 required" label="Name"
                  v-model="formData['name']"
                  v-validate="'required'" />

        <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first("name") }}</span>


        <!-- <vs-input class="w-full mt-4 required" :label="$t('modules.profile.Last Name')"
                  v-model="formData['last_name']"
                  v-validate="'required'" />

        <span class="text-danger text-sm" v-show="errors.has('last_name')">{{ errors.first("last_name") }}</span> -->


        <vs-input class="w-full mt-4 required" :label="$t('modules.profile.Email')"
                  v-model="formData['email']"
                  v-validate="'required'" />

        <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first("email") }}</span>


        <vs-input class="w-full mt-4 required" :label="$t('modules.profile.Phone')"
                  v-model="formData['phone']"
                  v-validate="'required'" />

        <span class="text-danger text-sm" v-show="errors.has('phone')">{{ errors.first("phone") }}</span>


        <vs-select class="mt-4" :color="colorx" :label="$t('modules.profile.User type')" v-model="formData['user_type']">
          <vs-select-item value="1" text="Admin" />
          <vs-select-item value="2" text="Teacher" />
          <vs-select-item value="3" text="Student" />
        </vs-select>


        <vs-select class="mt-4"
          :color="colorx"
          :label="$t('modules.profile.Country')"
          v-model="formData['country']"
          @change="getCities"
        >
          <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in countries" />
        </vs-select>


        <vs-select class="mt-6"
          :color="colorx"
          :label="$t('modules.profile.City')"
          v-model="formData['city']"
           v-if="showName"
        >
          <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in cities" />
        </vs-select>


        <div class="mt-6 center">
          <div> {{$t('modules.profile.Gender')}}</div>
          <ul class="leftx">
            <li>
              <vs-radio color="primary" v-model="formData['gender']" vs-value="1">{{$t('modules.profile.Male')}}</vs-radio>
            </li>
            <li>
              <vs-radio color="primary" v-model="formData['gender']" vs-value="2">{{$t('modules.profile.Female')}}</vs-radio>
            </li>
          </ul>
          <span class="text-danger text-sm" v-show="errors.has('gender')">{{ errors.first("gender") }}</span>
        </div>


        <div class="mt-6 ml-1 centerx w-full vx-row">
          <label class="text-sm my-4 my-1">{{$t('modules.profile.Status')}}</label>
          <vs-switch color="success my-3 mx-4" v-model="formData['status']" vs-value="1">
            <span slot="on">{{$t('modules.profile.Active')}}</span>
            <span slot="off">{{$t('modules.profile.InActive')}}</span>
          </vs-switch>
        </div>
      </div>
      <!--      col : close-->

      <div class="vx-col md:w-1/2 w-full">
        <div class="mt-8 justify-between">
          <label>{{$t('modules.profile.Profile Image')}}</label>
          <input class="w-100 vx-row mx-1" type="file" accept="image/*" @change="handleImage" ref="image">
          <vs-image class="w-100 shadow" :src="previewImage" />
        </div>
      </div>

    </div>


    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" :disabled="!validateForm" @click="save">{{$t('modules.profile.Save Changes')}}</vs-button>
          <vs-button class="ml-4 mt-2" v-if="reset_btn" type="border" color="warning" @click="reset">{{$t('modules.profile.Reset')}}</vs-button>
          <vs-button class="ml-4 mt-2" v-if="cancel_btn" type="border" color="warning" @click="cancel">{{$t('modules.profile.Cancel')}}
          </vs-button>
        </div>
      </div>
    </div>

  </vx-card>
</template>

<script>
// import moduleRole          from '@/store/role/moduleRole.js'
import vSelect from 'vue-select'
// import flatPickr from 'vue-flatpickr-component'
// import 'flatpickr/dist/flatpickr.css'
import axios from '@/axios'

export default {
  components: {
    vSelect
    // flatPickr
  },
  data () {
    return {
      formData: {
        name:'',
        email:'',
        phone:'',
        user_type:'',
        country:'',
        city:'',
        gender:'',
        image:'',
        status: '0',
        visibility: '1'
      },
      colorx: '#103767',
      reset_btn: true,
      cancel_btn: false,
      previewImage: '',
      validateForm: true,
      countries: [],
      cities: [],
      flow_type: '',
      showName: false
    }
  },

  created () {
    if (this.$route.params.id) {
      this.flow_type = 'edit'
    } else {
      this.flow_type = 'add'
    }

    if (this.flow_type === 'edit') {
      this.getUserDetail(this.$route.params.id)
      this.id = this.$route.params.id
    }

    this.getCountries()
  },

  computed: {
    countryCities () {
      return []
    }
  },

  methods: {
    cancel () {
      this.$router.push('/user').catch(() => {
      })
    },
    reset () {
      this.$router.push('/user')
    },
    save () {
      //const form = this.processForm()
      this.$vs.loading()
      if (this.flow_type === 'add') {
        axios.post('/users', this.formData)
          .then((response) => {
            if (response.status === 201) {
              this.notifyUI('Created!', 'User has been created', 'success')
              this.$router.push('/user')
            }
          })
          .catch((error) => {
            this.displayErrors(error)
          })
          .then(() => this.$vs.loading.close())
      } else {
        axios.put(`/users/${this.id}`, this.formData)
          .then((response) => {
            if (response.status === 200) {
              //IF updating logged in user
              if (this.$store.state.appUser.id === response.data._id) {
                this.$store.dispatch('updateUserInfo', response.data)
              }
              this.notifyUI('Updated!', 'User has been updated', 'success')
              this.$router.push('/user')
            }
          })
          .catch((error) => {
            this.displayErrors(error)
          })
          .then(() => this.$vs.loading.close())
      }
    },

    getUserDetail (id) {
      axios.get(`/users/${id}`)
        .then((response) => {
          if (response.status === 200) {
            this.formData = response.data
            this.previewImage = this.formData.image
          }
        })
        .catch((error) => {
          //alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },

    handleImage (e) {
      if (e.target.files[0]) {
        const image = e.target.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(image)
        reader.onload = e => {
          this.previewImage = e.target.result
          console.log(this.previewImage)
        }
      }
    },

    getCountries () {
      axios.get('/countries', { params: { limit: 1000 } })
        .then((response) => {
          if (response.status === 200) {
            //need format [{text: 'Square', value: 1}, ...],
            this.countries = response.data.items.map(item => {
              return { text: item.name, value: item._id }
            })
          }
        })
        .catch((error) => {
          //alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },

    getCities (country_id) {
      axios.get('/cities', { params: { country_id } })
        .then((response) => {
          if (response.status === 200) {
            if(this.showName !== ' ')
            {
              this.showName =  true
            }
            //need format [{text: 'Square', value: 1}, ...],
            if (response.data.items.length) {
              this.cities = response.data.items.map(item => {
                return { text: item.name, value: item._id }
              })
            } else {
              this.cities = [{ text: 'No Cities', value: '' }]
            }
          }
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },

    processForm () {
      const form = new FormData()
      form.append('name', this.formData['name']) //manually append name
      //form.append('last_name', this.formData['last_name']) //manually append name
      form.append('email', this.formData['email']) //manually append name
      form.append('phone', this.formData['phone']) //manually append name
      form.append('user_type', this.formData['user_type']) //manually append name
      form.append('country', this.formData['country']) //manually append name
      form.append('city', this.formData['city']) //manually append name
      form.append('gender', this.formData['gender']) //manually append name
      form.append('status', this.formData['status'] ? 1 : 0) //manually append status , plugin bug

      if (this.$refs.image.files.length) {
        form.append('image', this.$refs.image.files[0])
      } //manually append file obj

      return form
    }

  }
}
</script>
