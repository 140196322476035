<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vx-card>

    <!-- Content Row -->
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">

        <vs-input class="w-full mt-4 required" label="Vendor Name"
                  v-model="formData['name']"
                  v-validate="'required|max:100'"
                  data-vv-validate-on="blur" />

        <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first("name") }}</span>

        <vs-input class="w-full mt-4 required" label="Vendor Email"
                  v-model="formData['email']"
                  v-validate="'required|email|min:3'" />

        <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first("email") }}</span>

        <vs-input type="password" id="password" v-show="flow_type == 'add'" class="w-full mt-4 required"
                  label="Vendor password"
                  v-model="formData['password']"
                  v-validate="'required|min:6|max:30'" />
        <span class="text-danger text-sm"
              v-show="errors.has('password')">{{ errors.first("password") }}</span>


        <vs-input class="w-full mt-4 required" label="Vendor phone"
                  v-model="formData['phone']"
                  v-validate="'required|min:10|max:10'" />
        <span class="text-danger text-sm" v-show="errors.has('phone')">{{ errors.first("phone") }}</span>

        <div class="mt-4">
          <div>
            <input class="w-100 vx-row mx-1" type="file" accept="image/*" @change="handleImage" ref="image">
            <!--          <img :src="previewImage" alt="" class="">-->
            <vs-image class="w-100 shadow" :src="previewImage" />
            <!--   <vs-upload action="https://jsonplaceholder.typicode.com/posts/" @on-success="successUpload" />-->
          </div>
        </div>

        <div class="mt-6 centerx w-full vx-row">
          <label class="text-sm" style="margin-left: 4px;">Status</label>
          <vs-switch color="success" v-model="formData['status']">
            <span slot="on">Active</span>
            <span slot="off">InActive</span>
          </vs-switch>
        </div>


      </div>


    </div>


    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" :disabled="!validateForm" @click="save">Save Changes</vs-button>
          <vs-button class="ml-4 mt-2" v-if="reset_btn" type="border" color="warning" @click="reset">Reset</vs-button>
          <vs-button class="ml-4 mt-2" v-if="cancel_btn" type="border" color="warning" @click="cancel">Cancel
          </vs-button>
        </div>
      </div>
    </div>

  </vx-card>
</template>

<script>
// import moduleRole          from '@/store/role/moduleRole.js'
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "@/axios";
import Vue from "vue";

export default {
  components: {
    vSelect,
    flatPickr
  },
  data() {
    return {
      flow_type: "",
      id: 0,
      formData: {
        name: "",
        status: ""
      },
      reset_btn: true,
      cancel_btn: false,
      status: false,
      previewImage: ""
    };
  },

  created() {

    if (this.$route.params.id) {
      this.flow_type = "edit";
       this.reset_btn= false
       this.cancel_btn= true
    } else {
      this.flow_type = "add";
    }

    if (this.flow_type === "edit") {
      this.getVendorDetail(this.$route.params.id);
      this.id = this.$route.params.id;
    }

  },

  computed: {
    validateForm() {
      return !this.errors.any() && this.email !== "" && this.password !== "";
    }
  },

  methods: {
    cancel() {
      this.$router.push("/vendor").catch(() => {
      });
    },
    reset() {
      //this.$router.push("/vendor");
       this.formData=
        {
             grade:"",
             gender:""
            
         }
      
    },
    save() {
      const form = this.processForm();

      if (this.flow_type === "add") {
        axios.post("/vendors/create", form)
          .then((response) => {
            if (response.status === 200) {
              this.notifyUI("Created!", "Vendor has been created", "success");
              this.$router.push("/vendor");
            }
          })
          .catch((error) => {
            this.displayErrors(error);
          })
          .then(() => this.$vs.loading.close());
      } else {
        axios.post(`/vendors/edit/${this.id}`, form)
          .then((response) => {
            if (response.status === 200) {
              this.notifyUI("Updated!", "vendor updated sucessfully", "success");
              this.$router.push("/vendor");
            }
          })
          .catch((error) => {
            this.displayErrors(error);
          })
          .then(() => this.$vs.loading.close());
      }
    },

    getVendorDetail(id) {
      axios.get(`/vendors/${id}`)
        .then((response) => {
          if (response.status === 200) {
            const form = {};
            form.name = response.data.name;
            form.status = response.data.status;
            form._id = response.data._id;
            //console.log( response.data.name)
            this.formData = response.data;
            this.previewImage = this.formData.image;
          }
        })
        .catch((error) => {
          alert(`Error: ${error}`);
        })
        .then(() => this.$vs.loading.close());
    },
    handleImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e => {
        this.previewImage = e.target.result;
        console.log(this.previewImage);
      };
    },
    processForm() {
      const form = new FormData();
      form.append("name", this.formData["name"]); //manually append name
      form.append("email", this.formData["email"]); //manually append email
      form.append("phone", this.formData["phone"]); //manually append
      if (this.formData["password"]) {
        form.append("password", this.formData["password"]);
      } //manually append phone
      form.append("status", this.formData["status"] ? 1 : 0); //manually append status , plugin bug
      if (this.$refs.image.files.length) {
        form.append("image", this.$refs.image.files[0]);
      } //manually append file obj
      return form
    }

  }
}
</script>
