<template>
    <div>{{program_name(this.params.value)}}</div>
</template>

<script>
export default {
  name: 'CellRendererProgram',
  computed: {
  },
  methods: {
    program_name (programId) {
      return this.$store.getters['program/getProgramName'](programId)
    },
  },
  created () {
  },
}
</script>
