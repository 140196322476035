<template>
  <vx-card>
    <div>
      <vs-row class="my-4">
        <h1>Provider Feedback</h1>        
      </vs-row>
    </div>
    <div class="Help-support Ticket-details">          
      <vs-row>
        <div class="edit_profile_content_outers">
          <div class="edit_profile_content">
            <!-- <h2> {{ ticket.title }}</h2> -->
            <!-- HISTORY -->
            <div class="help_support_main">
                <div class="tickets_details_plan" v-for="(rcItem, rcIndex) in combinedList"
                     :key="rcIndex">
                    <vs-row class="row" vs-w="12"><h4 class="m-5">{{ rcItem.title }}</h4></vs-row>
                    <vs-row class="row" vs-w="12">
                        
                        <vs-col class="flex justify-items-center p-4" vs-w="1">
                          <div class="client_initial">
                              <h4 class="mt-3">{{ getCommentor(rcItem) }}</h4>
                          </div>
                        </vs-col>
                        <vs-col class="flex justify-items-center p-4" vs-w="10">
                          <div class="client_id">
                              

                              <p v-if="rcItem.submitted_at">
                                  {{ date_format(rcItem.submitted_at) }}
                              <p v-else> - </p>

                              <h5 v-html="rcItem.comment"></h5>
                              <!-- DOWNLOAD -->
                              <a v-if="rcItem.attachment && rcItem.attachment.filename"
                                 :href="rcItem.attachment.filename"
                                 target="_blank">
                                  <vs-icon icon="download">Download</vs-icon>
                              </a>
                          </div>
                        </vs-col>

                      <vs-col class="flex justify-items-center p-4" vs-w="1">
                          <div class="three_dots_menu">
                              <div class="three_dots_menu">
                                  <a href="#">
                                      <!-- <vs-icon icon="three-dots-vertical"></vs-icon> -->
                                  </a>
                              </div>
                          </div>
                      </vs-col>

                  </vs-row>
              </div>
              <vs-row class="m-4"><hr></vs-row>

              <!-- NEW POST -->
              <!-- <div v-for="(cmItem, cmIndex) in data.history" :key="cmIndex"> -->
              <!-- <div>
                  <vs-row>
                      <vs-col vs-w="1">
                          <div class="client_initial">
                              <h4 class="mt-3">{{$store.state.appUser.first_name.charAt(0)}} </h4>
                          </div>
                      </vs-col>
                      <vs-col vs-w="8">
                          <div class="client_id">
                              <vs-input type="text" class="form-control" v-model="new_reply.comment"
                              placeholder="Add your comment" />
                          </div>
                      </vs-col>
                      <vs-col vs-w="3">
                          <div class=" attachment_tickets">
                              <input class="ml-4 float-left h-6" type="file" accept="image/*" @change="handleImage" ref="attachment">
                          </div>
                      </vs-col>
                  </vs-row>
                  <vs-row class="post_submit_post_outers text-right">
                      <div class="post_submit_post">
                          <vs-button class="mr-2" @click="resetForm"> Cancel </vs-button>
                          <vs-button variant="danger" @click="save">Submit</vs-button>
                      </div>
                  </vs-row>
               </div> -->

            </div>
	        </div>

	    </div>
      </vs-row>
    </div>
  </vx-card>
</template>

<script>
// import vSelect from 'vue-select'
import axios from "@/axios";

export default {
    data() {
        return {
            ticketdrop: true,


            ticket: {
                title: "",
                description: "",
                attachment: { filename: "" },
                history: [{
                    comment: "",
                    attachment: {
                        filename: ""
                    }
                }]
            },

            new_reply: {
                comment: "",
                attachment: {}
            },

            askTime: true,
            tmpAttachment: "",
            displayPrompt: false,
            pageData: {},
            is_loaded: false,
            selected: {},
            keyword: "",
            sort_by: "",
            ordering: "",
            page: 1,
            limit: 3,
            total_results: 0

        };
    },
    methods: {
        resetForm(){
            this.new_reply.comment = ''
            this.new_reply.attachment = null
        },
        date_format (date) {
      const d = new Date(date)
      return d.toDateString()
    },
        // save() {

        //     const form = new FormData();
        //     form.append("comment", this.new_reply.comment);
        //     if (this.$refs.attachment.files.length) {
        //         form.append("attachment", this.$refs.attachment.files[0]);
        //     } //manually append file obj

        //     axios.post(`/provider-feedbacks/${this.id}/reply`, form)
        //         .then((response) => {
        //             if (response.status === 201) {
        //                 this.notifyUI("Added!", "Reply added successfully", "success");

        //                 //reload the data to reflect new state
        //                 this.getHelpDetail(this.id)

        //                 //clear inputs of  comment form
        //                 this.resetForm()
        //             }
        //         })
        //         .catch((error) => {
        //             this.displayErrors(error);
        //         });
        //     //.then(() => this.$vs.loading.close())
        // },


        getHelpDetail(id) {
            // alert(this.data);
            axios.get(`/provider-feedbacks/${id}`)
                .then((response) => {
                    if (response.status === 200) {
                        this.ticket = response.data;
                    }
                })
                .catch((error) => {
                    alert(`Error: ${error}`);
                });
            // .then(() => this.$vs.loading.close())
        },

        getCommentor(c){
            if(c.commentor_type == 'student'){
				//get login student name
				// return this.$store.state.appUser.first_name.charAt(0)
                return 'S'
            }else if(c.commentor_type == 'teacher'){
				// return this.$store.state.appUser.first_name.charAt(0)
              return 'T'
            }else if(c.commentor_type == 'admin'){
              return this.$store.state.appUser.first_name.charAt(0)
            }else{
                //admin
                return 'T'
            }
		},
		
		handleImage (e) {
	      const image = e.target.files[0]
	      const reader = new FileReader()
	      reader.readAsDataURL(image)
	      reader.onload = e => {
	        this.previewImage = e.target.result
	        console.log(this.previewImage)
	      }
	    }

    },

    created() {
        if (!this.$route.params.id) {
            this.notifyUI("Alert!", "Invalid request Url", "error");
            this.$router.push({ name: "Provider-Feedback" });
        }

        this.getHelpDetail(this.$route.params.id);
        this.id = this.$route.params.id;
    },

    computed: {
        combinedList() {
            let arr = [{
                commentor_type: this.ticket.commentor_type,
				student_id: this.ticket.student_id,
				teacher_id: this.ticket.teacher_id,
                title: this.ticket.title,
                comment: this.ticket.description,
                attachment: this.ticket.attachment,
                submitted_at: this.ticket.createdAt,
            }]

            if(this.ticket.history){
                arr.push(...this.ticket.history)
            }

            return arr
        }
    }
};
</script>

<style scoped>
  .client_initial {
    background: #ededed;
    border: 1px solid #ededed;
    border-radius: 25px;
    margin: 0;
    padding: 0;
    color: #9d74b0;
    width: 45px;
    height: 45px;
    text-align: center;
}
.client_id {
    display: inline-block;
    width: 100%;
    padding-bottom: 20px;
}
.vs-row{
    display: block !important;
  }
  .vs-con-input-label{
    width: 50%;
  }
</style>