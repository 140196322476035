<template>
    <div>{{media_type(this.params.value)}}</div>
</template>

<script>
export default {
  name: 'CellRendererMediaType',
  computed: {
  },
  methods: {
    media_type (v) {
      if(v==1){
        return 'Video'
      }else{
        return 'Music'
      }
    }
  },
  created () {
  },
}
</script>
