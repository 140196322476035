<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vx-card>

    <vs-table :sst="true" ref="table" pagination search striped :data="pageData.items"
              @search="handleSearch" @change-page="handleChangePage" @sort="handleSort" 
              :total="pageData.total"
              :max-items="pageData.page_size" noDataText="" :key="renderKey">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">

          <!-- ACTION - DROPDOWN -->
          <!-- <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4 mb-4"
                       style="margin-bottom: 0px !important">

            <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon">
              <span class="mr-2">Actions</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </button>

            <vs-dropdown-menu>

              <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Delete</span>
                      </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="ArchiveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Archive</span>
                      </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Print</span>
                      </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Another Action</span>
                      </span>
              </vs-dropdown-item>

            </vs-dropdown-menu>
          </vs-dropdown> -->

          <!-- ADD NEW -->
          <div class="dd-actions cursor-pointer mr-4 mb-4" style="margin-bottom: 0px !important">
            <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon"
                    @click="$router.push('/cms/new').catch(() => {})">
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base text-primary">{{$t('modules.cms.Add CMS')}}</span>
            </button>
          </div>
          <!-- REFRESH -->
          <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon" @click="refreshData()">
            <feather-icon icon="RefreshCwIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base text-primary">{{$t('modules.cms.Refresh')}}</span>
          </button>
        </div>
      </div>

      <template slot="thead">
        <vs-th sort-key="id">{{$t('modules.cms.Sl No')}}</vs-th>
        <vs-th sort-key="title">{{$t('modules.cms.Name')}}</vs-th>
        <vs-th sort-key="created_at">{{$t('modules.cms.Created At')}}</vs-th>
        <vs-th sort-key="status">{{$t('modules.cms.Status')}}</vs-th>
        <vs-th>{{$t('modules.cms.Action')}}</vs-th>
      </template>

      <template>
        <vs-tr :key="indextr" v-for="(tr, indextr) in pageData.items">

          <vs-td :data="pageData.items[indextr].name">
            {{ indextr + 1 }}
          </vs-td>

          <vs-td :data="pageData.items[indextr].name">
            {{ pageData.items[indextr].name }}
          </vs-td>

          <vs-td :data="pageData.items[indextr].createdAt">
            {{ date_format(pageData.items[indextr].createdAt) }}
          </vs-td>

          <vs-td :data="pageData.items[indextr].status">
            <vs-chip v-if="pageData.items[indextr].status!=1" :color="'#d9534f'" class="product-order-status">{{$t('modules.profile.InActive')}}</vs-chip>
            <vs-chip v-if="pageData.items[indextr].status==1" :color="'#5cb85c'" class="product-order-status">{{$t('modules.profile.Active')}}</vs-chip>
          </vs-td>

          <vs-td class="whitespace-no-wrap">
            <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current edit_delete"
                          @click.stop="$router.push('/cms/edit/'+tr._id).catch(() => {})" />
            <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current edit_delete"
                          class="ml-2" @click.stop="deleteCms(tr._id)" />
          </vs-td>
        </vs-tr>
      </template>
      <template #footer>
        <vs-pagination v-model="page" :total="pageData.total_pages" :key="renderKey" />
      </template>

    </vs-table>

  </vx-card>
</template>

<script>

import Vue from 'vue'
import axios from '@/axios'

export default {
  data () {
    return {
      renderKey: 2,
      formData: {},
      displayPrompt: false,
      pageData: {},
      is_loaded: false,
      selected: {},
      keyword: '',
      sort_by: '',
      ordering: '',
      page: 1,
      limit: 3,
      total_results: 0
    }
  },
  components: {},
  computed: {},
  methods: {
    handleSearch (searching) {
      this.page = 1
      this.keyword = searching
      this.fetch_cms()
    },

    handleSort (col, ord) {
      this.sort_by = col
      this.ordering = ord
      this.fetch_cms()
    },

    handleChangePage (page) {
      this.page = page
      this.fetch_cms()
    },

    refreshData () {
      this.fetch_cms()
    },

    deleteCms (id) {
      this.$vs.loading()
      axios.delete(`/cms/${id}`)
        .then((response) => {
          if (response.status === 200) {
             this.notifyUI('Deleted!','cms deleted sucessfully', 'success')
              this.fetch_cms();

          }
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },

    date_format (date) {
      const d = new Date(date)
      return d.toDateString()
    },


    //Fecth from API
    fetch_cms () {
      this.$vs.loading()

      axios.get('/cms', {
        params: {
          keyword: this.keyword,
          sort_by: this.sort_by,
          ordering: this.ordering,
          page: this.page,
          limit: this.limit
        }
      })
        .then((response) => {
          // this.pageData = response.data
          // this.is_loaded = true
          // this.total_results = response.total
          Vue.set(this, 'pageData', response.data)
          this.is_loaded = true
          if (this.page == 1) this.renderKey = Math.random()
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    }
  },
  created () {
    this.fetch_cms()
  },
  beforeDestroy () {
  }
}
</script>
