<template>

  <!--
       vs-align
       align vertically : center, flex-end, flex-start.
    -->

  <!--
     vs-justify
     align horizontally: flex-start, center, flex-end, space-around, space-between
   -->

  <!--    screens: {
    'sm': '640px',
    // => @media (min-width: 640px) { ... }

    'md': '768px',
    // => @media (min-width: 768px) { ... }

    'lg': '1024px',
    // => @media (min-width: 1024px) { ... }

    'xl': '1280px',
    // => @media (min-width: 1280px) { ... }

    '2xl': '1536px',
    // => @media (min-width: 1536px) { ... }
  }
  -->

  <!-- heading -->

  <div class="tab__header">
    <vx-card>

      <a href="javascript:null;"
         class="tab__link py-4 block bg-blue-dark hover:bg-blue-darker no-underline text-white border-b-2 border-white flex justify-between"
         @click.prevent="active = !active">
        <vs-row>
          <vs-col class="bg-white" vs-w="12">
            <b>{{ $t("modules.package.Course Type") }}</b>
          </vs-col>
        </vs-row>
      </a>

      <!-- SECTION DETAILS -->
      <!-- section is a (info + contents) -->
      <vs-row class="bg-white" vs-w="12">

        <div class="flex justify-between w-full">
          <h4>Section Details</h4>

          <div class="float-right">
            <vs-button class="" @click="delSection" color="danger"
                       type="border"
                       icon="delete_forever" size="small">
              {{ $t("modules.package.Delete Section") }}
            </vs-button>
          </div>
        </div>


        <!-- course and instructions -->
        <vs-col vs-w="12" class="mt-2">
          <vs-select class="w-full mt-4 required font-bold" :label="$t('modules.package.Course')"
                     :placeholder="$t('modules.package.Section Course')"
                     v-model="section.course_type" v-validate="'required'"
                     :name="`random-section_${sectionIndex}_course_type`"
                     :id="`random-section_${sectionIndex}_course_type`">
            <vs-select-item :key="course_index" :value="course.value" :text="course.text"
                            v-for="(course,course_index) in course_types"/>
          </vs-select>

          <vs-col vs-w="12" vs-type="flex">
                        <span class="text-danger text-sm w-full flex-wrap"
                              v-show="errors.has(`random-section_${sectionIndex}_course_type`)">
                            {{ errors.first(`random-section_${sectionIndex}_course_type`) }}
                        </span>
          </vs-col>
        </vs-col>

        <vs-col vs-w="12" class="mt-2">
          <vs-textarea :label="$t('modules.package.Instructions')" width="100%" height="12rem"
                       v-model="section.instruction[currLang]"
                       :name="`random-section_${sectionIndex}_instruction`"
                       :id="`random-section_${sectionIndex}_instruction`"/>
          <span class="text-danger text-sm w-full flex-wrap"
                v-show="errors.has(`random-section_${sectionIndex}_instruction`)">
                        {{ errors.first(`random-section_${sectionIndex}_instruction`) }}
                    </span>
        </vs-col>
      </vs-row>


      <!-- LOOP CONTENTS -->
      <!-- content is a (para + que[]) -->

      <vs-collapse type="margin" accordion @change="triggerWindowResize">
        <vs-collapse-item v-for="(content, contentIndex) in section.contents" :key="contentIndex">
          <div slot="header">
            <!--   Trigger header open collapse -->
            <vs-row vs-w="12" @click="triggerWindowResize">
              <vs-col vs-w="12">
                <vs-chip color="primary">
                  <vs-avatar icon="widgets"/>
                  Paragraph : {{ (contentIndex + 1) }}
                </vs-chip>
              </vs-col>
            </vs-row>
          </div>

          <vs-row class="bg-white" vs-w="12">
            <vs-col vs-w="12" class="mt-2">

              <div class="flex justify-between w-full mt-10 p-2">
                <div>
                  <h4 class=""
                      :id="`random-section_${sectionIndex}_content_${contentIndex}`">
                    Section {{ sectionIndex + 1 }} : Paragraph {{ contentIndex + 1 }}</h4>
                  <span class="text-danger text-sm w-full flex-wrap mt-2"
                        v-show="errors.has(`random-section_${sectionIndex}_content_${contentIndex}`)">
                        {{ errors.first(`random-section_${sectionIndex}_content_${contentIndex}`) }}
                        </span>
                </div>

                <div class="float-right">
                  <vs-button class="" @click="delParagraph(contentIndex)" color="danger"
                             type="border"
                             icon="delete_forever" size="small">
                    {{ $t("modules.package.Delete Paragraph") }}
                  </vs-button>
                </div>
              </div>


              <!-- title -->
              <vs-col vs-w="12" class="mt-4">
                <vs-input class="w-full" :label="$t('modules.package.Title')" placeholder="Enter title"
                          v-model="content.paragraph.title[currLang]"
                          :name="`random-section_${sectionIndex}_content_${contentIndex}_paragraph_title`"
                          :id="`random-section_${sectionIndex}_content_${contentIndex}_paragraph_title`"/>
                <span class="text-danger text-sm w-full flex-wrap"
                      v-show="`random-section_${sectionIndex}_content_${contentIndex}_paragraph_title`">
                            {{ errors.first(`random-section_${sectionIndex}_content_${contentIndex}_paragraph_title`) }}
                        </span>
              </vs-col>

              <!-- description -->
              <vs-col vs-w="12" class="mt-4">
                <!-- set options via props -->

                <!--                <vs-button @click="wysiwyg_show=true; current_markdown=content.paragraph.body[$i18n.locale]"-->
                <!--                           color="primary" type="border">Open In Editor-->
                <!--                </vs-button>-->

                <vs-textarea :label="$t('modules.package.Paragraph Body')" width="100%" height="12rem"
                             v-model="content.paragraph.body[currLang]"
                             :name="`random-section_${sectionIndex}_content_${contentIndex}_paragraph_body`"
                             :id="`random-section_${sectionIndex}_content_${contentIndex}_paragraph_body`"/>
                <span class="text-danger text-sm w-full flex-wrap"
                      v-show="`random-section_${sectionIndex}_content_${contentIndex}_paragraph_body`">
                            {{ errors.first(`random-section_${sectionIndex}_content_${contentIndex}_paragraph_body`) }}
                        </span>
              </vs-col>

              <vs-row>
                <vs-col vs-w="12" class="mt-4">

                  <vs-checkbox v-model="content.paragraph.does_disappears">
                    {{$t('modules.package.Paragraph disappears')}}
                  </vs-checkbox>


                  <vs-col vs-w="6" vs-type="flex" style="margin-top:20px;" v-if="content.paragraph.does_disappears">
                    <vs-input-number class="w-full flex-wrap" v-model="content.paragraph.disappears_after" :label="$t('modules.package.Disappears after (In Seconds)')"
                                     v-validate="'required'" name="random-disappears_after" id="random-disappears_after" />
                    <span class="text-danger text-sm w-full flex-wrap" v-show="errors.has('random-disappears_after')">
                                  {{ errors.first('random-disappears_after') }}
                    </span>
                  </vs-col>

                </vs-col>
              </vs-row>

              <vs-row>
                <vs-col vs-w="12" class="mt-4">
                  <vs-checkbox v-model="content.paragraph.has_image">{{
                      $t('modules.package.Paragraph has image')
                    }}
                  </vs-checkbox>

                  <!-- image -->
                  <div class="" v-if="content.paragraph.has_image">
                    <crop-upload v-if="is_mounted"
                                 @paragraph-image-uploaded="handleParagraphImageSuccess(content.paragraph, $event)">
                    </crop-upload>
                  </div>
                </vs-col>
                <vs-col vs-w="8" v-if="content.paragraph.has_image" class="mt-16">
                  <img :src="content.paragraph.paragraph_image_url" alt="">
                </vs-col>
              </vs-row>


              <!-- instruction -->
              <vs-col vs-w="12" class="mt-4">
                <vs-input class="w-full" :label="$t('modules.package.Instructions')" placeholder="Enter Instructions"
                          v-model="content.paragraph.instruction[currLang]"
                          :name="`random-section_${sectionIndex}_content_${contentIndex}_paragraph_instruction`"
                          :id="`random-section_${sectionIndex}_content_${contentIndex}_paragraph_instruction`"/>
                <span class="text-danger text-sm w-full flex-wrap"
                      v-show="`random-section_${sectionIndex}_content_${contentIndex}_paragraph_instruction`">
                            {{
                    errors.first(`random-section_${sectionIndex}_content_${contentIndex}_paragraph_instruction`)
                  }}
                        </span>
              </vs-col>

              <div class="mb-12"> &nbsp;</div>

              <!-- LOOP: ADD QUESTION AND ANSWER -->
              <vs-collapse type="margin" accordion @change="triggerWindowResize">
                <vs-collapse-item v-for="(que, queIndex) in content.questionnaire" :key="queIndex">

                  <div slot="header">
                    <!--   Trigger header open collapse -->
                    <vs-row vs-w="12" @click="triggerWindowResize">
                      <vs-col vs-w="12">
                        <vs-chip color="primary">
                          <vs-avatar icon="widgets"/>
                          Question : {{ (queIndex + 1) }}
                        </vs-chip>
                      </vs-col>
                    </vs-row>
                  </div>

                  <!-- question contents -->
                  <vs-row class="bg-white" vs-w="12">
                  <vs-col vs-w="12">
                    <vs-divider position="center" class="mt-20 text-lg font-bold py-2 border-solid border-2">
                      Section {{ sectionIndex + 1 }} [ Paragraph - {{ (contentIndex + 1) }} ] Question #{{
                        queIndex + 1
                      }}
                    </vs-divider>
                  </vs-col>
                </vs-row>

                  <vs-row vs-w="12">
                    <vs-col vs-w="12" class="my-6 flex" vs-justify="center">

                      <vs-row vs-w="12">
                        <vs-col vs-w="4" vs-sm="12" vs-xs="12" vs-offset="4" class="my-6 flex" vs-justify="flex-start">
                          <vs-chip color="#24c1a0" class="text-center text-xl px-4 pr-6 py-1" v-if="que.answering_type">
                            {{ getQuestionAnswerType(que) }}
                          </vs-chip>
                        </vs-col>

                        <vs-col vs-w="3" vs-sm="12" vs-xs="12" class="my-6 flex" vs-justify="flex-end">
                          <div class="">
                            <vs-button @click="delQuestion(content, queIndex)" color="danger"
                                       type="border"
                                       icon="delete_forever" size="small">
                              {{ $t("modules.package.Delete Question") }}
                            </vs-button>
                          </div>
                        </vs-col>
                      </vs-row>

                    </vs-col>
                  </vs-row>

                  <vs-row class="bg-white" vs-w="12">
                    <!-- answering type -->
                    <vs-col vs-w="4" class="mt-2" vs-sm="12">
                      <vs-select
                          :label="$t('modules.package.Type')"
                          v-model="que.answering_type"
                          :name="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_answering_type`"
                          :id="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_answering_type`">
                        <vs-select-item :key="ans_type_index" :value="ans_type.value" :text="ans_type.text"
                                        v-for="(ans_type,ans_type_index) in answering_types"/>
                      </vs-select>
                      <span class="text-danger text-sm w-full flex-wrap"
                            v-show="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_answering_type`">
                                      {{
                          errors.first(`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_answering_type`)
                        }}
                                  </span>
                    </vs-col>

                    <!-- body -->
                    <vs-col vs-w="8" class="mt-2">
                      <vs-textarea :label="$t('modules.package.Enter Question')" v-model="que.body[currLang]"
                                   class="mt-6"
                                   :name="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_body`"
                                   :id="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_body`"/>
                      <span class="text-danger text-sm w-full flex-wrap"
                            v-show="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_body`">
                                      {{
                          errors.first(`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_body`)
                        }}
                                  </span>
                    </vs-col>


                    <vs-row class="flex" vs-w="12" vs-justify="space-between">
                      <!-- type_code -->
                      <vs-col vs-w="6" class="mt-6">
                        <label>{{ $t("modules.package.Question Type Code") }}</label>
                        <div class="ckd-box">
                          <v-select class="w-full" v-model="que.type_code"
                                    :name="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_type_code`"
                                    :id="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_type_code`"
                                    :options="question_type"></v-select>
                        </div>
                        <span class="text-danger text-sm w-full flex-wrap"
                              v-show="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_type_code`">
                                          {{ errors.first(`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_type_code`) }}
                        </span>
                      </vs-col>

                      <!-- thinking_type_code -->
                      <vs-col vs-w="6" class="mt-6">
                          <label>{{ $t("modules.package.Question Thinking Type Code") }}</label>
                          <div class="ckd-box">
                            <v-select class="w-full" v-model="que.thinking_type_code"
                                      :name="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_thinking_type_code`"
                                      :id="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_thinking_type_code`"
                                      :options="question_thinking_codes"></v-select>
                          </div>
                          <span class="text-danger text-sm w-full flex-wrap"
                                v-show="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_thinking_type_code`">
                            {{
                              errors.first(`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_thinking_type_code`)
                            }}
                          </span>
                      </vs-col>

                      <!-- problem_solving_code -->
                      <vs-col vs-w="6" class="mt-6">
                        <label>{{ $t("modules.package.Problem solving Code") }}</label>
                        <div class="ckd-box">
                          <v-select class="w-full" v-model="que.problem_solving_code"
                                    :name="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_problem_solving_code`"
                                    :id="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_problem_solving_code`"
                                    :options="problem_solving_codes"></v-select>
                        </div>
                        <span class="text-danger text-sm w-full flex-wrap"
                              v-show="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_problem_solving_code`">
                            {{
                              errors.first(`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_problem_solving_code`)
                            }}
                        </span>
                      </vs-col>

                    </vs-row>

                    <vs-col vs-w="12" class="mt-10">
                      <h6 v-if="[1,2].includes(que.answering_type)">{{
                          $t("modules.package.Create Choice")
                        }}</h6>
                      <h6 v-else-if="[3].includes(que.answering_type)">
                        {{ $t("modules.package.Create Statement") }}</h6>
                      <h6 v-else-if="[4].includes(que.answering_type)">
                        {{ $t("modules.package.Create Highlight") }}</h6>
                      <h6 v-else-if="[5].includes(que.answering_type)">{{
                          $t("modules.package.Create Blank")
                        }}</h6>
                    </vs-col>


                    <!-- ::::::::::::::::::::::::::::::MCQ::::::::::::::::::::::- -->
                    <vs-row class="my-10 flex" vs-w="12" v-for="(mcqItem,mcqIndex) in que.mcq"
                            :v-if="que.answering_type == 1 || que.answering_type == 2" :key="mcqIndex">
                      <!-- Answer -->
                      <vs-col class="pr-3" vs-w="6" vs-sm="12" vs-type="flex" vs-justify="space-between"
                              vs-align="center">
                        <vs-input class="w-full" :label="$t('modules.package.Choice')"
                                  placeholder="Write the choice"
                                  v-model="mcqItem.choice[currLang]"
                                  :name="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_choice_${mcqIndex}`"
                                  :id="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_choice_${mcqIndex}`"/>
                        <span class="text-danger text-sm w-full flex-wrap"
                              v-show="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_choice_${mcqIndex}`">
                                          {{
                            errors.first(`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_choice_${mcqIndex}`)
                          }}
                                      </span>
                      </vs-col>
                      <!-- Mark -->

                      <vs-col class="pr-3" vs-w="2" vs-type="flex" vs-justify="space-between"
                              vs-align="center">
                        <vs-input class="w-full" :label="$t('modules.package.Marks')" placeholder="Marks"
                                  v-model="mcqItem.marks"
                                  :name="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_choice_${mcqIndex}_marks`"
                                  :id="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_choice_${mcqIndex}_marks`"/>
                        <span class="text-danger text-sm w-full flex-wrap"
                              v-show="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_choice_${mcqIndex}_marks`">
                                          {{
                            errors.first(`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_choice_${mcqIndex}_marks`)
                          }}
                                      </span>
                      </vs-col>
                      <!-- Weight -->
                      <vs-col class="pr-3" vs-w="2" vs-type="flex" vs-justify="space-between"
                              vs-align="center">
                        <vs-input class="w-full" :label="$t('modules.package.Weight')" placeholder="Weight"
                                  v-model="mcqItem.weight"
                                  :name="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_choice_${mcqIndex}_weight`"
                                  :id="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_choice_${mcqIndex}_weight`"/>
                        <span class="text-danger text-sm w-full flex-wrap"
                              v-show="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_choice_${mcqIndex}_weight`">
                                          {{
                            errors.first(`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_choice_${mcqIndex}_weight`)
                          }}
                                      </span>
                      </vs-col>

                      <!-- correct or del -->
                      <vs-col vs-w="2" vs-sm="4" vs-type="flex" vs-align="center">
                        <vs-col class="" vs-w="8" vs-justify="center" vs-align="center">
                          <!-- case1: IF SINGLE MCQ - show radio -->
                          <vs-radio @change="updateSingleMCQId(sectionIndex, contentIndex, queIndex, mcqIndex, mcqItem)"
                                    :vs-name="`radio_single_mcq_${sectionIndex}_${contentIndex}_${queIndex}`"
                                    v-model="que.mcq_single_answer[currLang]"
                                    v-if="que.answering_type == 1"
                                    :vs-value="mcqItem.choice[currLang]"

                                    :name="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_mcq_single_answer`"
                                    :id="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_mcq_single_answer`">
                            {{ $t("modules.package.Correct") }}?
                          </vs-radio>

                          <!-- case2:IF MULTI MCQ - show checkbox -->
                          <vs-checkbox v-model="mcqItem.is_correct" v-if="que.answering_type == 2"
                                       :name="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_choice_${mcqIndex}_is_correct`"
                                       :id="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_choice_${mcqIndex}_is_correct`">
                            {{ $t("modules.package.Correct") }}?
                          </vs-checkbox>
                        </vs-col>

                        <vs-col vs-w="4" vs-type="flex">
                          <vs-button class="" @click="delMcqChoice(que.mcq, mcqIndex)" color="danger"
                                     type="border" icon="delete"
                                     size="small"></vs-button>
                        </vs-col>
                      </vs-col>
                    </vs-row>

                    <div v-if="que.answering_type == 1 || que.answering_type == 2">
                      <vs-button class="flex-wrap mt-4" @click="addMcqChoice(que.mcq)" color="primary"
                                 type="border"
                                 icon="add_circle_outline" size="small"
                                 v-if="que.answering_type == 1 || que.answering_type == 2">
                        {{ $t("modules.package.Add Choice") }}
                      </vs-button>
                    </div>
                    <!-- ::::::::::::::::::::::::::::::MCQ::::::::::::::::::::::- -->


                    <!-- ::::::::::::::::::::::::::::::T/F::::::::::::::::::::::- -->
                    <vs-row class="my-5 flex" vs-w="12"
                            v-for="(tfItem,tfIndex) in que.true_or_false"
                            :v-if="que.answering_type == 3" :key="tfIndex">
                      <!-- Answer -->
                      <vs-col class="pr-3" vs-w="6" vs-sm="12" vs-type="flex" vs-justify="space-between"
                              vs-align="center">
                        <vs-input class="w-full" placeholder="Write the statement"
                                  :label="$t('modules.package.Statement')"
                                  v-model="tfItem.statement[currLang]"
                                  :name="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_statement_${tfIndex}`"
                                  :id="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_statement_${tfIndex}`"/>
                        <span class="text-danger text-sm w-full flex-wrap"
                              v-show="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_statement_${tfIndex}`">
                                          {{
                            errors.first(`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_statement_${tfIndex}`)
                          }}
                                      </span>
                      </vs-col>
                      <!-- Mark -->
                      <vs-col class="pr-3" vs-w="2" vs-type="flex" vs-justify="space-between"
                              vs-align="center">
                        <vs-input class="w-full" :label="$t('modules.package.Marks')" placeholder="Marks"
                                  v-model="tfItem.marks"
                                  :name="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_statement_${tfIndex}_marks`"
                                  :id="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_statement_${tfIndex}_marks`"/>
                        <span class="text-danger text-sm w-full flex-wrap"
                              v-show="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_statement_${tfIndex}_marks`">
                                          {{
                            errors.first(`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_statement_${tfIndex}_marks`)
                          }}
                                      </span>
                      </vs-col>
                      <!-- Weight -->
                      <vs-col class="pr-3" vs-w="2" vs-type="flex" vs-justify="space-between"
                              vs-align="center">
                        <vs-input class="w-full" :label="$t('modules.package.Weight')" placeholder="Weight"
                                  v-model="tfItem.weight"
                                  :name="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_statement_${tfIndex}_weight`"
                                  :id="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_statement_${tfIndex}_weight`"/>
                        <span class="text-danger text-sm w-full flex-wrap"
                              v-show="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_statement_${tfIndex}_weight`">
                                          {{
                            errors.first(`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_statement_${tfIndex}_weight`)
                          }}
                                      </span>
                      </vs-col>
                      <!-- Choice -->
                      <vs-col class="md:mt-6" vs-w="2" vs-sm="4" vs-type="flex" vs-align="center">
                        <vs-col vs-w="8" vs-justify="center" vs-align="center">
                          <vs-checkbox class="" v-model="tfItem.is_true"
                                       :name="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_statement_${tfIndex}_is_true`"
                                       :id="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_statement_${tfIndex}_is_true`">
                            {{ $t("modules.package.True") }}?
                          </vs-checkbox>
                        </vs-col>

                        <vs-col vs-w="4" vs-type="flex">
                          <vs-button @click="delTFChoice(que.true_or_false, tfIndex)" color="danger"
                                     type="border" icon="delete"
                                     size="small"></vs-button>
                        </vs-col>
                      </vs-col>
                    </vs-row>

                    <div v-if="que.answering_type == 3">
                      <vs-button class="flex-wrap mt-4" @click="addTFChoice(que.true_or_false)"
                                 color="primary" type="border"
                                 icon="add_circle_outline" size="small" v-if="que.answering_type == 3">
                        {{ $t("modules.package.Add More") }}
                      </vs-button>
                    </div>
                    <!-- ::::::::::::::::::::::::::::::T/F::::::::::::::::::::::- -->


                    <!-- ::::::::::::::::::::::::::::::highlight::::::::::::::::::::::- -->
                    <!-- Phrase -->
                    <vs-col class="pr-3" vs-w="12" vs-sm="12" vs-type="flex" vs-justify="space-between"
                            vs-align="center" v-if="que.answering_type == 4">
                      <vs-textarea class="w-full" :placeholder="$t('modules.package.Enter the orignal text')"
                                   :label="$t('modules.package.Orignal text')"
                                   v-model="que.highlight.canvas[currLang]"
                                   :name="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_canvas`"
                                   :id="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_canvas`"/>
                      <span class="text-danger text-sm w-full flex-wrap"
                            v-show="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_canvas`">
                        {{
                          errors.first(`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_canvas`)
                        }}
                      </span>
                    </vs-col>

                    <vs-row class="my-10 flex" vs-w="12" v-for="(hlItem,hlIndex) in que.highlight.markers"
                            :v-if="que.answering_type == 4" :key="hlIndex">
                      <!-- Answer -->
                      <vs-col class="pr-3" vs-w="6" vs-sm="12" vs-type="flex" vs-justify="space-between" vs-align="center">
                        <vs-textarea class="w-full"
                                     :placeholder="$t('modules.package.Enter segment that should be highlighted by student')"
                                     :label="$t('modules.package.Highlight')"
                                     v-model="hlItem[currLang]"
                                     :name="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_highlight_${hlIndex}`"
                                     :id="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_highlight_${hlIndex}`"/>
                        <span class="text-danger text-sm w-full flex-wrap"
                              v-show="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_highlight_${hlIndex}`">
                          {{
                            errors.first(`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_highlight_${hlIndex}`)
                          }}
                        </span>
                      </vs-col>

                      <!-- Mark -->
                      <vs-col class="pr-3" vs-w="2" vs-type="flex" vs-justify="space-between"
                              vs-align="center">
                        <vs-input class="w-full" :label="$t('modules.package.Marks')" placeholder="Marks"
                                  v-model="hlItem.marks"
                                  :name="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_highlight_${hlIndex}_marks`"
                                  :id="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_highlight_${hlIndex}_marks`"/>
                        <span class="text-danger text-sm w-full flex-wrap"
                              v-show="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_highlight_${hlIndex}_marks`">
                          {{
                            errors.first(`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_highlight_${hlIndex}_marks`)
                          }}
                        </span>
                      </vs-col>

                      <!-- Weight -->
                      <vs-col class="pr-3" vs-w="2" vs-type="flex" vs-justify="space-between"
                              vs-align="center">
                        <vs-input class="w-full" :label="$t('modules.package.Weight')" placeholder="Weight"
                                  v-model="hlItem.weight"
                                  :name="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_highlight_${hlIndex}_weight`"
                                  :id="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_highlight_${hlIndex}_weight`"/>
                        <span class="text-danger text-sm w-full flex-wrap"
                              v-show="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_highlight_${hlIndex}_weight`">
                          {{
                            errors.first(`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_highlight_${hlIndex}_weight`)
                          }}
                        </span>
                      </vs-col>

                      <!-- Add/del -->
                      <vs-col class="md:mt-6" vs-w="2" vs-sm="4" vs-type="flex" vs-align="center">
                        <vs-col vs-w="12" vs-type="flex">
                          <vs-button class="" @click="delHighlightWord(que.highlight, hlIndex)"
                                     color="danger" type="border"
                                     icon="delete" size="small"> {{ $t("modules.package.Delete") }}
                          </vs-button>
                        </vs-col>
                      </vs-col>
                    </vs-row>

                    <div v-if="que.answering_type == 4">
                      <vs-button class="flex-wrap mt-4" @click="addHighlightWord(que.highlight)"
                                 color="primary" type="border"
                                 icon="add_circle_outline" size="small">{{ $t("modules.package.Add Choice") }}
                      </vs-button>
                    </div>

                    <!-- ::::::::::::::::::::::::::::::highlight::::::::::::::::::::::- -->


                    <!-- ::::::::::::::::::::::::::::::fill in blank::::::::::::::::::::::- -->
                    <!-- Phrase -->
                    <vs-col class="pr-3" vs-w="12" vs-sm="12" vs-type="flex" vs-justify="space-between"
                            vs-align="center" v-if="que.answering_type == 5">
                      <vs-textarea class="w-full" :placeholder="$t('modules.package.Enter the orignal text')"
                                   :label="$t('modules.package.Orignal text')"
                                   v-model="que.blank.phrase[currLang]"
                                   :name="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_phrase`"
                                   :id="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_phrase`"/>
                      <span class="text-danger text-sm w-full flex-wrap"
                            v-show="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_phrase`">
                        {{
                          errors.first(`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_phrase`)
                        }}
                      </span>
                    </vs-col>

                    <vs-row class="mt-10 flex" vs-w="12" v-for="(fillItem,fillIndex) in que.blank.fills"
                            :v-if="que.answering_type == 5" :key="fillIndex">
                      <!-- Answer -->
                      <vs-col class="pr-3" vs-w="6" vs-sm="12" vs-type="flex" vs-justify="space-between"
                              vs-align="center">
                        <vs-textarea class="w-full" placeholder="Filling"
                                     :label="$t('modules.package.Filling')"
                                     v-model="fillItem[currLang]"
                                     :name="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_blank_${fillIndex}`"
                                     :id="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_blank_${fillIndex}`"/>
                        <span class="text-danger text-sm w-full flex-wrap"
                              v-show="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_blank_${fillIndex}`">
                          {{
                            errors.first(`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_blank_${fillIndex}`)
                          }}
                        </span>
                      </vs-col>


                      <!-- Mark -->
                      <vs-col class="pr-3" vs-w="2" vs-type="flex" vs-justify="space-between"
                              vs-align="center">
                        <vs-input class="w-full" :label="$t('modules.package.Marks')" placeholder="Marks"
                                  v-model="fillItem.marks"
                                  :name="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_blank_${fillIndex}_marks`"
                                  :id="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_blank_${fillIndex}_marks`"/>
                        <span class="text-danger text-sm w-full flex-wrap"
                              v-show="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_blank_${fillIndex}_marks`">
                          {{
                            errors.first(`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_blank_${fillIndex}_marks`)
                          }}
                        </span>
                      </vs-col>
                      <!-- Weight -->
                      <vs-col class="pr-3" vs-w="2" vs-type="flex" vs-justify="space-between"
                              vs-align="center">
                        <vs-input class="w-full" :label="$t('modules.package.Weight')" placeholder="Weight"
                                  v-model="fillItem.weight"
                                  :name="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_blank_${fillIndex}_weight`"
                                  :id="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_blank_${fillIndex}_weight`"/>
                        <span class="text-danger text-sm w-full flex-wrap"
                              v-show="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_blank_${fillIndex}_weight`">
                                          {{
                            errors.first(`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_blank_${fillIndex}_weight`)
                          }}
                                      </span>
                      </vs-col>

                      <vs-col class="md:mt-6" vs-w="2" vs-sm="4" vs-type="flex" vs-align="center">
                        <vs-col vs-w="12" vs-type="flex">
                          <vs-button class="" @click="delBlank(que.blank, fillIndex)" color="danger"
                                     type="border" icon="delete"
                                     size="small">
                            {{ $t("modules.package.Delete") }}
                          </vs-button>
                        </vs-col>
                      </vs-col>


                    </vs-row>

                    <div v-if="que.answering_type == 5">
                      <vs-button class="flex-wrap mt-4" @click="addBlank(que.blank)" color="primary"
                                 type="border"
                                 icon="add_circle_outline" size="small">
                        {{ $t("modules.package.Add Blanks") }}
                      </vs-button>
                    </div>
                    <!-- ::::::::::::::::::::::::::::::fill in blank::::::::::::::::::::::- -->


                  </vs-row>

                  <vs-row vs-w="12">
                    <vs-textarea :label="$t('modules.package.Enter Answer Explanation')"
                                 v-model="que.answer_explanation[currLang]" class="mt-4"/>
                    <span class="text-danger text-sm w-full flex-wrap"
                          v-show="`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_answer_explanation`">
                                      {{
                        errors.first(`random-section_${sectionIndex}_content_${contentIndex}_question_${queIndex}_answer_explanation`)
                      }}
                                  </span>
                  </vs-row>

                  <!--   {{que.answering_type}} -->


                </vs-collapse-item>
              </vs-collapse>



              <vs-row class="bg-white flex" vs-w="12" vs-justify="space-between">
                <!-- :: Add QUESTION button :: -->
                <vs-button class="mt-4" @click="addQuestion(content)"
                           color="primary" type="border" icon="add_circle_outline">
                  {{ $t("modules.package.Add Question") }}
                </vs-button>

                <span> &nbsp;
                  <div class="text-danger text-sm w-full flex-wrap"
                       v-show="`random-section_${sectionIndex}_content_${contentIndex}_questionnaire`">
                    {{
                      errors.first(`random-section_${sectionIndex}_content_${contentIndex}_questionnaire`)
                    }}
                  </div>
                </span>
              </vs-row>

            </vs-col>

          </vs-row>
        </vs-collapse-item>
      </vs-collapse>

      <vs-button class="mr-4 my-4" color="primary" type="filled" @click="addPara">
        {{ $t("modules.package.Add paragraph") }}
      </vs-button>

    </vx-card>

    <!-- For Editor -->
    <!--    <vs-popup fullscreen class="holamundo" title="Paragraph Content" :active.sync="wysiwyg_show">-->
    <!--      <wysiwyg :markdown.sync="current_markdown"></wysiwyg>-->
    <!--    </vs-popup>-->
  </div>

</template>

<script>
const windowUpdater = _.throttle(()=>window.dispatchEvent(new Event('resize')), 800)

import config from '@/config'

import Vue from 'vue'
import Select from '@ckd/vue-select'
import '@ckd/vue-select/dist/@ckd/vue-select.css'
Vue.component('v-select', Select)

import axios from '@/axios'
import _ from 'lodash'
// import validator from 'validator'
import cropUpload from './cropUpload'


import Wysiwyg from './wysiwyg/Wysiwyg'
import {vsPopup} from 'vuesax'
Vue.use(vsPopup)

export default {
  name: 'PackageSection',
  props: ['section', 'sectionIndex', 'currLang', 'errors',
          'question_type', 'question_thinking_codes', 'problem_solving_codes', 'course_types'
  ],
  components: {
    cropUpload,
    //Wysiwyg
  },
  data() {
    return {
      current_markdown: '',
      current_markdown_section: '',
      current_markdown_content: '',

      popupActivo: false,
      wysiwyg_show: false,
      is_mounted: false,
      langExtPragraphImage: {
        hint: this.$t("modules.package.Click or drag the file here to upload"),
        loading: this.$t("modules.package.Uploading…"),
        noSupported: this.$t("modules.package.Browser is not supported, please use IE10+ or other browsers"),
        success: this.$t("modules.package.Upload success"),
        fail: this.$t("modules.package.Upload failed"),
        preview: this.$t("modules.package.Preview"),
        btn: {
          off: this.$t("modules.package.Cancel"),
          close: this.$t("modules.package.Close"),
          back: this.$t("modules.package.Back"),
          save: this.$t("modules.package.Save"),
        },
        error: {
          onlyImg: this.$t("modules.package.Image only"),
          outOfSize: this.$t("modules.package.Image exceeds size limit"),
          lowestPx: this.$t("modules.package.Image's size is too low. Expected at least"),
        }
      },

      active: false,
      type_code: '',
      course_type: '',
      answering_types: [
        {value: 1, text: 'MCQ (single answer)'},
        {value: 2, text: 'MCQ (multiple answer)'},
        {value: 3, text: 'True or False'},
        {value: 4, text: 'Highlighting'},
        {value: 5, text: 'Fill in the blanks'}
      ],
      apiBaseUrl: config.apiBaseUrl
    }
  },
  created() {


    this.preAdjustMCQSingle()
  },
  mounted() {
    this.is_mounted = true
  },
  computed: {
    imageUploadHeaders() {
      return {Authorization: this.$store.state.token}
    }
  },
  methods: {
    triggerWindowResize() {
      console.log('mt')
      windowUpdater()
    },
    handleParagraphImageSuccess(paragraph, event) {
      paragraph.paragraph_image = event.image_name
      paragraph.paragraph_image_url = event.image_url
      this.notifyUI('Sucess', this.$t('Image uploaded successfully'), 'success')
    },

    preAdjustMCQSingle() {
      for (let c = 0; c < this.section.contents.length; c++) {
        const currContent = this.section.contents[c]
        for (let q = 0; q < currContent.questionnaire.length; q++) {
          const currQuestion = currContent.questionnaire[q]

          //if mcq single
          if (currQuestion.answering_type == 1) {
            const correctIndex = parseInt(currQuestion.mcq_single_answer_index)

            if (correctIndex) {
              const mcqItem = currQuestion.mcq[correctIndex]
              //for auto update on other lang
              this.section.contents[c].questionnaire[q].mcq_single_answer = {
                en: mcqItem.choice['en'],
                zh: mcqItem.choice['zh']
              }
              this.uiUpdateSection()
              this.triggerWindowResize()
            }
          }
        }
      }
    },

    updateSingleMCQId(sectionIndx, contentIndx, questionIndx, mcqIndx, mcqItem) {
      this.section.contents[contentIndx].questionnaire[questionIndx].mcq_single_answer_index = mcqIndx
      //for auto update on other lang
      this.section.contents[contentIndx].questionnaire[questionIndx].mcq_single_answer = {
        en: mcqItem.choice['en'],
        zh: mcqItem.choice['zh']
      }
      this.uiUpdateSection()
      this.triggerWindowResize()

    },
    delSection() {
      this.$emit('delete-section', this.sectionIndex)
      this.triggerWindowResize()

    },
    delParagraph(index) {
      this.section.contents.splice(index, 1)
      this.triggerWindowResize()

    },
    addPara() {
      this.section.contents.push(this.getDefaultPara())
      this.triggerWindowResize()

    },
    getDefaultPara() {
      return {
        paragraph: {
          does_disappears: false,
          disappears_after: 0,
          course_type: '',
          title: {
            en: '',
            zh: ''
          },
          instruction: {
            en: '',
            zh: ''
          },
          body: {
            en: '',
            zh: ''
          },
          has_image: false,
          paragraph_image: '',
          paragraph_image_url: ''
        },
        questionnaire: []
      }
    },

    getQuestionAnswerType(que) {
      let label = ''

      switch (que.answering_type) {
        case 1:
          label = 'MCQ (Single Choice)'
          break
        case 2:
          label = 'MCQ (Multiple Choice)'
          break
        case 3:
          label = 'True/False'
          break
        case 4:
          label = 'Highlight'
          break
        case 5:
          label = 'Fill in Blank'
          break
      }

      return label

    },
    addQuestion(content) {
      content.questionnaire.push(this.getDefaultQuestion())
      this.$emit('update:section', this.section)
      this.triggerWindowResize()

    },
    delQuestion(content, index) {
      content.questionnaire.splice(index, 1)
      this.$emit('update:section', this.section)
      this.triggerWindowResize()

    },
    //Mcq
    delMcqChoice(mcq, index) {
      mcq.splice(index, 1)
      this.$emit('update:section', this.section)
      this.triggerWindowResize()

    },
    addMcqChoice(mcq) {
      mcq.push(this.getDefaultMcqChoice())
      this.$emit('update:section', this.section)
      this.triggerWindowResize()

    },

    //Tf
    delTFChoice(tf, index) {
      tf.splice(index, 1)
      this.$emit('update:section', this.section)
      this.triggerWindowResize()

    },
    addTFChoice(tf) {
      tf.push(this.getDefaultTorF())
      this.$emit('update:section', this.section)
      this.triggerWindowResize()

    },

    //HIGHLIGHT
    delHighlightWord(hl, index) {
      hl.markers.splice(index, 1)

      this.$emit('update:section', this.section)
      this.triggerWindowResize()

    },
    addHighlightWord(hl) {
      hl.markers.push(this.getDefaultHighlightMarker())
      this.$emit('update:section', this.section)
      this.triggerWindowResize()

    },

    //FILL BLANKS
    delBlank(fill, index) {
      fill.fills.splice(index, 1)
      this.$emit('update:section', this.section)
      this.triggerWindowResize()

    },
    addBlank(fill) {
      fill.fills.push(this.getDefaultBlank())
      this.$emit('update:section', this.section)
      this.triggerWindowResize()

    },


    getDefaultQuestion() {
      return {
        answering_type: 0,
        body: {
          en: '',
          zh: ''
        },
        type_code: '',
        thinking_type_code: '',
        problem_solving_code: '',
        answer_explanation: {
          en: '',
          zh: ''
        },

        mcq_single_answer: {
          en: '',
          zh: ''
        }, //for single MCQ only
        mcq_single_answer_index: 0,
        mcq: [
          {
            choice: {en: '', zh: ''},
            is_correct: false,

            marks: '',
            weight: ''
          }
        ],

        true_or_false: [
          {
            statement: {en: '', zh: ''},
            is_true: false,

            marks: '',
            weight: ''
          }
        ],
        highlight: {
            canvas: {en: '', zh: ''},
            markers: [],
        },
        blank: {
            phrase: {en: '', zh: ''},
            fills: [],
        }
      }
    },

    getDefaultMcqChoice() {
      return {
        choice: {en: '', zh: ''},
        is_correct: false,
        marks: '',
        weight: ''
      }
    },
    getDefaultBlank() {
      return {
        en: '',
        zh: '',
        marks: '',
        weight: ''
      }
    },
    getDefaultHighlightMarker() {
      return {
        en: '',
        zh: '',
        marks: '',
        weight: ''
      }
    },
    getDefaultTorF() {
      return {
        statement: {en: '', zh: ''},
        marks: '',
        weight: '',
        is_true: false
      }
    },





    paragraphImageUploadSuccess() {

    },

    uiUpdateSection:  _.throttle(function() {
      this.$emit('update:section', this.section) // <-- this is defined
    }, 400)

  },
  updated() {
    this.uiUpdateSection()
    this.triggerWindowResize()
  }
}
</script>

<style>
.aliceSelect {
  background: aliceblue;
  height: 30px;
  font-size: medium;
  padding-left: 10px;
}
.ckd-box .v-optgroup { background-color: rgb(35, 30, 73)!important; color: rgb(255, 255, 255)!important; }
</style>
