<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vx-card>

    <!-- Content Row -->
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <vs-input class="w-full mt-4 required" :label="$t('modules.role.Role Name')"
                  v-model="formData['name']"
                  v-validate="'required'"/>

        <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first("name") }}</span>

        <div class="mt-4">
          <label class="text-sm" style="margin-left: 4px;">{{$t('modules.role.Status')}}</label>
          <vs-switch color="success" v-model="formData['status']">
            <span slot="on">{{$t('modules.profile.Active')}}</span>
            <span slot="off">{{$t('modules.profile.InActive')}}</span>
          </vs-switch>
        </div>
      </div>
    </div>

    <vx-card class="mt-base" no-shadow card-border>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon svgClasses="w-6 h-6" icon="LockIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none">{{$t('modules.role.Permissions')}}</span>
          </div>
          <vs-divider />
        </div>
      </div>

      <div class="block overflow-x-auto">
        <table class="w-full">

              <tr class="mb-2">
                <th class="font-semibold text-base text-left px-3 py-2">
                  {{$t('modules.role.Module')}}
                </th>

                <th class="font-semibold text-base text-left px-3 py-2" v-for="heading in ['list', 'create', 'edit', 'delete']" :key="heading">
                    {{ ucfirst(heading) }}

                </th>
              </tr>

              <tr>
                <td>
                  <vs-divider class="block w-full"> {{$t('modules.role.Select All')}} </vs-divider>
                </td>
                <td class="font-semibold text-base text-left px-3 py-2" v-for="heading in ['list', 'create', 'edit', 'delete']" :key="heading">
                  <vs-checkbox @change="mark($event, formData['permissions'], heading)" />
                </td>
              </tr>


              <tr v-for="mod in Object.keys(getDefaultConfig ())" :key="mod">
                <td class="px-3 py-2">{{formData['permissions'][`${mod}`]['label']}}</td>

                <td class="px-3 py-2">
                  <vs-checkbox v-model="formData['permissions'][`${mod}`]['access']['list']" />
                </td>

                <td class="px-3 py-2">
                  <vs-checkbox v-model="formData['permissions'][`${mod}`]['access']['create']" />
                </td>

                <td class="px-3 py-2">
                  <vs-checkbox v-model="formData['permissions'][`${mod}`]['access']['edit']" />
                </td>

                <td class="px-3 py-2">
                  <vs-checkbox v-model="formData['permissions'][`${mod}`]['access']['delete']" />
                </td>

              </tr>

        </table>
      </div>


    </vx-card>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" :disabled="!validateForm" @click="save" >{{$t('modules.role.Save Changes')}}</vs-button>
          <vs-button class="ml-4 mt-2" v-if="reset_btn" type="border" color="warning" @click="reset">{{$t('modules.role.Reset')}}</vs-button>
          <vs-button class="ml-4 mt-2" v-if="cancel_btn" type="border" color="warning" @click="cancel">{{$t('modules.role.Cancel')}}
          </vs-button>
        </div>
      </div>
    </div>

  </vx-card>
</template>

<script>
// import moduleRole          from '@/store/role/moduleRole.js'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import axios from '@/axios'
import Vue from 'vue'
import _ from 'lodash'

export default {
  components: {
    vSelect,
    flatPickr
  },
  data () {
    return {
      flow_type: '',
      id: 0,
      validateForm: true,
      checkbox_all:{
        list: false,
        create: false,
        delete: false,
        edit: false
      },
      formData: {
        name: '',
        status: 0,
        permissions: this.getDefaultConfig()
      },

      reset_btn: true,
      cancel_btn: false,
      status: false
    }
  },

  created () {

    if (this.$route.params.id) this.flow_type = 'edit'
    else this.flow_type = 'add'

    if (this.flow_type === 'edit') {
      this.getRoleDetail(this.$route.params.id)
      this.id = this.$route.params.id
    }

  },

  methods: {
    getDefaultConfig () {
      return {
        user: {
          name: 'user',
          label: 'User',
          access: { list: false, create: false, edit: false, delete: false }
        },
        country: {
          name: 'country',
          label: 'Country',
          access: { list: false, create: false, edit: false, delete: false }
        },

        city: {
          name: 'city',
          label: 'City',
          access: { list: false, create: false, edit: false, delete: false }
        },

        classes: {
          name: 'classes',
          label: 'classes',
          access: { list: false, create: false, edit: false, delete: false }
        },

        cms: {
          name: 'cms',
          label: 'CMS',
          access: { list: false, create: false, edit: false, delete: false }
        },

        blog: {
          name: 'blog',
          label: 'Blog',
          access: { list: false, create: false, edit: false, delete: false }
        },

       /*  vendor: {
          name: 'vendor',
          label: 'Vendor',
          access: { list: false, create: false, edit: false, delete: false }
        }, */

        role: {
          name: 'role',
          label: 'Role',
          access: { list: false, create: false, edit: false, delete: false }
        },

        role_assignment: {
          name: 'role_assignment',
          label: 'Role Assignment',
          access: { list: false, create: false, edit: false, delete: false }
        },

        setting: {
          name: 'setting',
          label: 'Setting',
          access: { list: false, create: false, edit: false, delete: false }
        },

        general_setting: {
          name: 'general_setting',
          label: 'General Setting',
          access: { list: false, create: false, edit: false, delete: false }
        },

        email_setting: {
          name: 'email_setting',
          label: 'Email Setting',
          access: { list: false, create: false, edit: false, delete: false }
        },

        social_media: {
          name: 'social_media',
          label: 'Social Media',
          access: { list: false, create: false, edit: false, delete: false }
        },


      }
    },
    cancel () {
      this.$router.push('/role').catch(() => { })
    },
    reset () {
      this.$router.push('/role')
    },
    save () {
      if (this.flow_type === 'add') {
        axios.post('/roles/create', this.formData)
          .then((response) => {
            if (response.status === 201) {
              this.notifyUI('Created!', 'Roles has been created', 'success')
              this.$router.push('/role')
            }
          })
          .catch((error) => {
            alert(`Error: ${error}`)
          })
          .then(() => this.$vs.loading.close())
      } else {
        axios.post(`/roles/edit/${this.id}`, this.formData)
          .then((response) => {
            if (response.status === 200) {
             this.notifyUI('Updated!', 'Roles has been updated', 'success')
              this.$router.push('/role')
            }
          })
          .catch((error) => {
            alert(`Error: ${error}`)
          })
          .then(() => this.$vs.loading.close())
      }
    },

    getRoleDetail (id) {
      axios.get(`/roles/${id}`)
        .then((response) => {
          if (response.status === 200) {
            const form = {}
            form.name = response.data.name
            form.status = response.data.status
            form._id = response.data._id

            const perm = {}
            //process json for proper format
            response.data.permission.forEach((item) => {
              perm[item.module_name] = {
                name: item.module_name,
                label: item.label,
                access: item.access
              }
            })

            form.permissions = { ...this.formData.permissions, ...perm }
            this.formData = form
          }
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },

    mark (e, obj, crud) {
      const value = e.target.checked
      _.set(obj, `user.access.${crud}`, value)
      _.set(obj, `country.access.${crud}`, value)
      _.set(obj, `city.access.${crud}`, value)
      _.set(obj, `classes.access.${crud}`, value)
      _.set(obj, `cms.access.${crud}`, value)
      _.set(obj, `blog.access.${crud}`, value)
      _.set(obj, `role.access.${crud}`, value)
      _.set(obj, `role_assignment.access.${crud}`, value)
      _.set(obj, `setting.access.${crud}`, value)
      _.set(obj, `general_setting.access.${crud}`, value)
      _.set(obj, `social_media.access.${crud}`, value)
      _.set(obj, `email_setting.access.${crud}`, value)

    }

  }

}
</script>
