<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div>
    <vs-row class="bg-white my-4">
      <vs-col class="flex justify-items-center p-4">
        <h2 class="text-black"></h2>
        <vs-button class="mr-4" color="primary" type="filled" vs-align="right" @click="refreshData()">
          {{ $t("modules.questionnaires.Refresh") }}
        </vs-button>
        <vs-button class="mr-4" color="primary" type="filled" vs-align="right" @click="exportData()">
          {{ $t("modules.questionnaires.Export") }}
        </vs-button>

      </vs-col>
    </vs-row>

    <!-- SEARCH -->
    <div class="bg-white my-4">
      <vs-row>
        <vs-col class="flex justify-items-center p-4" vs-w="12">
          <h2 class="text-black text-sm">{{ $t("modules.questionnaires.Search Filter") }}</h2>
        </vs-col>
      </vs-row>

      <vs-row>
        <!-- <vs-col class="justify-items-center p-4 xs:w-full" vs-w="4" vs-xs="12">
          <vs-input class="inputx sm:w-full" placeholder="Name"
                    @keyup="handleNameSearch" v-model="name" />
        </vs-col> -->

        <vs-col class="justify-items-center p-4 xs:w-full" vs-w="4" vs-xs="12">
          <vs-input class="inputx sm:w-full" placeholder="Ticket ID"
                    @keyup="handleTicketIDSearch" v-model="ticket_id" />
        </vs-col>
        <vs-col class="justify-items-center p-4 xs:w-full" vs-w="4" vs-xs="12">
          <vs-input class="inputx sm:w-full" placeholder="Title"
                    @keyup="handleTitleSearch" v-model="title" />
        </vs-col>
      </vs-row>
    </div>

    <!-- TABLE -->
      <vx-card>
        <vs-table :data="pageData.items" :sst="true" ref="table" :key="renderKey"
                  @search="handleSearch" @change-page="handleChangePage" @sort="handleSort"
                  :total="pageData.total"
                  :max-items="pageData.page_size" noDataText="" stripe pagination search> 

      <template slot="thead">
        <vs-th sort-key="id">{{$t('modules.helpsupport.ID')}}</vs-th>
        <!-- <vs-th sort-key="name">{{$t('modules.helpsupport.Name')}}</vs-th> -->
        <vs-th sort-key="title">{{$t('modules.helpsupport.Title')}}</vs-th>
        <vs-th sort-key="created_at">{{$t('modules.helpsupport.Date')}}</vs-th>
        <!-- <vs-th sort-key="status">{{$t('modules.helpsupport.Status')}}</vs-th> -->
        <vs-th>{{$t('modules.helpsupport.Action')}}</vs-th>
      </template>
      <template>
          <vs-tr :key="indextr" v-for="(tr, indextr) in pageData.items">
            <vs-td :data="pageData.items[indextr].ticket_id">
              {{ pageData.items[indextr].ticket_id }}
            </vs-td>

            <!-- <vs-td :data="pageData.items[indextr].history[0].teacher_id.first_name">
              {{ pageData.items[indextr].history[0].teacher_id.first_name }}
            </vs-td> -->

            <vs-td :data="pageData.items[indextr].title">
              {{ pageData.items[indextr].title }}
            </vs-td>

            <vs-td :data="pageData.items[indextr].createdAt">
              {{ date_format(pageData.items[indextr].createdAt) }}
            </vs-td>

            <!-- <vs-td :data="pageData.items[indextr].status">
              <vs-chip v-if="pageData.items[indextr].status==0" :color="'#d9534f'" class="product-order-status">{{$t('modules.profile.InActive')}}</vs-chip>
              <vs-chip v-if="pageData.items[indextr].status==1" :color="'#5cb85c'" class="product-order-status">{{$t('modules.profile.Active')}}</vs-chip>
            </vs-td> -->

            <vs-td class="whitespace-no-wrap">
              <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current edit_delete" @click.stop="$router.push('/provider-feedback/view/'+tr._id).catch(() => {})" color="danger" type="border" />
              <!-- <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current edit_delete" class="ml-2" @click.stop="deletegrade(tr._id)" /> -->
            </vs-td>
          </vs-tr>
        </template>
      <template #footer>
        <vs-pagination :total="pageData.total_pages" v-model="pageData.page" :key="renderKey" />
      </template>
      </vs-table>

    </vx-card>
  </div>

</template>

<script>
import Vue from 'vue'
import axios from '@/axios'

export default {
  data () {
    // const locales = getSupportedLocales().map((el) => {
    //   return { text: el.name, value: el.code }
    // })
    return {
      renderKey: 2,
      formData: {},
      pageData: {},
      is_loaded: false,
      selected: {},
      keyword: '',
      name: '',
      title: '',
      ticket_id: '',
      sort_by: '',
      ordering: '',
      page: 1,
      limit: 3,
      total_results: 0,
      popupActive: false
      // locales
    }
  },
  components: { },
  methods: {
  //   updateLocale (locale) {
  //    this.$i18n.locale = locale
  //  },
    popupDetails(id){
      this.getHelpDetail(id)
    },
    handleSearch (searching) {
      this.page = 1
      this.keyword = searching
      this.fetch_feedback()
    },

    handleSort (col, ord) {
      this.sort_by = col
      this.ordering = ord
      this.fetch_feedback()
    },

    handleChangePage (page) {
      this.page = page
      this.fetch_feedback()
    },
    handleNameSearch () {
      this.page = 1 //reset page if criteria changed
      this.fetch_feedback()
    },
    handleTicketIDSearch() {
      this.page = 1 //reset page if criteria changed
      this.fetch_feedback()
    },
    handleTitleSearch() {
      this.page = 1 //reset page if criteria changed
      this.fetch_feedback()
    },

    refreshData () {
      this.name = ''
      this.title = ''
      this.fetch_feedback()
    },

    // deleteHelpSupport (id) {
    //   this.$vs.loading()
    //   axios.delete(`/provider-feedbacks/${id}`)
    //     .then((response) => {
    //       if (response.status === 200) {
    //          this.notifyUI('Deleted!', 'Blog has been deleted', 'success')
    //           this.fetch_feedback()
    //       }
    //     })
    //     .catch((error) => {
    //       alert(`Error: ${error}`)
    //     })
    //     .then(() => this.$vs.loading.close())
    // },

    date_format (date) {
      const d = new Date(date)
      return d.toDateString()
    },

    //Fecth from API
    fetch_feedback () {
      this.$vs.loading()

      axios.get('/provider-feedbacks', {
        params: {
          name: this.name,
          ticket_id: this.ticket_id,
          title: this.title,
          keyword: this.keyword,
          sort_by: this.sort_by,
          ordering: this.ordering,
          page: this.page,
          limit: this.limit
        }
      })
        .then((response) => {
          Vue.set(this, 'pageData', response.data)
          this.is_loaded = true
          if (this.page == 1) this.renderKey = Math.random()
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    }

  },
  created () {
    this.fetch_feedback()
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
  .vs-row{
    display: block !important;
  }
  .vs-con-input-label{
    width: 100%;
  }
</style>
