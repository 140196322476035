<template>
    <div>{{category_name(this.params.value)}}</div>
</template>

<script>
export default {
  name: 'CellRendererCategory',
  computed: {
  },
  methods: {
    category_name (categoryId) {
      return this.$store.getters['category/getCategoryName'](categoryId)
    },
  },
  created () {
  },
}
</script>
