<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vx-card>

    <!-- Content Row -->
    <div class="vx-row">

      <div class="vx-col md:w-1/2 w-full">
        <vs-dropdown>
          <a class="a-icon" href.prevent>
            Content Language
            <vs-icon class="" icon="expand_more"></vs-icon>
          </a>

          <vs-dropdown-menu>

            <vs-dropdown-item @click="currLang='en'">
              {{ $t("English") }}
              <country-flag country="gb" size="normal" />
            </vs-dropdown-item>
            <vs-dropdown-item @click="currLang='zh'">
              {{ $t("Chinese") }}
              <country-flag country="cn" size="normal" />
            </vs-dropdown-item>

          </vs-dropdown-menu>

        </vs-dropdown>

        <span class="text-md align-middle float-right flex">
          <span class="align-middle">{{ getCountryLang }}</span>
          <country-flag class="ml-1" :country="getCountryFlag" size="normal" />
        </span>
      </div>

      <div class="vx-col w-full">
    <div>
       <label class="text-sm">{{$t('modules.language.Language Name')}} <span class="text-danger"> * </span></label>
        <vs-input class="w-full required"  
                  v-model="formData.translations[currLang]['lang_name']"
                  v-validate="'required'"/>

        <span class="text-danger text-sm" v-show="errors.has('lang_name')">{{ errors.first("lang_name") }}</span>
</div>
<div>
<label class="text-sm">{{$t('modules.language.Code')}} <span class="text-danger"> * </span></label>
        <vs-input class="w-full required" 
                  v-model="formData['lang_code']"
                  v-validate="'required'"/>

        <span class="text-danger text-sm" v-show="errors.has('lang_code')">{{ errors.first("lang_code") }}</span>
        </div>
       <!--
        <vs-input class="w-full mt-4 required" :label="$t('modules.language.Is_Rtl')" 
                  v-model="formData['is_rtl']"
                  v-validate="'required'"/>

        <span class="text-danger text-sm" v-show="errors.has('is_rtl')">{{ errors.first("is_rtl") }}</span>
         -->

         <div class="mt-6 centerx w-full vx-row">
          <label class="text-sm my-4 my-1">{{$t('modules.language.Is_Rtl')}}</label>
          <vs-switch color="success my-3 mx-4" v-model="formData['is_rtl']" vs-value="1">
            <span slot="on">Yes</span>
            <span slot="off">No</span>
          </vs-switch>
        </div>

        <div class="mt-6 centerx w-full vx-row">
          <label class="text-sm my-4 my-1">{{$t('modules.language.Status')}}</label>
          <vs-switch color="success my-3 mx-4" v-model="formData['status']" vs-value="1">
            <span slot="on">{{$t('modules.profile.Active')}}</span>
            <span slot="off">{{$t('modules.profile.InActive')}}</span>
          </vs-switch>
        </div>

      </div>
    </div>


    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" :disabled="!validateForm" @click="save" >{{$t('modules.language.Save Changes')}}</vs-button>
          <vs-button class="ml-4 mt-2" v-if="reset_btn" type="border" color="warning" @click="reset">{{$t('modules.language.Reset')}}</vs-button>
          <vs-button class="ml-4 mt-2" v-if="cancel_btn" type="border" color="warning" @click="cancel">{{$t('modules.language.Cancel')}}
          </vs-button>
        </div>
      </div>
    </div>

  </vx-card>
</template>

<script>
// import moduleRole          from '@/store/role/moduleRole.js'
import vSelect from 'vue-select'
import axios from '@/axios'
import Vue from 'vue'
import CountryFlag from 'vue-country-flag'
Vue.component('country-flag', CountryFlag)

export default {
  components: {
    vSelect, CountryFlag
  },
  data () {
    return {
      formData: {
        // lang_name: '',
        lang_code: '',
        is_rtl: '',
        status: '0',
        translations: {
          // fill obj array with default values
          en: { ...this.getDefaults() },
          zh: { ...this.getDefaults() }
        }
      },
      colorx:'#103767',
      reset_btn: true,
      cancel_btn: false,
      validateForm: true,
      flow_type: '',
      currLang: 'en'

    }
  },

  computed: {
    validateForm () {
      return !this.errors.any() && this.lang_name !== '' && this.lang_code !== '' && this.is_rtl !== ''
    },
    getCountryFlag () {
      if (this.currLang == 'en') {
        return 'gb'
      }
      if (this.currLang == 'zh') {
        return 'cn'
      }
    },

    getCountryLang () {
      if (this.currLang == 'en') {
        return this.$t('English')
      }
      if (this.currLang == 'zh') {
        return this.$t('Chinese')
      }
    }
  },

  created () {

       if (this.$route.params.id) {
      this.flow_type = "edit";
       this.reset_btn= false
       this.cancel_btn= true
    } else {
      this.flow_type = "add";
       this.cancel_btn= true
       this.reset_btn= true
    }

    if (this.$route.params.id) this.flow_type = 'edit'
    else this.flow_type = 'add'

    if (this.flow_type === 'edit') {
      this.getLanguageDetail(this.$route.params.id)
      this.id = this.$route.params.id
    }

    this.getCountries()
  },

  methods: {
    getDefaults () {
      return {
        lang_name: ''
      }
    },
    cancel () {
      this.$router.push('/languages').catch(() => { })
    },
    reset () {
       this.formData= { }
      //this.$router.push('/languages')
    },
    save () {
      const form = this.processForm()
      if (this.flow_type === 'add') {
        axios.post('/languages', form)
          .then((response) => {
            if (response.status === 201) {
              this.notifyUI('Created!', 'Language has been created', 'success')
              this.$router.push('/languages')
            }
          })
          .catch((error) => {
         this.displayErrors(error)
          })
          .then(() => this.$vs.loading.close())
      } else {
        axios.put(`/languages/${this.id}`, form)
          .then((response) => {
            if (response.status === 200) {
              this.$router.push('/languages')
              this.notifyUI('Updated!', 'Language has been updated', 'success')
            }
          })
          .catch((error) => {
               this.displayErrors(error)
          })
          .then(() => this.$vs.loading.close())
      }
    },

    getLanguageDetail (id) {
      axios.get(`/languages/${id}`)
        .then((response) => {
          if (response.status === 200) {
            this.formData = response.data
          }
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },

    getlanguages () {
      axios.get('/languages', { params: {limit: 1000} })
        .then((response) => {
          if (response.status === 200) {
            //need format [{text: 'Square', value: 1}, ...],
            this.languages = response.data.items.map(item => {
              return {text: item.lang_name, value: item._id}
            })
          }
        })
        .catch((error) => {
          //alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },

    processForm () {
      const form = {en: {}, zh: {}}

      //LANG BASED
      const lf = Object.keys(this.getDefaults()) //['name', 'slug', 'content', 'status']
      lf.forEach((item) => {
        //2-D
        form.en[item] = this.formData.translations['en'][item]
        form.zh[item] = this.formData.translations['zh'][item]
      })

      //NON-LANG BASED
      form.lang_code = this.formData.lang_code
      form.is_rtl = this.formData.is_rtl
      form.status = this.formData.status
      return form
    }

  }
}
</script>
