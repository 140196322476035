<template>
    <div>{{organization_name(this.params.value)}}</div>
</template>

<script>
export default {
  name: 'CellRendererOrganization',
  computed: {
  },
  methods: {
    organization_name(organizationId) {
      return this.$store.getters['organization/getOrganizationName'](organizationId)
    },
  },
  created () {
  },
}
</script>
