<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vx-card>

    <!-- Content Row -->
    <div class="vx-row">

      <div class="vx-col md:w-1/2 w-full">
<!--
        <vs-input class="w-full mt-4 "
                  :label="$t('modules.setting.Contact Mail')"  v-model="formData['contact_mail']"    />
        <span class="text-danger text-sm" v-show="errors.has('contact_mail')">{{ errors.first("contact_mail") }}</span>


        <vs-input class="w-full mt-4  action_btn"
                  :label="$t('modules.setting.Support Mail')" v-model="formData['support_mail']"/>
        <span class="text-danger text-sm"  v-show="errors.has('support_mail')">{{ errors.first('support_mail') }}</span>


        <vs-input class="w-full mt-4 action_btn"
                  :label="$t('modules.setting.Mobile')" v-model="formData['mobile_number']"/>
        <span class="text-danger text-sm"  v-show="errors.has('mobile_number')">{{ errors.first('mobile_number') }}</span>


        <vs-input class="w-full mt-4  "
                  :label="$t('modules.setting.Skype')"  v-model="formData['skype']"    />
        <span class="text-danger text-sm" v-show="errors.has('skype')">{{ errors.first("skype")}}</span>
-->
        <div class="mt-4 block">
          <span class="required">
            <label class="text-xs text-black">{{$t('modules.setting.Smtp Enable')}}</label>
          </span>
          <ul class="leftx">
            <li class="modelx">
              <vs-radio v-model="formData['smtp_enable']" vs-value="0">Enable</vs-radio>
            </li>
            <li>
              <vs-radio v-model="formData['smtp_enable']" vs-value="1">Disable</vs-radio>
            </li>
          </ul>
          <span class="text-danger text-sm" v-show="errors.has('smtp_enable')">{{ errors.first("smtp_enable") }}</span>
        </div>

       

        <vs-input class="w-full mt-4 "
                  :label="$t('modules.setting.Mail Driver')" v-model="formData['mail_driver']"    />
        <span class="text-danger text-sm" v-show="errors.has('mail_driver')">{{ errors.first("mail_driver") }}</span>

        <vs-input class="w-full mt-4 "
                  :label="$t('modules.setting.Smpt Host Name')" v-model="formData['smtp_host_name']"    />
        <span class="text-danger text-sm" v-show="errors.has('smtp_host_name')">{{ errors.first("smtp_host_name") }}</span>

        <vs-input class="w-full mt-4 "
                  :label="$t('modules.setting.Smpt User Name')" v-model="formData['smtp_username']"    />
        <span class="text-danger text-sm" v-show="errors.has('smtp_username')">{{ errors.first("smtp_username") }}</span>


        <vs-input class="w-full mt-4 " :label="$t('modules.setting.Smtp Password')" v-model="formData['smtp_password']"    />
        <span class="text-danger text-sm" v-show="errors.has('smtp_password')">{{ errors.first("smtp_password") }}</span>


        <vs-input class="w-full mt-4 "
                  :label="$t('modules.setting.Smtp Port')" v-model="formData['smtp_port']"  />
        <span class="text-danger text-sm" v-show="errors.has('smtp_port')">{{ errors.first("smtp_port") }}</span>

        <vs-input class="w-full mt-4 "
                  :label="$t('modules.setting.Smtp Encryption')" v-model="formData['smtp_encryption']"    />
        <span class="text-danger text-sm" v-show="errors.has('smtp_encryption')">{{ errors.first("smtp_encryption") }}</span>

        <vs-input class="w-full mt-4 "
                  :label="$t('modules.setting.Sms Url')"  v-model="formData['sms_url']"    />
        <span class="text-danger text-sm" v-show="errors.has('sms_url')">{{ errors.first("sms_url") }}</span>

        <vs-input class="w-full mt-4 "
                  :label="$t('modules.setting.Sms Username')" v-model="formData['sms_username']"    />
        <span class="text-danger text-sm" v-show="errors.has('sms_username')">{{ errors.first("sms_username") }}</span>

        <vs-input class="w-full mt-4 "
                  :label="$t('modules.setting.Sms Password')" v-model="formData['sms_password']"    />
        <span class="text-danger text-sm" v-show="errors.has('sms_password')">{{ errors.first("sms_password") }}</span>

        <vs-input class="w-full mt-4 "
                  :label="$t('modules.setting.Sms Sender Id')"  v-model="formData['sms_sender_id']"    />
        <span class="text-danger text-sm" v-show="errors.has('sms_sender_id')">{{ errors.first("sms_sender_id") }}</span>
         <div class="con-select-example">
    <vs-select
      class="selectExample"
      label="SSL Security"
      v-model="ssl"
      >
      <vs-select-item :is-selected.sync="item.isSelected" :key="index" :value="item.value" :text="item.isSelected?item.selectedText:item.label" v-for="item,index in options1" />
    </vs-select>
  </div>

         <div class="mt-6 centerx w-full vx-row">
          <label class="text-sm my-4 my-1">{{$t('modules.setting.Status')}}</label>
          <vs-switch color="success my-3 mx-4" v-model="formData['status']" vs-value="1">
            <span slot="on">{{$t('modules.profile.Active')}}</span>
            <span slot="off">{{$t('modules.profile.InActive')}}</span>
          </vs-switch>
        </div> 

       </div>
       </div>
      

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" @click="save_changes">{{$t('modules.setting.Save Changes')}}</vs-button>
          <vs-button class="ml-4 mt-2" type="border" color="warning" @click="cancel">{{$t('modules.setting.Cancel')}}</vs-button>
        </div>
      </div>
    </div>

  </vx-card>
</template>

<script>
// import moduleSetting          from '@/store/setting/moduleSetting.js'
import vSelect from 'vue-select'
import axios from "@/axios";

export default {
  data () {
    return {
      formData: {},
     /*  add_image_before: false,
      add_image_after: true,
      add_icon_before: false,
      add_icon_after: true,
      iconUpload: 1,
      imageUpload: 1,
      previewFavIcon: '',
      previewLogo: '' */
      ssl:3,
      options1:[
        {value: 1, label: 'SSL', selectedText: 'SSL', isSelected: false },
        {value: 2, label: 'TLS', selectedText: 'TLS', isSelected: false },
      ],
    }
  },
  components: {
    vSelect
  },

  methods: {
    cancel () {
      this.$router.push('/dashboard').catch(() => { })
    },
    save_changes () {
      this.$validator.validateAll().then(result => {

        if (result) {
          this.save()
        }
      })
    },

    save () {
     // const form = this.processForm()
      //console.log(form)
      axios.put('/settings/email', this.formData)
        .then((response) => {
          console.log(response)
          if (response.status === 200) {
            this.notifyUI('Updated!', 'Email Settings has been updated', 'success')
          }
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },

   /*  handleLogo (e) {
      const image = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = e => {
        this.previewLogo = e.target.result
        console.log(this.previewImage)
      }
    },

    handleFavIcon (e) {
      const image = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = e => {
        this.previewFavIcon = e.target.result
        console.log(this.previewImage)
      }
    }, */

    processForm () {
      const form = new FormData()
      form.append('contact_mail', this.formData['contact_mail']) //manually append name
      form.append('support_mail', this.formData['support_mail']) //manually append slug
      form.append('mobile_number', this.formData['mobile_number']) //manually append category
      form.append('skype', this.formData['skype']) //manually append visibility
      form.append('smtp_enable', this.formData['smtp_enable']) //manually append visibility
      form.append('mail_driver', this.formData['mail_driver']) //manually append visibility
      form.append('smtp_host_name', this.formData['smtp_host_name']) //manually append visibility
      form.append('smtp_username', this.formData['smtp_username']) //manually append visibility
      form.append('smtp_password', this.formData['smtp_password']) //manually append visibility
      form.append('smtp_port', this.formData['smtp_port']) //manually append visibility
      form.append('smtp_encryption', this.formData['smtp_encryption']) //manually append visibility
      form.append('status', this.formData['status']) //manually append visibility
      form.append('sms_url', this.formData['sms_url']) //manually append visibility
      form.append('sms_username', this.formData['sms_username']) //manually append visibility
      form.append('sms_sender_id', this.formData['sms_sender_id']) //manually append visibility
      
      

      return form
    },

    getEmailSetting () {
      axios.get('/settings/email')
        .then((response) => {
          if (response.status === 200) {
            this.formData = response.data
           // this.previewLogo = this.formData.logo
           // this.previewFavIcon = this.formData.favicon
          }
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    }

  },
  created () {
    this.getEmailSetting()
  },
  beforeDestroy () {
    // this.$store.unregisterModule('setting')
  }
}
</script>
<style>
.favicon_url {
  position: relative;
  top: 12px;
}
</style>
