<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vx-card>

    <!-- Content Row -->
    <div class="vx-row">
      <!-- <div class="vx-col md:w-1/2 w-full">
        <vs-dropdown>
          <a class="a-icon" href.prevent>
            Content Language
            <vs-icon class="" icon="expand_more"></vs-icon>
          </a>

          <vs-dropdown-menu>

            <vs-dropdown-item @click="currLang='en'">
              {{ $t("English") }}
              <country-flag country="gb" size="normal" />
            </vs-dropdown-item>
            <vs-dropdown-item @click="currLang='zh'">
              {{ $t("Chinese") }}
              <country-flag country="cn" size="normal" />
            </vs-dropdown-item>

          </vs-dropdown-menu>

        </vs-dropdown>

        <span class="text-md align-middle float-right flex">
          <span class="align-middle">{{ getCountryLang }}</span>
          <country-flag class="ml-1" :country="getCountryFlag" size="normal" />
        </span>
      </div> -->

      <div class="vx-col w-full">

        <div class="mt-4">
          <label class="text-sm">Title<span class="text-danger"> * </span></label>
        
            <vs-input class="w-full mt-4 required" v-model="formData['title']" v-validate="'required'" min-length="3" />

        <span class="text-danger text-sm" v-show="errors.has('title')">{{ errors.first("title") }}</span>
        </div>

        <div class="mt-4">
          <label class="text-sm">Description<span class="text-danger"> * </span></label>          
          <vs-input class="w-full mt-4 required" v-model="formData['description']" />
          <span class="text-danger text-sm" v-show="errors.has('description')">{{ errors.first("description") }}</span>
        </div>

        <div class="mt-4">
          <label class="text-sm">Visibility<span class="text-danger"> * </span></label>
          <vs-input class="w-full mt-4 required" v-model="formData['status']" />
          <span class="text-danger text-sm" v-show="errors.has('status')">{{ errors.first("status") }}</span>
          <!-- <v-select
                  :loading="inputLoading"
                  :items="status"
                  :error-messages="errors"
                  label="Visibilty"
                  :value="formData.status"
                  v-model="formData.status"
                ></v-select> -->
     
        </div>

        <!-- <div class="mt-4">
          <label class="text-sm">Visibility<span class="text-danger"> * </span></label>
          <vs-input class="w-full mt-4 required" v-model="formData['status']" />
          <span class="text-danger text-sm" v-show="errors.has('status')">{{ errors.first("status") }}</span>     
        </div> -->


        <div class="mt-4">
          <label class="text-sm">Meta Title<span class="text-danger"> * </span></label>  
             <vs-input class="w-full mt-4 required" v-model="formData['meta_title']" />
            <span class="text-danger text-sm" v-show="errors.has('meta_title')">{{ errors.first("meta_title") }}</span>
        </div>

         <div class="mt-4">
          <label class="text-sm">Meta Keyword<span class="text-danger"> * </span></label>  
             <vs-input class="w-full mt-4 required" v-model="formData['meta_keyword']" />
            <span class="text-danger text-sm" v-show="errors.has('meta_keyword')">{{ errors.first("meta_keyword") }}</span>
        </div>

        <div class="mt-4">
          <label class="text-sm">Meta Description<span class="text-danger"> * </span></label>  
            <vs-textarea class="h-20"  v-model="formData['meta_description']" />
            <span class="text-danger text-sm" v-show="errors.has('meta_description')">{{ errors.first("meta_description") }}</span>
        </div>

        <div class="mt-4 centerx w-full">
          <div>
          <input class="w-100 vx-row mx-1" type="file" name="image" accept="image/*" @change="handleImage" ref="image">
          <!--          <img :src="previewImage" alt="" class="">-->
          <!-- <vs-image  :src="previewImage" /> -->
          <v-img style="width:35%" :src="previewImage"></v-img>
          <!--   <vs-upload action="https://jsonplaceholder.typicode.com/posts/" @on-success="successUpload" />-->
          </div>
        </div>


        <div  style="margin-top:20px">
          <!-- <label class="text-sm mt-4 my-1 mx-4">Is Approved</label>
          <vs-switch color="success my-3 mx-4" v-model="formData['is_approved']" vs-value="1">
            <span slot="on">Approved</span>
            <span slot="off">Not Approved</span>
          </vs-switch> -->

          <div class="centerx w-full vx-row"> Is Approved </div>
          <ul class="leftx">
            <li>
              <vs-radio color="success" v-model="formData['is_approved']" vs-value="1">Approved</vs-radio>
            </li>
            <li>
              <vs-radio color="danger" v-model="formData['is_approved']" vs-value="0">Not Approved</vs-radio>
            </li>
          </ul>
        </div>

      </div>
    </div>


    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" :disabled="!validateForm" @click="save" >{{$t('modules.grade.Save Changes')}}</vs-button>
          <vs-button class="ml-4 mt-2" v-if="reset_btn" type="border" color="warning" @click="reset">{{$t('modules.grade.Reset')}}</vs-button>
          <vs-button class="ml-4 mt-2" v-if="cancel_btn" type="border" color="warning" @click="cancel">{{$t('modules.profile.Cancel')}}
          </vs-button>
        </div>
      </div>
    </div>

  </vx-card>
</template>

<script>
// import moduleRole          from '@/store/role/moduleRole.js'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import axios from '@/axios'
import Vue from 'vue'
import CountryFlag from 'vue-country-flag'
Vue.component('country-flag', CountryFlag)

export default {
  components: {
    vSelect,
    flatPickr,
    CountryFlag
  },
  data () {
    return {
      formData: {
        title: '',
        description: '',
        category: '',
        visiblity: '',
        meta_title: '',
        meta_keyword: '',
        meta_description: ''
      },
      visiblity: '',
      colorx:'#103767',
     // categories:[{text: 'category name', value: 1}],
      reset_btn: true,
      cancel_btn: false,
      previewImage: '',
      flow_type: '',
      currLang: 'en'
    }
  },

  created () {
    if (this.$route.params.id) this.flow_type = 'edit'
    else this.flow_type = 'add'
          this.cancel_btn= true
    if (this.flow_type === 'edit') {
      this.getBlogDetail(this.$route.params.id)
      this.id = this.$route.params.id
       this.reset_btn= false
       this.cancel_btn= true
    }

    //this.getBlogCategories()
  },

  computed: {
    validateForm () {
      return true;//!this.errors.any() && this.name !== '' && this.slug !== '' && this.content!==''
    },
    getCountryFlag () {
      if (this.currLang == 'en') {
        return 'gb'
      }
      if (this.currLang == 'zh') {
        return 'cn'
      }
    },

    getCountryLang () {
      if (this.currLang == 'en') {
        return this.$t('English')
      }
      if (this.currLang == 'zh') {
        return this.$t('Chinese')
      }
    }
  },

  methods: {

    // getBlogCategories () {
    //   axios.get('/categories', { params: {limit: 1000} })
    //     .then((response) => {
    //       if (response.status === 200) {
    //         //need format [{text: 'Square', value: 1}, ...],
    //         this.categories = response.data.items.map(item => {
    //           return {text: item.name, value: item._id}
    //         })
    //       }
    //     })
    //     .catch((error) => {
    //     //  alert(`Error: ${error}`)
    //        this.displayErrors(error)
    //     })
    //     .then(() => this.$vs.loading.close())
    // },

    getDefaults () {
      return {
        name: '',
        content: ''
      }
    },
    cancel () {
      this.$router.push('/video').catch(() => { })
    },
    // reset () {
    //   //this.$router.push('/blog')

    //     this.formData=
    //     {
    //           // category:'' ,
    //           visibility: '1'
    //      }

    // },
    save () {


      // if (this.flow_type === 'add') {
      //   axios.post('/videos', this.formData)
      //     .then((response) => {
      //       if (response.status === 201) {
      //         this.notifyUI('Created!', 'Video has been created', 'success')
      //          this.$router.push('/video')
      //       }
      //     })
      //     .catch((error) => {
      //      this.displayErrors("Required All Fields!")
      //     })
      //     .then(() => this.$vs.loading.close())
      // } else {

        console.log("sample dadad", this.formData)


        axios.put(`/videos/${this.id}`, this.formData)
          .then((response) => {
            if (response.status === 200) {
              this.notifyUI('Updated!', 'Video has been updated', 'success')
              this.$router.push('/video')
            }
          })
          .catch((error) => {
           this.displayErrors (error);
          })
          .then(() => this.$vs.loading.close())
      // }
    },

    getBlogDetail (id) {
      axios.get(`/videos/${id}`)
        .then((response) => {
          if (response.status === 200) {
            this.formData = response.data
            this.previewImage = this.formData.thumbnailUrl
            this.visiblity = this.formData.status
          }
        })
        .catch((error) => {
          alert(`Error: ${error}`)
        })
        .then(() => this.$vs.loading.close())
    },

    handleImage (e) {
      const image = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = e => {
        this.previewImage = e.target.result
        console.log(this.previewImage)
      }
    },

    processForm () {
      const form = new FormData()

      form.append('name', this.formData.name)
      form.append('content', this.formData.content)
      form.append('slug', this.formData.slug)
      // form.append('status', this.formData.status)
      form.append('visibility', this.formData.status)
      
      //FILES
      if (this.$refs.image.files.length) {
        form.append('image', this.$refs.image.files[0])
      } //manually append file obj
      //alert(this.formData.content);
      return form
      
    }

  }
}
</script>
